import axios from 'axios'
import React from 'react'

const coinMap = {}
const coinList = []
const loadCoinData = async () => {
  const coins = await import('assets/data/coins-min.json')
  coins.data.forEach((entry) => {
    const [cmcId, name, symbol, cmcRank] = entry
    coinMap[symbol] = { cmcId, name, symbol, cmcRank }
    coinList.push({ cmcId, name, symbol, cmcRank })
  })
}

export const useCoinData = () => {
  const [updateCount, setUpdateCount] = React.useState(0)
  const initCoinData = async () => {
    await loadCoinData()
    setUpdateCount(updateCount + 1)
  }
  React.useEffect(() => {
    if (coinList.length === 0) {
      initCoinData()
    }
  }, [])
  return { coinMap, coinList }
}

const extractCoinData = (entry) => {
  const {
    id,
    name,
    symbol,
    cmcRank,
    circulatingSupply,
    selfReportedCirculatingSupply,
    totalSupply,
    maxSupply,
    quotes,
  } = entry

  const {
    price,
    volume24h,
    marketCap,
    percentChange1h,
    percentChange24h,
    percentChange7d,
    percentChange30d,
    percentChange60d,
    percentChange90d,
    fullyDilluttedMarketCap,
    marketCapByTotalSupply,
  } = quotes[0]

  return {
    cmcId: id,
    name,
    symbol,
    cmcRank,
    circulatingSupply,
    selfReportedCirculatingSupply,
    totalSupply,
    maxSupply,
    price,
    volume24h,
    marketCap,
    percentChange1h,
    percentChange24h,
    percentChange7d,
    percentChange30d,
    percentChange60d,
    percentChange90d,
    fullyDilluttedMarketCap,
    marketCapByTotalSupply,
  }
}

// unusable due to CORS restricted
export const fetchCoinMarketCapPage = async (start, limit) => {
  const BASE_URL = 'https://api.coinmarketcap.com/data-api/v3/cryptocurrency/listing'
  const ret = {}
  try {
    const response = await axios.get(
      `${BASE_URL}?start=${start}&limit=${limit}&sortBy=market_cap&sortType=desc&convert=USD&cryptoType=all&tagType=all&audited=false`,
    )
    const list = response?.data?.data?.cryptoCurrencyList
    if (Array.isArray(list)) {
      list.forEach((coin) => {
        ret[coin.symbol] = extractCoinData(coin)
      })
    }
  } catch (err) {
    console.log(err)
  }
  return ret
}

const extractMessariCoinData = (coin) => {
  const {
    symbol,
    metrics: {
      market_data: { price_usd, volume_last_24_hours, percent_change_last_24_hours },
      marketcap: {current_marketcap_usd},
      roi_data: {
        percent_change_last_1_week,
        percent_change_last_1_month,
        percent_change_last_3_months,
      },
    },
  } = coin

  return {
    price: price_usd,
    marketCap: current_marketcap_usd,
    volume24h: volume_last_24_hours,
    percentChange24h: percent_change_last_24_hours,
    percentChange7d: percent_change_last_1_week,
    percentChange30d: percent_change_last_1_month,
    percentChange90d: percent_change_last_3_months,
  }
}

export const fetchMessariCoinDataPage = async (start, limit) => {
  const page = 1 + Math.floor(start / limit)
  const URL = `https://data.messari.io/api/v2/assets?fields=symbol,metrics/market_data/price_usd,metrics/market_data/volume_last_24_hours,metrics/market_data/percent_change_usd_last_24_hours,metrics/marketcap/current_marketcap_usd,metrics/roi_data/percent_change_last_1_week,metrics/roi_data/percent_change_last_1_month,metrics/roi_data/percent_change_last_3_months&page=${page}&limit=${limit}`
  const ret = {}
  try {
    const response = await axios.get(URL)
    const list = response?.data?.data
    if (Array.isArray(list)) {
      list.forEach((coin) => {
        ret[coin.symbol] = extractMessariCoinData(coin)
      })
    }
  } catch (err) {
    console.log(err)
  }
  return ret
}
