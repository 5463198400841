import { ethers } from 'ethers'
const getSmartContract = (contractAddress, abi, signer) => {
  return new ethers.Contract(contractAddress, abi, signer)
}
const getSigner = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  return provider.getSigner()
}

const approve = (contract, addressWallet, amount) => {
  return contract.approve(addressWallet, amount)
}
const getCurrentProvider = () => {
  return new ethers.providers.Web3Provider(window.ethereum)
}
const getCurrentChainId = async () => {
  return await window.ethereum.request({ method: 'eth_chainId' });
}

const getSignerByRPC = async (rpcUrls) => {

  var result = null;
  for (let index = 0; index < rpcUrls.length; index++) {
    const url = rpcUrls[index];
    try {
      const provider = new ethers.providers.JsonRpcProvider(url);
      await provider.getNetwork();
      result = provider;
      break;
    } catch (err) {
      console.log(err)
    }
  }
  return result;
}

const requestSwitchNetwork = async (chain) =>
  new Promise(async (resolve, reject) => {
    const chainIds = ethers.utils.hexValue(Number(chain.chainID));
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIds }],
      })
      return resolve(true)
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      if (error.code === 4902) {
        const chainParam = {
          chainId: chainIds,
          chainName: chain.chainName,
          rpcUrls: chain.rpcUrls,
          nativeCurrency: chain.nativeCurrency,
          blockExplorerUrls: chain.blockExplorerUrls
        }
        try {
          const rs = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainParam],
          })

          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (chainId !== chain.chainID) {
            return reject({ messsage: 'User rejected the request switch chain' })
          }
          return resolve(true)
        } catch (error) {
          return reject(error)
        }
      }
      return reject(error)
    }
  })

export {
  getSmartContract,
  getSigner,
  getSignerByRPC,
  approve,
  getCurrentProvider,
  requestSwitchNetwork,
  getCurrentChainId
}
