import { Select } from 'antd'
import React from 'react'
import { GetInvestmentStatusName, InvestmentStatus } from 'utils/constants'

export const StatusSelect = (props) => {
  return (
    <Select defaultValue={InvestmentStatus.UNDEFINED} style={{minWidth: 200}} {...props}>
      {Object.keys(InvestmentStatus).map((key) => {
        const v = InvestmentStatus[key]
        return (
          <Select.Option key={key} value={v}>
            {GetInvestmentStatusName(v)}
          </Select.Option>
        )
      })}
    </Select>
  )
}
