import { formatNumber, getTypeVc } from 'utils/constants'

const Overview = (props) => {
  const { detailVc, socials } = props
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="nav-01"
        role="tabpanel"
        aria-labelledby="nav-01-tab"
      >
        <div className="row">
          <div className="col-12 col-sm-2">
            <div className="title_main">
              <h2>Basic Information</h2>
            </div>
            <ul className="de-title">
              <li>
                <small>Type of VC: </small>
                <b>{getTypeVc(detailVc?.TypeVentureCapital)}</b>
              </li>
              <li>
                <small>Legal Entity: </small>
                <b>{detailVc?.LegalEntity}</b>
              </li>
              <li>
                <small>Total Fund: </small>
                <b>{formatNumber(detailVc?.TotalFund)} </b>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-10">
            <div className="investment_de">
              <div className="title_main">
                <h2>Interested investment</h2>
              </div>
              <ul>
                <li>
                  <small>Field:</small>
                  {detailVc?.InterestedFielad_Industr?.split(';').map((value, key) => {
                    return <b key={key}>{value}</b>
                  })}
                </li>
                <li>
                  <small>Stage:</small>
                  {detailVc?.InterestedStage?.split(';').map((value, key) => {
                    return <b key={key}>{value}</b>
                  })}
                </li>
                <li>
                  <small>Series:</small>
                  {detailVc?.InterestedSeries?.split(';').map((value, key) => {
                    return <b key={key}>{value}</b>
                  })}
                </li>
                <li>
                  <small>Hashtag:</small>
                  {detailVc?.HashTag?.split(';').map((value, key) => {
                    return <b key={key}>{value}</b>
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="title_main">
        <h2>Social Media</h2>
      </div>
      <ul className="social">
        {socials?.map((item, index) => {
          if (item.url) {
            return (
              <li key={index}>
                <a href={item?.url} target="_blank" className="hover-icon" data-bs-toggle="tooltip" data-bs-placement="top" title={item?.url}>
                  <img src={item.icon} alt="" /> {item.name}
                </a>
              </li>
            )
          }
        })}
      </ul>
    </>
  )
}
export default Overview
