/// create component StatusProject
import React from 'react'
import { useTranslation } from 'react-i18next'
import API from 'apis/apiProject'
import { useSelector, useDispatch } from 'react-redux'
import { addItem } from 'redux/reducers/project'
import { Button, Result } from 'antd'
const StatusProject = (props) => {
  return (
    <div>
      <Result
        status="success"
        title="Your buidl has been created successfully!"
        subTitle="Please wait for us to process your buidl"
      />
    </div>
  )
}
export default StatusProject
