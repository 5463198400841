import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { userProfileSelector } from 'redux/reducers/userProfile'
/**
 * State and action to connect wallet
 * @returns
 */
export function useConnectWallet() {
  const { walletID, accessToken,currentNetwork, requestConnectWallet, requestBackendAuth } =
    useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (walletID && !accessToken && currentNetwork) {
      dispatch(authActions.authenticateWithBackend({chain:currentNetwork}))
    }
  }, [walletID])

  const onConfirmMetamask = (chain) => {
    if (!walletID) {
      dispatch(authActions.connectMetaMask({chain}))
      return
    }
    if (!accessToken) {
      dispatch(authActions.authenticateWithBackend({chain}))
    }
  }

  const onConfirmWalletConnect = () => {
    // if (!walletID) {
    //   dispatch(authActions.connectWalletConnect())
    //   return
    // }
    // if (!accessToken) {
    //   dispatch(authActions.authenticateWithBackend())
    // }
  }
  return {
    walletID,
    accessToken,
    currentNetwork,
    userProfile,
    requestConnectWallet,
    requestBackendAuth,
    onConfirmMetamask,
    onConfirmWalletConnect,
  }
}
