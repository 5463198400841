import { Modal, Button, Upload, Spin, InputNumber, Input, Col, Row } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { uploadFile } from 'apis/apistorage'
import { useEffect } from 'react'
const { Dragger } = Upload
const { TextArea } = Input
function CKUploadImage(props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const [messageError, setMessageError] = useState('')
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [originWidth, setOriginWidth] = useState(0)
  const [originHeight, setOriginHeight] = useState(0)
  useEffect(() => {
    setImageUrl('')
  }, [props.visible])
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    if (!isJpgOrPng) {
      setMessageError(t('uploadImage.imageOnly'))
    }

    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isLt2M) {
      setMessageError(t('uploadImage.imageSizeLimit', [2]))
    }
    var rs = isJpgOrPng && isLt2M
    readImage(file)
    // if (rs)

    return rs
  }

  const readImage = (file) => {
    var reader = new FileReader()

    //Read the contents of Image File.
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image()

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result

      //Validate the File Height and Width.
      image.onload = function () {
        setOriginWidth(this.width)
        setOriginHeight(this.height)
        setHeight(this.height)
        setWidth(this.width)
        return true
      }
    }
  }

  const customRequest = (evt) => {
    console.log(evt.file);
    setImageUrl('')
    var formData = new FormData()
    formData.append('file', evt.file) // myFile is the input type="file" control
    //var _url = '@Url.Action("UploadFile", "Upload")';
    // formData.append('type', "aaaaaaaaaaa");
    uploadFile(formData)
      .then(function (response) {
        //handle success
        setLoading(false)
        if (response && response.UrlFile) {
          setImageUrl(response.UrlFile)
        } else if (response && response.message) {
          setMessageError(response.message)
        } else {
          setMessageError(t('errorMessage.internalServer'))
        }
      })
      .catch(function (response) {
        setLoading(false)
        setMessageError(response.message)
      })
  }
  const propsUpload = {
    name: 'file',
    multiple: false,
    action: '',
    showUploadList: false,
    beforeUpload: beforeUpload,
    onChange: handleChange,
    customRequest: customRequest,
  }
  const onSizeChange = (isWidth, val) => {
    var newWidth = 0
    var newHeight = 0
    if (isWidth) {
      newWidth = val
      newHeight = originWidth > 0 ? (newWidth * originHeight) / originWidth : 0
      newHeight = Math.round(newHeight)
    } else {
      newHeight = val
      newWidth = originHeight > 0 ? (newHeight * originWidth) / originHeight : 0
      newWidth = Math.round(newWidth)
    }
    setWidth(newWidth)
    setHeight(newHeight)
  }
  function handleOk() {
    const txtAlt = document.getElementById('txtAlt')
    const altText = txtAlt ? txtAlt.value : ''
    var img = `<img id='img-check' alt="${altText}" src="${imageUrl}" style="width:${originWidth}px; height:${originHeight}px"  />`
    if (width > 0 && height > 0) {
      img = `<img id='img-check' alt="${altText}" src="${imageUrl}" style="width:${width}px; height:${height}px"  />`
    }
    if (props.onClosed) props.onClosed(img)
  }
  const handleCancel = () => {
    if (props.onClosed) props.onClosed('')
  }
  return (
    <Modal
      width="800px"
      title={t('uploadImage.title')}
      onCancel={handleCancel}
      visible={props.visible}
      footer={[
        <button key="submit" disabled={!imageUrl} className="btn-submit" onClick={handleOk}>
          {t('button.ok')}
        </button>,
        <button key="back" type="button" className="btn-cancel" danger onClick={handleCancel}>
          {t('button.close')}
        </button>,
      ]}
    >
      <>
        <span className="red-color">{messageError}</span>
        <Dragger {...propsUpload}>
          {imageUrl ? (
            <img src={imageUrl} style={{ width: '100%' }} alt="image" />
          ) : (
            <Spin spinning={loading}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('uploadImage.uploadText')}</p>
              <p className="ant-upload-hint">{t('uploadImage.uploadHint')}</p>
            </Spin>
          )}
        </Dragger>
        <Row>
          <Col span={12}>
            <Row style={{ padding: '10px 0px' }}>
              <Col span={6}>{t('uploadImage.width')}:</Col>
              <Col span={18}>
                <InputNumber
                  value={width}
                  onChange={(val) => onSizeChange(true, val)}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>{t('uploadImage.height')}:</Col>
              <Col span={18}>
                <InputNumber
                  value={height}
                  onChange={(val) => onSizeChange(false, val)}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row style={{ padding: '10px 0px 0px 10px' }}>
              <Col span={8}>{t('uploadImage.altText')}:</Col>
              <Col span={16}>
                <TextArea id="txtAlt" rows={3} />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Modal>
  )
}
export default CKUploadImage
