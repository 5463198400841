import { Form, Input, InputNumber, message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import API from 'apis/apiProject'
import { useSelector,useDispatch } from 'react-redux'
import { createProject } from 'smartcontract/projectContract'
import { ConfirmCreateProjectModal } from 'components/common/ConfirmModal'
import { getOrdinalSuffix } from 'utils/formatting'
import { authSelector } from 'redux/reducers/authSlice'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
function Description(props) {
  const dispatch = useDispatch();
  const { setCurrent, current, setBackStep } = props
  const [loading, setLoading] = useState(true)
  const step05Content = useSelector((state) => state.myProject.step05)
  const step1Content = useSelector((state) => state.myProject.step01)
  const projectId = useSelector((state) => state.myProject.projectId)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [body, setBody] = useState('')
  const [confirmSubmitVisible, setconfirmSubmitVisible] = useState(false)
  const { currentNetwork } = useSelector(authSelector);

  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }

  const onFinish = (values) => {
    if (checkChangeBeforeSubmit(step1Content.Chain)) {
      const body = {
        projectID: projectId,
        information: values.listInvestmentRound,
      }
      setBody(body)
      setconfirmSubmitVisible(true)
    }
    else {
      dispatch(updateSwitchChainId({
        chainId: step1Content.Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
    }
  }

  const createProjectStep5 = async (body) => {
    setLoading(true)
    try {
      await createProject(projectId)
      const res = await API.createProjectStep5(body)
      if (res?.ResultCode != 200) {
        message.error(res.Message)
        setLoading(false)
      } else {
        setCurrent(current + 1)
        body.currentStep = current
        body.projectId = res.Data
        setLoading(false)
      }
    } catch (err) {
      message.error(err.message)
    }
  }
  useEffect(() => {
    if (step05Content) {
      form.setFieldsValue({
        listInvestmentRound: step05Content?.Information,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [step05Content])

  const cancelSubmit = () => {
    setconfirmSubmitVisible(false)
  }
  return (
    <Spin spinning={loading}>
      <>
        <Form form={form} layout="vertical" name="time_related_controls" onFinish={onFinish}>
          <div className="form-main">
            <div className="bg-step bg-d">
              <div className="row mb-5">
                <div className="col-12 col-sm-5">
                  <div className="title-show">
                    <h5>Create information of investment round</h5>
                    <hr />
                    <p>
                      * This is section for startup owners to introduce and post public information
                      about upcoming sales events. <br />
                      * Startups need to fill in detailed information about the names of the opening
                      orders, selling prices, vesting information, clifts to bring maximum
                      effectiveness in attracting investors. <br />* Feature related to token
                      deposit, smart contract will be installed in another section.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-7"></div>
              </div>
              <Form.List name="listInvestmentRound" initialValue={[{}]}>
                {(listInvestmentRound, { add, remove }) => (
                  <>
                    {listInvestmentRound.map((field, index) => (
                      <div key={field.key}>
                        <h3 className="step05">{index + 1}{getOrdinalSuffix(index + 1)} round</h3>
                        <div className="box-layout">
                          <div className="row">
                            <div className="col-12 col-sm-3">
                              <div className="form-group">
                                <Form.Item
                                  name={[index, 'NameOfRound']}
                                  label={t('myProject.roundName')}
                                // rules={[
                                //   { required: true, message: `${t('myProject.inputRequired')}` },
                                // ]}
                                >
                                  <Input placeholder="Ex: Private round" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-sm-3">
                              <div className="form-group">
                                <Form.Item
                                  name={[index, 'Price']}
                                  label={t('myProject.price') + ' ' + '($)'}
                                  rules={[
                                    {
                                      type: 'number',
                                      min: 0,
                                      // required: true,
                                      message: `${t('myProject.inputRequired')}`,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="0,1$"
                                    style={{
                                      width: 'calc(100%)',
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-sm-3">
                              <div className="form-group">
                                <Form.Item
                                  name={[index, 'Fundraised']}
                                  label={t('myProject.fundraised') + ' ' + '($)'}
                                  rules={[
                                    {
                                      type: 'number',
                                      min: 0,
                                      // required: true,
                                      message: `${t('myProject.inputRequired')}`,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Ex: 1,000,000$"
                                    style={{
                                      width: 'calc(100%)',
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-sm-3">
                              <div className="form-group">
                                <Form.Item
                                  name={[index, 'LockUp']}
                                  label="Lock-up"
                                // rules={[
                                //   { required: true, message: `${t('myProject.inputRequired')}` },
                                // ]}
                                >
                                  <Input
                                    placeholder="Ex: Cliff 1 month, vesting 2 months"
                                    style={{
                                      width: 'calc(100%)',
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <button type="button" className="close-x" onClick={() => remove(field.name)}>
                            &#10005;
                          </button>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn-submit" onClick={() => add()}>
                      <img src={require('assets/images/icon-add.png').default} alt="" /> Add Round
                    </button>
                  </>
                )}
              </Form.List>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => {
                  setBackStep(true)
                  setCurrent(current - 1)
                }}
              >
                {t('button.back')}
              </button>
              <button type="primary" htmlType="submit" className="btn-submit">
                {t('button.saveAndSubmitForReview')}
              </button>
            </div>
          </div>
        </Form>
        <ConfirmCreateProjectModal
          setVisible={setconfirmSubmitVisible}
          visible={confirmSubmitVisible}
          createProjectStep5={createProjectStep5}
          body={body}
          setCurrent={setCurrent}
          current={current}
          onCancel={cancelSubmit}
          text="When you submit, your project will be sent to DStarter’s admin to audit.
All data can not be edited during  the  audit.
Please make sure your data  is correct before submiting."
        />
      </>
    </Spin>
  )
}
export default Description
