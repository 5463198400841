import { Button, Col, message, Modal, Result, Row, Spin } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { useTranslation } from 'react-i18next'
import { useGetUserProfile } from 'components/hooks/useGetUserProfile'
import * as tsprojectContract from 'smartcontract/projectContract'
import { selectUserRole } from 'apis/apiprofiles'
import { useConnectWallet } from 'components/hooks/useConnectWallet'
import { KycModal, SelectRoleModal, VerifyEmailModal } from './RequiredModal'
import { chainSelector } from 'redux/reducers/networkSlice'
/**
 *
 * @param props {requireWallet, requireAccessToken, requireEmail, requireUserRole, requireKYC, onlyStartup, onlyInvestor}
 * @returns
 */
export const AuthRequirementModal = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { onConfirmMetamask, onConfirmWalletConnect } = useConnectWallet()
  const network = useSelector(chainSelector)
  const [selectChain, setSelectChain] = React.useState('');

  const {
    requireWallet,
    requireAccessToken,
    requireEmail,
    requireUserRole,
    requireKYC,
    onlyStartup,
    onlyInvestor,
  } = props
  const { accessToken, walletID, selectUserRoleInProgress,currentNetwork } = useSelector(authSelector)
  const { inProgress: userProfileInProgress, userProfile } = useGetUserProfile()

  const [requireWalletVisible, setRequireWalletVisible] = React.useState(false)
  const [requireAccessTokenVisible, setRequireAccessTokenVisible] = React.useState(false)
  const [requireVerifyEmailVisible, setRequireVerifyEmailVisible] = React.useState(false)
  const [requireUserRoleVisible, setRequireUserRoleVisible] = React.useState(false)
  const [requireKYCVisible, setRequireKYCVisible] = React.useState(false)
  const [requireStartupVisible, setRequireStartupVisible] = React.useState(false)
  const [requireVcVisible, setRequireVcVisible] = React.useState(false)
  const [connectStep, setConnectStep] = React.useState(0)

  React.useEffect(() => {
    setRequireWalletVisible(
      (requireWallet || requireAccessToken || requireEmail || requireUserRole) && !walletID,
    )
  }, [requireWallet, walletID])

  React.useEffect(() => {
    const showModal = (requireAccessToken || requireEmail || requireUserRole) && !accessToken
    if (showModal != requireAccessTokenVisible) {
      setRequireAccessTokenVisible(showModal)
    }
  }, [requireAccessToken, requireEmail, requireUserRole, accessToken])

  React.useEffect(() => {
    const showModal =
      (requireEmail || requireUserRole) &&
      !userProfileInProgress &&
      (!accessToken || !userProfile?.Email || !userProfile?.ValidateEmail)

    if (showModal != requireVerifyEmailVisible) {
      setRequireVerifyEmailVisible(showModal)
    }
  }, [requireEmail, requireUserRole, accessToken, userProfile, userProfileInProgress])

  React.useEffect(() => {
    const showModal =
      requireKYC && !userProfileInProgress && (!accessToken || !userProfile?.ValidateKYC)

    if (showModal != requireKYCVisible) {
      setRequireKYCVisible(showModal)
    }
  }, [requireKYC, accessToken, userProfile, userProfileInProgress, userProfile.ValidateKYC])

  React.useEffect(() => {
    const showModal =
      requireUserRole &&
      !selectUserRoleInProgress &&
      !userProfileInProgress &&
      !userProfile.RoleVC &&
      !userProfile.RoleStartup
    if (showModal != requireUserRoleVisible) {
      setRequireUserRoleVisible(showModal)
    }
  }, [
    requireUserRole,
    userProfile,
    selectUserRoleInProgress,
    userProfile.RoleVC,
    userProfileInProgress,
  ])

  React.useEffect(() => {
    const showModal = onlyStartup && !userProfileInProgress && !userProfile?.RoleStartup
    if (showModal != requireStartupVisible) {
      setRequireStartupVisible(showModal)
    }
  })

  React.useEffect(() => {
    const showModal = onlyInvestor && !userProfileInProgress && !userProfile?.RoleVC
    if (showModal != requireVcVisible) {
      setRequireVcVisible(showModal)
    }
  })
  const chainChanged = (evt) => {
    setSelectChain(evt.currentTarget.value);
  }
  const submitChain = async () => {
    const itemChain = document.querySelector('input[name=chooseChain][checked]');
    const chainId = selectChain ? selectChain : itemChain?.value;
    let chain = network?.listChain.find((item) => item.chainID === chainId)
    onConfirmMetamask(chain)
  }
  const navigateBack = () => {
    navigate(-1)
  }
  const navigateHome = () => {
    navigate('/')
  }
  const signAuthenticationMessage = () => {
    dispatch(authActions.authenticateWithBackend({chain:currentNetwork}))
  }

  const comSelectWallet = () => {
    return <><div className="group_connect">
      <a href="#" onClick={()=>setConnectStep(1)}>
        <img src="/images/wallet-metamask.png" alt="" />
        Metamask
      </a>
      <a href="#" onClick={onConfirmWalletConnect}>
        <img src="/images/walletconnect.png" alt="" />
        WalletConnect
      </a>
    </div></>
  }

  const comSelectChain = () => {
    if (!network || !network.listChain)
      return null;

    return <><div className="group_connect">
      {network.listChain.map((item, index) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="chooseChain"
              id={item.id}
              onChange={chainChanged}
              value={item.chainID}
              defaultChecked={index === 0 ? true : false}
            />
            <label className="form-check-label" for="chain-okex">
              <img src={item.icon} alt="" /> {item.chainName}
            </label>
          </div>
        )
      })}
    </div>
      <div className="modal-footer d-flex justify-content-center align-items-center ">
        <button type="button" className="btn btn-cancel" onClick={() =>{
          setConnectStep(0)
        } }>
          Cancel
        </button>
        <button type="button" className="btn btn-submit" onClick={() => submitChain()}>
          Confirm
        </button>
      </div>
    </>
  }

  if (requireWalletVisible) {
    return (
      <Modal
        width={450}
        style={{ padding: 0 }}
        bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
        closable={false}
        footer={
          <Button type="link" onClick={navigateBack}>
            Go Back
          </Button>
        }
        visible
        maskClosable={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Connect Wallet</h5>
        </div>
        <div className="modal-body">
          
          {
            connectStep === 0 ? comSelectWallet() : comSelectChain()
          }
        </div>
      </Modal>
    )
  }

  if (requireAccessTokenVisible) {
    return (
      <Modal
        style={{ padding: 0 }}
        bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
        closable={false}
        footer={
          <Button type="link" onClick={navigateBack}>
            Go Back
          </Button>
        }
        visible
        maskClosable={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Web3 Signing Authentication</h5>
        </div>
        <Row justify="center">
          <Button onClick={signAuthenticationMessage}>
            Click to start signing
          </Button>
        </Row>
      </Modal>
    )
  }

  if (requireVerifyEmailVisible) {
    return (
      <VerifyEmailModal
        visible
        footer={
          <Button type="link" onClick={navigateBack}>
            Go Back
          </Button>
        }
      />
    )
  }

  if (requireKYCVisible) {
    return <KycModal visible />
  }

  if (requireUserRoleVisible) {
    return <SelectRoleModal visible userProfile footer={null} />
  }

  if (requireStartupVisible) {
    return (
      <Modal
        style={{ padding: 0 }}
        bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
        closable={false}
        footer={
          <Button type="link" onClick={navigateHome}>
            Back to Home
          </Button>
        }
        visible
        maskClosable={false}
      >
        <Result title="This section is only for Startup account" />
      </Modal>
    )
  }

  if (requireVcVisible) {
    return (
      <Modal
        style={{ padding: 0 }}
        bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
        closable={false}
        footer={
          <Button type="link" onClick={navigateHome}>
            Back to Home
          </Button>
        }
        visible
        maskClosable={false}
      >
        <Result title="This section is only for Investor account" />
      </Modal>
    )
  }
}
