import { ethers } from 'ethers'
import { getSigner,getSignerByRPC, getSmartContract } from 'smartcontract/common'
import saleConfig from 'smartcontract/abi/TSSaleFactory.json'
import { getContractByCode,getChainById } from 'utils/common'
import {SmartContractCode} from 'utils/constants';

const getTSSaleContract= async (chainId)=> {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  const CONTRACT_TSSALE_ADDRESS = getContractByCode(SmartContractCode.SALE_CONTRACT,chainId)
  return getSmartContract(CONTRACT_TSSALE_ADDRESS, saleConfig.abi,signer??getSigner())
}


export const claimToken = async (smartSaleId, receiveAddress) => {
  try {
    const contract = await getTSSaleContract()
    return await contract.claimToken(smartSaleId, receiveAddress)
  } catch (err) {
    console.log(err)
  }
  return null
}

export const createSale = async (poolId, objPost) => {
  const saleContract = await getTSSaleContract()
  const rs = await saleContract.createSale(ethers.utils.formatBytes32String(poolId), objPost)
  const rsSmart = rs.wait()
  return rsSmart
}

export const txCallTSSaleContract = async (name, ...args) => {
  console.log(`[txCallTSSaleContract][${name}]`, args)
  const contract = await getTSSaleContract()
  const txResponse = await contract[name](...args)
  const txReceipt = await txResponse.wait()
  if (txReceipt.status == 1) {
    console.log(`tx ${txResponse.hash} confirmed at block #${txReceipt.blockNumber}`)
  } else {
    console.log('Failed', txReceipt)
  }
}


export const addWhiteList = async (saleIndex, vc, amount) => {
  let amount_wei = ethers.utils.parseEther(amount + '')
  return txCallTSSaleContract('addWhiteList', saleIndex, vc, amount_wei)
}

/**
 * Invest approved token amount
 * @param {*} saleIndex
 * @param {*} amount Token amount, not currency amount
 * @returns
 */
export const invest = (saleIndex, amount) => {
  return txCallTSSaleContract('invest', saleIndex, amount)
}


export const doClaimToken = (smartSaleId, account) => {
  return txCallTSSaleContract('claimToken', smartSaleId, account)
}
