import { getSigner,getSignerByRPC, getSmartContract } from 'smartcontract/common'
import utilsConfig from 'smartcontract/abi/TSUtils.json'
import { BigNumber, ethers } from 'ethers'
import { getContractByCode,getChainById } from 'utils/common'
import { SmartContractCode } from 'utils/constants';



const getUtilsContract = async (chainId) => {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  const addressUtils = getContractByCode(SmartContractCode.UTILS_CONTRACT,chainId)
  return getSmartContract(addressUtils, utilsConfig.abi, signer??getSigner())
}
export const getClaimTokenInfo = async (saleId, receiveAddress,chainId) => {
  const addressSale = getContractByCode(SmartContractCode.SALE_CONTRACT,chainId)
  const addressProject = getContractByCode(SmartContractCode.TSPROJECT_CONTRACT,chainId)
  try {
    const contract = await getUtilsContract(chainId)
    const { tokenRemaining, tokenClaimed, fee } = await contract.getClaimTokenInfo(
      addressProject,
      addressSale,
      saleId,
      receiveAddress,
    )
    return { tokenRemaining, tokenClaimed, fee }
  } catch (err) {
    console.log(err)
  }
  return {
    tokenRemaining: 0,
    tokenClaimed: 0,
    fee: 0,
  }
}
export const getBlockVoteInfo = async (
  governorAddress,
  vestingAddress,
  proposalId,
  receiveAddress,
  chainId
) => {
  try {
    const contract = await getUtilsContract(chainId)
    const rs = await contract.getBlockVoteInfo(
      governorAddress,
      vestingAddress,
      ethers.BigNumber.from(proposalId),
      receiveAddress,
    )
    return rs
  } catch (err) {
    console.log(err)
  }
  return {
    blockRemainingRegister: BigNumber.from(0),
    blockRemainingVote: BigNumber.from(0),
    amountBuy: BigNumber.from(0),
    total: BigNumber.from(0),
    totalSell: BigNumber.from(0),
  }
}
export const getTokenLockInfo = async (vestingAddress, account, timestamp,chainId) => {
  try {
    const contract = await getUtilsContract(chainId)
    const rs = await contract.getTokenLockInfo(vestingAddress, account, timestamp)
    return rs
  } catch (err) {
    console.log(err)
  }
  return null
}
