import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelector } from 'redux/reducers/authSlice'

export const ConnectWalletButton = () => {
  const dispatch = useDispatch()
  const { walletID } = useSelector(authSelector)

  function ellipsisAddress(walletId) {
    return walletId
      ? walletId.substring(0, 5) + '...' + walletId.substring(walletId.length - 5, walletId.length)
      : ''
  }

  const onClickButton = () => {
    if (!walletID) {
      dispatch(authActions.requestConnectWallet())
    } else {
      dispatch(authActions.globalLogout())
    }
  }

  return (
    <>
      {!walletID ? (
        <a className="nav-link" onClick={onClickButton}>
          <img src="/images/wallet.svg" alt="" />
          Connect Wallet
        </a>
      ) : (
        <a className="nav-link" onClick={onClickButton}>
          {ellipsisAddress(walletID)}
        </a>
      )}
    </>
  )
}

export const ConnectAccountButton = () => {
  const dispatch = useDispatch()
  const { accessToken, walletID } = useSelector(authSelector)

  function ellipsisAddress(walletId) {
    return walletId
      ? walletId.substring(0, 5) + '...' + walletId.substring(walletId.length - 5, walletId.length)
      : ''
  }

  const onClickButton = () => {
    if (!walletID) {
      dispatch(authActions.requestConnectWallet())
    } else {
      dispatch(authActions.globalLogout())
    }
  }

  return (
    <>
      {!accessToken ? (
        <a className="nav-link" onClick={onClickButton}>
          <img src="/images/wallet.svg" alt="" />
          Connect Wallet
        </a>
      ) : (
        <a className="nav-link" onClick={onClickButton}>
          {ellipsisAddress(walletID)}
        </a>
      )}
    </>
  )
}
