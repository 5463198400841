import Pools from 'components/for-startup/pool/pool-list/Pools'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { debounce } from 'utils/common'
export default function MyPool(props) {
  const navigate = useNavigate()
  const [totalCount, setTotalCount] = useState(0)
  const [param, setParam] = useState({poolName:''})
  const handleChangeSearch = debounce((value) => {
    const newParam ={...param};
    newParam.poolName = value.target.value;
    setParam(newParam);
  })
  return (
    <>
      <div>
        <div className="title_main row">
          <div className="col-12 col-sm-12">
            <div className="group_tool j-right d-flex col-7">
              <div className="create-new" onClick={() => navigate('/dashboard/pools/create-pool')}>
                <img src={require('assets/images/icon-create.png').default} alt="" /> Create a new
                pool
              </div>
              <div className="search search-config col-5">
                <input
                  onChange={handleChangeSearch}
                  type="text"
                  placeholder="Search pool here..."
                />
                <img src={require('assets/images/icon-search.png').default} alt="" />
                <div className="total-search">
                  Total: <span className="violent">{totalCount}</span> Pools
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12">
          <div className="row list-large">
            <div>
              <div
                id="listPool"
                className="scroll-infinity"
              >
                <Pools param={param} setTotalCount={setTotalCount} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
