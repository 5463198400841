import axios from 'axios'
import {
  dStarterApiDataExtractor,
  dStarterApiErrorHandler,
  setupAxiosInterceptor,
} from 'utils/common'

const defaultHeaders = {
  'Content-Type': 'application/json',
}

/**
 * Create axios instance for Profiles service
 */
export const storageApi = setupAxiosInterceptor(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/storage',
    timeout: 30000,
    headers: defaultHeaders,
  }),
)

export const uploadFile = (param) => {
  return storageApi
    .post('/Upload/auth/UploadFiles', param, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}
