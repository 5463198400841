import {
  Col,
  Descriptions,
  Divider,
  Input,
  PageHeader,
  Row,
  Spin,
  Typography,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import React from 'react'
import { InvestorTable } from './InvestorTable'
import { usePoolInvestorManagementData } from './usePoolInvestorData'
import { StatusSelect } from 'components/common/pool/StatusSelect'
import { useNavigate } from 'react-router-dom'
import { ProgressBar } from 'components/common/ProgressBar'
import { getCurrencyById } from 'utils/common'

export const PoolInvestorManagement = (props) => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  const { poolID } = props
  const { isLoading, poolInvestors, filter, setFilter, search, setSearch, poolInfo = {} } =
    usePoolInvestorManagementData(poolID)

  const { TotalFundRaise, TotalMoneyRaise, RateRaise } = poolInfo || {}

  return (
    <>
      <PageHeader
        onBack={goBack}
        title="Manage Pool Investor"
        subTitle={`${poolInfo?.NameInvestment}`}
      />
      <ProgressBar target={TotalFundRaise} raised={TotalMoneyRaise} rate={RateRaise} />
      <Row justify="space-between">
        <Typography.Text>0 {getCurrencyById(poolInfo.Currency)}</Typography.Text>
        <Typography.Text>{TotalFundRaise} {getCurrencyById(poolInfo.Currency)}</Typography.Text>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col>
          <Typography.Title level={5}>Total: {poolInvestors.length} VCs</Typography.Title>
        </Col>
        <Col>
          <StatusSelect value={filter} onChange={setFilter} />
          <Input
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 300 }}
            addonAfter={<SearchOutlined />}
          /></Col>
      </Row>
      <Descriptions>
        <DescriptionsItem label=""></DescriptionsItem>
      </Descriptions>
      <Spin spinning={isLoading}>
        <InvestorTable data={poolInvestors} poolInfo={poolInfo} />
      </Spin>
    </>
  )
}
