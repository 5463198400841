import axios from 'axios'
import {
  dStarterApiDataExtractor,
  dStarterApiErrorHandler,
  setupAxiosInterceptor,
} from 'utils/common'

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const API_URL = process.env.REACT_APP_API_URL

/**
 * Create axios instance for Profiles service
 */
export const profilesApi = setupAxiosInterceptor(
  axios.create({
    baseURL: `${API_URL}/profiles`,
    timeout: 30000,
    headers: defaultHeaders,
  }),
)
/**
 *
 * @param {*} walletID
 * @returns responseData {walletID, EKYC: {}, Information: {ID, WalletID, Avatar, FirstName,
 * LastName, DateOfBirth, Nationality, Country, Email, Phone, ValidateEmail, ValidatePhone,
 * ValidateKYC, Status, IsDelete, RoleVC, RoleStartup}}
 */
export const getProfileByWalletIDApi = async (walletID) => {
  return profilesApi
    .get(`/UserProfile/auth/GetInfo/${walletID}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 *
 * @param {walletID, information: {avatar, firstName, lastName, dateOfBirth, nationality,
 * country, email, phone}} param
 * @param {*} authToken
 * @returns
 */
export const createProfileApi = (param) => {
  return profilesApi
    .post('/UserProfile/auth/Create', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 *
 * @param param {walletID,information: {avatar,fundName,introduction,investmentType,investmentRound,website,linkedin,investmentSector,email,numberEmployees}}
 * @returns
 */
export const createInvestorProfile = (param) => {
  return profilesApi
    .post('/UserProfile/auth/Investor/Create', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Get investor profile
 * @param {*} walletID
 * @returns
 */
export const getInvestorProfile = (walletID) => {
  return profilesApi
    .get(`/UserProfile/auth/Investor/GetInfo/${walletID}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 *
 * @param {*} param
 * @param {*} authToken
 * @returns responseData {firstName, lastName, dateOfBirth, nationality, country, stage, city, street, postalCode, email, phone}
 */
export const getKYCProfileApi = (param) => {
  return profilesApi
    .post('/EKYC/auth/GetInfo/{walletID}', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Start veryfing with provided email
 * @param {email} param
 * @returns
 */
export const startVerifyingEmail = (param) => {
  return profilesApi
    .post(`/UserProfile/auth/ActivatedMail?email=${param.email}`, param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}
/**
 * Resend verification code
 * @returns
 */
export const resendVerificationCode = (email) => {
  return profilesApi
    .post('/UserProfile/auth/ReSendActivatedMail', { email })
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Confirm verification code received from email
 * @param {walletID, email, code} param
 * @returns
 */
export const confirmVerificationCode = (param) => {
  return profilesApi
    .post(`/UserProfile/auth/ReActivatedMail?code=${param.code}`, param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Select user role between Investor and Startup
 * @param {*} param {roleVC, roleStartup} must be different true/false values
 * @returns
 */
export const selectUserRole = (isInvestor) => {
  const param = { roleVC: !!isInvestor, roleStartup: !isInvestor }
  return profilesApi
    .post(`/UserProfile/auth/Role`, param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}
