import { useState } from 'react'
import 'assets/css/pool-public.css'
import API from 'apis/apiProject'
import PoolItem from './index'
import { LoadMoreList } from 'pages/project-public/LoadMoreList'
import { List } from 'antd'

const PoolListWrapper = (props) => {
  const { param, setTotalCount } = props
  const [currentlyNoPool, setCurrentlyNoPool] = useState(false)

  const loadPageData = (page, size, param) => {
    let body = {
      nameInvestment: param.poolName,
      status: param.poolStatus,
      isSmartID: true,
      pageIndex: page,
      pageSize: size,
    }
    return API.getListPoolPub(body)
  }

  if (currentlyNoPool) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src="/images/updating@2x.png" style={{ maxWidth: 600, maxHeight: 300 }} />
        <span style={{ color: '#74ABFF', fontSize: '36px' }}>
          There haven't been any pools in our system
        </span>
      </div>
    )
  }

  return (
    <LoadMoreList
      loadPageData={loadPageData}
      dataKey="PoolID"
      param={param}
      setTotalCount={setTotalCount}
      pageSize={12}
      grid={{
        column: 4,
        gutter: [20, 0],
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
      }}
      renderItem={(item, idx) => {
        return (
          <List.Item>
            <PoolItem item={item} />
          </List.Item>
        )
      }}
    />
  )
}
export default PoolListWrapper
