import {
  Button,
  Col,
  Form,
  Image,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import * as saleContract from 'smartcontract/saleContract'
import { updateStatus } from 'apis/apiPoolInvestor'
import { formartDateTimeFromUnix, getIconChain } from 'utils/common'
import { InvestmentWhiteListStatus } from 'utils/constants'
import { DStarterModal } from 'components/common/DStarterModal'
import { ConfirmModal } from 'components/common/ConfirmModal'
import {useSelector,useDispatch} from 'react-redux'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
import { authSelector } from 'redux/reducers/authSlice'
// poolInfo.AmountOfTokenSell
// AddWhitelist_Amount

export const InvestorTable = (props) => {
  const {
    APPLIED_WHITELIST,
    WIN_WHITELIST,
    NOT_WIN_WHITELIST,
    POOL_INVESTOR_Whitelist_WaitConfirm,
  } = InvestmentWhiteListStatus
  const [form] = Form.useForm()
  const { data = [], poolInfo = {} } = props
  const [dataSource, setDataSource] = useState(props.data || [])
  const [maxAmount, setMaxAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [declineRowIndex, setDeclineRowIndex] = React.useState(-1)
  const [acceptRowIndex, setAcceptRowIndex] = React.useState(-1)
  const { currentNetwork } = useSelector(authSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    setDataSource(data)
    let max = poolInfo.AmountOfTokenSell
    data.forEach((item) => {
      if (typeof item.AddWhiteList_Amount === 'number') {
        max -= item.AddWhiteList_Amount
      }
    })
    setMaxAmount(max)
  }, [poolInfo, data])

  const onConfirmAcceptInvestor = async (row, amount, rowIndex) => {
    try {
      setLoading(true)
      await saleContract.addWhiteList(poolInfo.PoolSmartID, row.WalletAddress, amount)
      // const { OK, Message } = await updateStatus({
      //   code: row.CODE,
      //   AddWhiteList_Status: POOL_INVESTOR_Whitelist_WaitConfirm,
      //   Deposit_Status: 0,
      // })
      // if (!OK) {
      //   console.log('updateStatus', Message)
      // }
      const newData = [...dataSource]
      newData[rowIndex].AddWhiteList_Status = POOL_INVESTOR_Whitelist_WaitConfirm
      setDataSource(newData)
      setMaxAmount((oldVal) => oldVal - amount)
      setAcceptRowIndex(-1)
    } catch (err) {
      if (err.reason) {
        message.error(err.reason)
      } else {
        message.error('Failed: ' + err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const onConfirmRejectInvestor = async (rowIndex) => {
    try {
      if (rowIndex < 0 || rowIndex >= dataSource.length) {
        return
      }
      const { OK, Message } = await updateStatus({
        code: dataSource[rowIndex].CODE,
        AddWhiteList_Status: NOT_WIN_WHITELIST,
        Deposit_Status: 0,
      })
      if (OK) {
        const newData = [...dataSource]
        newData[rowIndex].AddWhiteList_Status = NOT_WIN_WHITELIST
        setDataSource(newData)
      } else {
        console.log('UpdateStatus', Message)
      }
    } finally {
      setDeclineRowIndex(-1)
    }
  }

  const onDeclineClick = (row, rowIndex) => {
    setDeclineRowIndex(rowIndex)
  }

  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }
  const onAcceptClick = (row, rowIndex) => {
    if (!checkChangeBeforeSubmit(row.Chain)) {
      dispatch(updateSwitchChainId({
        chainId: row.Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
      return;
    }
    setAcceptRowIndex(rowIndex)
  }

  const renderDeclineModal = () => {
    return (
      <ConfirmModal
        visible={declineRowIndex >= 0}
        message="Are your sure to decline this investor?"
        icon={<InfoCircleOutlined style={{ fontSize: 32 }} />}
        onCancel={() => setDeclineRowIndex(-1)}
        onOk={() => onConfirmRejectInvestor(declineRowIndex)}
      />
    )
  }

  const renderAcceptModal = () => {
    if (acceptRowIndex < 0) {
      return null
    }
    const row = dataSource[acceptRowIndex]
    return (
      <DStarterModal
        visible
        onCancel={() => setAcceptRowIndex(-1)}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Row justify="center" style={{ padding: 16 }}>
          <Typography.Text style={{ fontSize: 22 }}>Accepting a Venture Capital</Typography.Text>
        </Row>
        <Spin spinning={loading}>
          <Form
            initialValues={{ amount: 0 }}
            layout="vertical"
            form={form}
            onFinish={(values) => {
              onConfirmAcceptInvestor(row, values.amount, acceptRowIndex)
            }}
          >
            <Row gutter={16}>
              <Col span={16}>
                <Typography.Paragraph>
                  You need to set amount of token the VC can buy
                </Typography.Paragraph>
                <Form.Item name="amount" label="Amount of token" rules={[{ required: true }]}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <img src={poolInfo.PoolAvatar} style={{ maxWidth: '100%', maxHeight: 150, borderRadius: '1em' }} />
              </Col>
            </Row>
            <Row>
              <Typography.Paragraph>
                You have maximum of {maxAmount} token to sell
              </Typography.Paragraph>
            </Row>
            <Row justify="center">
              <Space size="large">
                <Button htmlType="submit">Accept</Button>
                <Button onClick={() => setAcceptRowIndex(-1)}>Cancel</Button>
              </Space>
            </Row>
          </Form>
        </Spin>
      </DStarterModal>
    )
  }

  const columns = [
    {
      title: 'Investor',
      dataIndex: 'NameVentureCapital',
      key: 'NameVC',
    },
    {
      title: 'Chain',
      dataIndex: 'Chain',
      key: 'Chain',
      render: (_,record) => {
        return <img style={{ width: 30 }} src={getIconChain(record.Chain)} />
      },
    },
    {
      title: 'Address',
      dataIndex: 'WalletAddress',
      key: 'WalletAddress',
      render: (text) => {
        return <Typography.Text copyable>{text}</Typography.Text>
      },
    },
    {
      title: 'Registered Time',
      dataIndex: 'RegisteredTime',
      key: 'RegisteredTime',
      render: (val) => formartDateTimeFromUnix(val),
    },
    {
      title: 'Invested',
      dataIndex: 'Deposit_Amount',
      key: 'Deposit_Amount',
    },
    {
      title: 'Status',
      dataIndex: 'AddWhiteList_Status',
      key: 'AddWhiteList_Status',
      render: (val) => {
        var result = ''
        switch (val) {
          case APPLIED_WHITELIST:
            result = 'APPLIED_WHITELIST'
            break
          case WIN_WHITELIST:
            result = 'WIN_WHITELIST'
            break
          case NOT_WIN_WHITELIST:
            result = 'NOT_WIN_WHITELIST'
            break
          case POOL_INVESTOR_Whitelist_WaitConfirm:
            result = 'WaitConfirm'
            break
        }
        return result
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, rowIndex) =>
        record.AddWhiteList_Status == APPLIED_WHITELIST ? (
          <Space size="middle">
            <Button
              type="link"
              key="accept"
              disabled={!(typeof poolInfo.PoolSmartID === 'number')}
              onClick={() => onAcceptClick(record, rowIndex)}
            >
              Accept
            </Button>
            <Button type="link" key="decline" onClick={() => onDeclineClick(record, rowIndex)}>
              Decline
            </Button>
          </Space>
        ) : null,
    },
  ]

  return (
    <>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      {renderAcceptModal()}
      {renderDeclineModal()}
    </>
  )
}
