import axios from 'axios'
import store from 'redux/stores'
const customAxios = axios.create()

customAxios.interceptors.request.use(
  async (config) => {
    const accessToken = store.getState().auth.accessToken
    if (accessToken) {
      config.headers['Authorization'] = accessToken
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

customAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (axios.isCancel(error)) {
    }
    const response = {
      code: 0,
      data: error?.response?.data,
    }

    throw response
  },
)
export { customAxios }
