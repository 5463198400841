import { getCurrenciesPub } from 'apis/apipool'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sharedActions, sharedSelector } from 'redux/reducers/sharedSlice'

/**
 * Get supported currencies from userver
 */
export function useGetSupportedCurrencies() {
  const dispatch = useDispatch()
  let { currencies } = useSelector(sharedSelector)
  React.useEffect(() => {
    if (currencies.length == 0) {
      getCurrenciesPub()
        .then((resp) => {
          if (Array.isArray(resp.data?.Data)) {
            dispatch(sharedActions.updateCurrencies(resp.data?.Data))
          }
        })
        .catch()
    }
  }, [])
  const getCurrencyName = (code) => {
    const nameCurrencies = currencies.find((item) => {
      return item.CODE === code
    })
    return nameCurrencies?.NAME
  }
  return { currencies, getCurrencyName }
}
