import React from 'react'
import { Button, Modal, Result, Row, Spin, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { KYCButton } from 'components/profile/KYCButton'
import { selectUserRole } from 'apis/apiprofiles'
import * as tsprojectContract from 'smartcontract/projectContract'
import { authSelector } from 'redux/reducers/authSlice'
import { useSelector } from 'react-redux'
import API_Chain from 'apis/apichain'

export const KycModal = (props) => {
  const { style, bodyStyle, closable, maskClosable, ...restProps } = props
  const navigate = useNavigate()
  const navigateBack = () => {
    navigate(-1)
  }
  const navigateHome = () => {
    navigate('/')
  }
  return (
    <Modal
      style={{ padding: 0 }}
      bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
      closable={false}
      maskClosable={false}
      footer={
        <Row justify="space-between">
          <Button type="link" onClick={navigateBack}>
            Go Back
          </Button>
          <Button type="link" onClick={navigateHome}>
            Home Page
          </Button>
        </Row>
      }
      {...restProps}
    >
      <Result title="KYC Required to continue" extra={<KYCButton />} />
    </Modal>
  )
}

export const VerifyEmailModal = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { style, bodyStyle, closable, maskClosable, ...restProps } = props
  return (
    <Modal
      style={{ padding: 0 }}
      bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
      closable={false}
      maskClosable={false}
      {...restProps}
    >
      <Result
        status="warning"
        title={t('user-profile.verify-email-required')}
        extra={
          <Button type="primary" onClick={() => navigate('/dashboard/verify-email')}>
            {t('user-profile.go-verifying-email')}
          </Button>
        }
      />
    </Modal>
  )
}

const STARTUP = 'STARTUP'
const INVESTOR = 'INVESTOR'

export const SelectRoleModal = (props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [role, setRole] = React.useState(STARTUP)
  const onClickSelect = (r) => setRole(r)
  const {userProfile} = props;
  const { currentNetwork} = useSelector(authSelector);
  const onConfirmSelectRole = async () => {
    try {
      setLoading(true)
      if (role === INVESTOR) {
        await tsprojectContract.registerVc()
      } else {
        await tsprojectContract.registerStartup()
      }
      const body = {
        walletID: userProfile.WalletID,
        originalChainID: currentNetwork.chainID,
        email: userProfile.Email,
      }
      const res = await API_Chain.createNewChainForWallet(body);
      console.log(res);

      const { OK } = await selectUserRole(role === INVESTOR ? true : false)
      if (OK) {
        navigate('/dashboard/my-profile')
      } else {
        // message.error('onConfirmSelectRole: ', ResultCode, Message)
        navigate('/')
      }
    } catch (err) {
      // message.error(`${err.code} ${err.data?.code} ${err?.data?.message}`)
      console.log(`${err.code} ${err.data?.code} ${err?.data?.message}`)
      if (err.reason) {
        message.error(err.reason)
      }
    } finally {
      setLoading(false)
    }
    return false
  }

  const { style, bodyStyle, closable, maskClosable, ...restProps } = props
  return (
    <Modal
      style={{ padding: 0 }}
      bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
      closable={false}
      maskClosable={false}
      {...restProps}
    >
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-6">
            <div className="box-radio">
              <img for="startup" src="/images/icon-startup.png" alt="" />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="chooseRole"
                  id="startup"
                  value="option1"
                  checked={role === STARTUP}
                  onChange={() => onClickSelect(STARTUP)}
                />
                <label className="form-check-label" for="startup">
                  <small>Apply to join as a Startup</small>
                </label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="box-radio">
              <img for="investor" src="/images/icon-investor.png" alt="" />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="chooseRole"
                  id="investor"
                  value="option2"
                  checked={role === INVESTOR}
                  onChange={() => onClickSelect(INVESTOR)}
                />
                <label className="form-check-label" for="investor">
                  <small>Apply to join as a Investor</small>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <Row justify="center">
          <Typography.Title level={4}>Select your Role to continue</Typography.Title>
        </Row> */}
        <Row justify="center">
          <Button className="btn-submit" onClick={onConfirmSelectRole}>
            Confirm
          </Button>
        </Row>
      </Spin>
    </Modal>
  )
}
