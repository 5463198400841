import { useTranslation } from 'react-i18next'
import { Form, Input, Select, message, InputNumber, Spin, Upload } from 'antd'
import API from 'apis/apiProject'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector} from 'react-redux'
import {} from 'redux/reducers/project'
import { useNavigate, useParams } from 'react-router-dom'
import iconAvatar from 'assets/images/avatar.png'
import { ConfirmModal } from 'components/common/ConfirmModal'
import * as erc20 from 'smartcontract/erc20Contract'
import { chainSelector } from 'redux/reducers/networkSlice'
const { Option } = Select

function FilBasicInfo(props) {
  const { listChain } = useSelector(chainSelector)
  const { setCurrent, current } = props
  const [loading, setLoading] = useState(true)
  const step01Content = useSelector((state) => state.myProject.step01)
  const projectId = useSelector((state) => state.myProject.projectId)
  const [form] = Form.useForm()
  const paramUrl = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [imageUrl, setImageUrl] = useState()
  const [dataImageUpload, setDataImageUpload] = useState('')
  const [confirmSubmitVisible, setConfirmSubmitVisible] = useState(false)
  const [totalRequest, setTotalRequest] = useState(0)
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  }

  const onFinish = async (values) => {
    if (imageUrl) {
      setTotalRequest(totalRequest + 1)
      if (totalRequest === 0) {
        let body = {
          projectID: projectId,
          information: {
            avatar: imageUrl,
            projectName: values.projectName,
            shortDescription: values.shortDescription,
            fundraisingStage: values.fundraisingStage,
            field: values.field,
            leadVC: values.leadVC,
            marketMaker: values.marketMaker,
            chain: values.chain,
            hastag: values.hashtag?.toString(),
            website: values.website,
            telegram: values.telegram,
            numberEmployee: values.numberEmployee,
            email: values.email,
            tokenAddress: values.tokenAddress,
            tokenSymbol: values.tokenSymbol,
            tokenDecimal: values.tokenDecimal,
            discord: values.discord,
            linkedlin: values.linkedlin,
            youtube: values.youtube,
            reddit: values.reddit,
            twitter: values.twitter,
          },
        }
        setLoading(true)
        if (dataImageUpload) {
          let formData = new FormData()
          formData.append('file', dataImageUpload)
          const response = await API.uploadImage(formData)
          body.information.avatar = response.UrlFile
        }
        createProjectStep1(body)
        setLoading(false)
        setTotalRequest(0)
      } else {
        message.error('To prevent duplicate submission, please wait for a while')
      }
    } else {
      message.error('Please upload image for project')
    }
  }

  const createProjectStep1 = async (body) => {
    const res = await API.createProjectStep1(body)
    if (res?.ResultCode != 200) {
      message.error(res.Message)
    } else {
      setCurrent(current + 1)
      body.currentStep = current
      body.projectId = res.Data
      if (paramUrl.id === 'create-project') {
        navigate(`/dashboard/myproject/${res.Data}`)
      }
      message.success(res.Message)
    }
  }

  useEffect(() => {
    if (step01Content) {
      setImageUrl(step01Content.Avatar)
      form.setFieldsValue({
        projectName: step01Content.ProjectName,
        shortDescription: step01Content.ShortDescription,
        fundraisingStage: step01Content.FundraisingStage,
        field: step01Content.Field,
        leadVC: step01Content.LeadVC,
        marketMaker: step01Content.MarketMaker,
        chain: step01Content.Chain,
        hashtag: step01Content.Hastag?.split(','),
        website: step01Content.Website,
        telegram: step01Content.Telegram,
        numberEmployee: step01Content.NumberEmployee,
        email: step01Content.Email,
        tokenAddress: step01Content.TokenAddress,
        tokenSymbol: step01Content.TokenSymbol,
        tokenDecimal: step01Content.TokenDecimal,
        discord: step01Content.Discord,
        linkedlin: step01Content.Linkedlin,
        youtube: step01Content.Youtube,
        reddit: step01Content.Reddit,
        twitter: step01Content.Twitter,
      })
      setLoading(false)
    }
    if (paramUrl.id === 'create-project') {
      setLoading(false)
    }
  }, [step01Content])

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange = (info) => {
    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    const isLt2M = info.file.size / 1024 / 1024 < 2
    if (isJpgOrPng && isLt2M) {
      getBase64(info.file.originFileObj, (url) => {
        setDataImageUpload(info.file.originFileObj)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        <img src={iconAvatar} alt="" />
      </div>
    </div>
  )
  const confirmSubmit = () => {
    navigate('/dashboard/myproject')
    setConfirmSubmitVisible(false)
  }
  const cancelSubmit = () => {
    setConfirmSubmitVisible(false)
  }
  const updateTokenInfo = async (address) => {
    try {
      const symbol = await erc20.symbol(address)
      const decimals = await erc20.decimals(address)
      if (!!symbol || !!decimals) {
        form.setFieldsValue({ tokenSymbol: symbol, tokenDecimal: decimals })
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const onFieldsChange = (changedFields) => {
    try {
      changedFields.forEach((f) => {
        if (f.name[0] != 'tokenAddress') {
          return
        }
        updateTokenInfo(f.value)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Spin spinning={loading}>
        <ConfirmModal
          visible={confirmSubmitVisible}
          onOk={confirmSubmit}
          onCancel={cancelSubmit}
          message="Are you sure you want to cancel?"
        />
        <div className="row d-flex justify-content-between bg-step-2">
          <div className="col-12 col-sm-3">
            <div className="title-show">
              <h5>Avatar of Buidl</h5>
              <hr />
              <p>
                Avatar is used as a representation image of buidl. This is a required field. Avatar
                should be the company logo or featured image of the project to customers can easily
                recognize you. This image will be shown public to everyone.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-4">
                <div className="picture-frames d-flex align-items-center justify-content-center flex-wrap">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action=""
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
              </div>
              <div className="col-12 col-sm-7">
                <p className="note-p mt-5">
                  * The image should be used with a ratio suitable for the display interface
                  (Recommended: 334 x 500 px). <br />
                  * Drag or tap upload here to add photos.
                  <br />
                  * JPEG or PNG format. <br />* No larger than 2MB.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Form
          form={form}
          layout="vertical"
          name="time_related_controls"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <div className="row mt-6 d-flex justify-content-between">
            <div className="col-12 col-sm-3">
              <div className="title-show">
                <h5>Basic Information</h5>
                <hr />
                <p>
                  Fill in all basic information about the project such as name, fundaraising stage,
                  website, etc so that the project cover page conveys the most information to
                  investors.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-8 pl-5">
              <div className="row bg-step-item ">
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="projectName"
                    label={t('myProject.projectName')}
                    rules={[
                      {
                        required: true,
                        message: 'Project Name is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: Dstarter" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="shortDescription"
                    label={t('myProject.shortDescription')}
                    rules={[
                      {
                        required: true,
                        message: 'Short Description is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: Enter a brief description of the project" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Item name="hashtag" label={t('myProject.hashtag')}>
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select hashtag"
                      optionLabelProp="label"
                    >
                      <Option value="Web3" label="Web3">
                        Web3
                      </Option>
                      <Option value="Chain" label="Chain">
                        Chain
                      </Option>
                      <Option value="GameFi" label="GameFi">
                        GameFi
                      </Option>
                      <Option value="Metaverse" label="Metaverse">
                        Metaverse
                      </Option>
                      <Option value="DAO" label="DAO">
                        DAO
                      </Option>
                      <Option value="DeFi" label="DeFi">
                        DeFi
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="title-show">
                <h5>Financial data</h5>
                <hr />
                <p>
                  Fill in all finance data of the project such as token address, token symbol....
                </p>
                <p>Token address can not be edited after submitting build .</p>
              </div>
            </div>
            <div class=" col-12 col-sm-8 bg-step-item">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <Form.Item
                    name="chain"
                    label={t('myProject.chain')}
                    rules={[
                      {
                        required: true,
                        message: 'Chain is required',
                      },
                    ]}
                  >
                    <Select placeholder="Select chain">
                      {listChain.map((item, index) => {
                        return (
                          <Option key={item.chainID} value={item.chainID}>
                            <img
                              src={item.icon}
                              alt=""
                              style={{ paddingRight: '5px', width: '35px' }}
                            />
                            <span>{item.chainName}</span>
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-12 col-sm-12">
                <div className="form-group">
                  <Form.Item
                    name="tokenAddress"
                    label={t('createPool.tokenAddress')}
                    rules={[
                      {
                        required: true,
                        message: 'Token Address is required',
                      },
                    ]}
                  >
                    <Input placeholder="Insert token address" />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex justify-content-space-between">
                <div className="col-12 col-sm-5">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Token Symbol is required',
                      },
                    ]}
                    name="tokenSymbol"
                    label={t('myProject.ticker')}
                  >
                    <Input placeholder="Insert your token symbol" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-5">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Token Decimal is required',
                      },
                    ]}
                    name="tokenDecimal"
                    label={t('createPool.tokenDecimal')}
                  >
                    <Input placeholder="Insert your token decimal" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="title-show">
                <h5>Fundraising Infomation</h5>
                <hr />
                <p>
                  Fill in all fundraising information of the project such as fundraising stage, Lead
                  VC, Field, market marker , round, etc
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-8 pl-5">
              <div className="row bg-step-item">
                <div className="col-12 col-sm-6">
                  <Form.Item
                    name="fundraisingStage"
                    label={t('myProject.fundraisingStage')}
                    rules={[
                      {
                        required: true,
                        message: 'Fundraising Stage is required',
                      },
                    ]}
                  >
                    <Select placeholder={t('myProject.fundraisingStage')}>
                      <Option value="idea">Idea stage</Option>
                      <Option value="coFounder">Co founder stage</Option>
                      <Option value="mvp">MVP stage</Option>
                      <Option value="early">Early stage</Option>
                      <Option value="prototype">Prototype stage</Option>
                      <Option value="growth">Growth stage</Option>
                      <Option value="earlyRevenue">Early revenue stage</Option>
                      <Option value="renenueGenerate">Renenue generate stage</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Item name="marketMaker" label={t('myProject.marketMaker')}>
                    <Input placeholder="Ex: Dstarter" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Item name="leadVC" label={t('myProject.leadVc')}>
                    <Input placeholder="Ex: Kaichain" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Item
                    name="field"
                    label={'Field/industry of your buidl'}
                    rules={[
                      {
                        required: true,
                        message: 'Field Stage is required',
                      },
                    ]}
                  >
                    <Select placeholder={t('myProject.chooseField')}>
                      <Option value="gameFi">GameFi</Option>
                      <Option value="edtech">Edtech</Option>
                      <Option value="martech">Martech</Option>
                      <Option value="software"> Software as a Service</Option>
                      <Option value="metaverse">Metaverse</Option>
                      <Option value="ai">AI</Option>
                      <Option value="web3">Web3</Option>
                      <Option value="agritech"> Agritech</Option>
                      <Option value="healthCare">Health Care</Option>
                      <Option value="socialPlatform">Social platform</Option>
                      <Option value="commerce">E-Commerce</Option>
                      <Option value="estate">Real Estate</Option>
                      <Option value="banking">Banking</Option>
                      <Option value="automative">Automative</Option>
                      <Option value="analytics">Analytics</Option>
                      <Option value="advertising"> Advertising</Option>
                      <Option value="security">Security</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="numberEmployee"
                    label={t('myProject.numberOfEmployee')}
                    rules={[
                      {
                        type: 'number',
                        min: 1,
                        message: 'Number of employee cannot be less than 1',
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Ex: 200"
                      style={{
                        width: 'calc(100%)',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="title-show">
                <h5>Social Media</h5>
                <hr />
                <p>Fill in social media of the project such as telegram, website, twitter...</p>
              </div>
            </div>
            <div class="col-12 col-sm-8 pl-5 pl-5 bg-step-item">
              <div class="col-12 col-sm-13 ">
                <div class="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="twitter"
                    label={'Twitter'}
                    rules={[
                      {
                        type: 'url',
                        message: 'Twitter is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://twitter.com/Dstarterio" />
                  </Form.Item>
                </div>

                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="telegram"
                    label={t('myProject.telegram')}
                    rules={[
                      {
                        type: 'url',
                        message: 'Telegram is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://t.me/dstarter" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="website"
                    label="Website"
                    rules={[
                      {
                        type: 'url',
                        message: 'Invalid website url',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: http://dstarter.io/" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="linkedlin"
                    label="LinkedIn"
                    rules={[
                      {
                        type: 'url',
                        message: 'Invalid LinkedIn ',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://www.linkedin.com/company/dstarter/" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="email"
                    label={t('myProject.contactEmail')}
                    rules={[
                      {
                        message: 'Contact email is not a valid email',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: Dstarter@gmail.com" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="discord"
                    label={'Discord'}
                    rules={[
                      {
                        type: 'url',
                        message: 'Discord is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://discord.gg/dstarterio" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="reddit"
                    label={'Reddit'}
                    rules={[
                      {
                        type: 'url',
                        message: 'Reddit is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://www.reddit.com/user/dstarterio" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    {...formItemLayout}
                    name="youtube"
                    label={'Youtube'}
                    rules={[
                      {
                        type: 'url',
                        message: 'Youtube is required',
                      },
                    ]}
                  >
                    <Input placeholder="Ex: https://www.youtube.com/channel/Dstarter" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div class="btn-group d-flex justify-content-end">
              <div class="btn-cancel" onClick={() => setConfirmSubmitVisible(true)}>
                {t('button.cancel')}
              </div>
              <button type="primary" htmlType="submit" class="btn-submit">
                {t('button.saveAndContinue')}
              </button>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}
export default FilBasicInfo