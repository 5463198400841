import { combineReducers } from 'redux'
import connectWalletSlice from './connectWalletSlice'
import createPoolSlice from './pool/createPoolSlice'
import authReducer from './authSlice'
import userProfileReducer from './userProfile'
import myProjectReducer from './project'
import investmentReducer from './investmentSlice'
import sharedReducer from './sharedSlice'
import networkReducer from './networkSlice'

let reducers = combineReducers({
  shared: sharedReducer,
  createPoolSlice: createPoolSlice,
  auth: authReducer,
  network: networkReducer,
  userProfile: userProfileReducer,
  connectWalletSlice: connectWalletSlice,
  myProject: myProjectReducer,
  investment: investmentReducer,
})
export default reducers
