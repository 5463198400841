import { Button, Col, Form, Input, message, Row, Space, Spin, Upload } from 'antd'
import React, { useState } from 'react'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import API from 'apis/apiProject'
import {} from 'redux/reducers/project'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getOrdinalSuffix } from 'utils/formatting'
import CKUploadImage from 'components/common/ckeditor/CKUploadImage'
import { uploadFile } from 'apis/apistorage'
import iconAvatar from 'assets/images/avatar.png'
import { CustomFormUploadItem } from 'components/common/CustomFormUploadItem'
const { Dragger } = Upload
function TeamMember(props) {
  const { setCurrent, current, setBackStep } = props
  const step02Content = useSelector((state) => state.myProject.step02)
  const projectId = useSelector((state) => state.myProject.projectId)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const isValidUrl = (string) => {
    try {
      new URL(string)
      return true
    } catch (err) {
      return false
    }
  }
  const onFinish = async (values) => {
    let newData = []
    setLoading(true)
    for (let i = 0; i < values.listTeamMember.length; i++) {
      let formData = new FormData()
      formData.append('file', values.listTeamMember[i].ImageUrl)
      if (!isValidUrl(values.listTeamMember[i].ImageUrl)) {
        const response = await API.uploadImage(formData)
        newData.push({
          Linkedin: values.listTeamMember[i].Linkedin,
          MembershipFunction: values.listTeamMember[i].MembershipFunction,
          Name: values.listTeamMember[i].Name,
          ImageUrl: response.UrlFile,
        })
      } else {
        newData.push({
          Linkedin: values.listTeamMember[i].Linkedin,
          MembershipFunction: values.listTeamMember[i].MembershipFunction,
          Name: values.listTeamMember[i].Name,
          ImageUrl: values.listTeamMember[i].ImageUrl,
        })
      }
    }
    let body = {
      projectID: projectId,
      information: newData,
    }
    createProjectStep2(body)
    setLoading(false)
  }
  const createProjectStep2 = async (body) => {
    const res = await API.createProjectStep2(body)
    if (res?.ResultCode != 200) {
      message.error(res.Message)
    } else {
      setCurrent(current + 1)
      body.currentStep = current
      body.projectId = res.Data
      message.success(res.Message)
    }
  }

  useEffect(() => {
    if (step02Content) {
      form.setFieldsValue({
        listTeamMember: step02Content.Information,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [step02Content])

  return (
    <>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="form-main">
            <div className="bg-step">
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Member Information</h5>
                    <hr />
                    <p>
                      Fill name, role of all core members. Then, complete their linkedin. These
                      information will be shown public to other investor
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.List name="listTeamMember" initialValue={[{}]}>
                    {(listTeamMember, { add, remove }) => (
                      <>
                        {listTeamMember.map((field, index) => (
                          <div key={field.key}>
                            <div className="box-layout">
                              <h4>
                                {index + 1}
                                {getOrdinalSuffix(index + 1)} Member
                              </h4>
                              <div className="row">
                                <div className="col-12">
                                  <div className="col-12 col-sm-4">
                                    <div className="file-upload-2">
                                      <Form.Item
                                        name={[index, 'ImageUrl']}
                                        label={'Avatar'}
                                        // rules={[
                                        //   {
                                        //     required: true,
                                        //     message: 'Avatar is required',
                                        //   },
                                        // ]}
                                      >
                                        <CustomFormUploadItem />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="form-group">
                                    <Form.Item
                                      name={[index, 'Name']}
                                      label={t('myProject.name')}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Name is required',
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Ex: John Smith" />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="form-group">
                                    <Form.Item
                                      name={[index, 'MembershipFunction']}
                                      label={t('myProject.role')}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Role is required',
                                        },
                                      ]}
                                    >
                                      <Input placeholder={t('myProject.ExRole')} />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <Form.Item
                                  name={[index, 'Linkedin']}
                                  label={t('myProject.linkedin')}
                                  rules={[
                                    {
                                      type: 'url',
                                      message: 'Linkedin is not a valid url',
                                    },
                                  ]}
                                >
                                  <Input placeholder={t('myProject.ExLinkedIn')} />
                                </Form.Item>
                              </div>

                              <button
                                type="button"
                                className="close-x"
                                onClick={() => remove(field.name)}
                              >
                                &#10005;
                              </button>
                            </div>
                          </div>
                        ))}
                        <Form.Item>
                          <button
                            type="button"
                            id="new-layout"
                            className="btn-submit"
                            onClick={() => add()}
                          >
                            <img src={require('assets/images/icon-add.png').default} alt="" /> Add
                            Member
                          </button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => {
                  setBackStep(true)
                  setCurrent(0)
                }}
              >
                {t('button.back')}
              </button>
              <button htmlType="submit" className="btn-submit">
                {t('button.saveAndContinue')}
              </button>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}
export default TeamMember


