import { CheckCircleOutlined } from '@ant-design/icons';
import { forwardRef } from 'react';
const PoolDepositReview = forwardRef((props, ref) => {
    return <form className="form-main" style={{ marginBottom: 0 }} action="">
        <div className="bg-step">

            <div className="deposit_review">
                <h5>Deposit Review</h5>
                <div className="box-main">
                    Status:{
                        <span style={{ color: "#18c518", padding: '0px 8px 8px 10px' }}><CheckCircleOutlined /> </span>
                    }
                    <span style={{color: "#18c518"}}>Success</span>
                    
                </div>
                <div style={{color: 'white', padding:'10px 0px', lineHeight:'30px'}}>
                    <span>Your investment pool have been upload to Dstarter's smart contract successfully. </span>
                    <span>Your pool is now public on our system </span>
                </div>
                <div style={{padding:'10px 0px'}}>
                    <button className='btn-submit' onClick={()=>props.backPool()}>Back to my pools</button>
                </div>
            </div>

        </div>
    </form>
})
export default PoolDepositReview