import { Modal, Row, Space, Spin, Typography } from 'antd'
import React from 'react'

import { DStarterModal } from './DStarterModal'
/**
 * General confirm modal dialog
 * @param {*} props
 * @returns
 */
export const ConfirmModal = (props) => {
  const { title, icon, message, okText, cancelText, onOk, onCancel, loading, ...restProps } = props
  return (
    <DStarterModal footer={null} onCancel={onCancel} onOk={onOk} {...restProps}>
      <Row justify="center" style={{ margin: 8 }}>
        <Typography.Text style={{ fontSize: 22 }}>{title}</Typography.Text>
      </Row>
      <Row justify="center" style={{padding: 8}}>{icon}</Row>
      <Row justify="center" style={{ marginBottom: 16 }}>
        <Typography.Text style={{ fontSize: 20 }}>{message}</Typography.Text>
      </Row>
      <Row justify="center">
        <Spin spinning={!!loading}>
          <Space size={'large'}>
            <button className="btn btn-submit" onClick={() => onOk()}>
              {okText || 'Yes'}
            </button>
            <button className="btn btn-cancel" onClick={() => onCancel()}>
              {cancelText || 'No'}
            </button>
          </Space>
        </Spin>
      </Row>
    </DStarterModal>
  )
}

export const BSConfirmModal = (props) => {
  const { text, okText, cancelText, onOK, onCancel, loading } = props
  return (
    <div className="modal fade" id="modal-action" tabindex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <p className="warning-vote">{text}</p>
          </div>
          <Spin spinning={loading}>
            <button type="button" className="btn btn-cancel" onClick={() => onCancel()}>
              {cancelText || 'No'}
            </button>
            <button className="btn btn-submit" onClick={() => onOK()}>
              {okText || 'Yes'}
            </button>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export const ConfirmCreateProjectModal = (props) => {
  const {
    title,
    text,
    okText,
    cancelText,
    onOK,
    onCancel,
    visible,
    loading,
    setVisible,
    body,
    createProjectStep5,
  } = props

  const onSummit = async () => {
    setVisible(false)
    await createProjectStep5(body)
  }
  return (
    <Modal
      style={{ padding: 0 }}
      bodyStyle={{ background: "#1A1E54 url('/images/bg-wave.png') no-repeat top left" }}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body text-center">
        <p className="warning-vote">{text}</p>
      </div>
      <Row justify="center">
        <Spin spinning={!!loading}>
          <Space size={'large'}>
            <button className="btn btn-cancel" onClick={() => onCancel()}>
              {cancelText || 'No'}
            </button>
            <button className="btn btn-submit" onClick={() => onSummit()}>
              {okText || 'Yes'}
            </button>
          </Space>
        </Spin>
      </Row>
    </Modal>
  )
}
