import axios from 'axios'
import {
  dStarterApiDataExtractor,
  dStarterApiErrorHandler,
  setupAxiosInterceptor,
} from 'utils/common'

const API_URL = process.env.REACT_APP_API_URL

const defaultHeaders = {
  'Content-Type': 'application/json',
}

/**
 * Create axios instance for Profiles service
 */
export const poolInvestorApi = setupAxiosInterceptor(
  axios.create({
    baseURL: `${API_URL}/projects`,
    timeout: 30000,
    headers: defaultHeaders,
  }),
)
/**
 * Get a specific pool-investor
 * @param {*} poolID
 * @returns {CODE, PoolID, ProjectID, NameVC, WalletAddress, InvestedAmount,
 * RegisteredTime, IsDelete, Status, CUSER, CDATE, LUSER, LDATE}
 */
export const getPoolInvestor = async (poolID) => {
  return poolInvestorApi
    .get(`/Pool_Investor/auth/GetInfo/${poolID}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Create pool-investor entry
 * @param {*} param
 * @returns
 */
export const createPoolInvestor = (param) => {
  return poolInvestorApi
    .post('/Pool_Investor/auth/Create', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Find pool investor entries
 * @param {*} param
 * @returns
 */
export const getListPoolInvestor = (param) => {
  return poolInvestorApi
    .post('/Pool_Investor/auth/GetList', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Get Pool-Investor detail
 *
 * @param {*} code Pool-Investor CODE
 * @returns "CODE" "PoolID" "ProjectID" "PoolName" "ProjectName" "NameVC" "WalletAddress" "InvestedAmount"
 * "RegisteredTime" "LastVote" "ClaimableToken" "DisbursedCurrency" "RefundCurrency" "IsDelete" "Status"
 * "CUSER" "CDATE" "LUSER" "LDATE" "NO"
 *
 */
export const getPoolInvestorDetail = (code) => {
  return poolInvestorApi
    .get(`/Pool_Investor/auth/GetInfo/${code}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Accept amount from investor
 * @param {*} param
 * @returns
 */
export const updateStatus = (param) => {
  return poolInvestorApi
    .put('/Pool_Investor/auth/UpdateStatus', param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

export const getPoolInformationDetail = (poolID) => {
  return poolInvestorApi
    .get(`/Pool_Information/auth/GetInfo/${poolID}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

export const getMyPoolInformationDetail = (poolID) => {
  return poolInvestorApi
    .get(`/Pool_Information/auth/MyPool/GetInfo/${poolID}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}
/**
 *
 * @param {*} investorWalletID
 * @returns [{CODE, PoolID, ProjectID, DepositAmount, DepositTime, SmartID, AddressInvestor, IsDelete}]
 */
export const getDepositListByInvestor = (investorWalletID) => {
  return poolInvestorApi
    .post('/Pool_Deposit/auth/GetList', { addressInvestor: investorWalletID })
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}
export const getDepositInfo = (depositCode) => {
  return poolInvestorApi
    .get(`/Pool_Deposit/auth/GetInfo/${depositCode}`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/**
 * Get claimable investment
 * @returns list 
 *   ["CODE": "62ede726a3cd10000195ed15",
      "PoolID": "62ede4caa3cd10000195ecf0",
      "ProjectID": "62e4a7f7d84e330001952df1",
      "PoolName": "Kai pool 4",
      "TokenVesting": null,
      "ClaimableToken": null,
      "TotalClaimableToken": null,
      "IsDelete": false,
      "Status": 3]
 */
export const getClaimableInvestment = () => {
  return poolInvestorApi
    .post(`/Pool_Deposit/auth/GetList`)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/*
    {
      "0": {
        "PoolID": "62f9ae08bb0ca60001820cfc",
        "PoolSmartID": 25,
        "PoolName": "Pool 08 BUIDL 07",
        "ProjectID": "62f08ebd0328f60001074f41",
        "ProjectSmartID": 37,
        "ProjectName": "Buidl number 7",
        "ProposalID": null,
        "StartTime": 1660530148,
        "EndTime": 1660532431,
        "AddressVesting": "0x7aeb610dcb94d937a311ff5d9c5a4dd00b07fdc9",
        "AddressDao": "0x51a314de8b9bf55def0279179da3d6032eb152da",
        "TokenAddress": "0x5D43F2C41c00e8d59677092852D453830792abE5",
        "Currency": "CURR001",
        "AmountOfTokenSell": 20000,
        "PriceOfToken": 8
      }
    }
*/
export const getTransferToVestingList = (param = {}) => {
  return poolInvestorApi
    .post(`/Vote_Reponse/auth/GetList/Vote_TransferToVesting`, param)
    .then((res) => res.data)
    .then(dStarterApiDataExtractor)
    .catch(dStarterApiErrorHandler)
}

/*
[{
    "_id": "62fefb7b51a34600010c921e",
    "CODE": "62fefb7b51a34600010c921d",
    "PoolID": "62fd9e803336260001fa58db",
    "ProjectID": "62e4a7f7d84e330001952df1",
    "MilestoneID": "62fd9ed93336260001fa58df",
    "VoteType": 1,
    "StartTime": 1660788327,
    "EndTime": 1660788932,
    "TotalTokenSupply": 1,
    "AmountDisbursed": 750,
    "ProposalID": "44426689961364403483169257252990586350445717535613554460947814929356078266956",
    "IsDelete": false,
    "Status": null,
    "CUSER": "0x76bd8090d8b41168830ce742ee3fdf32310c3f75",
    "CDATE": 1660877691,
    "LUSER": null,
    "LDATE": null,
    "NO": 1
}]
*/
/**
 * 
 */
export const getMyVoteCreateList = (param = {}) => {
  return poolInvestorApi
  .post('/Vote_Reponse/auth/GetList/Vote_Create', param)
  .then((res) => res.data)
  .then(dStarterApiDataExtractor)
  .catch(dStarterApiErrorHandler)
}