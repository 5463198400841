import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showModal: false,
  code: '',
  step: 0,
  approvedTokenAmount: 0,
  approvedCurrencyAmount: 0,
  currencyName: '',
  param: {
    amount: 0,
    addressTokenDeposit: '',
    addressReceipt: '',
    smPoolId: 0,
  },
  successCode:''
}

//Steps: 0 - Approve currency, 1 - deposit
const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.showModal = payload
    },
    approveSuccess: (state, { payload }) => {
      const { currencyAmount, tokenAmount } = payload
      if (currencyAmount > 0) {
        state.approvedCurrencyAmount = currencyAmount
        state.approvedTokenAmount = tokenAmount
        state.step = 1
      }
    },
    reset: (state) => {
      // Reset to inital state
      state.param = initialState.param
      state.showModal = false
      state.code = ''
      state.step = 0
      state.approvedTokenAmount = 0
      state.approvedCurrencyAmount = 0
      state.currencyName = ''
    },
    start: (state, { payload }) => {
      state.code = payload
      state.showModal = true
    },
    setCurrencyName:(state, { payload }) => {
      state.currencyName = payload
    },
    setSuccessCode:(state, { payload }) => {
        state.successCode = payload
    }
  },
})

export const investmentActions = investmentSlice.actions
/**
 * investor branch selector to use as parameter to useSelector
 * @param {*} state
 * @returns
 */
export const investmentSelector = (state) => state.investment

export default investmentSlice.reducer
