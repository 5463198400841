import React from 'react'
import API from 'apis/apiProject'
import { LoadMoreList } from 'pages/project-public/LoadMoreList'
import { MyProjectItem } from './MyProjectItem'
import { List } from 'antd'

const MyProjectListWrapper = (props) => {
  const { param, setTotalCount } = props

  const loadPageData = (index, size, param) => {
    const body = {
      projectName: param.projectName,
      pageIndex: index,
      pageSize: size,
    }
    return API.getMyProject(body)
  }

  return <LoadMoreList
    param={param}
    setTotalCount={setTotalCount}
    grid = {{
      column:2,
      gutter:[20,0],
      xs:1,
      sm:1,
      md:2
    }}
    dataKey="ProjectID"
    loadPageData={loadPageData}
    renderItem={(item) =><List.Item> <MyProjectItem item={item} /></List.Item>} />
}
export default MyProjectListWrapper
