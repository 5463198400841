import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Modal, Spin } from 'antd'
import { authActions } from 'redux/reducers/authSlice'
import { authSelector } from 'redux/reducers/authSlice'
import API_Chain from 'apis/apichain'
import { getProfileByWalletIDApi } from 'apis/apiprofiles'
import { useState } from 'react'
import { registerStartup, registerVc } from 'smartcontract/projectContract'

export const RegisterNewChain = () => {
    const dispatch = useDispatch()
    const [isVisible, setIsVisible] = useState(false);
    const [busy, setBusy] = useState(false);
    const { currentNetwork, accessToken, walletID } = useSelector(authSelector);
    const checkExistOnChain = async () => {
        const body = {
            walletID: walletID,
            originalChainID: currentNetwork.chainID,
        }
        const res = await API_Chain.chainExist(body);
        if (res && res.ResultCode === 200) {
            const status = res.Data;
            setIsVisible((status === 2));
        }
        else if (res && res.message) {
            console.log(res.message);
        }
        else {
            console.log("An error occured on server.");
        }
    }
    React.useEffect(() => {
        if (accessToken && walletID) {
            checkExistOnChain();
        }
    }, [accessToken, walletID])

    const createWalletNewChain = async () => {
        try {
            const rsProfile = await getProfileByWalletIDApi(walletID);
            const profile = rsProfile?.Data?.Information;
            if (!profile || !profile.Email) {
                message.error("Can not find profile");
                return;
            }
            setBusy(true);
            if (profile.RoleVC) {
                await registerVc();
            } else if (profile.RoleStartup) {
                await registerStartup()
            }
            else {
                message.error("User not register any role.");
            }
            const body = {
                walletID: walletID,
                originalChainID: currentNetwork.chainID,
                email: profile.Email,
            }
            const res = await API_Chain.createNewChainForWallet(body);
            setBusy(false)
            if (res?.ResultCode != 200) {
                message.error(res.Message)
            } else {
                setIsVisible(false)
                message.success(res.Message)
            }
        } catch (err) {
            console.log(err.message);
            setBusy(false);
        }
    }
    return (
        <>
            <Modal
                width={500}
                style={{ padding: 0 }}
                bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
                visible={isVisible}
                footer={null}
                closable={false}
            >   <Spin spinning={busy} >
                    <div className="modal-header">
                        <h5 className="modal-title">Do you want to Register new chain?</h5>
                    </div>
                    <div className="modal-body">
                        <div className="group_connect">
                            <p className="text-center">You need be registered on the chain to use DStarter</p>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center align-items-center">
                        <button
                            onClick={() => {
                                setIsVisible(false)
                                dispatch(authActions.globalLogout())
                            }}
                            type="button"
                            className="btn btn-cancel"
                        >
                            Cancel
                        </button>
                        <button type="button" className="btn btn-submit" onClick={createWalletNewChain}>
                            Confirm
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}