import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'
import { useConnectWallet } from 'components/hooks/useConnectWallet'
import { authActions } from 'redux/reducers/authSlice'
import { useGetUserProfile } from 'components/hooks/useGetUserProfile'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { chainSelector } from 'redux/reducers/networkSlice'

export const ConnectWalletModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visible, setVisible] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false)
  const [selectChain, setSelectChain] = useState('');
  const { requestConnectWallet,currentNetwork, requestBackendAuth, onConfirmMetamask, onConfirmWalletConnect } =
    useConnectWallet()
  const network = useSelector(chainSelector)
  const { userProfile } = useGetUserProfile()

  React.useEffect(() => {
    if (visible != requestConnectWallet) {
      setVisible(requestConnectWallet)
    }
    if (requestBackendAuth && currentNetwork) {
      dispatch(authActions.authenticateWithBackend({chain:currentNetwork}))
    }
  }, [requestConnectWallet, requestBackendAuth])

  // Navigate to verify-email if not yet verified
  React.useEffect(() => {
    if (userProfile.FirstSignIn || userProfile.UnverifiedEmail) {
      navigate('/dashboard/verify-email')
    } else if (userProfile.UndefinedRole) {
      navigate('/dashboard/select-user-role')
    }
  }, [userProfile])

  const hideModal = () => {
    dispatch(authActions.cancelRequestConnectWallet())
  }
  const chainChanged = (evt) => {
    setSelectChain(evt.currentTarget.value);
  }
  const submitChain = async () => {
    const itemChain = document.querySelector('input[name=chooseChain][checked]');
    const chainId = selectChain ? selectChain : itemChain?.value;
    let chain = network?.listChain.find((item) => item.chainID === chainId)
    onConfirmMetamask(chain)
    setVisible(false)
  }
  const comSelectWallet = () => {
    return <>
      <div className="modal-body">
        <div className="group_connect">
          <a href="#" onClick={() => setIsVisible(true)}>
            <img src="/images/wallet-metamask.png" alt="" />
            Metamask
          </a>
          <a href="#" onClick={onConfirmWalletConnect}>
            <img src="/images/walletconnect.png" alt="" />
            WalletConnect
          </a>
          <button>
            <img src="images/wallet-trust.png" alt="" /> Trust Wallet
          </button>
        </div>
      </div>
      <div className="modal-footer" style={{ justifyContent: 'center' }}>
        <button type="button" className="btn btn-cancel" onClick={hideModal}>
          Cancel
        </button>
      </div>
    </>
  }

  const comSelectChain = () => {
    return <>
      <div className="modal-body">
        <div className="group_connect">
          {network.listChain ? (
            <>
              {network?.listChain?.map((item, index) => {
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="chooseChain"
                      id={item.id}
                      onChange={chainChanged}
                      value={item.chainID}
                      defaultChecked={index === 0 ? true : false}
                    />
                    <label className="form-check-label" for="chain-okex">
                      <img src={item.icon} alt="" /> {item.chainName}
                    </label>
                  </div>
                )
              })}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-center align-items-center ">
        <button type="button" className="btn btn-cancel" onClick={() => setIsVisible(false)}>
          Cancel
        </button>
        <button type="button" className="btn btn-submit" onClick={() => submitChain()}>
          Confirm
        </button>
      </div>
    </>
  }
  return (
    <>
      <Modal
        width={450}
        style={{ padding: 0 }}
        bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
        visible={visible}
        footer={null}
        onCancel={hideModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">Connect Wallet</h5>
        </div>
        {isVisible ? comSelectChain() : comSelectWallet()}
      </Modal>
    </>
  )
}




