import { Outlet, useLocation } from 'react-router-dom'
import HeaderLayout from './HeaderLayout'
import Footer from './Footer'
import { useEffect } from 'react'
import FooterAll from './FooterAll';
function MainLayout() {
  let location = useLocation();
  return (
    <>
      <HeaderLayout />
      <main className={`${(location.pathname === '/' || location.pathname === '/funding' || location.pathname === '/daoclub')  ? '' : 'content-width'}`}>
        <Outlet />
      </main>
      {
        (location && (location.pathname==="/"||location.pathname==="")) ? <Footer /> : <FooterAll />
      }
    </>
  )
}
export default MainLayout
