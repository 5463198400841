import axios from 'axios'
const baseURL =`${process.env.REACT_APP_API_URL}/identity`
const defaultHeaders = {
  'Content-Type': 'application/json',
}
export function auth(data) {
  var url = `${baseURL}/TokenAPI/pub/Authentication`
  
  const config = {
    headers: defaultHeaders
  }
  return axios.post(url, data, config)
}

export function getServerTime() {
  var config = {
    headers: defaultHeaders,
  }
  var url = `${baseURL}/TokenAPI/pub/DateTime`
  return axios.get(url, config)
}

export const identityApi = axios.create({
  baseURL: baseURL,
  timeout: 30000,
})

// param: {address, message, signature}
export const identityAuthentication = async (authToken, param) => {
  return identityApi
    .post('/TokenAPI/pub/Authentication', param, {
      headers: {
        Authorization: authToken,
        ...defaultHeaders,
      },
    })
    .then((res) => res.data)
}
