import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import 'assets/css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store, { persistor } from 'redux/stores'
// import i18n (needs to be bundled ;))
import './i18n'
import { PersistGate } from 'redux-persist/integration/react'
import { Spin } from 'antd'

const REACT_APP_BLOCKPASS_SDK_URI = process.env.REACT_APP_BLOCKPASS_SDK_URI

const embedBlockpassSDK = () => {
  if (!window.BlockpassKYCConnect) {
    const script = document.createElement('script')
    script.src = REACT_APP_BLOCKPASS_SDK_URI
    script.async = false
    document.body.appendChild(script)
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
embedBlockpassSDK()

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Suspense fallback={<Spin spinning />}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Suspense>
  </Provider>,
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
