import { Form, Row, Col, message } from 'antd'
import BaseInputNumber from 'components/common/form-control/BaseInputNumber'
import BaseDatePicker from 'components/common/form-control/BaseDatePicker'
import { useTranslation } from 'react-i18next'
import { forwardRef, memo, useImperativeHandle } from 'react'
import { saveStep2 } from 'apis/apipool'
import { apiCall, convertToTimestamp } from 'utils/common'
import { setBusy, setStep, setStep2Info, setStep3Info } from 'redux/reducers/pool/createPoolSlice'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from 'utils/constants'
import { v4 as newId } from 'uuid';
import moment from 'moment'
const PoolFinanceMetric = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const poolId = useSelector((state) => state.createPoolSlice.poolId)
  const step2Infor = useSelector((state) => state.createPoolSlice.step2Infor)
  const step1Infor = useSelector((state) => state.createPoolSlice.step1Infor)
  const step3Infor = useSelector(state => state.createPoolSlice.step3Infor);
  const { t } = useTranslation()
  const [form] = Form.useForm()
  useImperativeHandle(ref, () => ({
    submit() {
      form.submit()
    },
  }))

  const submitData = async (data) => {
    var rs = await apiCall(saveStep2, [data])
    if (rs && rs.data && rs.data.Data) {
      if (!step3Infor || step3Infor.length == 0) {
        //mặc định khi sang step3 thì có sẵn 1 milestone
        const key = newId();
        const newMilestone = {
          key: key,
          MilestoneName: "Milestone 1",
          OrderBy: 1,
          StartTime: null,
          EndTime: null,
          Goal: []
        }
        dispatch(setStep3Info([newMilestone]));
      }
      dispatch(setStep(2))
    } else {
      message.error(t('errorMessage.internalServer'))
    }
    dispatch(setBusy(false))
  }

  const onFinish = (values) => {
    // if(!validateData(values)) return;
    dispatch(setBusy(true))
    const data = {
      poolID: poolId,
      curentStep: 1,
      information: {
        TotalTokenSupply: 1,
        AmountOfTokenSell: values.AmountOfTokenSell,
        PriceOfToken: values.PriceOfToken,
        TGETime: convertToTimestamp(values.TGETime),
        tokenAddress: 'abc',//BE đang require nên tạm thời cứ để đó
        FirstReleaseTime: convertToTimestamp(values.FirstReleaseTime),
        FirstReleasePercent: values.FirstReleasePercent,
        CliffTime: values.CliffTime,
        LinearUnlockTime: values.LinearUnlockTime,
      },
    }
    dispatch(setStep2Info(values))
    submitData(data)
  }
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo)
  }
  const isDisable = () => {
    if (step1Infor && step1Infor.SmartID) return true
    return false
  }
  return (
    <Form
      form={form}
      initialValues={step2Infor}
      onFinish={onFinish}
      layout="vertical"
      className="form-main"
      style={{ marginBottom: 0 }}
      onFinishFailed={onFinishFailed}
      disabled={isDisable()}
      autoComplete="off"
    >
      <div className="bg-step">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <label>
                Total Token Supply<span>*</span>
              </label>
              <p className="only-p">
                {formatNumber(step1Infor.TotalSupply)} {step1Infor.TokenSymbol}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <Form.Item
                label={t('createPool.tokenSellAmount')}
                required
                name="AmountOfTokenSell"
                rules={[
                  (f) => {
                    return {
                      validator: (_, value) => {
                        try {
                          if (!value) {
                            return Promise.reject(new Error("Amount of token sell is required."));
                          }
                          
                          if (value > step1Infor.TotalSupply) {
                            return Promise.reject(new Error("Amount of token sell must be less than or equals total supply."));
                          }
                          return Promise.resolve();
                        } catch (err) {
                          return Promise.reject(err)
                        }
                      },
                    }
                  },
                ]}
              >
                <BaseInputNumber />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <Form.Item
                label={t('createPool.price')}
                name="PriceOfToken"
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    required: true,
                  },
                ]}
              >
                <BaseInputNumber />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4"></div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <Form.Item
                label={t('createPool.tgeTime')}
                name="TGETime"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <BaseDatePicker showTime />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <Form.Item
                label={t('createPool.firtReleaseTime')}
                required
                name="FirstReleaseTime"
                rules={[
                  (f) => {
                    return {
                      validator: (_, value) => {
                        try {
                          if (!value) {
                            return Promise.reject(new Error("First release time is required."));
                          }
                          const tgeTime = convertToTimestamp(f.getFieldValue('TGETime'));
                          const firstReleaseTime = convertToTimestamp(value);
                          const currentTime = convertToTimestamp(moment());
                          if (firstReleaseTime < currentTime) {
                            return Promise.reject(new Error("First release time must be greater than or equals current date."));
                          }
                          if (firstReleaseTime < tgeTime) {
                            return Promise.reject(new Error("First release time must be greater than or equals TGE time."));
                          }
                          return Promise.resolve();
                        } catch (err) {
                          return Promise.reject(err)
                        }
                      },
                    }
                  },
                ]}
              >
                <BaseDatePicker showTime />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              <Form.Item
                label={t('createPool.firstReleasePercent')}
                name="FirstReleasePercent"
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    max: 100,
                    required: true,
                  },
                ]}
              >
                <BaseInputNumber />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              {/* <label>Cliff Time (months)<span>*</span></label>
                        <input type="text" className="form-control" placeholder="Ex: AXS" /> */}
              <Form.Item
                label={t('createPool.cliffTime')}
                name="CliffTime"
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    required: true,
                  },
                ]}
              >
                <BaseInputNumber />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="form-group">
              {/* <label>Linear Unlock Time (months)<span>*</span></label>
                        <input type="text" className="form-control" placeholder="Ex: 1,000,000" /> */}
              <Form.Item
                label={t('createPool.unlockTime')}
                name="LinearUnlockTime"
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    required: true,
                  },
                ]}
              >
                <BaseInputNumber />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
})
export default memo(PoolFinanceMetric)
