import { Link } from 'react-router-dom'

import imgIMO_01 from 'assets/images/pic-imo-1.svg'
import imgIMO_02 from 'assets/images/pic-imo-2.svg'

import iconGlobal from 'assets/images/icon-global.png'
import iconPerformance from 'assets/images/icon-performance.png'
import iconProfit from 'assets/images/icon-profit.png'
import iconVote from 'assets/images/icon-vote.png'
import iconCashBack from 'assets/images/icon-cashback.png'

function Funding() {    
    

    return ( <>
            <section id="slide-top" class="animate two funding-head">
                <div class="content-width text-center">
                    <h1 class="text-uppercase animate three">IMO DAO Funding</h1>
                    <h2 class="text-uppercase animate four">Web3 Founders Fundraising Protocol</h2>
                    <div class="group_btn animate five">
                        <Link class="btn-blue" to="/buidls">Explore Builder Space</Link>
                        <Link class="btn-outline" to="/pool">Explore Fundraising Pools</Link>
                    </div>
                    
                    <div class="intro-img text-start animate six">
                        <article class="row">
                            <div class="col-12 col-sm-6 order-a">
                                <h2>What is an IMO DAO Pool?</h2>
                                <p>
                                    IMO DAO is a fundraising protocol where allocated funds are 
                                    escrowed until a startup achieves its predetermined milestones. DAO 
                                    governance allows investors to be involved in a trustless and safe 
                                    funding process. <br /><br />
                                    At the same time startups don't have to focus on 
                                    fundraising and just do what they do best - Buidl, to make sure they 
                                    continue to receive the funding as they go on to achieve milestones.
                                </p>
                            </div>
                            <div class="col-12 col-sm-6 order-b">
                                <img class="img-fluid" src={imgIMO_01} alt="What is an IMO DAO Pool?" />
                            </div>
                        </article>
                    </div>                    
                    
                </div>
            </section>


            <section id="why-img-dao">
                <div class="content-width">
                    <div class="intro-img text-start">
                        <article class="row">
                            <div class="col-12 col-sm-6">
                                <img class="img-fluid" src={imgIMO_02} alt="Why use an IMO DAO Pool?" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <h2>Why use an IMO DAO Pool?</h2>
                                <p>
                                    Dabase is all about reducing the risks for investors and increasing 
                                    the funding possibilities for startups. An immutable funding protocol 
                                    enables investors to put their confidence in new blockchain businesses 
                                    while also pushing startups to achieve what they truly aim to become.
                                </p>
                            </div>
                        </article>
                    </div>                        
                </div>
            </section>


            <section id="bg-features" class="space_section">
                <div class="content-width">
                    <section class="box-features">
                        <div class="title-feature">
                            <h2>DAO Governance - Smartcontract Escrow</h2>
                            <h6>Decisions in an IMO Pool are voted on by the specific investors of that pool only</h6>
                        </div>
                        <div class="row">
                            <div class="col-xs-5ths col-sm-5ths col-md-5ths col-lg-5ths">
                                <article>
                                    <img src={iconGlobal} alt="Buidler Space" />
                                    <h5>Buidler Space</h5>
                                    <p>Free for Builders to submit and introduce projects to global crypto users</p>                                        
                                </article>
                            </div>
                            <div class="col-xs-5ths col-sm-5ths col-md-5ths col-lg-5ths">
                                <article>
                                    <img src={iconPerformance} alt="Verified IMO Pools" />
                                    <h5>Verified IMO Pools</h5>
                                    <p>Only projects that meet the appraisal criteria can create an IMO pool and start raising funds</p>                                        
                                </article>
                            </div>
                            <div class="col-xs-5ths col-sm-5ths col-md-5ths col-lg-5ths">
                                <article>
                                    <img src={iconProfit} alt="Raise Funds" />
                                    <h5>Raise Funds</h5>
                                    <p>Contributing to smartcontract escrow with investors ownership</p>                                        
                                </article>
                            </div>
                            <div class="col-xs-5ths col-sm-5ths col-md-5ths col-lg-5ths">
                                <article>
                                    <img src={iconVote} alt="Proposals & Voting" />
                                    <h5>Proposals & Voting</h5>
                                    <p>Investors will vote on proposals to decide how the budget should be used</p>                                        
                                </article>
                            </div>
                            <div class="col-xs-5ths col-sm-5ths col-md-5ths col-lg-5ths">
                                <article>
                                    <img src={iconCashBack} alt="Refundable" />
                                    <h5>Refundable</h5>
                                    <p>Decisions will be made by majority vote, even if it is a proposal for reimbursement</p>                                        
                                </article>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            
            
            <section id="faq" class="space_section">
                <div class="content-width">
                    <h3>FAQ</h3>
                    <div class="accordion" id="accordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-01">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-01" aria-expanded="true" aria-controls="collapse-01">
                                    What is an IMO DAO Pool?
                                </button>
                            </h2>
                            <div id="collapse-01" class="accordion-collapse collapse show" aria-labelledby="heading-01" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    IMO DAO Pool is a pool created by a startup to raise funds from early investors. It differs from other fundraising platforms in 2 features: IMO & DAO. 
                                    IMO (Initial Milestone offering) is a milestone-based funding release protocol where allocated funds are escrowed until a startup achieves its predetermined 
                                    milestones. <br /><br />DAO governance allows investors to be involved in the funding release process base on proposal & voting features. Let's say it simply: Even if 
                                    startups successfully raise funds from investors, they cannot get all these funds, but escrowed in milestone smartcontract. Investors will vote on proposals 
                                    of release funds or even a reimbursement in case startups fail to meet the committed milestones. The protocol is fully decentralized and non-custodial for 
                                    all funds from Dabase.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-02">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-02" aria-expanded="false" aria-controls="collapse-02">
                                    How do I create an IMO DAO pool?
                                </button>
                            </h2>
                            <div id="collapse-02" class="accordion-collapse collapse" aria-labelledby="heading-02" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                Only projects that meet the appraisal criteria can create an IMO pool and start raising funds. To join the IMO platform, startups first need to verify 
                                KYC and start submitting profiles. The startup profile will be listed in the Buidl Space. However, to create an IMO Pool, startups need to meet the appraisal 
                                criteria from Dabase team. 
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-03">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-03" aria-expanded="false" aria-controls="collapse-03">
                                    How much does it cost to create an IMO DAO Pool?
                                </button>
                            </h2>
                            <div id="collapse-03" class="accordion-collapse collapse" aria-labelledby="heading-03" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    It is free for startups to submit profiles and get listed in Buidl Space, excluding the network gas fee. For the IMO protocol, Dabase charges based on the amount raised. 
                                    This fee is collected when the startup successfully claims from the pool.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-04">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-04" aria-expanded="false" aria-controls="collapse-04">
                                    How to join in an IMO Pool?
                                </button>
                            </h2>
                            <div id="collapse-04" class="accordion-collapse collapse" aria-labelledby="heading-04" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                    All the IMO Pools will be listed in Pools space. However, to join an IMO Pool, investors need to apply and be accepted by startups. A successful startup, in 
                                    addition to investment capital, also needs a lot of support resources from investors. Therefore, we think it will be best for the startup to choose the most suitable 
                                    investors. Early-stage investors can be VCs/communities/organizations that can invest capital as well as support resources in potential startups.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-05">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-05" aria-expanded="false" aria-controls="collapse-05">
                                    What is a DAO Club?
                                </button>
                            </h2>
                            <div id="collapse-05" class="accordion-collapse collapse" aria-labelledby="heading-05" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                DAOClub is a group of people who contribute funds and invest together. DAOClub is governed by all the members with decision-making based on proposal and voting features.  
                                Dabase DAOClub protocol enables friends or communities to easily assemble, formalize, fund, and manage assets together.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-06">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-06" aria-expanded="false" aria-controls="collapse-06">
                                How do I create a DAO Club?
                                </button>
                            </h2>
                            <div id="collapse-06" class="accordion-collapse collapse" aria-labelledby="heading-06" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                Anyone verified KYC account can create a DAOClub.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-07">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-07" aria-expanded="false" aria-controls="collapse-07">
                                How much does it cost to start a DAO Club?
                                </button>
                            </h2>
                            <div id="collapse-07" class="accordion-collapse collapse" aria-labelledby="heading-07" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                Dabase is currently not charging any fees DAOClub protocol, excluding the network gas fee. A verified KYC user can easily create a DAOClub with no charge. However, the Club owner can set up the Club membership policy for his own club. 
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading-08">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-08" aria-expanded="false" aria-controls="collapse-08">
                                How to join in a DAOClub?
                                </button>
                            </h2>
                            <div id="collapse-08" class="accordion-collapse collapse" aria-labelledby="heading-08" data-bs-parent="#accordion">
                                <div class="accordion-body">
                                All the public clubs will be listed in the Club space, excluding private clubs. Depend on the Club setup, investors can apply to join or waiting for approval from Club owner. 
                                </div>
                            </div>
                        </div>

                    </div>                    
                </div>
            </section>
            </>
    )
}

export default Funding