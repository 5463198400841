import MyProjectListWrapper from 'components/for-startup/project/project-list/MyProjectListWrapper'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { resetData } from 'redux/reducers/project'
import { useDispatch } from 'react-redux'
import { debounce } from 'utils/common'

function MyProject(props) {
  const dispatch = useDispatch()
  const [totalCount, setTotalCount] = useState(0)
  const navigate = useNavigate()
  const [param, setParam] = useState({projectName:''})
  const handleChangeSearch = debounce((value) => {
    const newParam={...param};
    newParam.projectName = value.target.value;
    setParam(newParam);
  })
  useEffect(() => {
    dispatch(resetData())
  }, [])
  return (
    <>
      <div>
        <div className="title_main row">
          <div className="col-12 col-sm-12">
            <div className="group_tool j-right d-flex col-7">
              <div
                style={{ cursor: 'pointer' }}
                className="create-new"
                onClick={() => navigate('/dashboard/myproject/create-project')}
              >
                <img src={require('assets/images/icon-create.png').default} alt="" /> Create a new
                buidl
              </div>
              <div className="search search-config col-5">
                <input
                  onChange={handleChangeSearch}
                  type="text"
                  placeholder="Search buidls here..."
                />
                <img src={require('assets/images/icon-search.png').default} alt="" />
                <div className="total-search">
                  Total: <span className="violent">{totalCount}</span> Buidls
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12">
          <div className="row list-large">
            <div id="myProjectListItem" className="scroll-infinity">
              <MyProjectListWrapper param={param} setTotalCount={setTotalCount} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MyProject
