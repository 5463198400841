
import { Link } from 'react-router-dom'
import { getBgStatusMyProject, getStatusPool } from 'utils/constants'
import { convertDatetimeFromTimestamp } from 'utils/common'
import ReadMore from 'components/common/read-more'

export const MyProjectItem = (props) => {
  const { item } = props
  return (
    <div key={item.PoolID}>
      <article className="item-box box-style">
        <div className="row">
          <div className="col-12 col-sm-5">
            <div className="avatar">
              <img className="img-fluid" src={item.Avatar} alt="" />
              {/* <p className="overlay overlay-02">
                <img src={require('assets/images/network-08.jpg').default} alt="" />
                <span>
                  Creator
                  <small>Bright Win</small>
                </span>
              </p> */}
              <span className={`status-box ${getBgStatusMyProject(item.Status)}`}>
                <img src={require('assets/images/icon-time.png').default} alt="" />
                {getStatusPool(item.Status)}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-7">
            <h4 className="over-flow ">{item.ProjectName}</h4>
            <time>
              {convertDatetimeFromTimestamp(item?.CDATE).format('YYYY-MM-DD HH:mm')}
            </time>
            <div style={{ minHeight: "15vh" }}>
              <ReadMore>{item.ShortDescription}</ReadMore>
            </div>
            {item.Status === 2 ? (
              <></>
            ) : (
              <>
                <div className="link-a">
                  <Link to={`/dashboard/myproject/${item?.ProjectID}`}>
                    <img src="images/icon-edit.png" alt="" /> Edit
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </article>
    </div>
  )
}