import icon01LOGO from 'assets/images/dstarter-dark.png'
import icon02LOGO from 'assets/images/dstarter-light.png'
import icon04LOGO from 'assets/images/icon-dstarter.png'

const MeditKit = ()=>{
    return <div className="content-width">
        <div className="title_main">
            <h2>Medit Kit</h2>
        </div>
        <div className="box-medit-logo">
            <div className="medit-logo-focus">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <img className="img-fluid" src={icon01LOGO} alt="" />
                    </div>
                    <div className="col-12 col-sm-6">
                        <img className="img-fluid" src={icon02LOGO} alt="" />
                    </div>
                </div>
            </div>            
            <div className="icon-logo-medit">
              <img className="img-fluid" src={icon04LOGO} alt="" />
            </div>
            
        </div>
    </div>
}
export default MeditKit;