
import Timeline from 'components/common/timeline'
import 'components/common/timeline/scss/style.scss'
import moment from 'moment'
import { v4 as newId } from 'uuid';
import {convertDatetimeFromTimestamp} from 'utils/common'
const { useState, useEffect } = require("react");

const PoolTimeline = (props) => {
    const MIN_ZOOM = 2
    const MAX_ZOOM = 20
    const now = new Date();

    const buildHeaderTimeline = (start, end) => {
        const result = [];
        var d = convertDatetimeFromTimestamp(start);
        var strDate = d.year() + "-" + (parseInt(d.month()) + 1) + "-01";
        var startTime = new moment(strDate, "YYYY-MM-DD");
        var tempStartTime = startTime.clone().toDate();
        d = convertDatetimeFromTimestamp(end);
        d = d.add(1, "months");
        strDate = d.year() + "-" + (parseInt(d.month()) + 1) + "-01";
        var endTime = new moment(strDate, "YYYY-MM-DD");

        const startYear = parseInt(startTime.year());
        const endYear = parseInt(endTime.year());
        var index = startYear;
        const cellYears = [];
        while (index <= endYear) {
            var tempEndDate = null;
            if (index == endYear) {
                tempEndDate = new Date(endTime.year(), endTime.month(), 1);
            }
            else {
                tempEndDate = new Date(index + 1, 0, 1);
            }
            cellYears.push({
                id: newId(),
                title: index.toString(),
                start: new Date(tempStartTime.getFullYear(), tempStartTime.getMonth(), 1),
                end: tempEndDate
            });
            index += 1;
            tempStartTime = new Date(index, 0, 1);
        }
        //ntkien: hide year cell
        // if (cellYears.length > 0)
        //     result.push({
        //         id: "years", title: "Year", cells: cellYears
        //     });

        const cellsMonth = [];
        // const diffMonth = monthDiff(startTime.toDate(), endTime.toDate());
        const diffMonth = endTime.diff(startTime, "months", true);
        var from = startTime.clone();
        // var to = new moment((end.getYear() + 1) + "-01-01");
        for (let i = 0; i < diffMonth; i++) {
            cellsMonth.push({
                id: newId(),
                title: from.format("MMM")+'-' + from.format("YYYY"),
                start: from.toDate(),
                end: from.clone().add(1, "months").toDate()
            });
            from = from.add(1, "months");
        }
        if (cellsMonth.length > 0)
            result.push({
                id: "months", title: "Milestone", cells: cellsMonth,useAsGrid: true
            });
        return result;
    }
    const buildData = (milestones) => {
        const results = [];
        if (milestones) {
            milestones.map(item => {
                const tracks = [];
                const completeCount = item.Goal.filter(x => x.Status).length
                const totalCount = item.Goal.length;
                let bg = "";
                if (completeCount == 0) {
                    bg = "grey";
                }
                else if (completeCount == totalCount) {
                    bg = "green";
                }
                else {
                    const per = ((completeCount/totalCount)*100).toFixed(2);
                    bg = `linear-gradient(90deg, #384BF2 ${per}%, rgba(9,9,121,1) ${per}%, #9FB4FF ${per}%)`;
                }
                item.Goal.map(goal => {
                    tracks.push({
                        id: newId(),
                        title: goal.GoalName,
                        isLeaf:true,
                        status:goal.Status,
                        elements: []
                    });
                });
                const newObj = {
                    id: newId(),
                    title: item.MilestoneName,
                    isOpen: false,
                    elements: [
                        {
                            id: newId(),
                            title: (completeCount + "/" + totalCount),
                            start: convertDatetimeFromTimestamp(item.StartTime).toDate(),
                            end: convertDatetimeFromTimestamp(item.EndTime).toDate(),
                            style: {
                                borderRadius: "15px",
                                color: "#ffffff",
                                background: bg
                            }
                        }
                    ],
                    tracks
                }
                results.push(newObj);
            });
        }
        return results;
    }
    const [open, setOpen] = useState(false);
    const [zoom, setZoom] = useState(3);
    const [tracks, setTracks] = useState([]);
    const [timebars, setTimeBars] = useState([]);
    const [scale, setScale] = useState({
        start: new Date(),
        end: new Date(),
        zoom,
        zoomMin: MIN_ZOOM,
        zoomMax: MAX_ZOOM,
    });
    const handleToggleOpen = () => {
        setOpen(open => !open);
    }
    // eslint-disable-next-line no-alert
    const clickElement = element => {
        //alert(`Clicked element\n${JSON.stringify(element, null, 2)}`)
    }

    const handleZoomIn = () => {
        
        setScale(objscale=>{
            const newObj = {...objscale};
            newObj.zoom=Math.min(zoom + 1, MAX_ZOOM)
            return newObj;
        })
        // setZoom(zoom => );
    }

    const handleZoomOut = () => {
        setScale(objscale=>{
            const newObj = {...objscale};
            newObj.zoom=Math.max(zoom - 1, MIN_ZOOM)
            return newObj;
        })
        // setZoom(zoom => Math.max(zoom - 1, MIN_ZOOM));
    }
    const handleToggleTrackOpen = track => {
        setTracks(currentTracks => {
            const newTracks = [...currentTracks]
            const obj = newTracks.find(x => x.id === track.id);
            obj.isOpen = !obj.isOpen;
            return newTracks;
        })
    }
    const getStartDate = (milestones) => {
        var result = 0;
        if (milestones) {
            for (let index = 0; index < milestones.length; index++) {
                if (result === 0) result = milestones[index].StartTime;
                if (milestones[index].StartTime < result) result = milestones[index].StartTime;
            }
        }
        return result;
    }
    const getEndDate = (milestones) => {
        var result = 0;
        if (milestones) {
            for (let index = 0; index < milestones.length; index++) {
                if (result === 0) result = milestones[index].EndTime;
                if (milestones[index].EndTime > result) result = milestones[index].EndTime;
            }
        }
        return result;
    }
    const getScaleObject = (start, end) => {
        var d = new convertDatetimeFromTimestamp(start);
        var strDate = d.year() + "-" + (parseInt(d.month()) + 1) + "-01";
        const startTime = new moment(strDate, "YYYY-MM-DD");

        d = new convertDatetimeFromTimestamp(end);
        d = d.add(1, "months");
        strDate = d.year() + "-" + (parseInt(d.month()) + 1) + "-01";
        var endTime = new moment(strDate, "YYYY-MM-DD");

        const result = {
            start: startTime.toDate(),
            end: endTime.toDate(),
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
        }
        return result;
    }
    useEffect(() => {
        const start = getStartDate(props.data);
        const end = getEndDate(props.data);
        const objScale = getScaleObject(start, end);
        setScale(objScale);
        const objTimeBars = buildHeaderTimeline(start, end);
        setTimeBars(objTimeBars);
        const objData = buildData(props.data);
        setTracks(objData);
    }, [props.data]);


    return (
        <Timeline
            scale={scale}
            isOpen={open}
            toggleOpen={handleToggleOpen}
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            clickElement={clickElement}
            clickTrackButton={track => {
                //alert(JSON.stringify(track))
            }}
            timebar={timebars}
            tracks={tracks}
            now={now}
            toggleTrackOpen={handleToggleTrackOpen}
            enableSticky
            scrollToNow
        />
    )
}
export default PoolTimeline