import React from 'react'
import { useSelector } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { userProfileSelector } from 'redux/reducers/userProfile'
import { useGetUserProfile } from './useGetUserProfile'

/**
 * Check permissions
 * @param param { requireWallet, requireAccessToken, requireProfile, requireVerifyEmail, requireKYC, requireUserRole, onlyStartup, onlyInvestor }
 * @returns
 */
export const useCheckPermission = (param) => {
  const {
    requireWallet,
    requireAccessToken,
    requireProfile,
    requireEmail,
    requireKYC,
    requireUserRole,
    onlyStartup,
    onlyInvestor,
  } = param
  const [ret, setRet] = React.useState(false)

  const { walletID, accessToken } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  React.useEffect(() => {
    // Check connect wallet
    if (requireWallet && !walletID) {
      setRet(false)
      return
    }
    // Check access token
    if (requireAccessToken && !accessToken) {
      setRet(false)
      return
    }
    if (requireProfile && !userProfile?.WalletID) {
      setRet(false)
      return
    }
    if (requireEmail && (!userProfile.WalletID || !userProfile.ValidateEmail)) {
      setRet(false)
      return
    }
    if (requireKYC && (!userProfile.WalletID || !userProfile.ValidateKYC)) {
      setRet(false)
      return
    }
    if (
      requireUserRole &&
      (!userProfile.WalletID || (!userProfile.RoleVC && !userProfile.RoleStartup))
    ) {
      setRet(false)
      return
    }
    if (onlyStartup && (!userProfile.WalletID || !userProfile.RoleStartup)) {
      setRet(false)
      return
    }
    if (onlyInvestor && (!userProfile.WalletID || !userProfile.RoleVC)) {
      setRet(false)
      return
    }
    setRet(true)
  }, [walletID, accessToken, userProfile])

  return ret
}
