const StepDefault = (props) => {
  const { titleStep, contentSteps, currentStep } = props

  return (
    <>
      <div className="title_main row d-flex justify-content-center">
        {/* <div className="col-12 col-sm-2">
            <h2>{titleStep}</h2>
          </div> */}
        <div className="col-12 col-sm-12">
          <ul className="title-step">
            {contentSteps?.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${currentStep > index ? 'checked' : currentStep == index ? 'active' : ''}`}
                >
                  <span>{item?.title}</span>
                  <p>{item?.contentText}</p>
                </li>
              )
            })}
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>

      <div className="p-5">{contentSteps[currentStep].content}</div>
    </>
  )
}
export default StepDefault
