import { takeEvery, call } from 'redux-saga/effects';
import * as actionName from 'redux/actions/actionName';
import { message } from 'antd';
import { getServerTime } from 'apis/apiauth';
function* poolSaga() {
    yield takeEvery(actionName.Pool.LoadData, doLoadData)
}

function* doLoadData(action) {
    try {
        yield call(() => getServerTime());
    } catch (error) {
        message.warning(error.message);
    }
}
export default poolSaga;

