import { Form, Input, Row, Col, Select, message, Upload } from 'antd'
import BaseDatePicker from 'components/common/form-control/BaseDatePicker'
import BaseInputNumber from 'components/common/form-control/BaseInputNumber'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  setBusy,
  setImageUrl,
  setImageFile,
  setPoolId,
  setStep,
} from 'redux/reducers/pool/createPoolSlice'
import { useDispatch, useSelector } from 'react-redux'
import { saveStep1 } from 'apis/apipool'
import { uploadFile } from 'apis/apistorage'
import { apiCall, convertToTimestamp } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import { setStep1Info } from 'redux/reducers/pool/createPoolSlice'
import { memo } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { chainSelector } from 'redux/reducers/networkSlice'
import { formatNumber } from 'utils/constants'
import iconAvatar from 'assets/images/avatar.png'
const { Dragger } = Upload
const { Option } = Select
const PoolBasicInformation = forwardRef((props, ref) => {
  const poolId = useSelector((state) => state.createPoolSlice.poolId)
  const imageUrl = useSelector((state) => state.createPoolSlice.imageUrl)
  const imageFile = useSelector((state) => state.createPoolSlice.imageFile)
  const step1Infor = useSelector((state) => state.createPoolSlice.step1Infor)
  const { listCurrency, listChain } = useSelector(chainSelector)

  const projects = useSelector((state) => state.createPoolSlice.projects)

  // const currencies = useSelector((state) => state.createPoolSlice.currencies)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [localCurrency, setLocalCurrency] = useState([])
  const [messageError, setMessageError] = useState('')
  const [linkDemo, setLinkDemo] = useState('')
  const [currentProjectId, setCurrentProjectId] = useState(step1Infor?.ProjectID || '')
  const [projectDetail, setProjectDetail] = useState('')
  useImperativeHandle(ref, () => ({
    submit() {
      form.submit()
    },
  }))

  const handleChange = (info) => {
    console.log(info)
  }
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

    if (!isJpgOrPng) {
      return setMessageError(t('uploadImage.imageOnly'))
    }

    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isLt2M) {
      return setMessageError(t('uploadImage.imageSizeLimit', [2]))
    }
    var rs = isJpgOrPng && isLt2M
    readImage(file)
    return rs
  }
  const readImage = (file) => {
    var reader = new FileReader()

    //Read the contents of Image File.
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      dispatch(setImageFile(file))
      dispatch(setImageUrl(e.target.result))
      setMessageError('')
    }
  }
  const propsUpload = {
    name: 'file',
    multiple: false,
    action: '',
    showUploadList: false,
    beforeUpload: beforeUpload,
    onChange: handleChange,
  }

  const getChainName = (code) => {
    const obj = listChain.find((item) => item.chainID === code)
    console.log('obj', obj)
    if (obj) return obj
  }

  const projectChange = (val) => {
    setCurrentProjectId(val)
  }

  useEffect(() => {
    const project = projects.find((x) => x.ProjectID === currentProjectId)
    console.log(projectDetail?.Step04)
    setProjectDetail(project)
    const listCurrencyByChainOfProject = listCurrency.filter(
      (x) => x.chain.chainID === project?.Chain,
    )
    setLocalCurrency(listCurrencyByChainOfProject)
  }, [currentProjectId, step1Infor])

  const submitData = async (data) => {
    var rs = await apiCall(saveStep1, [data])
    if (rs && rs.data && rs.data.Data) {
      dispatch(setPoolId(rs.data.Data))
      dispatch(setStep(1))
      if (!poolId) {
        const path = '/dashboard/pools/' + rs.data.Data
        navigate(path)
      }
    } else {
      message.error(t('errorMessage.internalServer'))
    }
    dispatch(setBusy(false))
  }
  const uploadImage = async () => {
    var result = step1Infor?.Avatar
    if (imageFile) {
      const formData = new FormData()
      formData.append('file', imageFile)
      const rsUpload = await uploadFile(formData)
      result = rsUpload?.UrlFile
    }
    return result
  }
  const onFinish = async (values) => {
    if (!validateImage()) {
      return
    }
    dispatch(setBusy(true))
    const urlFile = await uploadImage()
    if (urlFile) {
      const objProject = projects.find((item) => item.ProjectID === values.ProjectID)
      const objCurrency = listCurrency.find((item) => item.id === values.Currency)
      const objData = {
        poolID: poolId,
        curentStep: 0,
        information: {
          ProjectID: values.ProjectID,
          ProjectName: objProject ? objProject.ProjectName : '',
          ProjectName: objProject ? objProject.ProjectName : '',
          TokenSymbol: objProject ? objProject.TokenSymbol : '',
          Chain: objProject ? objProject.Chain : '',
          TokenAddress: objProject ? objProject.TokenAddress : '',
          SmartProjectId: objProject ? objProject.SmartID : null,
          TotalSupply: objProject?.Step04?.TotalSupply,
          Avatar: urlFile,
          ChainName: getChainName(objProject.Chain).chainName,
          NameInvestment: values.NameInvestment,
          StartTime: convertToTimestamp(values.StartTime),
          EndTime: convertToTimestamp(values.EndTime),
          DueToDeposit: values.DueToDeposit,
          Currency: values.Currency,
          CurrencyName: objCurrency ? objCurrency.name : '',
          CurrencyTokenAddress: objCurrency ? objCurrency.contractAddress : '',

          YoutubeLink: values.YoutubeLink,
        },
      }
      const infor = { ...objData.information }
      infor.StartTime = values.StartTime
      infor.EndTime = values.EndTime
      dispatch(setStep1Info(infor))
      submitData(objData)
    } else {
      dispatch(setBusy(false))
      message.error('Can not upload avatar')
    }
  }
  const onFinishFailed = (errorInfo) => {
    validateImage()
  }
  const onValuesChange = (changedValues) => {
    if (changedValues['YoutubeLink'] !== undefined) {
      setLinkDemo(changedValues['YoutubeLink'])
    }
  }
  const validateImage = () => {
    if (!imageUrl) {
      setMessageError('Avatar is required')
      return false
    }
    return true
  }
  const isDisable = () => {
    if (step1Infor && step1Infor.SmartID) return true
    return false
  }
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      layout="vertical"
      className="form-main"
      style={{ marginBottom: 0 }}
      initialValues={step1Infor}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={isDisable()}
    >
      <div className="col-12 d-flex flex-wrap justify-content-space-between col-sm-12  pl-5 bg-step-item ">
        <div className="col-12 col-sm-4">
          <Form.Item
            label={t('createPool.projectName')}
            name="ProjectID"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select onChange={projectChange} placeholder="Select buidl">
              {projects.map((item) => (
                <Option key={item.ProjectID}>{item.ProjectName}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {currentProjectId ? (
          <>
            <div className="col-12 col-sm-6">
              <Form.Item label={'Token Address'} name="tokenAddress">
                <span className="text-step">{projectDetail?.TokenAddress}</span>
              </Form.Item>
            </div>
            <div className="col-12 col-sm-6">
              <Form.Item label={'Total Token sell (Token)'} name="tokenAddress">
                <span className="text-step">
                  {formatNumber(projectDetail?.Step04?.PublicTotalRaise)}
                </span>
              </Form.Item>
            </div>
            <div className="col-12 col-sm-6">
              <Form.Item label={'Token symbol (Ticker)'} name="tokenAddress">
                <span className="text-step">{projectDetail?.TokenSymbol}</span>
              </Form.Item>
            </div>
            <div className="col-12 col-sm-6">
              <Form.Item label={'Total public fundraising ($)'} name="tokenAddress">
                <span className="text-step">{formatNumber(projectDetail?.Step04?.TokenOffer)}</span>
              </Form.Item>
            </div>
            <div className="col-12 col-sm-6">
              <Form.Item label={'Chain'} name="tokenAddress">
                <span className="text-step">
                  <img
                    src={getChainName(projectDetail?.Chain)?.icon}
                    alt=""
                    style={{ width: '25px', paddingRight: '5px' }}
                    srcset=""
                  />
                  {getChainName(projectDetail?.Chain)?.chainName}.
                </span>
              </Form.Item>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="bg-step-item ">
        <div className="col-12">
          <div className="pool-box-upload">
            <div className="bg-file">
              <div className="row">
                <div className="col-12 col-sm-5">
                  <div className="file-upload">
                    <Dragger {...propsUpload}>
                      {imageUrl ? (
                        <img src={imageUrl} style={{ width: '100%' }} alt="image" />
                      ) : (
                        <>
                          <img src={iconAvatar} alt="" style={{ width: '120px' }} />
                        </>
                      )}
                    </Dragger>
                  </div>
                  <span style={{ padding: '0px 0px 0px 20px' }} className="red-color">
                    {messageError}
                  </span>
                </div>
                <div className="col-12 col-sm-7">
                  <p className="note-p">
                    * The image should be used with a ratio suitable for the display interface
                    (Recommended: 334 x 500 px). <br />
                    * Drag or tap upload here to add photos <br />
                    * JPEG or PNG format. <br />* No larger than 2MB.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <Form.Item
                  label={'Pool Name'}
                  name="NameInvestment"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Input investment pool" />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <Form.Item
                label={t('createPool.currency')}
                name="Currency"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Select currency">
                  {localCurrency?.map((item) => (
                    <Option key={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4">
              <div className="form-group">
                <Form.Item
                  label={t('createPool.startTime')}
                  name="StartTime"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <BaseDatePicker placeholder="Ex: YYYY/MM/DD HH:mm" showTime />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="form-group">
                <Form.Item
                  label={t('createPool.endTime')}
                  name="EndTime"
                  rules={[
                    { required: true },
                    (f) => {
                      return {
                        validator: (_, value) => {
                          try {
                            if (!value) {
                              return Promise.reject(new Error('End time is required.'))
                            }
                            const startTime = convertToTimestamp(f.getFieldValue('StartTime'))
                            const endtime = convertToTimestamp(value)
                            const currentTime = convertToTimestamp(moment())
                            if (endtime < currentTime) {
                              return Promise.reject(
                                new Error('End time must be greater than or equals current date.'),
                              )
                            }
                            if (endtime < startTime) {
                              return Promise.reject(
                                new Error('End time must be greater than or equals start time.'),
                              )
                            }
                            return Promise.resolve()
                          } catch (err) {
                            return Promise.reject(err)
                          }
                        },
                      }
                    },
                  ]}
                >
                  <BaseDatePicker placeholder="Ex: YYYY/MM/DD HH:mm" showTime />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <div className="form-group">
                <Form.Item
                  label={t('createPool.waitlistTime')}
                  name="DueToDeposit"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      required: true,
                    },
                  ]}
                >
                  <BaseInputNumber />
                </Form.Item>
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group">
            <Form.Item
              label={t('createPool.youtubeLink')}
              name="YoutubeLink"
              rules={[
                {
                  type: 'url',
                  message: 'This field must be a valid url.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          {linkDemo ? (
            <ReactPlayer
              url={linkDemo}
              playing={false}
              volume={1}
              width="100%"
              height="50vh"
              onReady={() => console.log('ready now')}
            />
          ) : null}
        </div>
      </div>
    </Form>
  )
})
export default memo(PoolBasicInformation)
