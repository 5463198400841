import React from "react"
import { useDispatch } from "react-redux"
import { authActions } from "redux/reducers/authSlice"

/**
 * Headless component for wallet authentication
 * @returns 
 */
export const WalletAuth = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    // Notify when network or account changed
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        dispatch(authActions.walletAccountChanged(accounts))
        console.log('ACCOUNT_CHANGED', accounts)
      })
      window.ethereum.on('chainChanged', (chainId) => {
        dispatch(authActions.walletNetworkChanged(chainId))
        console.log('CHAIN_CHANGED', chainId)
      })
    }
  }, [])

  return null
}