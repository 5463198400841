import { Form, message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import API from 'apis/apiProject'
import { useSelector } from 'react-redux'
import CKBaseEditor from 'components/common/ckeditor/CKBaseEditor'

function DetailDescription(props) {
  const [form] = Form.useForm()
  const { setCurrent, current, setBackStep } = props
  const [loading, setLoading] = useState(false)
  const step04Content = useSelector((state) => state.myProject.step03)
  const projectId = useSelector((state) => state.myProject.projectId)
  const { t } = useTranslation()
  const onFinish = async (values) => {
    let body = {
      projectID: projectId,
      information: {
        whatIsThisProject: values.WhatIsThisProject,
        howToUse: values.HowToUse,
        advantageCompetive: values.AdvantageCompetive,
        roadmap: values.Roadmap,
        technology: values.Technology,
        partner: values.Partner,
        marketingStrategy: values.MarketingStrategy,
        tokenEconomy: values.TokenEconomy,
      },
    }
    setLoading(true)
    await createProjectStep4(body)
    setLoading(false)
  }

  const createProjectStep4 = async (body) => {
    const res = await API.createProjectStep3(body)
    if (res?.ResultCode != 200) {
      message.error(res.Message)
    } else {
      setCurrent(current + 1)
      message.success(res.Message)
    }
  }

  // useEffect(() => {
  //   if (step04Content) {
  //     form.setFieldsValue({
  //       whatIsThisProject: step04Content.WhatIsThisProject,
  //       howToUse: step04Content.HowToUse,
  //       advantageCompetive: step04Content.AdvantageCompetive,
  //       roadmap: step04Content.Roadmap,
  //       technology: step04Content.Technology,
  //       partner: step04Content.Partner,
  //       marketingStrategy: step04Content.MarketingStrategy,
  //       tokenEconomy: step04Content.TokenEconomy,
  //     })
  //     setLoading(false)
  //   } else {
  //     setLoading(false)
  //   }
  // }, [step04Content])
  return (
    <>
      <Spin spinning={loading}>
        <Form form={form} initialValues={step04Content} layout="vertical" name="time_related_controls" onFinish={onFinish}>
          <div className="form-main">
            <div className="bg-step">
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>What is this Buidl</h5>
                    <hr />
                    <p>
                      General description of the project, business model, business story, etc ...
                      through a short paragraph or a pre-designed image.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="WhatIsThisProject"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"What is this Buidl is require"
                      },
                    ]}
                  >
                    <CKBaseEditor />
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>How to use</h5>
                    <hr />
                    <p>
                      Description of project features and product usage. Encourage feature
                      cataloging and clarifying salient features.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="HowToUse"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"How to use is require"
                      },
                    ]}
                  >
                    <CKBaseEditor/>
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Advantage Competitive</h5>
                    <hr />
                    <p>
                      A description of the product’s competitive advantage over other products on
                      the market. Or a description of the project’s highlights.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="AdvantageCompetive"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"Advantage Competitive is require"
                      },
                    ]}
                  >
                    <CKBaseEditor
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Roadmap</h5>
                    <hr />
                    <p>
                      More details on the product development roadmap or insert a picture with a
                      clearly designed project timeline with the goals to be achieved in each phase.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="Roadmap"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"Roadmap is require"
                      },
                    ]}
                  >
                    <CKBaseEditor/>
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Technology</h5>
                    <hr />
                    <p>Add details for your event like: Schedule, sponsors or special guest.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="Technology"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message: "Technology is require"
                      },
                    ]}
                  >
                    <CKBaseEditor/>
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Partner</h5>
                    <hr />
                    <p>
                    More information about your partners who are consulting or collaborating with you to develop the project. You can use images to list partners.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="Partner"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"Partner is require"
                      },
                    ]}
                  >
                    <CKBaseEditor/>
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Marketing strategy</h5>
                    <hr />
                    <p>
                    More information about the marketing plan. Need to mention highlights in marketing strategy or how to build community.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="MarketingStrategy"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message: "MarketingStrategy is require"
                      },
                    ]}
                  >
                    <CKBaseEditor />
                  </Form.Item>
                </div>
              </div>

              <div className="row mt-6 d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Token Economy</h5>
                    <hr />
                    <p>More basic information about token allocation, how to use tokens.</p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.Item
                    name="TokenEconomy"
                    valuePropName="data"
                    label={t('myProject.detail')}
                    rules={[
                      {
                        required: true,
                        // message: `${t('myProject.inputRequired')}`,
                        message:"Token Economy is require"
                      },
                    ]}
                  >
                    <CKBaseEditor />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => {
                  setBackStep(true)
                  setCurrent(current - 1)
                }}
              >
                {t('button.back')}
              </button>
              <button type="submit" htmlType="submit" className="btn-submit">
                {t('button.saveAndContinue')}
              </button>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}
export default DetailDescription
