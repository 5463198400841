import { useState } from 'react'
import { useEffect } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { isMobile } from 'react-device-detect';
const { Panel } = Collapse

const buidlTotalRaiseFilterOptions = [
  {
    key: 1,
    content: '$0 - $50.000',
  },
  {
    key: 2,
    content: '$50.000 - $100.000',
  },
  {
    key: 3,
    content: '$100.000 - $200.000',
  },
  {
    key: 4,
    content: '$200.000 - $500.000',
  },
  {
    key: 5,
    content: '$500.000 - $1.000.000',
  },
  {
    key: 6,
    content: '$1.000.000 - $2.000.000',
  },
  {
    key: 7,
    content: '$2.000.000 - $5.000.000',
  },
]

const totalFundsFilterOptions = [
  {
    key: 1,
    content: '$0 - $100.000',
  },
  {
    key: 2,
    content: '$100.000 - $500.000',
  },
  {
    key: 3,
    content: '$500.000 - $1.000.000',
  },
  {
    key: 4,
    content: '$1.000.000 - $5.000.000',
  },
  {
    key: 5,
    content: '$5.000.000- $10.000.000',
  },
  {
    key: 6,
    content: '$10.000.000- $50.000.000',
  },
  {
    key: 7,
    content: '$50.000.000 - $100.000.000',
  },
]

const buidlTrendingFilterOptions = [
  {
    key: 1,
    content: 'Web3',
  },
  {
    key: 2,
    content: 'Chain',
  },
  {
    key: 3,
    content: 'GameFi',
  },
  {
    key: 4,
    content: 'Metaverse',
  },
  {
    key: 5,
    content: 'DAO',
  },
  {
    key: 6,
    content: 'DeFi',
  },
]

const trendingFilterOptions = [
  {
    key: 1,
    content: 'DAO',
  },
  {
    key: 2,
    content: 'NFT',
  },
  {
    key: 3,
    content: 'GameFi',
  },
  {
    key: 4,
    content: 'Metaverse',
  },
  {
    key: 5,
    content: 'X-2-Earn',
  },
  {
    key: 6,
    content: 'Music NFT',
  },
  {
    key: 7,
    content: 'ETH 2.0',
  },
  {
    key: 8,
    content: 'L2',
  },
  {
    key: 9,
    content: 'ZK',
  },
  {
    key: 10,
    content: 'Frontier Tech',
  },
]

const investedFieldFilterOptions = [
  {
    key: 1,
    content: 'GameFi',
  },
  {
    key: 2,
    content: 'Edtech',
  },
  {
    key: 3,
    content: 'Martech',
  },
  {
    key: 4,
    content: 'Software as a Services',
  },
  {
    key: 5,
    content: 'Metaverse',
  },
  {
    key: 6,
    content: 'AI',
  },
  {
    key: 7,
    content: 'Web3',
  },
  {
    key: 8,
    content: 'Agritech',
  },
  {
    key: 9,
    content: 'Social Platform',
  },
  {
    key: 10,
    content: 'Health Care',
  },
  {
    key: 11,
    content: 'Social Platform',
  },
  {
    key: 12,
    content: 'E-Commerce',
  },
  {
    key: 13,
    content: 'Real Estate',
  },
  {
    key: 14,
    content: 'Banking',
  },
  {
    key: 15,
    content: 'Automative',
  },
  {
    key: 16,
    content: 'Analystics',
  },
  {
    key: 17,
    content: 'Advertising',
  },
  {
    key: 18,
    content: 'Security',
  },
  {
    key: 19,
    content: 'Other',
  },
]

const chainFilterOptions = [
  {
    key: 1,
    content: 'Binance smart',
  },
  {
    key: 2,
    content: 'Opensea',
  },
  {
    key: 3,
    content: 'Binance Incubation',
  },
  {
    key: 4,
    content: 'Dora Factory',
  },
  {
    key: 5,
    content: 'Harmony',
  },
  {
    key: 7,
    content: 'Filecoin',
  },
  {
    key: 8,
    content: 'Ayala',
  },
]

const Accordion = (props) => {
  const {
    setFilterTotalFund,
    setListFilterTrending,
    setListInvestedField,
    isClear,
    setIsClear,
    param,
  } = props
  const data = [
    {
      key: 1,
      title: 'Trending',
      content: (
        <Trending
          setListFilterTrending={setListFilterTrending}
          isClear={isClear}
          setIsClear={setIsClear}
          param={param}
        />
      ),
    },
    {
      key: 2,
      title: 'Interested field',
      content: (
        <InvestedField
          setListInvestedField={setListInvestedField}
          isClear={isClear}
          setIsClear={setIsClear}
          param={param}
        />
      ),
    },
    {
      key: 3,
      title: 'Total fund',
      content: (
        <TotalFunds
          setFilterTotalFund={setFilterTotalFund}
          isClear={isClear}
          setIsClear={setIsClear}
          param={param}
        />
      ),
    },
  ]
  return (
    <>
      <Collapse
        bordered={false}
        defaultActiveKey={isMobile?['']:['1', '2', '3']}
        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition={'end'}
        className="site-collapse-custom-collapse"
      >
        {data.map((item) => {
          return (
            <Panel header={item.title} key={item.key} className="site-collapse-custom-panel">
              {item.content}
            </Panel>
          )
        })}
      </Collapse>
    </>
  )
}
export default Accordion

const TotalFunds = ({ setFilterTotalFund, isClear, param }) => {
  const onChange = () => {
    const checked = document.querySelectorAll('.fund:checked')
    let checkboxValues = []
    checked.forEach((item) => {
      checkboxValues.push(item.id)
    })
    const d = Object.assign({}, checkboxValues)
    d.checkboxValues = checkboxValues.join(';')
    setFilterTotalFund(d.checkboxValues)
  }

  useEffect(() => {
    console.log('clear TotalFunds')
    for (let i = 0; i < totalFundsFilterOptions.length; i++) {
      if (document.getElementById(totalFundsFilterOptions[i].content))
        document.getElementById(totalFundsFilterOptions[i].key).checked = false
    }
  }, [isClear])

  return (
    <>
      <div className="col-12">
        {totalFundsFilterOptions.map((item) => {
          return (
            <div key={item.key} className="vc-item-filter">
              <input id={item.key} type="checkbox" className="checkboxField fund" onChange={onChange} />
              <label for="cb">{item.content}</label>
            </div>
          )
        })}
      </div>
    </>
  )
}

const Trending = ({ setListFilterTrending, param, isClear }) => {
  const [dataDropsit, setDataDropsit] = useState(trendingFilterOptions.slice(0, 7))
  const [isDropsit, setIsDropsit] = useState(false)
  const onChange = () => {
    const checked = document.querySelectorAll('.trend:checked')
    let checkboxValues = []
    checked.forEach((item) => {
      checkboxValues.push(item.id)
    })
    const d = Object.assign({}, checkboxValues)
    d.checkboxValues = checkboxValues.join(';')
    setListFilterTrending(d.checkboxValues)
  }
  useEffect(() => {
    for (let i = 0; i < trendingFilterOptions.length; i++) {
      if (document.getElementById(trendingFilterOptions[i].content))
        document.getElementById(trendingFilterOptions[i].content).checked = false
    }
  }, [isClear])

  const showMore = () => {
    setDataDropsit(trendingFilterOptions)
    setIsDropsit(true)
  }
  const HideMore = () => {
    setDataDropsit(trendingFilterOptions.slice(0, 7))
    setIsDropsit(false)
  }
  return (
    <>
      <div className="col-12">
        {dataDropsit.map((item) => {
          return (
            <div key={item.key} className="vc-item-filter">
              <input
                id={item.content}
                type="checkbox"
                className="checkboxField trend"
                onChange={onChange}
                checked={param.listFilterTrending?.includes(item.content)}
              />
              <label for="cb">{item.content}</label>
            </div>
          )
        })}
        {!isDropsit ? (
          <div className="text-ocean-2 filter-show-more" onClick={showMore}>
            Show more ({trendingFilterOptions.length - 7})
          </div>
        ) : (
          <div className="text-ocean-2 filter-show-more" onClick={HideMore}>
            Hide more
          </div>
        )}
      </div>
    </>
  )
}

const InvestedField = ({ setListInvestedField, param, isClear }) => {
  const onChange = () => {
    const checked = document.querySelectorAll('.investField:checked')
    let checkboxValues = []
    checked.forEach((item) => {
      if (item.id.split('#')) {
        checkboxValues.push(item.id.split('#')[1])
      }
    })
    const d = Object.assign({}, checkboxValues)
    d.checkboxValues = checkboxValues.join(';')
    setListInvestedField(d.checkboxValues)
  }
  useEffect(() => {
    for (let i = 0; i < investedFieldFilterOptions.length; i++) {
      if (document.getElementById(investedFieldFilterOptions[i].content))
        document.getElementById('#' + investedFieldFilterOptions[i].content).checked = false
    }
  }, [isClear])
  const [dataDropsit, setDataDropsit] = useState(investedFieldFilterOptions.slice(0, 7))
  const [isDropsit, setIsDropsit] = useState(false)

  const showMore = () => {
    setDataDropsit(investedFieldFilterOptions)
    setIsDropsit(true)
  }
  const HideMore = () => {
    setDataDropsit(investedFieldFilterOptions.slice(0, 7))
    setIsDropsit(false)
  }

  console.log();
  return (
    <>
      <div className="col-12">
        {dataDropsit.map((item) => {
          return (
            <div key={item.key} className="vc-item-filter">
              <input
                id={`#${item.content}`}
                type="checkbox"
                className="checkboxField investField"
                onChange={onChange}
                checked={param.listFilterInvestedField?.includes(item.content)}
              />
              <label for="cb">{item.content}</label>
            </div>
          )
        })}
        {!isDropsit ? (
          <div className="text-ocean-2 filter-show-more" onClick={showMore}>
            Show more ({investedFieldFilterOptions.length - 7})
          </div>
        ) : (
          <div className="text-ocean-2 filter-show-more" onClick={HideMore}>
            Hide more
          </div>
        )}
      </div>
    </>
  )
}

export {
  trendingFilterOptions,
  investedFieldFilterOptions,
  totalFundsFilterOptions,
  chainFilterOptions,
  buidlTotalRaiseFilterOptions,
  buidlTrendingFilterOptions,
}
