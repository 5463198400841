import { Button, message } from 'antd'
import { useTranslation } from 'react-i18next'
import Milestone from './Milestone'
import { v4 as newId } from 'uuid'
import { forwardRef, memo, useImperativeHandle, useRef } from 'react'
import { saveStep3 } from 'apis/apipool'
import { apiCall, convertToTimestamp } from 'utils/common'
import { setStep3Info, setStep, setBusy } from 'redux/reducers/pool/createPoolSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
const PoolMilestone = forwardRef((props, ref) => {
  const arrml = [
    { index: 1, formRef: useRef(null) },
    { index: 2, formRef: useRef(null) },
    { index: 3, formRef: useRef(null) },
    { index: 4, formRef: useRef(null) },
    { index: 5, formRef: useRef(null) },
    { index: 6, formRef: useRef(null) },
    { index: 7, formRef: useRef(null) },
    { index: 8, formRef: useRef(null) },
    { index: 9, formRef: useRef(null) },
    { index: 10, formRef: useRef(null) },
    { index: 11, formRef: useRef(null) },
    { index: 12, formRef: useRef(null) },
    { index: 13, formRef: useRef(null) },
    { index: 14, formRef: useRef(null) },
    { index: 15, formRef: useRef(null) },
    { index: 16, formRef: useRef(null) },
    { index: 17, formRef: useRef(null) },
    { index: 18, formRef: useRef(null) },
    { index: 19, formRef: useRef(null) },
    { index: 20, formRef: useRef(null) },
  ]
  const dispatch = useDispatch()
  const poolId = useSelector((state) => state.createPoolSlice.poolId)
  const step3Infor = useSelector((state) => state.createPoolSlice.step3Infor)
  const step1Infor = useSelector((state) => state.createPoolSlice.step1Infor)
  useImperativeHandle(ref, () => ({
    submit() {
      submitData()
    },
  }))
  const { t } = useTranslation()
  const submitData = async () => {
    const lstMilestone = []
    const lstMilestoneSave = []
    let abort = false
    if (!step3Infor || step3Infor.length == 0) {
      message.error('Pool must have at least a milestone.')
      return
    }
    const lstRedux = []
    arrml.forEach((item, idx, arr) => {
      if (item.formRef && item.formRef.current) {
        const obj = item.formRef.current.getData()
        if (obj) {
          lstRedux.push({ ...obj })
          const objSave = { ...obj }
          objSave.StartTime = convertToTimestamp(objSave.StartTime)
          objSave.EndTime = convertToTimestamp(objSave.EndTime)
          lstMilestoneSave.push(objSave)
        }
        lstMilestone.push(obj)
        if (idx > 0) {
          if (lstMilestoneSave[idx]?.StartTime < lstMilestoneSave[idx - 1].EndTime) {
            const msg = `Milestone ${arr[idx].index} start time must be after previous milestone's end time`
            message.error(msg)
            abort = true
          }
        } else {
          if (lstMilestoneSave[0]?.StartTime < convertToTimestamp(step1Infor.EndTime)) {
            message.error(`Milestone start time must be after pool's end time`)
            abort = true
          }
        }
      }
    })
    if (abort) {
      return
    }
    if (validateData(lstMilestone)) {
      var totalPercent = 0 //lstMilestoneSave.reduce((a, b) => a.DisbursementPer + b.DisbursementPer, 0);
      lstMilestoneSave.map((item) => {
        totalPercent += item.DisbursementPer
      })
      if (totalPercent !== 100) {
        message.error('Total percent of release fund must be 100% ')
        return
      }
      var obj = {
        poolID: poolId,
        information: {
          milestones: lstMilestoneSave,
        },
      }
      dispatch(setBusy(true))
      dispatch(setStep3Info(lstRedux))
      var rs = await apiCall(saveStep3, [obj])
      if (rs && rs.data && rs.data.ResultCode == '200') {
        dispatch(setStep(3))
      }
      dispatch(setBusy(false))
    }
  }
  const validateData = (lstMilestone) => {
    var result = true
    if (lstMilestone.length == 0) return false
    const hasNullObj = lstMilestone.filter((item) => item === null).length > 0
    if (hasNullObj) return false
    return result
  }
  const addMilestone = () => {
    const currentMilestone = step3Infor.map((item, index) => {
      return {
        key: item.key,
        MilestoneName: 'Milestone ' + (index + 1).toString(),
        OrderBy: index + 1,
        StartTime: item.StartTime,
        EndTime: item.EndTime,
        Goal: item.Goal,
      }
    })
    const key = newId()
    const newMilestone = {
      key: key,
      MilestoneName: 'Milestone ' + (currentMilestone.length + 1).toString(),
      OrderBy: step3Infor.length + 1,
      StartTime: currentMilestone[currentMilestone.length - 1].EndTime,
      EndTime: null,
      Goal: [],
    }
    dispatch(setStep3Info([...currentMilestone, newMilestone]))
  }
  const deleteItem = (key) => {
    var newData = step3Infor.filter((item) => item.key != key)
    dispatch(setStep3Info([...newData]))
  }
  const isDisable = () => {
    if (step1Infor && step1Infor.SmartID) return true
    return false
  }
  return (
    <>
      {step3Infor
        ? step3Infor.map((item, index) => (
            <Milestone
              disabled={isDisable()}
              key={item.key}
              ref={arrml[index].formRef}
              deleteItem={deleteItem}
              data={{ ...item }}
            />
          ))
        : null}
      {isDisable() ? null : (
        <button
          type="button"
          className="btn-submit"
          style={{ display: 'flex', alignItems: 'center', width: 200 }}
          onClick={addMilestone}
        >
          <PlusOutlined style={{ color: 'white', fontSize: 20, paddingRight: 5 }} />
          {t('createPool.addMilestone')}
        </button>
      )}
    </>
  )
})
export default memo(PoolMilestone)
