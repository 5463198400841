import { Link } from 'react-router-dom'

import chainAptos from '../assets/images/multichain/chain-aptos.png'
import chainAvax from '../assets/images/multichain/chain-avax.png'
import chainBSC from '../assets/images/multichain/chain-bsc.png'
import chainEth from '../assets/images/multichain/chain-eth.png'
import chainOkex from '../assets/images/multichain/chain-okex.png'
import chainPolygon from '../assets/images/multichain/chain-polygon.png'
import chainSol from '../assets/images/multichain/chain-sol.png'

import picGovern from '../assets/images/picture-01.jpg'

import imgFunding from '../assets/images/icon-01.png'
import imgDaoClub from '../assets/images/icon-02.png'
import imgHackathon from '../assets/images/icon-03.png'

import imgPartnerBitMart from '../assets/images/partner/logo-bitmart.png'
import imgPartnerBitrue from '../assets/images/partner/logo-bitrue.png'
import imgPartnerPearl from '../assets/images/partner/logo-black-pearl.png'
import imgPartnerCls from '../assets/images/partner/logo-cls.png'
import imgPartnerDigifinex from '../assets/images/partner/logo-digifinex.png'
import imgPartnerLbank from '../assets/images/partner/logo-lbank.png'
import imgPartnerMaxCapital from '../assets/images/partner/logo-max-captial.png'
import imgPartnerMexc from '../assets/images/partner/logo-mexc.png'
import imgPartnerOkex from '../assets/images/partner/logo-okex.png'
import imgPartnerVestingCapital from '../assets/images/partner/logo-vesting-capital.png'
import imgPartnerXt from '../assets/images/partner/logo-xt.png'
import imgPartnerZex from '../assets/images/partner/logo-zex.png'
import imgPartnerBmoon from '../assets/images/partner/logo-bmoon.png'

import { useEffect } from 'react'

function Home(props) {
useEffect(() => {
  const root = document.documentElement;
  const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
  const marqueeContent = document.querySelector("ul.marquee-content");

  root.style.setProperty("--marquee-elements", marqueeContent.children.length);

  for(let i=0; i<marqueeElementsDisplayed; i++) {
    marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
  }
},[])
  return (
    <>
      <section id="slide-top" class="animate two">
        <div class="content-width text-center">
          <h1 class="animate three">Web3 Founders <br /> & Investors Network</h1>
          <h2 class="animate four">Dabase is a protocol for fundraising & asset <br /> management that is secure, transparent, and efficient.</h2>
          <div class="group_btn animate five">
            <Link class="btn-blue" to="">Learn More</Link>
            <Link class="btn-outline" to="">Discover</Link>
          </div>

          <div class="multichain animate six">
            <div class="row">
              <div class="col-4 col-sm-2">
                <h5>
                  Multichain <br />
                  <span>Building</span>                                        
                </h5>
              </div>
              <div class="col-8 col-sm-10 pdl-0">
                <div class="marquee">
                  <ul class="marquee-content">
                    <li><img src={chainAptos} alt="Aptos Chain" /></li>
                    <li><img src={chainAvax} alt="Avax Chain" /></li>
                    <li><img src={chainBSC} alt="Binance Smart Chain" /></li>
                    <li><img src={chainEth} alt="Ethereum Chain" /></li>
                    <li><img src={chainOkex} alt="Okex Chain" /></li>
                    <li><img src={chainPolygon} alt="Matic Polygon Chain" /></li>
                    <li><img src={chainSol} alt="Solana Chain" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <section class="bg-box-focus animate seven">
        <div class="content-width">
          <section id="box-focus">
            <div class="box-bg">
              <article class="box-main">
                <div class="row">
                  <div class="col-12 col-sm-4">
                    <img class="img-fluid" src={picGovern} alt="Govern better, win together" />
                  </div>
                  <div class="col-12 col-sm-8">
                    <div class="content_focus">
                      <h2>Govern better, win together</h2>
                      <p>
                        Dabase makes it easy for anyone to form DAO communities and invest any asset 
                        together in the crypto space. <br /><br />
                        Along with the milestone-based fund management protocol that can be integrated into any 
                        launch pad to minimize risks for investors. Dabase protocol enables investors to develop 
                        a secure funding ecosystem while ensuring results-driven startups in their portfolios.
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </section>
      

      <section id="feature-box" class="space_section">
        <div class="content-width">
          <div class="row">
            <div class="col-12 col-sm-4 child-article">
              <article class="card">
                <img src={imgFunding} alt="IMO DAO Funding" />
                <h3>IMO DAO Funding</h3>
                <p>The next generation of startup fundraising with milestonebased funding releases.</p>
              </article>
            </div>
            <div class="col-12 col-sm-4 child-article">
              <article class="card">
                <img src={imgDaoClub} alt="DAOCLUB" />
                <h3>DAOCLUB</h3>
                <p>A collaborative, group investment platform that enables web3 users form a DAO investor group, pool money, and manage joint investments.</p>
              </article>
            </div>
            <div class="col-12 col-sm-4 child-article">
              <article class="card">
                <img src={imgHackathon} alt="Hackathon" />
                <h3>Hackathon</h3>
                <p>The hub of hackathon to support buidlers.</p>
              </article>
            </div>
          </div>
        </div>
      </section>
      

      <section id="partner">
        <div class="content-width">
          <h2 data-wow-delay="0.4s" >Partner & Backers</h2>
          <div class="marquee">
            <ul class="marquee-content">
              <li><img src={imgPartnerBitMart} alt="BitMart" /></li>
              <li><img src={imgPartnerBitrue} alt="BitTrue" /></li>
              <li><img src={imgPartnerPearl} alt="Black Pearl Venture" /></li>
              <li><img src={imgPartnerCls} alt="CLS" /></li>
              <li><img src={imgPartnerDigifinex} alt="Digifinex" /></li>
              <li><img src={imgPartnerLbank} alt="LBank" /></li>
              <li><img src={imgPartnerMaxCapital} alt="Maxx Captial" /></li>
              <li><img src={imgPartnerMexc} alt="MEXC Global" /></li>
              <li><img src={imgPartnerOkex} alt="Okex" /></li>
              <li><img src={imgPartnerVestingCapital} alt="" /></li>
              <li><img src={imgPartnerXt} alt="xt.com" /> </li>
              <li><img src={imgPartnerZex} alt="ZEX" /></li>
              <li><img src={imgPartnerBmoon} alt="BMoon" /></li>
            </ul>
          </div>
        </div>
      </section>
      

      <section id="get-it-touch">
          <div class="content-width">
              <p>
                  partnership@dabase.xyz <br />
                  support@dabase.xyz
              </p>
              <div class="group_btn">
                  <a class="btn-blue" href="#">Get It Touch!</a>
              </div>
          </div>
      </section>     
    </>
  )
}

export default Home
