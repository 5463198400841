import { useSelector, useDispatch } from 'react-redux'
import { forwardRef, useImperativeHandle } from 'react'
import { setShowDeposit, setStepDeposit } from 'redux/reducers/pool/createPoolSlice'
import PoolDepositProgressModal from 'components/for-startup/pool/create-pool/PoolDepositProgressModal'
import { displayDateTime, dateDiff } from 'utils/common'
import { formatNumber } from 'utils/constants'
import { authSelector } from 'redux/reducers/authSlice'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
const PoolSummary = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const step1Infor = useSelector((state) => state.createPoolSlice.step1Infor)
  const step2Infor = useSelector((state) => state.createPoolSlice.step2Infor)
  const step3Infor = useSelector((state) => state.createPoolSlice.step3Infor)
  const { currentNetwork } = useSelector(authSelector);
  useImperativeHandle(ref, () => ({
    submit() {
      deposit()
    },
  }))
  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }
  const deposit = () => {
    if (checkChangeBeforeSubmit(step1Infor.Chain)) {
      dispatch(setStepDeposit(0))
      dispatch(setShowDeposit(true))
    }
    else {
      dispatch(updateSwitchChainId({
        chainId: step1Infor.Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
    }
  }
  return (
    <>
      <form
        className="form-main"
        style={{ marginBottom: 0 }}
        method="POST"
        action="create-pool-05.html"
      >
        <div className="bg-step">
          <div className="overview-pool">
            <h4>Summary</h4>

            <div className="box-layout">
              <div className="row">
                <div className="col-12 col-sm-2">
                  <div className="figure-avatar">
                    <img className="img-fluid" src={step1Infor.Avatar} alt="" />
                    <p>Avatar</p>
                  </div>
                </div>
                <div className="col-12 col-sm-5">
                  <ul>
                    <li>
                      Name of buidl: <b>{step1Infor.ProjectName}</b>
                    </li>
                    <li>
                      Name of investment pool: <b>{step1Infor.NameInvestment}</b>
                    </li>
                    <li>
                      Chain: <b>{step1Infor.ChainName}</b>
                    </li>
                    <li>
                      Token name:<b>{step1Infor.TokenSymbol}</b>
                    </li>
                    <li>
                      Amount of token sell:
                      <b>
                        {formatNumber(step2Infor.AmountOfTokenSell)} {step1Infor.TokenSymbol}
                      </b>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-5">
                  <ul>
                    <li>
                      Start time of fundraising: <b>{displayDateTime(step1Infor.StartTime)}</b>
                    </li>
                    <li>
                      End time of fundraising: <b>{displayDateTime(step1Infor.EndTime)}</b>
                    </li>
                    <li>
                      Total fundraising:{' '}
                      <b >
                        {formatNumber(step2Infor.AmountOfTokenSell * step2Infor.PriceOfToken)}{' '}
                        {step1Infor.CurrencyName}
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="box-layout">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <ul>
                    <li>
                      TGE Time: <b>{displayDateTime(step2Infor.TGETime)}</b>
                    </li>
                    <li>
                      First release time: <b>{displayDateTime(step2Infor.FirstReleaseTime)}</b>
                    </li>
                    <li>
                      Cliff Time:<b>{step2Infor.CliffTime} days</b>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-6">
                  <ul>
                    <li>
                      First release percent: <b>{step2Infor.FirstReleasePercent} %</b>
                    </li>
                    <li>
                      Linear Unlock Time: <b>{step2Infor.LinearUnlockTime} days</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {step3Infor.map((item) => {
              return (
                <div className="box-layout">
                  <div className="row">
                    <div className="col-12 col-sm-3">
                      <h5>{item.MilestoneName}</h5>
                    </div>
                    <div className="col-12 col-sm-9">
                      <div className="d-flex justify-content-space-between">
                        <div>
                          Start Time:
                          <b className="violent">{displayDateTime(item.StartTime)}</b>
                        </div>
                        <div>
                          Duration:
                          <b className="violent">
                            {dateDiff(item.StartTime, item.EndTime)}
                            days
                          </b>
                        </div>
                        <div>
                          Disbursement Per Milestone:{' '}
                          <b className="violent">{item.DisbursementPer}%</b>
                        </div>
                      </div>
                      <p>
                        {item.Goal.map((obj) => {
                          return (
                            <div>
                              <span>{obj.GoalName}</span>
                            </div>
                          )
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </form>
      <PoolDepositProgressModal />
    </>
  )
})
export default PoolSummary
