import React from 'react'
import { Tabs } from 'antd'
import { AppliedWhitelistTable } from './AppliedWhitelistTable'
import { MyWinWhiteList } from './MyWinWhiteList'
import { NotWinWhitelistTable } from './NotWinWhitelistTable'
import { MyClaim } from './MyClaim'
import { TransferToVestingTable } from './TransferToVestingTable'
import { MyRegisterVote } from './MyRegisterVote'

const { TabPane } = Tabs
export const MyInvestmentList = () => {
  return (
    <Tabs>
      <TabPane tab="Apply Whitelist" key="1">
        <AppliedWhitelistTable />
      </TabPane>
      <TabPane tab="Win Whitelist" key="2">
        <MyWinWhiteList />
      </TabPane>
      <TabPane tab="Transfer to Vesting" key="3">
        <TransferToVestingTable />
      </TabPane>
      <TabPane tab="Not Win Whitelist" key="4">
        <NotWinWhitelistTable />
      </TabPane>
      <TabPane tab="Register Vote" key="5">
        <MyRegisterVote />
      </TabPane>
      <TabPane tab="Claimable" key="6">
        <MyClaim />
      </TabPane>
    </Tabs>
  )
}
