import { createSlice } from '@reduxjs/toolkit'

// Common shared data

const initialState = {
  currencies: []
}

const slice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    updateCurrencies: (state, { payload }) => {
      state.currencies = payload
    },
  },
})

// Action creators
export const sharedActions = slice.actions

// Selector
export const sharedSelector = (state) => state.shared

export default slice.reducer
