import React from 'react'
import { Table } from 'antd'
import { getListPoolInvestor } from 'apis/apiPoolInvestor'
import { useSelector } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { InvestmentWhiteListStatus } from 'utils/constants'
import { PoolInfoCell } from 'components/pool/PoolInfoCell'

const columns = [
  {
    title: 'Pool Name',
    dataIndex: 'PoolName',
    key: 'PoolName',
    render: (text, record) => {
      return <PoolInfoCell pool={record} />
    },
  },
  {
    title: 'Total token sale',
    dataIndex: 'TotalTokenSale',
    key: 'TotalTokenSale',
  },
]

const { NOT_WIN_WHITELIST } = InvestmentWhiteListStatus

export const NotWinWhitelistTable = (props) => {
  const { walletID } = useSelector(authSelector)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { OK, Data, Message } = await getListPoolInvestor({
        WalletAddress: walletID,
        AddWhiteList_Status: NOT_WIN_WHITELIST,
        pageIndex: 1,
        pageSize: 1000,
      })
      if (OK) {
        setData(Data)
      } else {
        console.log('getListPoolInvestor', Message)
      }
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  return <Table loading={loading} dataSource={data} columns={columns} pagination={false} />
}
