import { Link, Outlet, useLocation } from 'react-router-dom'
import iconPerson from 'assets/images/icon-person.png'
import iconGrip from 'assets/images/icon-grid.png'
import iconChart from 'assets/images/icon-grid.png'
import iconVote from 'assets/images/icon-vote.png'
import iconClaim from 'assets/images/icon-diamond-24x24.png'
import iconInvestorProfile from 'assets/images/icon-profile.png'
import iconInvestmentList from 'assets/images/investment-list.png'
import iconPools from 'assets/images/icon-pools.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { userProfileSelector } from 'redux/reducers/userProfile'
const Dashboard = (props) => {
  let location = useLocation()
  const userProfile = useSelector(userProfileSelector)
  const [listPathForStartUp, setListPathForStartUp] = useState([
    {
      key: 1,
      pathname: '/dashboard/my-profile',
      icon: iconPerson,
      name: 'My Profile',
    },
    {
      key: 2,
      pathname: '/dashboard/myproject',
      icon: iconGrip,
      name: 'My Buidls',
    },
    {
      key: 3,
      pathname: '/dashboard/pools',
      icon: iconPools,
      name: 'My Pools',
    },
    {
      key: 4,
      pathname: '/dashboard/startup/vote-detail',
      icon: iconVote,
      name: 'Vote Detail',
    },
    {
      key: 5,
      pathname: '/dashboard/claims',
      icon: iconClaim,
      name: 'Claim',
    },
  ])
  const [listPathForVC, setListPathForVC] = useState([
    {
      key: 1,
      pathname: '/dashboard/my-profile',
      icon: iconPerson,
      name: 'My Profile',
    },
    {
      key: 2,
      pathname: '/dashboard/investor/profile',
      icon: iconInvestorProfile,
      name: 'Investor Profile',
    },
    {
      key: 4,
      pathname: '/dashboard/investor/my-investment',
      icon: iconInvestmentList,
      name: 'Investment list',
    },
    {
      key: 3,
      pathname: '/dashboard/investor/vote-detail',
      icon: iconVote,
      name: 'Vote Detail',
    },
  ])

  const getMenuOfRole = () => {
    if (userProfile?.RoleStartup) {
      return listPathForStartUp
    } else if (userProfile?.RoleVC) {
      return listPathForVC
    }
    return []
  }
  const getTitleDashboard = () => {
    if (userProfile?.RoleStartup) {
      return 'For Startup'
    } else if (userProfile?.RoleVC) {
      return 'For Investors'
    }
    return 'DStarter'
  }
  return (
    <>
      <div>
        <div className="row">
          <div className="col-12 col-sm-2">
            <div className="title_main">
              <h2>{getTitleDashboard()}</h2>
            </div>
            <ul className="list-menu-aside">
              {getMenuOfRole()
                ? getMenuOfRole().map((value, key) => {
                    return (
                      <li key={key} className={location.pathname === value.pathname ? 'active' : ''}>
                        <Link to={value.pathname}>
                          <img  src={value.icon} alt="" /> {value.name}
                        </Link>
                      </li>
                    )
                  })
                : null}
            </ul>
          </div>
          <div className="col-12 col-sm-10">
            <Outlet />
          </div>
        </div>
        {/* <div className="title_main row">
          <div className="col-12 col-sm-3">
            <h2>{userProfile?.RoleStartup ? 'For Startup' : 'For Investors'}</h2>
          </div>
          <div className="col-12 col-sm-9"></div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-2">
            <aside>
              <ul className="list-menu-aside">
                {(userProfile?.RoleStartup ? listPathForStartUp : listPathForVC).map(
                  (value, key) => {
                    return (
                      <li className={location.pathname === value.pathname ? 'active' : ''}>
                        <Link to={value.pathname}>
                          <img src={value.icon} alt="" /> {value.name}
                        </Link>
                      </li>
                    )
                  },
                )}
              </ul>
            </aside>
          </div>
          <div className="col-12 col-sm-10">
            <Outlet />
          </div>
        </div> */}
      </div>
    </>
  )
}
export default Dashboard
