import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: '',
  tokenExpire: 0,
  refreshToken: '',
  walletID: '',
  currentNetwork:null,
  listChain: [],
  listContract: [],

  requestConnectWallet: false,
  requestBackendAuth: false,

  selectUserRoleInProgress: false,
  selectUserRoleError: false,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    walletConnected: (state, { payload }) => {
      state.walletID = payload
      state.requestConnectWallet = false
    },
    authenticated: (state, { payload }) => {
      state.accessToken = payload
      state.requestBackendAuth = false
      // TODO parse jwt expiration
    },
    deauthenticated: (state) => {
      state.accessToken = ''
      state.userProfile = {}
    },
    requestConnectWallet: (state, { payload }) => {
      if (typeof payload === 'undefined') {
        state.requestConnectWallet = true
      } else {
        state.requestConnectWallet = !!payload
      }
    },
    cancelRequestConnectWallet: (state) => {
      state.requestConnectWallet = false
    },
    requestBackendAuth: (state, { payload }) => {
      if (typeof payload === 'undefined') {
        state.requestBackendAuth = true
      } else {
        state.requestBackendAuth = !!payload
      }
    },
    connectMetaMask: () => {},
    connectWalletConnect: () => {},
    authenticateWithBackend: () => {},

    selectUserRoleStart: (state, { payload }) => {
      state.selectUserRoleInProgress = true
      state.selectUserRoleError = false
    },
    globalLogout: (state) => {
      // do not set initialState here, leave the saga putting global clearing action
      // return initialState
    },
    invalidate: () => {
      //invalidate current auth data to relogin
      return initialState
    },
    walletAccountChanged: (state, { payload }) => {},
    walletNetworkChanged: (state, { payload }) => {},
    setCurrentNetWork: (state, { payload }) => {
      state.currentNetwork=payload;
    }
  },
})

// Action creators
export const authActions = slice.actions

// Selector
export const authSelector = (state) => state.auth

export default slice.reducer
