import moment from 'moment'
import { authActions } from 'redux/reducers/authSlice'
import store from 'redux/stores'
import { requestSwitchNetwork, switchNetwork } from 'smartcontract/common'

export function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

export function getAuthorizationHeader() {
  const accessToken = store.getState().auth.accessToken
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  }
  return config
}

export function getDefaultHeader() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return config
}
export function formatDateTime(momentDate) {
  if (!momentDate) {
    return ''
  }
  return momentDate.format('YYYY-MM-DD HH:mm')
}
export function displayDateTime(momentDate) {
  return momentDate.format('YYYY-MM-DD HH:mm')
}
export function dateDiff(from, to) {
  return to.diff(from, 'days')
}
export function formartDateTimeFromUnix(unix) {
  var momentDate = new moment.unix(unix)
  return momentDate.format('YYYY-MM-DD HH:mm')
}
export function convertDatetimeFromTimestamp(unix) {
  if (unix) {
    const d = new moment.unix(unix)
    return d
  }
  return new moment()
}
export function convertToTimestamp(dateMomment) {
  if (!dateMomment) return 0
  return dateMomment.unix()
}
export async function apiCall(callback, args) {
  var res = await doCallBack(callback, args)
  if (res.status === 401) {
    const accessToken = store.getState().auth.accessToken
    if (accessToken) {
      res = await doCallBack(callback, args)
    } else {
      // store.dispatch({type: 'ROOT_REDUCER_RESET'})
      store.dispatch(authActions.invalidate())
    }
  }
  return res
}
function doCallBack(callback, args) {
  var res = null
  res = callback
    .apply(this, args)
    .then((result) => (res = result))
    .catch((err) => (res = err.response))
  return res
}
/**
 * Setup request interceptor to set access token
 * @param {*} axiosInstance axios instance including default instance (axios)
 * @returns that instance
 */
export function setupAxiosInterceptor(axiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const accessToken = store.getState().auth.accessToken
      if (accessToken) {
        config.headers['Authorization'] = accessToken
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )
  return axiosInstance
}

/**
 * Extract Data from common API response body structure
 * @param data {*} API response body
 * @returns
 */
export const dStarterApiDataExtractor = (data) => {
  const { ResultCode, Message, Timestamp, Data, ...rest } = data
  if (ResultCode == '200') {
    return { OK: true, HttpStatus: 200, Message, Timestamp, Data, ...rest }
  } else {
    return { OK: false, HttpStatus: 200, Code: ResultCode, ResultCode, Message, Data, ...rest }
  }
}
/**
 * Catch error and form a common structure
 * @param {*} error
 * @returns
 */
export const dStarterApiErrorHandler = (error) => {
  let result = {}
  if (error.ResultCode) {
    result = { OK: false, HttpStatus: error.response.status, ...error }
  } else {
    result = {
      OK: false,
      HttpStatus: error.response.status,
      Code: -1,
      ResultCode: -1,
      Message: error.message,
      Data: error,
    }
  }
  if (result.HttpStatus === 401) {
    // unauthorized, mostly due to expired or invalid access token
    store.dispatch(authActions.invalidate())
  }
  return result
}

export const debounce = (func) => {
  let timer
  return function (...args) {
    const context = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(context, args)
    }, 500)
  }
}

/**
 * Switch to chainId defined by REACT_APP_DEFAULT_CHAINID env variable
 */
export const switchToDefaultNetwork = (chain) => {
  console.log('Switching to default chain')
  return requestSwitchNetwork(chain)
}
export const getIconChain = (chainId) => {
  const listChain = store.getState().network.listChain
  const chain = listChain.find((item) => item.chainID === chainId)
  return chain?.icon
}
export const getChainById = (chainId) => {
  const listChain = store.getState().network.listChain
  let chain = listChain?.find((item) => item.chainID === chainId);
  if (!chain) chain = { icon: '', chainId: '', chainName: '' }
  return chain;
}
export const getCurrencyById = (id) => {
  const listCurrency = store.getState().network.listCurrency
  const currency = listCurrency.find((item) => item.id === id)
  return currency?.symbol
}

export const getContractByCode = (code,chainId) => {
  var result = ''
  const listContract = store.getState().network.listContract
  const currentNetwork = store.getState().auth.currentNetwork
  if (listContract) {
    const res = listContract?.find(
      (item) => item.code === code && item.chain && item.chain.chainID === (chainId??currentNetwork.chainID)
    )
    result = res.address
  }
  if (!result) console.log('Can not get smart contract address by code ' + code)
  return result
}
