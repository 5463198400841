import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

export default function PoolFunction(props) {
    const { t } = useTranslation();


    <button type="submit" className="btn-submit">Continue</button>

    return <div className="btn-group">
        <Space>
            {
                props.steps === 0 ? <button type="button" className="btn-cancel" onClick={() => { if (props.onCancel) props.onCancel(); }} >{t("button.cancel")}</button> : null
            }
            {
                (props.steps > 0 && props.steps < 4) ? <button type="button" className="btn-cancel" onClick={() => { if (props.onPrevious) props.onPrevious(); }}>{t("button.previous")}</button> : null
            }
            {
                props.steps < 3 ? <button type="button" className="btn-submit" onClick={() => { if (props.onNext) props.onNext(); }}>{t("button.next")}</button> : null
            }
            {
                props.steps === 3 ? <button type="button" className="btn-submit" onClick={() => { if (props.onDeposit) props.onDeposit(); }}>{t("button.deposit")}</button> : null
            }
            {/* {
                props.steps === 4 ? <>
                    <button type="button" className="btn-cancel" onClick={() => { if (props.onBackList) props.onBackList(); }}>{t("button.backPool")}</button>
                </> : null
            } */}
        </Space>
    </div>
}