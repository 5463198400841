import { getSigner, getSignerByRPC, getSmartContract } from 'smartcontract/common'
import daoConfig from 'smartcontract/abi/TSGovernor.json'
import { getProposalInfo } from 'apis/apiclaim'
import { ethers } from 'ethers'
import { getChainById } from 'utils/common';
const getGovernorContract = async (address, chainId) => {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  return getSmartContract(address, daoConfig.abi, signer ?? getSigner())
}

export async function excutePropose(addressGovernor, addressReceive, tokenBuyAddress, amount, description, fee, ownerAddress) {
  let ABI = [
    "function transfer(address to, uint amount)"
  ];
  const contractGovernor = await getGovernorContract(addressGovernor);
  let iface = new ethers.utils.Interface(ABI);
  const encodeData = iface.encodeFunctionData("transfer", [addressReceive, ethers.utils.parseEther(amount.toString())]);
  const encodeDataFee = iface.encodeFunctionData("transfer", [ownerAddress, ethers.utils.parseEther(fee.toString())]);
  const rsSmart = await contractGovernor.execute([tokenBuyAddress, tokenBuyAddress], [0, 0], [encodeData, encodeDataFee], description);
  return await rsSmart.wait()
}

//hàm thực hiện đăng ký vote
export const delegate = async (addressGovernor, addressRegister) => {
  const contract = await getGovernorContract(addressGovernor)
  return await contract.delegate(addressRegister)
}

///hàm thực hiện kiểm tra số tiền claim khi giải ngân thành công
export const getAmountTokenClaim = async (addressGovernor, saleId, chainId) => {
  try {
    const contract = await getGovernorContract(addressGovernor, chainId)
    const rsSmart = await contract.getAmountTokenClaim(saleId);
    return rsSmart
  } catch (err) {
    console.log(err);
  }
  return {
    amountClaim: 0,
    amountClaimed: 0,
    fee: 0
  };
}
export const claimTokenVote = async (addressGovernor, proposalId) => {
  const contractGovernor = await getGovernorContract(addressGovernor);
  const rs = await getProposalInfo({
    proposalId: proposalId
  });
  const arr = rs?.data?.Data;
  const objProposal = (arr && arr.length > 0) ? arr[0] : null;
  if (objProposal) {
    try {
      const descriptionHashToByte = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(objProposal.Description));
      const callData = [
        "0x" + objProposal.Calldatas[0],
        "0x" + objProposal.Calldatas[1]
      ]
      return await contractGovernor.execute(objProposal.Targets, objProposal.Values, callData, descriptionHashToByte);
    } catch (err) {
      console.log(err);
    }
  }
  return null;
}

//============= Internal functions ===============//
const getContract = async (ctrAddress, chainId) => {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  return getSmartContract(ctrAddress, daoConfig.abi, signer ?? getSigner())
}
const txCallContract = async (ctrAddress, name, ...args) => {
  try {
    console.log(`[contract][${ctrAddress}][${name}]`, args)
    const contract = await getContract(ctrAddress)
    const txResponse = await contract[name](...args)
    const txReceipt = await txResponse.wait()
    if (txReceipt.status == 1) {
      console.log(`[contract][${ctrAddress}][${name}] tx#${txResponse.hash} confirmed at block#${txReceipt.blockNumber}`)
    } else {
      console.log('Failed', txReceipt)
    }
    return txReceipt
  } catch (err) {
    console.log(`[contract][${ctrAddress}][${name}]`, err)
    return {}
  }
}
const viewDataContract = async (ctrAddress, name, chainId, ...args) => {
  try {
    console.log(`[contract][${ctrAddress}][${name}]`, args)
    const contract = await getContract(ctrAddress, chainId)
    const result = await contract[name](...args)
    console.log(`[contract][${ctrAddress}][${name}]`, result)
    return result
  } catch (err) {
    console.log(`[contract][${ctrAddress}][${name}]`, err.reason)
    return {}
  }
}

//============ exported functions ===================//



/**
 * Get current vote result
 * @param {*} ctrAddress Contract address
 * @param {*} proposalId Proposal Id
 */
export const proposalVotes = async (ctrAddress, proposalId, chainId) => {
  const { againstVotes, forVotes, abstainVotes } = await viewDataContract(
    ctrAddress,
    'proposalVotes',
    chainId,
    proposalId,
  )
  return { againstVotes, forVotes, abstainVotes }
}

/**
 *
 * @param ctrAddress
 * @param proposalId
 * @param support uint8 vote value 0=NO, 1=YES, 2=ABSTAIN
 * @returns
 */
export const castVote = async (ctrAddress, proposalId, support) => {
  return txCallContract(ctrAddress, 'castVote', proposalId, support)
}
