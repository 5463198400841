import React, { useEffect, useState } from 'react'
import { Button, message, Spin, Table, Statistic, Space } from 'antd'
import { getRegisterVote } from 'apis/apiclaim'
import { useSelector, useDispatch } from 'react-redux'
import { checkRegisterVote, delegate } from 'smartcontract/vestingContract'
import { getBlockVoteInfo } from 'smartcontract/utilsContract'
import { getPoolInfo } from 'apis/apipool'
import moment from 'moment'
import { v4 as newId } from 'uuid';
import { ethers } from 'ethers'
import { PoolInfoCell } from 'components/pool/PoolInfoCell'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
import { authSelector } from 'redux/reducers/authSlice'
import { getIconChain, getChainById } from 'utils/common'
const { Column } = Table
const { Countdown } = Statistic
export const MyRegisterVote = () => {
    const dispatch = useDispatch();
    const { walletID } = useSelector(authSelector)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { currentNetwork } = useSelector(authSelector);
    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        try {
            setLoading(true)
            const rs = await getRegisterVote({})
            const data = rs?.data?.Data;
            if (data) {
                data.map(item => {
                    item.RowId = newId();
                    item.currentStamp = 0
                });
                setData(data);
            }
            else
                message.error("Get data failed.")
        } catch (err) {
            console.log(err.message)
        } finally {
            setLoading(false)
        }
    }

    const ColComp = ({ promise, ...props }) => {
        const [value, setValue] = useState(false);
        const [checking, setChecking] = useState(true);
        const [endTime, setEndTime] = useState(moment())
        const loadDataFromContract = async () => {
            setChecking(true);
            const res = await promise(props.record.AddressVesting, walletID, props.record.Chain);
            const rsPool = await getPoolInfo(props.record.PoolID);
            var milestones = rsPool?.data?.Data?.Step03?.Milestones;
            var proposalId = "";
            if (milestones && milestones.length > 0) {
                milestones = milestones.sort((a, b) => {
                    if (b.OrderBy == null || a.OrderBy == null)
                        return -9999
                    return (b.OrderBy - a.OrderBy)
                });
                //duyệt từ dưới lến trên và dừng lại ở milestone gần nhất
                for (let index = 0; index < milestones.length; index++) {
                    const objMilestone = milestones[index];
                    proposalId = objMilestone.RefundProposalID ? objMilestone.RefundProposalID : objMilestone.DisburseProposalID;
                    if (proposalId) break;
                }
            }
            const resBlockVoteInfo = await getBlockVoteInfo(props.record.AddressDao, props.record.AddressVesting, proposalId, walletID, props.record.Chain);
            if (resBlockVoteInfo != null) {
                setEndTime(getEndtimeFromBlock(resBlockVoteInfo.blockRemainingRegister.toNumber()));
            }
            setValue(res === ethers.constants.AddressZero);
            setChecking(false);
        }
        const getEndtimeFromBlock = (block) => {
            //đang quy ước số thời gian trên mạng BSC là 3s/block
            const totalSecond = block * 3;
            return moment().add(totalSecond, "seconds");
        }
        const checkEndtime = () => {
            const currentDate = moment();
            return endTime.diff(currentDate) >= 0;
        }
        const checkChangeBeforeSubmit = (chainId) => {
            var result = false;
            if (currentNetwork && chainId) {
                return (currentNetwork.chainID === chainId)
            }
            return result;
        }

        const registerVote = async (record) => {
            if (!checkChangeBeforeSubmit(record.Chain)) {
                dispatch(updateSwitchChainId({
                    chainId: record.Chain,
                    title: 'Please switch chain of buidl',
                    description: 'You need to switch chain to continue'
                }))
                return;
            }
            setChecking(true);
            try {
                const rsSmart = await delegate(record.AddressVesting, walletID);
                await rsSmart.wait();
            }
            catch (err) {
                console.log(err);
                message.error(err.message);
            }
            await loadDataFromContract();
        }


        useEffect(() => {
            loadDataFromContract();
        }, [props.currentStamp, currentNetwork]);

        return <>
            {
                checking ? <Spin></Spin> : <>{value ?
                    <Space>
                        <Countdown value={endTime} format={'D [Days] HH : mm : ss'} />
                        {
                            checkEndtime() ? <Button onClick={() => { registerVote(props.record) }}>Register vote</Button> : null
                        }
                    </Space>
                    : "Registed"}</>
            }
        </>
    };

    return (
        <>
            <Table rowKey="RowId" loading={loading} dataSource={data}>
                <Column title="Pool Name" dataIndex="PoolName" key="PoolName" render={(_, record) => {
                    return <PoolInfoCell pool={record} />
                }} />
                <Column title="Chain" dataIndex="Chain" key="Chain" width={80} render={(_, record) => {
                    return <img style={{ width: 30 }} src={getIconChain(record.Chain)} />
                }} />
                <Column
                    width={350}
                    title="Action"
                    dataIndex="RowId"
                    key="RowIdAction"
                    render={(_, record) => <ColComp record={record} promise={checkRegisterVote} />}
                />
            </Table>
        </>
    )
}
