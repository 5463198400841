import { Form, Table, Tooltip, Typography } from 'antd'
import BaseDatePicker from 'components/common/form-control/BaseDatePicker'
import BaseInputNumber from 'components/common/form-control/BaseInputNumber'
import TableEditable from 'components/common/table-editable/TableEditable'
import { DeleteOutlined, FlagOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useState, forwardRef, useImperativeHandle, memo } from 'react'
import { v4 as newId } from 'uuid'
import { convertToTimestamp } from 'utils/common'
import moment from 'moment'
import { useSelector } from 'react-redux'
const Milestone = forwardRef((props, ref) => {
  const { step1Infor } = useSelector((state) => state.createPoolSlice)
  const [form] = Form.useForm()
  useImperativeHandle(ref, () => ({
    getData() {
      return getMilestone()
    },
  }))

  const { t } = useTranslation()
  const [milestone, setMilestone] = useState(props.data)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasError, setHasError] = useState(false)
  const deleteItem = () => {
    if (props.deleteItem) props.deleteItem(props.data.key)
  }
  const saveChange = (goal, key) => {
    setMilestone((milestone) => {
      milestone.Goal = goal
      return milestone
    })
    validate(goal)
  }
  const validate = (goal) => {
    if (!goal || goal.length == 0) {
      setHasError(true)
      setErrorMessage('Milestone must have at least a goal.')
      return
    } else {
      setErrorMessage('')
    }
    var hasEmptyItem = goal.filter((item) => !item.GoalName).length > 0
    if (hasEmptyItem) {
      setHasError(true)
      setErrorMessage('Goal can be not null.')
      return
    } else {
      setErrorMessage('')
    }
    if (
      !milestone.StartTime ||
      !milestone.EndTime ||
      !milestone.DisbursementPer ||
      milestone.StartTime.unix() > milestone.EndTime.unix()
    ) {
      setHasError(true)
      return
    }

    setHasError(false)
    setErrorMessage('')
  }
  const getMilestone = () => {
    validate(milestone.Goal)
    if (
      !milestone.StartTime ||
      !milestone.EndTime ||
      !milestone.DisbursementPer ||
      milestone.StartTime.unix() > milestone.EndTime.unix()
    ) {
      form.validateFields()
      setHasError(true)
      return null
    }
    if (milestone.Goal.length == 0) return null

    var hasEmptyItem = milestone.Goal.filter((item) => !item.GoalName).length > 0
    if (hasEmptyItem) return null

    setHasError(false)
    setErrorMessage('')
    return milestone
  }
  const addNewGoal = (setGoals, goals, setEditingKey, form) => {
    const key = newId()
    var newGoal = {
      key: key,
      ID: key,
      GoalName: '',
      Order: goals.length + 1,
      editable: true,
    }
    var goalList = []
    if (goals) goalList = [...goals, newGoal]
    else goalList.push(newGoal)
    const globalData = goalList.map((item, index) => {
      return {
        key: item.key,
        ID: item.key,
        GoalName: item.GoalName,
        Order: index + 1,
        editable: true,
      }
    })
    setGoals(globalData)
    setEditingKey(newGoal.ID)
    form.setFieldsValue({ ...newGoal })
    setMilestone((milestone) => {
      milestone.Goal = goalList
      return milestone
    })
  }
  const startTimeChange = (time) => {
    setMilestone((milestone) => {
      milestone.StartTime = time
      return milestone
    })
  }
  const endTimeChange = (time) => {
    setMilestone((milestone) => {
      milestone.EndTime = time
      return milestone
    })
  }
  const disbursementPerChange = (value) => {
    setMilestone((milestone) => {
      milestone.DisbursementPer = value
      return milestone
    })
  }
  const columns = [
    {
      title: 'No',
      dataIndex: 'Order',
      key: 'Order',
      width: 90,
    },
    {
      title: 'Goal',
      dataIndex: 'GoalName',
      key: 'GoalName',
      editable: true,
    },
  ]
  return (
    <Tooltip color="red" title={errorMessage}>
      <div className={hasError ? 'milestone-form milestone-error' : 'milestone-form'}>
        <div className="milestone-title">
          <FlagOutlined style={{ marginRight: 10 }} />
          <span>{props.data.MilestoneName}</span>
          {props.disabled ? null : (
            <Typography.Link
              title={t('button.delete')}
              onClick={deleteItem}
              className="delete-btn"
              style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}
            >
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
          )}
        </div>
        <div className="milestone-body">
          <Form form={form} initialValues={milestone} layout="vertical" disabled={props.disabled}>
            <div className="row">
              <div className="col-12 col-sm-4">
                <div className="form-group">
                  <Form.Item
                    label="Start time of milestone"
                    name="StartTime"
                    rules={[
                      {
                        required: true,
                        message: 'StartTime is required',
                      },
                      () => {
                        return {
                          validator: (_, value) => {
                            console.log('STARTTIME', value)
                            console.log('STEP01', step1Infor)
                            if (step1Infor?.EndTime && step1Infor.EndTime.isAfter(value)) {
                              return Promise.reject(
                                new Error('Milestone StartTime must be after Pool EndTime'),
                              )
                            }
                            return Promise.resolve()
                          },
                        }
                      },
                    ]}
                  >
                    <BaseDatePicker onChange={startTimeChange} showTime />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="form-group">
                  <Form.Item
                    label="End time of milestone"
                    required
                    name="EndTime"
                    rules={[
                      (f) => {
                        return {
                          validator: (_, value) => {
                            try {
                              if (!value) {
                                return Promise.reject(
                                  new Error('End time of milestone is required.'),
                                )
                              }
                              const startTime = convertToTimestamp(f.getFieldValue('StartTime'))
                              const endTime = convertToTimestamp(value)
                              const currentTime = convertToTimestamp(moment())
                              // if (firstReleaseTime < currentTime) {
                              //   return Promise.reject(new Error("First release time must be greater than or equals current date."));
                              // }
                              if (endTime < startTime) {
                                return Promise.reject(
                                  new Error(
                                    'End time of milestone must be greater than start time.',
                                  ),
                                )
                              }
                              return Promise.resolve()
                            } catch (err) {
                              return Promise.reject(err)
                            }
                          },
                        }
                      },
                    ]}
                  >
                    <BaseDatePicker onChange={endTimeChange} showTime />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="form-group">
                  <Form.Item
                    label="Percent of release fund %"
                    name="DisbursementPer"
                    rules={[
                      {
                        required: true,
                        message: 'Percent of release fund required',
                      },
                    ]}
                  >
                    <BaseInputNumber onChange={disbursementPerChange} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
          <div style={{ marginLeft: 50 }}>
            {props.disabled ? (
              <Table rowKey="ID" columns={columns} data={milestone.Goal} />
            ) : (
              <TableEditable
                primaryKey="ID"
                addText="Add new goal"
                columns={columns}
                data={milestone.Goal}
                addNew={addNewGoal}
                onSaveChange={saveChange}
              />
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  )
})
export default memo(Milestone)
