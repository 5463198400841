import React from 'react'
import { Steps, Modal, Spin, message, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { getSmartContract, getSigner } from 'smartcontract/common'
import { ethers } from 'ethers'

import { investmentSelector, investmentActions } from 'redux/reducers/investmentSlice'
import { invest } from 'smartcontract/saleContract'
import erc20Config from 'smartcontract/abi/IERC20.json'
import { getPoolInformationDetail, getPoolInvestorDetail } from 'apis/apiPoolInvestor'
import { DStarterModal } from 'components/common/DStarterModal'
import { getContractByCode } from 'utils/common'
import {SmartContractCode} from 'utils/constants'
const { Step } = Steps



const VCDepositModal = () => {
  const dispatch = useDispatch()
  const [loadingDetail, setLoadingDetail] = React.useState(false)
  const [loadingContract, setLoadingContract] = React.useState(false)

  const [investment, setInvestment] = React.useState({})
  const [pool, setPool] = React.useState({})
  const [error, setError] = React.useState(false)
  const [amountDeposit, setAmountDeposit] = React.useState(0)
  const { code, step, showModal, approvedTokenAmount, currencyName } =
    useSelector(investmentSelector)

  const getInvestmentDetail = async (code) => {
    setLoadingDetail(true)
    try {
      const { OK, Data, Message } = await getPoolInvestorDetail(code)
      if (OK) {
        setInvestment(Data)
      } else {
        console.log('getInvestmentDetail', Message)
        setError(true)
        return
      }
      const {
        OK: okPool,
        Data: dataPool,
        Messeage: msgPool,
      } = await getPoolInformationDetail(Data.PoolID)
      if (okPool) {
        setPool(dataPool)
        setAmountDeposit(Data.AddWhiteList_Amount * dataPool.Step02.PriceOfToken)
      } else {
        console.log('getPoolInformationDetail', msgPool)
        setError(true)
      }
    } finally {
      setLoadingDetail(false)
    }
  }

  React.useEffect(() => {
    if (!!code) {
      getInvestmentDetail(code)
    }
  }, [code])

  const handleOk = () => {}
  const handleCancel = () => {
    dispatch(investmentActions.reset())
  }

  /**
   * Approve TSSALE contract to use currencyAmount of investor's currency token
   */
  const approveToken = async () => {
    try {
      setLoadingContract(true)
      const { CurrencyTokenAddress } = pool.Step01 || {}
      const AcceptedTokenAmount = investment.AddWhiteList_Amount || 0
      const currencyAmount = AcceptedTokenAmount * pool.Step02.PriceOfToken
      const amountParam = ethers.utils.parseEther(currencyAmount.toString())
      const erc20Contract = getSmartContract(CurrencyTokenAddress, erc20Config.abi, getSigner())
      const CONTRACT_TSSALE_ADDRESS = getContractByCode(SmartContractCode.SALE_CONTRACT)
      const txReceipt = await erc20Contract.approve(CONTRACT_TSSALE_ADDRESS, amountParam)
      await txReceipt.wait()
      dispatch(
        investmentActions.approveSuccess({ currencyAmount, tokenAmount: AcceptedTokenAmount }),
      )
    } catch (err) {
      console.log(err.message)
      message.error(err.message)
    } finally {
      setLoadingContract(false)
    }
  }

  // Deposit approved currency token amount into TSSale contract via invest method
  const depositToken = async () => {
    try {
      setLoadingContract(true)
      // approvedTokenAmount from previous step
      const amountParam = ethers.utils.parseEther(approvedTokenAmount.toString())
      await invest(pool.SmartID, amountParam)
      dispatch(investmentActions.setSuccessCode(code))
      dispatch(investmentActions.reset())
    } catch (err) {
      console.log(err.message)
      if (err.reason) {
        message.error(err.reason)
      }
    } finally {
      setLoadingContract(false)
    }
  }

  return (
    <>
      <DStarterModal
        maskClosable={false}
        footer={null}
        closable={false}
        visible={code && showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={loadingDetail || loadingContract}>
          <Steps size="small" current={step}>
            <Step title={'Approve ' + amountDeposit + ' ' + currencyName} />
            <Step title={'Deposit ' + amountDeposit + ' ' + currencyName} />
          </Steps>
          <div
            style={{ marginTop: 20 }}
            className="d-flex align-items-center justify-content-center"
          >
            <Space>
              {step == 0 ? (
                <button className="btn-submit" style={{ width: 180 }} onClick={approveToken}>
                  Approve currency
                </button>
              ) : null}
              {step == 1 ? (
                <button className="btn-submit" onClick={depositToken}>
                  Deposit currency
                </button>
              ) : null}
              <button className="btn-cancel" onClick={handleCancel}>
                Cancel
              </button>
            </Space>
          </div>
        </Spin>
      </DStarterModal>
    </>
  )
}
export default VCDepositModal
