import { Spin } from 'antd'
import { getMyVoteCreateList } from 'apis/apiPoolInvestor'
import React from 'react'
import { useSelector } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { VoteDetailItemTpl } from './VoteDetailItem'

// const mocking = require('./mocking.json')

export const InvestorVoteList = (props) => {
  const {userProfile} = useSelector(authSelector)
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    try {
      setLoading(true)
      const { OK, Data, Message } = await getMyVoteCreateList()
      if (OK) {
        setData(Data.map(voteCreateDataTransform))
        // setData(mocking.map(voteCreateDataTransform))
      } else {
        console.log('getMyVoteCreateList', Message)
      }
    } finally {
      setLoading(false)
    }
  }
  const voteCreateDataTransform = (item) => {
    return {
      VotingFor: `${item.MilestoneName}`,
      ...item
    }
  }

  return (
    <Spin spinning={loading}>
      {data.map((item) => (
        <VoteDetailItemTpl key={item.PoolID} item={item} user={userProfile} />
      ))}
    </Spin>
  )
}
