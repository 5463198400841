import { Collapse } from 'antd'
import { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
const { Panel } = Collapse

const AccordionInputGroup = (props) => {
    const {
      selectedOptions,
      onChange,
      options,
      dataType, // radio, checkedbox, ... 
      title,
      countOptionShow = 7
    } = props;

    const [ isShowMore, setShowMore ] = useState(false);
    const optionsShow = isShowMore ? options : options.slice(0, countOptionShow);

    const onShow = () => {
      setShowMore(true);
    }

    const onHide = () => {
      setShowMore(false);
    }

    const onCheckboxChange = (e) => {
      onChange(e.target.value)
    }

    const renderCollapseAction = () => {
      if (!isShowMore) {
        return (
          <div className="text-ocean-2 filter-show-more" onClick={onShow}>
            Show more ({options.length - countOptionShow})
          </div>
        )
      }

      return (
        <div className="text-ocean-2 filter-show-more" onClick={onHide}>
          Hide more
        </div>
      )
    }

    return (
        <Collapse
            bordered={false}
            defaultActiveKey={isMobile?[]:['1']}
            expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            expandIconPosition={'end'}
            className="site-collapse-custom-collapse"
        >
          <Panel header={title} key="1">
            {
              optionsShow?.map((option) => (
                <div key={option.key} className="vc-item-filter">
                  <input
                    id={option.content}
                    type="checkbox"
                    value={option.content}
                    className="checkboxField trend"
                    onChange={onCheckboxChange}
                    checked={selectedOptions?.includes(option.content)}
                  />
                  <label for="cb">{option.content}</label>
                </div>
              ))
            }
            {
              options.length > countOptionShow && renderCollapseAction()
            }
          </Panel>
        </Collapse>
    );
}

export default AccordionInputGroup;
