import React, { useEffect, useState } from 'react'
import { Button, message, Spin, Table } from 'antd'
import { getClaim } from 'apis/apiclaim'
import { useSelector, useDispatch } from 'react-redux'
import { ClaimType } from 'utils/constants'
import { claimToken } from 'smartcontract/saleContract'
import { getClaimTokenInfo, getTokenLockInfo } from 'smartcontract/utilsContract'
import { getAmountTokenClaim, claimTokenVote } from 'smartcontract/daoContract'
import { getBalanceRefund, claimRefund, claimTokenRefund, getTokenRefund, claimTokenDisburse } from 'smartcontract/vestingContract'
import { v4 as newId } from 'uuid';
import { ethers } from 'ethers'
import moment from 'moment'
import { userProfileSelector } from 'redux/reducers/userProfile'
import { PoolInfoCell } from 'components/pool/PoolInfoCell'
import { authSelector } from 'redux/reducers/authSlice'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
import { getIconChain } from 'utils/common'
const { Column } = Table
export const MyClaim = () => {
    const dispatch = useDispatch();
    const { walletID,currentNetwork } = useSelector(authSelector)
    const userProfile = useSelector(userProfileSelector)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        loadData()
    }, [currentNetwork])
    const loadData = async () => {
        try {
            setLoading(true)
            const rs = await getClaim({})
            const data = rs?.data?.Data
            if (data) {
                await Promise.all(
                    data.map(async (item) => {
                        item.RowId = newId();
                        item.currentStamp = 0;
                        switch (item.Status) {
                            case ClaimType.VOTE_Claimable_SummaryFailed:
                            case ClaimType.VOTE_Claimable_LeftoverToken:
                                const rs = await getClaimTokenInfo(item.PoolSmartID, walletID,item.Chain);
                                item.ClaimableToken = ethers.utils.formatEther(rs.tokenRemaining);
                                item.ClaimedToken = ethers.utils.formatEther(rs.tokenClaimed);
                                item.Fee = ethers.utils.formatEther(rs.fee);
                                break;
                            case ClaimType.VOTE_Claimable_Disburse:
                                await loadDisburmentData(item);
                                break;
                            case ClaimType.VOTE_Claimable_Refunded:
                                await loadRefundData(item);
                                break;
                        }
                        // if (currentNetwork.chainID === item.Chain) {
                            
                        // }
                        // else {
                        //     item.ClaimableToken = '0.0';
                        //     item.ClaimedToken = '0.0';
                        //     item.Fee = '0.0';
                        // }
                    })
                );
                setData(data)
            } else message.error('Get data failed.')
        } catch (err) {
            console.log(err.message)
        } finally {
            setLoading(false)
        }
    }
    const checkChangeBeforeSubmit = (chainId) => {
        var result = false;
        if (currentNetwork && chainId) {
            return (currentNetwork.chainID === chainId)
        }
        return result;
    }
    const claim = async (record, setCheckingCom) => {

        try {
            if (!checkChangeBeforeSubmit(record.Chain)) {
                dispatch(updateSwitchChainId({
                    chainId: record.Chain,
                    title: 'Please switch chain of buidl',
                    description: 'You need to switch chain to continue'
                }))
                return;
            }
            var rsSmart = null;
            var newData = [];
            switch (record.Status) {
                case ClaimType.VOTE_Claimable_SummaryFailed:
                case ClaimType.VOTE_Claimable_LeftoverToken:
                    rsSmart = await claimToken(record.PoolSmartID, walletID);
                    if (rsSmart) {
                        setCheckingCom(true);
                        await rsSmart.wait();
                        const rs = await getClaimTokenInfo(record.PoolSmartID, walletID,record.Chain);
                        record.ClaimableToken = ethers.utils.formatEther(rs.tokenRemaining);
                        record.ClaimedToken = ethers.utils.formatEther(rs.tokenClaimed);
                        record.Fee = ethers.utils.formatEther(rs.fee);
                        newData = [...data];
                        setData(newData);
                        setCheckingCom(false);
                    }
                    break;
                case ClaimType.VOTE_Claimable_Disburse:
                    if (userProfile.RoleStartup) {
                        rsSmart = await claimTokenVote(record.AddressDao, record.ProposalID)
                    }
                    else {
                        rsSmart = await claimTokenDisburse(record.AddressVesting, walletID)
                    }
                    if (rsSmart) {
                        setCheckingCom(true);
                        await rsSmart.wait()
                        await loadDisburmentData(record);
                        newData = [...data];
                        setData(newData);
                        setCheckingCom(false);
                    }
                    break;
                case ClaimType.VOTE_Claimable_Refunded:
                    if (userProfile.RoleStartup)
                        rsSmart = await claimTokenRefund(record.AddressVesting)
                    else
                        rsSmart = await claimRefund(record.AddressVesting, walletID)
                    if (rsSmart) {
                        setCheckingCom(true);
                        await rsSmart.wait()
                        await loadRefundData(record);
                        newData = [...data];
                        setData(newData);
                        setCheckingCom(false);
                    }
                    break;
            }
        } catch (err) {
            message.error(err.message)
        }
    }

    const loadRefundData = async (item) => {
        //nếu là Startup
        if (userProfile.RoleStartup) {
            const rsRefund = await getTokenRefund(item.AddressVesting,item.Chain);
            if (rsRefund) {
                item.ClaimableToken = ethers.utils.formatEther(rsRefund.amountRemaining);
                item.ClaimedToken = ethers.utils.formatEther(rsRefund.amountClaimed);
                item.Fee = '0.0';
            }
        }
        else {
            const rsRefund = await getBalanceRefund(item.AddressVesting, walletID,item.Chain);
            if (rsRefund) {
                item.ClaimableToken = ethers.utils.formatEther(rsRefund.amountRemaining);
                item.ClaimedToken = ethers.utils.formatEther(rsRefund.amountClaimed);
                item.Fee = ethers.utils.formatEther(rsRefund.fee);
            }
        }
    }

    const loadDisburmentData = async (item) => {
        var amountClaim = 0;
        var amountClaimed = 0;
        var fee = 0;
        //nếu là Startup

        if (userProfile.RoleStartup) {
            const rsDisburse = await getAmountTokenClaim(item.AddressDao, item.ProposalID,item.Chain);
            amountClaim = ethers.utils.formatEther(rsDisburse.amountClaim);
            amountClaimed = ethers.utils.formatEther(rsDisburse.amountClaimed);
            fee = ethers.utils.formatEther(rsDisburse.fee);
        }
        else {
            const rsDisburse = await getTokenLockInfo(item.AddressVesting, walletID, moment().unix(),item.Chain);
            if (rsDisburse) {
                amountClaim = ethers.utils.formatEther(rsDisburse.claimable);
                amountClaimed = ethers.utils.formatEther(rsDisburse.amountReleased);
                fee = '0.0';
            }
        }
        item.ClaimableToken = amountClaim;
        item.ClaimedToken = amountClaimed;
        item.Fee = fee;
    }
    const ColComp = ({ promise, ...props }) => {
        const [checking, setChecking] = useState(false);
        const getTokenSymbol = () => {
            var rs;
            //nếu là startup
            if (userProfile.RoleStartup) {
                switch (props.record.Status) {
                    case ClaimType.VOTE_Claimable_SummaryFailed:
                    case ClaimType.VOTE_Claimable_LeftoverToken:
                    case ClaimType.VOTE_Claimable_Refunded:
                        rs = props.record.TokenSymbol;
                        break;
                    case ClaimType.VOTE_Claimable_Disburse:
                        rs = props.record.CurrencyName;
                        break;
                }
            }
            else {
                switch (props.record.Status) {
                    case ClaimType.VOTE_Claimable_SummaryFailed:
                    case ClaimType.VOTE_Claimable_LeftoverToken:
                    case ClaimType.VOTE_Claimable_Refunded:
                        rs = props.record.CurrencyName;
                        break;
                    case ClaimType.VOTE_Claimable_Disburse:
                        rs = props.record.TokenSymbol;
                        break;
                }
            }
            return rs;
        }

        return (
            <>
                {checking ? (
                    <Spin></Spin>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={props.showClaim ? { width: 100 } : null}>
                            {props.showClaim && props.Value > 0 ? (
                                <Button
                                    className="claim-btn"
                                    onClick={() => {
                                        if (props.claim) props.claim(props.record, setChecking)
                                    }}
                                >
                                    Claim
                                </Button>
                            ) : null}
                        </div>
                        {props.Value} <span style={{ color: "#384bf2", paddingLeft: 10 }}> {getTokenSymbol()}</span>
                    </div>
                )}
            </>
        )
    }
    const convertLastvoteResult = (val) => {
        var result = "";
        switch (val) {
            case ClaimType.VOTE_Claimable_Disburse:
                result = "Disburse";
                break;
            case ClaimType.VOTE_Claimable_Refunded:
                result = "Refunded";
                break;
            case ClaimType.VOTE_Claimable_SummaryFailed:
                result = "Summary fail";
                break;
            case ClaimType.VOTE_Claimable_LeftoverToken:
                result = "Leftover token";
                break;
        }
        return result;
    }
    return (

        <>
            <Table rowKey="RowId" loading={loading} dataSource={data} pagination={false}>
                <Column title="Pool Name" dataIndex="PoolName" key="PoolName" render={(_, record) => {
                    return <PoolInfoCell pool={record} />
                }} />

                <Column
                    title="Last result vote"
                    dataIndex="Status"
                    width={140}
                    key="Status"
                    render={convertLastvoteResult}
                />
                <Column
                    title="Chain"
                    dataIndex="Chain"
                    width={80}
                    key="Chain"
                    render={(_, record) => {
                        return <img style={{ width: 30 }} src={getIconChain(record.Chain)} />
                    }}
                />
                <Column
                    title="Claimable token"
                    dataIndex="ClaimableToken"
                    width={250}
                    key="ClaimableToken"
                    render={(_, record) => <ColComp showClaim claim={claim} Value={record.ClaimableToken} record={record} />}
                />
                <Column
                    title="Claimed token"
                    width={180}
                    dataIndex="ClaimedToken"
                    render={(_, record) => <ColComp claim={claim} Value={record.ClaimedToken} record={record} />}

                />
                <Column
                    title="Protocol fee"
                    width={150}
                    dataIndex="Fee"
                    render={(_, record) => <ColComp claim={claim} Value={record.Fee} record={record} />}
                />
            </Table>
        </>
    )
}
