import React from 'react'
import { useGetSupportedCurrencies } from 'components/hooks/data/currencies'
import { getListPoolInvestor, getMyPoolInformationDetail } from 'apis/apiPoolInvestor'

export function usePoolInvestorManagementData(poolID) {
  const [poolInvestors, setInvestors] = React.useState([])
  const [poolInfo, setPoolInfo] = React.useState({})
  const [filter, setFilter] = React.useState()
  const [search, setSearch] = React.useState()
  const { currencies } = useGetSupportedCurrencies()

  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState([])

  const loadPoolInvestorList = async (id) => {
    setIsLoading(true)
    const { OK, Data } = await getListPoolInvestor({
      PoolID: id,
      PageIndex: 1,
      PageSize: 10000,
    })
    setIsLoading(false)
    if (OK) {
      setData(Data)
    }
  }
  React.useEffect(() => {
    loadPoolInvestorList(poolID)
  }, [])
  React.useEffect(() => {
    if (!Array.isArray(data)) {
      console.log('ListError: ', data)
      return
    }
    // client side search & filter
    const list = data
      .filter((item) => {
        if (filter >= 0) {
          return item.AddWhiteList_Status == filter
        } else {
          return true
        }
      })
      .filter((item) => {
        if (!!search) {
          if (!item.NameVentureCapital) {
            return true
          }
          return (
            item.NameVentureCapital.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            item.WalletAddress.toLowerCase().indexOf(search.toLowerCase()) >= 0
          )
        } else {
          return true
        }
      })
    setInvestors(list)
  }, [data, filter, search])

  const [isLoadingPool, setIsLoadingPool] = React.useState(false)
  const [dataPool, setDataPool] = React.useState([])
  const loadMyPoolInformationDetail = async (id) => {
    setIsLoadingPool(true)
    const { OK, Data } = await getMyPoolInformationDetail(id)
    setIsLoadingPool(false)
    if (OK) {
      setDataPool(Data)
    }
  }
  React.useEffect(() => {
    loadMyPoolInformationDetail(poolID)
  }, [])
  React.useEffect(() => {
    const currency = currencies.find((cur) => dataPool.Currency === cur.CODE)
    if (currency) {
      dataPool.Currency = currency.NAME
      setPoolInfo(Object.assign({}, dataPool, { Currency: currency.NAME }))
    } else {
      setPoolInfo(dataPool)
    }
  }, [dataPool, currencies])

  return {
    isLoading,
    data,
    poolInvestors,
    filter,
    setFilter,
    search,
    setSearch,
    isLoadingPool,
    poolInfo,
  }
}
