import React from 'react'
import { InvestorVoteList } from 'components/for-investor/voting/WaitingVoteList'
import { StartupVoteList } from 'components/for-startup/voting/StartupVoteList'

export const InvestorVoteDetailPage = () => {
  return (
    <div className="bg-frame">
      <div className="title-show">
        <h5>Vote Detail</h5>
        <hr />
        <p>Here are all proposal that you need to vote.</p>
      </div>
      <InvestorVoteList />
    </div>
  )
}

export const StartupVoteDetailPage = () => {
  return (
    <div className="bg-frame">
      <div className="title-show">
        <h5>Vote Detail</h5>
        <hr />
        <p>Here are all proposal that you need to vote.</p>
      </div>
      <StartupVoteList />
    </div>
  )
}
