import { Tooltip } from "antd"

/**
 * 
 * @param {*} props {raised, target, rate} 
 * @returns 
 */
export const ProgressBar = (props) => {
  const { raised, target, rate } = props
  return (
    <>
      <Tooltip title={`${raised}`}>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${rate}%` }}
            aria-valuenow={`${rate}`}
            aria-valuemin={`${target}`}
            aria-valuemax={`${raised}`}
          >
            {rate}%
          </div>
        </div>
      </Tooltip>
    </>
  )
}
