import { useState } from 'react'
import iconAvatar from 'assets/images/avatar.png'
export const CustomFormUploadItem = (props) => {
  const [photo, setPhoto] = useState(props.value)
  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const uploadButton = (
    <>
      <div
        style={{ width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={iconAvatar} alt="" style={{ width: '120px' }} />
      </div>
    </>
  )
  return (
    <>
      <div className="choose_file">
        <div>
          {photo || props.value ? (
            <img
              src={photo || props.value}
              alt=""
              srcset=""
              style={{ width: '200px', marginBottom: '10px' }}
            />
          ) : (
            uploadButton
          )}
        </div>
        <input
          accept="image/jpeg,image/gif,image/png"
          className="fileInput"
          id="fileInput"
          type="file"
          onChange={(evt) => {
            if (props.onChange) {
              getBase64(evt.target.files[0], (url) => {
                setPhoto(url)
              })
              props.onChange(evt.target.files[0])
            }
          }}
        />
      </div>
    </>
  )
}
