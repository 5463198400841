import { Space, Table } from 'antd'
import {
  fetchCoinMarketCapPage,
  fetchMessariCoinDataPage,
  useCoinData,
} from 'components/hooks/data/useCoinData'
import React from 'react'
import { usdFormatter, formatNumber } from 'utils/constants'

const AssetIcon = (props) => {
  const {
    token: { cmcId, symbol },
  } = props
  return (
    <img
      src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${cmcId}.png`}
      style={{ width: 32, height: 32 }}
      alt={symbol}
    />
  )
}
const Trend7Days = (props) => {
  const { cmcId, symbol } = props.token || {}
  return (
    <img
      src={`https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/${cmcId}.svg`}
      alt={symbol}
    />
  )
}

const TOP_COINMARKETCAP = process.REACT_APP_TOP_COINMARKETCAP || 2000

const renderChange = (num) =>
  !!num ? <span style={{ color: num > 0 ? 'green' : 'red' }}>{num.toFixed(2)} %</span> : num

const renderAmount = (num) => {
  if (!num) {
    return ''
  }
  return usdFormatter.format(num)
}

export const VCPortfolio = (props) => {
  const { data = [] } = props
  const { coinMap } = useCoinData()
  const [dataSource, setDataSource] = React.useState([])

  React.useEffect(() => {
    if (coinMap) {
      if (dataSource.length == 0) {
        setDataSource(
          data.map((symbol) => {
            return coinMap[symbol]
          }),
        )
      } else {
        const newData = [...dataSource]
        newData.forEach((val, idx, arr) => {
          if (val) {
            arr[idx] = Object.assign({}, val, coinMap[val.symbol])
          }
        })
        setDataSource(newData)
      }
    }
  }, [Object.keys(coinMap)])

  React.useEffect(() => {
    loadExtendedCoinData(data)
  }, [])

  const loadExtendedCoinData = async (symbolList) => {
    let start = 1,
      limit = 200
    const ret = {}
    const findList = {}
    symbolList.forEach((s) => {
      findList[s] = false
    })
    while (start < TOP_COINMARKETCAP && Object.keys(findList).length > 0) {
      const data = await fetchMessariCoinDataPage(start, limit)
      // const data = await fetchCoinMarketCapPage(start, limit)
      if (Object.keys(data).length == 0) {
        break
      }
      Object.keys(findList).forEach((key) => {
        if (data[key]) {
          ret[key] = data[key]
          delete findList[key]
        }
      })
      start += limit
    }
    if (Object.keys(ret).length > 0) {
      //Update dataSource
      let newData = []
      if (dataSource.length > 0) {
        newData = [...dataSource]
      } else {
        newData = data.map((symbol) => ({ symbol }))
      }
      newData.forEach((row, index, arr) => {
        if (ret[row.symbol]) {
          arr[index] = Object.assign({}, arr[index], ret[row.symbol])
        }
      })
      setDataSource(newData)
    }
  }

  const columns = [
    {
      title: '#',
      dataIndex: '#',
      key: '#',
      render: (_, record, index) => (index + 1),
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
      render: (_, record) => {
        if (!record) {
          return <></>
        }
        return (
          <Space>
            <AssetIcon token={record} />
            <span>{record.name}</span>
            <span>{record.symbol}</span>
          </Space>
        )
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (num) => renderAmount(num),
    },
    {
      title: 'Volume 24h',
      dataIndex: 'volume24h',
      key: 'volume24h',
      render: (num) => renderAmount(num),
    },
    {
      title: 'Market Cap',
      dataIndex: 'marketCap',
      key: 'marketCap',
      render: (num) => renderAmount(num),
    },
    {
      title: 'Change 7D',
      dataIndex: 'percentChange7d',
      key: 'percentChange7d',
      render: (num) => renderChange(num),
    },
    {
      title: 'Change 30D',
      dataIndex: 'percentChange30d',
      key: 'percentChange30d',
      render: (num) => renderChange(num),
    },
    {
      title: 'Change 90D',
      dataIndex: 'percentChange90d',
      key: 'percentChange90d',
      render: (num) => renderChange(num),
    },
    {
      title: 'Trend 7 Days',
      dataIndex: 'trend7days',
      key: 'trend7day',
      render: (_, record) => <Trend7Days token={record} />,
    },
  ]
  return (
    <Table
      style={{ width: '100%' }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      onHeaderRow={() => {
        return {
          className: 'portfolio-header',
        }
      }}
    />
  )
}
