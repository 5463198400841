import React from 'react'
import { getPools } from 'apis/apipool'
import PoolItem from './PoolItem'
import { LoadMoreList } from 'pages/project-public/LoadMoreList'
import { List } from 'antd'

export default function Pools(props) {
  const { param, setTotalCount } = props

  const loadPageData = (index, size, param) => {
    const objParam = {
      nameInvestment: param.poolName,
      pageIndex: index,
      pageSize: size,
    }
    return getPools(objParam).then((resp) => resp.data)
  }

  return (
    <LoadMoreList
      param={param}
      setTotalCount={setTotalCount}
      pageSize={12}
      grid={{
        column: 4,
        gutter: [20, 0],
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
      }}
      dataKey="PoolID"
      loadPageData={loadPageData}
      renderItem={(item) => (
        <List.Item>
          <PoolItem data={item} />
        </List.Item>
      )}
    />
  )
}
