import createSagaMiddleware from 'redux-saga'
//reducers
import reducers from '../reducers'
//sagas
import rootSaga from '../sagas'
import { configureStore } from '@reduxjs/toolkit'
// import storage from 'redux-persist/lib/storage/session'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import { persistReducer, persistStore } from 'redux-persist'

let sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

const persistConfig = {
  key: 'rootReducer',
  storage: new CookieStorage(Cookies),
  whitelist: ['auth'],
}

// Wrap root reducer to handle global reset
const rootReducer = (state, action) => {
  // console.log('[ACTION]', action)
  if (action.type == 'ROOT_REDUCER_RESET') {
    // Remove the persisted data
    persistConfig.storage.removeItem(persistConfig.key)
    // Return undefind state to reset the whole tree
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
})
sagaMiddleware.run(rootSaga)
export default store

export const persistor = persistStore(store)
