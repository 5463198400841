import React from 'react'
import { Input, Select } from 'antd'

const countries = require('assets/data/country_list.json')

export const PhoneNumberPrefixSelect = (props) => {
    const {showSearch, placeholder, optionFilterProp, filterOption, filterSort, ...rest} = props
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      }
      defaultValue="+84"
      {...rest}
    >
      {countries.map((cnt) => (
        <Select.Option key={cnt.alpha2} value={cnt.dial}>
          {`${cnt.name} ${cnt.flag} ${cnt.dial}`}
        </Select.Option>
      ))}
    </Select>
  )
}
