import { Button, Tabs, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { SendOutlined, GlobalOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import fan1 from 'assets/images/fan-01.png'
import fan2 from 'assets/images/fan-02.png'
import fan3 from 'assets/images/fan-03.png'
import eye from 'assets/images/icon-eye.png'
import iconTwitter from 'assets/images/social/s-twitter.svg'
import iconLinkedin from 'assets/images/social/s-linkedin.svg'
import iconTelegram from 'assets/images/social/s-telegram.svg'
import iconYoutube from 'assets/images/social/s-youtube.svg'
import iconDiscord from 'assets/images/social/s-discord.png'
import iconReddit from 'assets/images/social/s-reddit.png'
import iconMedium from 'assets/images/social/s-medium.png'
import iconGithub from 'assets/images/social/s-github.png'
import { formatNumber } from 'utils/constants'
import ReadMore from 'components/common/read-more'
import { useState } from 'react'
const { TabPane } = Tabs
const SimilarBuidlProjectPubItem = (props) => {
  const { t } = useTranslation()
  const [visibleBlogs, setVisibleBlogs] = useState(2)
  return props.data ? (
    <div className="project-pub-item">
      <div className="col-12">
        <article className="item-box mx-2">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="avatar">
                <img className="img-fluid" src={props.data.Avatar} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="row">
                <div className="col-12">
                  <div className="nav nav-tabs" role="tablist">
                    <button
                      className="nav-link active"
                      id={`nav-desc-${props.data?.ProjectID}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-desc-${props.data?.ProjectID}`}
                      type="button"
                      role="tab"
                      aria-controls={`nav-desc-${props.data?.ProjectID}`}
                      aria-selected="true"
                    >
                      Description
                    </button>
                    <button
                      className="nav-link"
                      id={`nav-offer-${props.data?.ProjectID}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-offer-${props.data?.ProjectID}`}
                      type="button"
                      role="tab"
                      aria-controls={`nav-offer-${props.data?.ProjectID}`}
                      aria-selected="false"
                    >
                      Offering
                    </button>
                    <button
                      className="nav-link"
                      id={`nav-screen-${props.data?.ProjectID}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-screen-${props.data?.ProjectID}`}
                      type="button"
                      role="tab"
                      aria-controls={`nav-screen-${props.data?.ProjectID}`}
                      aria-selected="false"
                    >
                      Screening
                    </button>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade "
                      id={`nav-offer-${props.data?.ProjectID}`}
                      role="tabpanel"
                      aria-labelledby={`nav-offer-${props.data?.ProjectID}-tab`}
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-offer-06"
                        role="tabpanel"
                        aria-labelledby="nav-offer-06-tab"
                      >
                        <h4>{props?.data?.ProjectName}</h4>
                        <ul>
                          <li>
                            Public Total Raise{' '}
                            <span>${formatNumber(props?.data?.Step04?.PublicTotalRaise)}</span>
                          </li>
                          <li>
                            Total Token Sell{' '}
                            <span>
                              {formatNumber(props?.data?.Step04?.TokenOffer)}{' '}
                              {props?.data?.Step04?.Ticker}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id={`nav-screen-${props.data?.ProjectID}`}
                      role="tabpanel"
                      aria-labelledby={`nav-screen-${props.data?.ProjectID}-tab`}
                    >
                      <h4>{props?.data?.ProjectName}</h4>
                      <div className="row">
                        <div className="col-12 text-nowrap mt-2">
                          <div>Lead VC</div>
                          <h4 className="text-ocean">{props?.data?.LeadVC}</h4>
                        </div>
                        <div className="col-12 text-nowrap mt-2">
                          <div>Market Maker</div>
                          <h4 className="text-ocean">{props?.data?.MarketMaker}</h4>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id={`nav-desc-${props.data?.ProjectID}`}
                      role="tabpanel"
                      aria-labelledby={`nav-desc-${props.data?.ProjectID}-tab`}
                    >
                      <div className="">
                        <h4>{props?.data?.ProjectName}</h4>
                        <div className="group_tags">
                          {props?.data?.Hastag?.split(',')
                            .slice(0, visibleBlogs)
                            .map((value, key) => {
                              return (
                                <span style={{ margin: '2px' }} key={key}>
                                  {value}
                                </span>
                              )
                            })}
                        </div>
                      </div>
                      <div className="group_tags d-flex flex-wrap over-flow align-items-center">
                        {props?.data?.Hastag?.split(',').length !==
                        props?.data?.Hastag?.split(',').slice(0, visibleBlogs).length ? (
                          <>
                            <div
                              style={{ fontSize: '12px', cursor: 'pointer', paddingLeft: '5px' }}
                              onClick={() => setVisibleBlogs((prev) => prev + 2)}
                            >
                              Watch more
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <p>
                        <ReadMore size={12}>{props?.data?.ShortDescription}</ReadMore>
                      </p>

                      <div className="my-3">
                        <p className="">
                          {props?.data?.Website ? (
                            <a href={props?.data?.Website} className="px-1" target="_blank">
                              <img src={fan1} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Telegram ? (
                            <a href={props?.data?.Telegram} className="px-1" target="_blank">
                              <img src={fan2} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Discord ? (
                            <a href={props?.data?.Discord} className="px-1" target="_blank">
                              <img src={iconDiscord} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Linkedlin ? (
                            <a href={props?.data?.Linkedlin} className="px-1" target="_blank">
                              <img src={iconLinkedin} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Youtube ? (
                            <a href={props?.data?.Youtube} className="px-1" target="_blank">
                              <img src={iconYoutube} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Reddit ? (
                            <a href={props?.data?.Reddit} className="px-1" target="_blank">
                              <img src={iconReddit} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                          {props?.data?.Twitter ? (
                            <a href={props?.data?.Twitter} className="px-1" target="_blank">
                              <img src={iconTwitter} alt="" className="img-icon-similar-2" />
                            </a>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="button-link-info">
                    <Link to={`/buidls/${props.data.ProjectID}`} className="w-100">
                      <img src={eye} alt="" /> {t('projectItem.viewdetail')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  ) : null
}
export default SimilarBuidlProjectPubItem
