import React from 'react'
import ProjectPublicFilter from './ProjectPublicFilter'
import { Pagination, Spin } from 'antd'
import ProjectPublicList from './ProjectPublicList'
import { NodataUpdatingPage } from 'pages/NodataUpdating'

function ProjectPublicWrapper(props) {
  const { projects, totalRecords, loading, filterParams, setFilterParams } = props

  const onPageChange = (newPage) => {
    setFilterParams({ ...filterParams, pageIndex: newPage })
  }
  return (
    <Spin spinning={loading}>
      <div className="row">
        <div className="col-12 col-sm-6 col-xl-3 padding0right">
          <ProjectPublicFilter
            filterParams={filterParams}
            setFilterParams={(params) => {
              setFilterParams(params)
            }}
          />
        </div>
        <div className="col-12 col-sm-6 col-xl-9 padding0right">
          {projects.length > 0 ? (
            <>
              <ProjectPublicList data={projects} />
              <div className="d-flex justify-content-center">
                <Pagination
                  defaultCurrent={1}
                  current={filterParams?.pageIndex}
                  showSizeChanger={false}
                  onChange={onPageChange}
                  total={totalRecords}
                />
              </div>
            </>
          ) : (
            <>
              <NodataUpdatingPage />
            </>
          )}
        </div>
      </div>
    </Spin>
  )
}

export default ProjectPublicWrapper
