import { CKEditor } from 'ckeditor4-react'
import { useState, memo, useRef } from 'react'
import CKUploadImage from 'components/common/ckeditor/CKUploadImage'
import img from 'assets/img/img.svg'
function CKBaseEditor(props) {
  const [visible, setVisible] = useState(false)
  const editorRef = useRef(null)
  function onClosed(img) {
    setVisible(false)
    if (img && editorRef.current) {
      editorRef.current.insertHtml(img)
    }
  }
  return (
    <>
      <CKEditor
        initData={props.data}
        config={{
          removeButtons: 'Image',
          extraPlugins: 'uploadImage',
          extraAllowedContent: 'img(*){*}[*]',
        }}
        onChange={(evt) => {
          if (props.onChange) {
            props.onChange(evt.editor.getData())
          }
        }}
        onInstanceReady={({ editor }) => {
          editor.addCommand('insertUploadImage', {
            exec: function (editor) {
              editorRef.current = editor
              setVisible(true)
            },
          })
        }}
        onNamespaceLoaded={(CKEDITOR) => {
          CKEDITOR.plugins.add('uploadImage', {
            init: function (editor) {
              editor.ui.addButton('UploadImage', {
                label: 'Upload image',
                command: 'insertUploadImage',
                toolbar: 'insert',
                icon: img,
              })
            },
          })
        }}
      />
      <CKUploadImage visible={visible} onClosed={onClosed} />
    </>
  )
}
export default memo(CKBaseEditor)
