import { Button, Form, message, Row, Space } from 'antd'
import React, { useState } from 'react'
import API from '../../apis/apiProject'
import { DStarterModal } from 'components/common/DStarterModal'

const JoinProjectModal = (props) => {
  const { isModalVisible, setIsModalVisible, poolDetail, setIsJoin } = props
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const onFinish = async () => {
    const objData = {
      code: '',
      poolID: poolDetail.PoolID,
      projectID: poolDetail.Step01.ProjectID,
      investedAmount: '',
    }
    const res = await API.createPoolInvestor(objData)
    if (res?.ResultCode != 200) {
      message.error(res.Message)
      setLoading(false)
    } else {
      message.success(res.Message)
      setLoading(false)
      setIsJoin(true)
    }
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <DStarterModal visible={isModalVisible} onCancel={handleCancel} footer={null} closable={false} maskClosable={false}>
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <div>Are you sure you want to invest?</div>
        <Form.Item>
          <Row justify="center" style={{ marginTop: '20px' }}>
            <Space>
              <Button loading={loading} htmlType="submit">
                Yes
              </Button>
              <Button htmlType="button" onClick={handleCancel}>
                No
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </DStarterModal>
  )
}
export default JoinProjectModal
