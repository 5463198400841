import React from 'react'
import { message, Spin } from 'antd'

import { selectUserRole } from 'apis/apiprofiles'
import * as tsprojectContract from 'smartcontract/projectContract'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userProfileActions, userProfileSelector } from 'redux/reducers/userProfile'
import { authSelector } from 'redux/reducers/authSlice'
import API_Chain from 'apis/apichain'
const STARTUP = 'STARTUP'
const INVESTOR = 'INVESTOR'

export const SelectUserRolePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [role, setRole] = React.useState(STARTUP)
  const onClickSelect = (r) => setRole(r)
  const userProfile = useSelector(userProfileSelector)
  const [profileRole, setProfileRole] = React.useState(null)
  const { currentNetwork} = useSelector(authSelector);
  React.useEffect(() => {
    if (!!userProfile.RoleStartup && !userProfile.RoleVC) {
      setProfileRole(STARTUP)
      setRole(STARTUP)
    }
    if (!userProfile.RoleStartup && !!userProfile.RoleVC) {
      setProfileRole(INVESTOR)
      setRole(INVESTOR)
    }
  }, [userProfile])

  const onConfirmSelectRole = async () => {
    try {
      setLoading(true)
      if (role === INVESTOR) {
        await tsprojectContract.registerVc()
      } else {
        await tsprojectContract.registerStartup()
      }
      const body = {
        walletID: userProfile.WalletID,
        originalChainID: currentNetwork.chainID,
        email: userProfile.Email,
      }
      const res = await API_Chain.createNewChainForWallet(body);
      console.log(res);
      dispatch(userProfileActions.updateSelectedRole(role === INVESTOR))
      const { OK, ResultCode, Message } = await selectUserRole(role === INVESTOR ? true : false)
      if (OK) {
        navigate('/dashboard/my-profile')
      } else {
        message.error('onConfirmSelectRole: ', ResultCode, Message)
        navigate('/')
      }
    } catch (err) {
      if (err.reason) {
        message.error(err.reason)
      } else {
        message.error(`${err.code} ${err.data?.code} ${err?.data?.message}`)
      }
    } finally {
      setLoading(false)
    }
    return false
  }
  return (
    <>
      <div className="title_main row">
        <div className="col-12 col-sm-3">
          <h2>Select User Role</h2>
        </div>
        <div className="col-12 col-sm-9">
          <ul className="title-step">
            <li className="checked">
              <span>01</span>Verify Email
            </li>
            <li className="active">
              <span>02</span>Choose Role
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="bg-step bg-verify">
        <div className="email-p">
          <p>
            Please choose your role <br />
            <small>* You can only choose the role once!</small>
          </p>
          <div className="verify-email">
            <Spin spinning={loading}>
              <div className="row">
                <div className="col-6">
                  <div className="box-radio">
                    <img for="startup" src="/images/icon-startup.png" alt="" />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="chooseRole"
                        id="startup"
                        value="option1"
                        checked={role === STARTUP}
                        onChange={() => onClickSelect(STARTUP)}
                        disabled={!!profileRole}
                      />
                      <label className="form-check-label" for="startup">
                        <small>Apply to join as a Startup</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="box-radio">
                    <img for="investor" src="/images/icon-investor.png" alt="" />
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="chooseRole"
                        id="investor"
                        value="option2"
                        checked={role === INVESTOR}
                        onChange={() => onClickSelect(INVESTOR)}
                        disabled={!!profileRole}
                      />
                      <label className="form-check-label" for="investor">
                        <small>Apply to join as a Investor</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group_btn">
                <button className="btn-submit" onClick={onConfirmSelectRole} disabled={!!profileRole}>
                  Confirm
                </button>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </>
  )
}
