import { Statistic, Select } from 'antd'
import '../../assets/css/pool-public.css'
import { Link, useNavigate } from 'react-router-dom'
import { formatNumber, getBgByStatus } from '../../utils/constants'
import { useTranslation } from 'react-i18next'
import coin01 from 'assets/images/wallet-bsc.png'
import moment from 'moment'
import { convertDatetimeFromTimestamp, getCurrencyById, getIconChain } from 'utils/common'
const { Countdown } = Statistic
const { Option } = Select

function PoolItem(props) {
  const { t } = useTranslation()
  const { item } = props

  const navigate = useNavigate()

  return (
    <div key={item.PoolID} style={{ width: '100%' }}>
      <article className="item-card">
        <img className="img-fluid" src={item?.PoolAvatar} alt="" />
        <h4 onClick={() => navigate(`/pool/${item?.PoolID}`)}>
          {item?.NameInvestment}
          <img src={getIconChain(item?.Chain) || coin01} alt="" style={{ width: '30px' }} />
        </h4>
        <hr />
        <ul>
          <li>
            Total Fund Raise:{' '}
            <span className="text-ocean">
              {formatNumber(item?.TotalFundRaise)} {getCurrencyById(item?.Currency)}
            </span>
          </li>
          <li>
            {item.StartTime > moment().unix() ? (
              <>
                {t('createPool.poolStartIn')}
                <span className="text-red">
                  <Countdown
                    value={convertDatetimeFromTimestamp(item?.StartTime)}
                    format={'D [Days] HH : mm : ss'}
                  />
                </span>
              </>
            ) : (
              <>
                {t('createPool.poolEndIn')}
                <span className="text-red">
                  <Countdown
                    value={convertDatetimeFromTimestamp(item?.EndTime)}
                    format={'D [Days] HH : mm : ss'}
                  />
                </span>
              </>
            )}
          </li>
        </ul>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${item?.RateRaise || 0}%` }}
            aria-valuenow={item?.RateRaise || 0}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {item?.RateRaise}%
          </div>
        </div>
        <p className="current">
          {formatNumber(item?.TotalMoneyRaise)} {getCurrencyById(item?.Currency)}
        </p>
        <div className="link-a">
          <Link to={`/pool/${item?.PoolID}`}>Join In</Link>
        </div>
        <span className={`status-item ${getBgByStatus(item?.Status)}`}>{item?.Status}</span>
      </article>
    </div>
  )
}

export default PoolItem
