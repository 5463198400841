import { Col, Row } from 'antd'
import fan1 from 'assets/images/fan-01.png'
import fan2 from 'assets/images/fan-02.png'
import fan3 from 'assets/images/fan-03.png'
import ReadMore from 'components/common/read-more'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from 'utils/constants'
const VcItem = (props) => {
  const navigate = useNavigate()
  const beautyArrayString = (value) => {
    if (!value) return value;
    var items = value.split(";");
    return items.join(", ")
  }
  return (
    <section className="content-main">
      <div className="row nogutter">
        {props.datas
          ? props?.datas?.map((item, index) => {
            return (
              <div key={index} className="col-12 col-sm-12">
                <article className="item-card row d-flex justify-content-space-between">
                  <div className="col-12 col-sm-2 flex-wrap pdl-0">
                    <img className="img-vcs img-fluid" src={item?.Avatar} alt="" />
                    <div className="row mt-3">
                      <div className="col-12 d-flex justify-content-space-between px-4">
                        <a href={item.Website} target="_blank">
                          <img src={fan1} alt="" />
                        </a>
                        <a href={item.Telegram} target="_blank">
                          <img src={fan2} alt="" />
                        </a>
                        <a href={item.Twitter}>
                          <img src={fan3} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-10 d-flex flex-wrap">
                    <div className="col-12">
                      <span
                        className="over-flow"
                        style={{ fontSize: '22px', fontWeight: '600', color: '#fff' }}
                        onClick={() => navigate(`/vcs-detail/${item.WalletID}/0`)}
                      >
                        {item.NameVentureCapital}
                      </span>
                    </div>
                    <div className="col-12 my-3 " style={{ minHeight: '5vh' }}>
                      <ReadMore color={'#D7D7D7'}>{item?.ShortDescription}</ReadMore>
                    </div>
                    <div className="hr-vc col-12 mb-3"></div>
                    <div className="d-flex col-12 only-order">
                      {/* <ul className="d-flex row ul-nopdf">
                          <li className="col-12 col-sm-2 col-xl-2 px-3 mx-3">
                            <div className="row">
                              <div className="col-12 vc-color">Hash tags</div>
                              <p className="text-ocean-2 d-flex over-flow">
                                {
                                  beautyArrayString(item.HashTag)
                                }
                              </p>
                            </div>
                          </li>
                          <li className="col-12 col-sm-2 col-xl-2 px-3 mx-3">
                            <div className="row ">
                              <div className="col-12 vc-color">Interested series</div>
                              <p className="text-ocean-2 d-flex over-flow">
                                {
                                  beautyArrayString(item.InterestedSeries)
                                }
                              </p>
                            </div>
                          </li>
                          <li className="col-12 col-sm-2 col-xl-2 px-3 mx-3">
                            <div className="row">
                              <div className="col-12 vc-color">Total fund </div>
                              <p className="text-ocean">{formatNumber(item?.TotalFund || 0)}</p>
                            </div>
                          </li>
                          <li className="col-12 col-sm-2 col-xl-4 px-3">
                            <div className="d-flex vc-action justify-content-end ">
                              <div
                                className="btn-detail vc-color"
                                onClick={() => navigate(`/vcs-detail/${item.WalletID}/0`)}
                              >
                                Go detail
                              </div>
                              <div
                                onClick={() => navigate(`/vcs-detail/${item.WalletID}/2`)}
                                className="btn-view vc-color"
                              >
                                View portfolio
                              </div>
                            </div>
                          </li>
                        </ul> */}
                      <Row>
                        <Col sm={24} xs={24} md={6}>
                          <div className="row pright10">
                            <div className="col-12 vc-color">Hash tags</div>
                            <p style={{fontSize:13}} className="text-ocean-2 d-flex over-flow">
                              {
                                beautyArrayString(item.HashTag)
                              }
                            </p>
                          </div>
                        </Col>
                        <Col sm={24} xs={24} md={6}>
                          <div className="row pright10">
                            <div className="col-12 vc-color">Interested series</div>
                            <p style={{fontSize:13}} className="text-ocean-2 d-flex over-flow">
                              {
                                beautyArrayString(item.InterestedSeries)
                              }
                            </p>
                          </div>
                        </Col>
                        <Col sm={24} xs={24} md={3}>
                          <div className="row pright10">
                            <div className="col-12 vc-color">Total fund </div>
                            <p style={{fontSize:13}} className="text-ocean">{formatNumber(item?.TotalFund || 0)}</p>
                          </div>
                        </Col>
                        <Col sm={24} xs={24} md={9}>
                          <div className="d-flex vc-action justify-content-end pright10">
                            <div
                              className="btn-detail vc-color"
                              onClick={() => navigate(`/vcs-detail/${item.WalletID}/0`)}
                            >
                              Go detail
                            </div>
                            <div
                              onClick={() => navigate(`/vcs-detail/${item.WalletID}/2`)}
                              className="btn-view vc-color"
                            >
                              View portfolio
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </article>
              </div>
            )
          })
          : null}
      </div>
    </section>
  )
}
export default VcItem
