import { buidlTotalRaiseFilterOptions, buidlTrendingFilterOptions, chainFilterOptions } from 'components/accordion';
import AccordionInputGroup from 'components/accordion/AccordionInputGroup';
import {chainSelector} from 'redux/reducers/networkSlice'
import { useSelector } from 'react-redux';

function ProjectPublicFilter(props) {
    const {
      filterParams,
      setFilterParams
    } = props;
    const network = useSelector(chainSelector);
    const getChainOptions = () => {
      const listChain = network?.listChain;
      return listChain.map((item) => ({
        key: item?.chainId,
        content: item?.chainName
      }));
    };

    const onChangeTrending = (trendingValue) => {
      if (filterParams?.trending?.includes(trendingValue)) {
        const newTrendingOptions = filterParams?.trending.filter((item) => item != trendingValue);
        setFilterParams({ ...filterParams, trending: newTrendingOptions });
      } else {
        const newTrendingOptions = [ ...filterParams?.trending, trendingValue ];
        setFilterParams({ ...filterParams, trending: newTrendingOptions});
      }
    }

    const onChainTotalRaise = (value) => {
      if (filterParams?.totalRaise.includes(value)) {
        const newTotalRaises = filterParams?.totalRaise.filter((item) => item != value);
        setFilterParams({ ...filterParams, totalRaise: newTotalRaises });
      } else {
        const newTotalRaises = [ ...filterParams?.totalRaise, value ];
        setFilterParams({ ...filterParams, totalRaise: newTotalRaises });
      }
    }

    const onChainSelectedChainOptions = (value) => {
      if (filterParams?.chains?.includes(value)) {
        const newChainsSelected = filterParams?.chains?.filter((item) => item != value);
        setFilterParams({ ...filterParams, chains: newChainsSelected });
      } else {
        const newChainsSelected = [ ...filterParams.chains, value ];
        setFilterParams({ ...filterParams, chains: newChainsSelected });
      }
    }

    return (
        <section className="content-main">
          <AccordionInputGroup
            options={buidlTrendingFilterOptions}
            selectedOptions={filterParams?.trending}
            onChange={onChangeTrending}
            title="Trending"
            countOptionShow={7}
            key="1"
          />

          <AccordionInputGroup
            options={buidlTotalRaiseFilterOptions}
            selectedOptions={filterParams?.totalRaise}
            onChange={onChainTotalRaise}
            title="Total raise"
            countOptionShow={7}
            key="2"
          />

          <AccordionInputGroup
            options={getChainOptions()}
            selectedOptions={filterParams?.chains}
            onChange={onChainSelectedChainOptions}
            title="Chain"
            countOptionShow={7}
            key="3"
          />
        </section>
  )
}
export default ProjectPublicFilter;
