import { Form, Input, InputNumber, message, Spin, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import API from 'apis/apiProject'
import { useSelector } from 'react-redux'
import { chainSelector } from 'redux/reducers/networkSlice'
const { Option } = Select
function AdvanceInformation(props) {
  const { listChain } = useSelector(chainSelector)
  const { setCurrent, current, setBackStep } = props
  const step03Content = useSelector((state) => state.myProject.step04)
  const projectId = useSelector((state) => state.myProject.projectId)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const onFinish = (values) => {
    let totalRate = 0
    values.listTokenDistribution.forEach((item) => {
      totalRate += item.OwnedRate
    })
    if (totalRate !== 100) {
      message.error('Total rate must be 100%')
    } else {
      let body = {
        projectID: projectId,
        information: {
          ticker: values.ticker,
          totalSupply: values.totalSupply,
          evaluation: values.evaluation,
          publicTotalRaise: values.publicTotalRaise,
          tokenOffer: values.tokenOffer,
          individualAllowcation: values.individualAllocation,
          listOwned: values.listTokenDistribution,
        },
      }
      setLoading(true)
      createProjectStep3(body)
      setLoading(false)
    }
  }

  const createProjectStep3 = async (body) => {
    const res = await API.createProjectStep4(body)
    if (res?.ResultCode != 200) {
      message.error(res.Message)
    } else {
      setCurrent(current + 1)
      message.success(res.Message)
    }
  }

  useEffect(() => {
    if (step03Content) {
      form.setFieldsValue({
        ticker: step03Content.Ticker,
        totalSupply: step03Content.TotalSupply,
        evaluation: step03Content.Evaluation,
        publicTotalRaise: step03Content.PublicTotalRaise,
        tokenOffer: Number(step03Content.TokenOffer),
        individualAllocation: Number(step03Content.IndividualAllowcation),
        listTokenDistribution: step03Content.ListOwned,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [step03Content])
  return (
    <>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="form-main">
            <div className="">
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Basic Financial Metric</h5>
                    <hr />
                    <p>
                      Here contains financial data for investors to have an overview of your
                      project. After the investor is sastified, they will continue to check token
                      distribution below.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <div className="row bg-step-item">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Form.Item
                          name="totalSupply"
                          label={t('myProject.totalSupply')}
                          rules={[
                            {
                              required: true,
                              message: 'Total Supply is required',
                            },
                            {
                              type: 'number',
                              min: 0,
                              message: 'Total Supply cannot be less than 0',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder={t('myProject.ExTotalSupply')}
                            style={{
                              width: 'calc(100%)',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Form.Item
                          name="evaluation"
                          label={t('myProject.evaluation')}
                          rules={[
                            {
                              type: 'number',
                              min: 0,
                              message: 'Evaluation ($) cannot be less than 0',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder={t('myProject.ExTotalSupply')}
                            style={{
                              width: 'calc(100%)',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Form.Item
                          name="publicTotalRaise"
                          label={t('myProject.publicTotalRaise')}
                          rules={[
                            {
                              required: true,
                              message: 'Total token sell is required',
                            },
                            {
                              type: 'number',
                              min: 0,
                              message: 'Public Total Raise ($) cannot be less than 0',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder={t('myProject.ExTotalSupply')}
                            style={{
                              width: 'calc(100%)',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Form.Item
                          name="tokenOffer"
                          label={t('myProject.tokenOffer')}
                          rules={[
                            {
                              required: true,
                              message: 'Total public fundraising is required',
                            },
                            {
                              min: 0,
                              type: 'number',
                              message: 'Token Offer cannot be less than 0',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder={t('myProject.ExTokenOffer')}
                            style={{
                              width: 'calc(100%)',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Form.Item
                          name="individualAllocation"
                          label={t('myProject.individualAllocation')}
                          rules={[
                            {
                              type: 'number',
                              min: 0,
                              message: 'Individual Allocation cannot be less than 0',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Ex: 100,000"
                            style={{
                              width: 'calc(100%)',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  d-flex justify-content-between">
                <div className="col-12 col-sm-3">
                  <div className="title-show">
                    <h5>Token Distribution</h5>
                    <hr />
                    <p>
                      Fill in details of how the tokens will be allocated, including the intended
                      use of the tokens and the ratio of each of those purpose.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-8">
                  <Form.List name="listTokenDistribution" initialValue={[{}]}>
                    {(listTokenDistribution, { add, remove }) => (
                      <>
                        {listTokenDistribution.map((field, index) => (
                          <div key={field.key}>
                            <div className="box-layout">
                              <div className="row">
                                <div className="col-12 col-sm-6">
                                  <div className="form-group">
                                    <Form.Item
                                      name={[index, 'OwnedRate']}
                                      label={
                                        t('myProject.ownershipRate') +
                                        ' ' +
                                        (index + 1) +
                                        ' ' +
                                        '(%)'
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Rate 1 (%) is required',
                                        },
                                        {
                                          type: 'number',
                                          min: 0,
                                          message: 'Rate 1 (%) cannot be less than 0',
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        placeholder={t('myProject.ExOwnerShipRate')}
                                        style={{
                                          width: 'calc(100%)',
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                  <div className="form-group">
                                    <Form.Item
                                      name={[index, 'OwnedContent']}
                                      label={t('myProject.owner') + ' ' + (index + 1)}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Owner 1 is required',
                                        },
                                      ]}
                                    >
                                      <Input placeholder={t('myProject.ExAngelInvestor')} />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="close-x"
                                onClick={() => remove(field.name)}
                              >
                                &#10005;
                              </button>
                            </div>
                          </div>
                        ))}
                        <button id="new-layout" className="btn-submit" onClick={() => add()}>
                          <img src={require('assets/images/icon-add.png').default} alt="" /> Add
                          Member
                        </button>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => {
                    setBackStep(true)
                    setCurrent(current - 1)
                  }}
                >
                  {t('button.back')}
                </button>
                <button type="submit" htmlType="submit" className="btn-submit">
                  {t('button.saveAndContinue')}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  )
}
export default AdvanceInformation
