import React from 'react'
import { Button, Table } from 'antd'
import { getListPoolInvestor } from 'apis/apiPoolInvestor'
import { useDispatch, useSelector } from 'react-redux'
import { formartDateTimeFromUnix, getIconChain } from 'utils/common'
import { investmentActions } from 'redux/reducers/investmentSlice'
import VCDepositModal from './VCDepositModal'
import { InvestmentWhiteListStatus } from 'utils/constants'
import { investmentSelector } from 'redux/reducers/investmentSlice'
import moment from 'moment'
import { PoolInfoCell } from 'components/pool/PoolInfoCell'
import { authSelector } from 'redux/reducers/authSlice'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
const { Column } = Table

const { WIN_WHITELIST } = InvestmentWhiteListStatus

export const MyWinWhiteList = () => {
  const dispatch = useDispatch()
  const { walletID } = useSelector(authSelector)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const currentDateUnix = moment().unix()
  const { successCode } = useSelector(investmentSelector)
  const { currentNetwork } = useSelector(authSelector);
  React.useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { OK, Data, Message } = await getListPoolInvestor({
        WalletAddress: walletID,
        AddWhiteList_Status: WIN_WHITELIST,
        pageIndex: 1,
        pageSize: 1000,
      })
      if (OK) {
        setData(Data)
      } else {
        console.log('getListPoolInvestor', Message)
      }
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }
  const getStatus = (code, depositStatus, record) => {
    var statusName = "Not Deposit";
    if (code == record.CODE)
      return "Deposited"
    switch (depositStatus) {
      case 1:
        statusName = "Deposited";
        break;
      default:
        if (record.EndTime < currentDateUnix)
          statusName = "Expired"
        break;
    }
    return statusName;
  }
  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }
  const showDepositModal = (record) => {
    if (checkChangeBeforeSubmit(record.Chain)) {
      dispatch(investmentActions.setCurrencyName(record.CurrencyName))
      dispatch(investmentActions.start(record.CODE))
    }
    else {
      dispatch(updateSwitchChainId({
        chainId: record.Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
    }
  }

  return (
    <>
      <Table loading={loading} dataSource={data} rowKey="CODE">
        <Column
          title="Pool Name"
          dataIndex="PoolName"
          key="PoolName"
          render={(_, record) => {
            return <PoolInfoCell pool={record} />
          }}
        />
        <Column title="Chain" dataIndex="Chain" key="Chain" render={(_, record) => {
            return <img style={{width:30}} src={getIconChain(record.Chain)} />
          }} />
        <Column
          title="Allocation token"
          dataIndex="AddWhiteList_Amount"
          key="AddWhiteList_Amount"
        />
        <Column
          title="Start time"
          dataIndex="StartTime"
          key="Starttime"
          render={(val) => formartDateTimeFromUnix(val)}
        />
        <Column
          title="End time"
          dataIndex="EndTime"
          key="Endtime"
          render={(val) => formartDateTimeFromUnix(val)}
        />
        <Column title="Status" dataIndex="Deposit_Status" key="Deposit_Status" render={(val, record) => getStatus(successCode, val, record)} />
        <Column
          title="Action"
          dataIndex="Action"
          key="Action"
          render={(_, record) => (record.Deposit_Status === 1 || record.EndTime < currentDateUnix || successCode == record.CODE) ? null : <Button type="primary" onClick={() => showDepositModal(record)}>
            Deposit
          </Button>}
        />
      </Table>
      <VCDepositModal />
    </>
  )
}
