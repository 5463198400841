import { Steps, Modal, Button, Spin, message, Row, Col, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import { getSmartContract, getSigner, approve } from 'smartcontract/common'
import { createSale } from 'smartcontract/saleContract'
import { ethers } from 'ethers'
import { saveStep4 } from 'apis/apipool'
import { apiCall, getContractByCode } from 'utils/common'
import iERC20Config from 'smartcontract/abi/IERC20.json'
import { setStep, setStepDeposit, setShowDeposit } from 'redux/reducers/pool/createPoolSlice'
import { useState } from 'react'
import { DStarterModal } from 'components/common/DStarterModal'
import {SmartContractCode} from 'utils/constants'
const { Step } = Steps
const PoolDepositProgressModal = () => {
  const dispatch = useDispatch()
  const stepDeposit = useSelector((state) => state.createPoolSlice.stepDeposit)
  const showDeposit = useSelector((state) => state.createPoolSlice.showDeposit)

  const poolId = useSelector((state) => state.createPoolSlice.poolId)
  const step1Infor = useSelector((state) => state.createPoolSlice.step1Infor)
  const step2Infor = useSelector((state) => state.createPoolSlice.step2Infor)

  const [loading, setLoading] = useState(false)
  const handleOk = () => { }
  const handleCancel = () => {
    dispatch(setShowDeposit(false))
  }

  const approveToken = async () => {
    setLoading(true)
    //tổng số token bán trong đợt sale
    const totalSell = step2Infor.AmountOfTokenSell.toString()
    const amount = ethers.utils.parseEther(totalSell)

    //địa chỉ token của project
    const tokenProjectAddress = step1Infor.TokenAddress
    const signer = getSigner()
    const projectContract = getSmartContract(tokenProjectAddress, iERC20Config.abi, signer)

    //địa chỉ smart contract Sale của Dstarter
    const saleContractAddress =  getContractByCode(SmartContractCode.SALE_CONTRACT)

    try {
      const rsApprove = await approve(projectContract, saleContractAddress, amount)
      const rsSmart = await rsApprove.wait()
      dispatch(setStepDeposit(1))
    } catch (err) {
      message.error(err.message)
    }
    setLoading(false)
  }
  const deposit = async () => {
    //địa chỉ của stable coin dùng để mua (USDT,BUSC...)
    setLoading(true)
    const tokenUseToBuyAddress = step1Infor.CurrencyTokenAddress

    //địa chỉ token của project
    const tokenProjectAddress = step1Infor.TokenAddress

    const smProjectId = step1Infor.SmartProjectId
    //quy ước 1$ = 1000000
    const price = step2Infor.PriceOfToken * 1000000

    //tổng số token bán trong đợt sale
    const totalSell = step2Infor.AmountOfTokenSell.toString()
    const amount = ethers.utils.parseEther(totalSell)
    try {
      const objPost = {
        projectId: smProjectId,
        startTime: step1Infor.StartTime.unix(),
        endTime: step1Infor.EndTime.unix(),
        price: price,
        totalSell: amount,
        currentWhiteList: 0,
        currentBuy: 0,
        onlyWhiteList: true,
        tokenAddress: tokenProjectAddress,
        tokenBuy: tokenUseToBuyAddress,
        status: 0,
        tsVesting: ethers.constants.AddressZero,
        governance: ethers.constants.AddressZero,
        vtStartTime: step2Infor.FirstReleaseTime.unix(),
        vtCliffTime:Math.round(step2Infor.CliffTime * 24 * 60 * 60), //tính theo giây
        vtDuration: Math.round(step2Infor.LinearUnlockTime * 24 * 60 * 60) ,//tính theo giây
        feeRefund: 0,
        feeDisbursement: 0
      }
      await createSale(poolId, objPost)
      const rs = await apiCall(saveStep4, [poolId])
      const rsCode = rs?.data?.ResultCode;
      if (rsCode === '200') {
        dispatch(setStep(4))
        dispatch(setShowDeposit(false))
      }
    } catch (err) {
      console.log(err)
      message.error(err.message)
    }
    setLoading(false)
  }
  return (
    <>
      <DStarterModal
        closable={false}
        maskClosable={false}
        footer={null}
        visible={showDeposit}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row style={{ padding: 16 }} />
        <Spin spinning={loading}>
          <Steps size="small" current={stepDeposit}>
            <Step title={"Approve " + step2Infor.AmountOfTokenSell.toString() + " " + step1Infor.TokenSymbol} />
            <Step title={"Deposit " + step2Infor.AmountOfTokenSell.toString() + " " + step1Infor.TokenSymbol} />
          </Steps>
          <div style={{ marginTop: 20 }} className="d-flex align-items-center justify-content-center">
            <Space>
              {stepDeposit == 0 ? <button className='btn-submit' onClick={approveToken}>Approve token</button> : null}
              {stepDeposit == 1 ? <button className='btn-submit' onClick={deposit}>Deposit token</button> : null}
              <button className="btn-cancel" onClick={() => handleCancel()}>Cancel </button>
            </Space>
          </div>
        </Spin>
      </DStarterModal>
    </>
  )
}
export default PoolDepositProgressModal
