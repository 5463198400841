import React, { useState } from 'react'

const ReadMore = ({ children, size, color }) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <p className="text">
      {text?.length > 100 ? (
        <>
          <span style={{ fontSize: size, color: color }}>
            {isReadMore ? text.slice(0, 180) : text}
          </span>
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? '...read more' : ' show less'}
          </span>
        </>
      ) : (
        <span style={{ fontSize: size, color: color }}>{text}</span>
      )}
    </p>
  )
}
export default ReadMore
