import { ethers } from 'ethers'
import { getSigner, getSmartContract } from 'smartcontract/common'
import { getContractByCode } from 'utils/common'
import {SmartContractCode} from 'utils/constants';
const CONTRACT_TSPROJECT_CONFIG = require('./abi/TSProject.json')

function getTSProjectContract() {
  const CONTRACT_TSPROJECT_ADDRESS = getContractByCode(SmartContractCode.TSPROJECT_CONTRACT)
  return getSmartContract(CONTRACT_TSPROJECT_ADDRESS, CONTRACT_TSPROJECT_CONFIG.abi, getSigner())
}

export const txCallTSProjectContract = async (name, ...args) => {
  console.log(`[txCallTSProjectContract][${name}]`, args)
  const contract = getTSProjectContract()
  const txResponse = await contract[name](...args)
  const txReceipt = await txResponse.wait()
  if (txReceipt.status == 1) {
    console.log(`tx ${txResponse.hash} confirmed at block #${txReceipt.blockNumber}`)
  } else {
    console.log('Failed', txReceipt)
  }
}
export const getDataTSProjectContract = async (name, ...args) => {
  console.log(`[getDataTSProjectContract][${name}]`, args)
  const contract = getTSProjectContract()
  const result = await contract[name](...args)
  console.log(`[getDataTSProjectContract][${name}]`, result)
  return result
}

export const registerVc = () => {
  return txCallTSProjectContract('registerVc')
}
export const registerStartup = () => {
  return txCallTSProjectContract('registerStartup')
}
export const smUserIsStartup = (walletID) => {
  return getDataTSProjectContract('userIsStartup', walletID)
}
export const smUserIsVc = (walletID) => {
  return getDataTSProjectContract('userIsVc', walletID)
}
export const createProject = async (projectId) => {
  const contract = getTSProjectContract();
  const rs = await contract.createProject(ethers.utils.formatBytes32String(projectId), ethers.utils.formatBytes32String("abc"));
  const smartRs = await rs.wait();
  return smartRs;
}
