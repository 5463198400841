import imgNetwork01 from 'assets/images/network-1.png'
import imgNetwork02 from 'assets/images/network-2.png'
import imgNetwork03 from 'assets/images/network-3.png'
import imgNetwork04 from 'assets/images/network-4.png'
import imgNetwork05 from 'assets/images/network-5.png'

import iconDAO_01 from 'assets/images/dao-icon-1.svg'
import iconDAO_02 from 'assets/images/dao-icon-2.svg'
import iconDAO_03 from 'assets/images/dao-icon-3.svg'
import iconDAO_04 from 'assets/images/dao-icon-4.svg'
import iconDAO_05 from 'assets/images/dao-icon-5.svg'

function DaoClub() {    
    return ( <>
        <section id="slide-top" class="animate two">
            <div class="content-width text-center">
                <h1 class="animate three">DAOCLUB Platform</h1>
                <h2 class="animate four">A collaborative, group investment platform that enables web3 users <br />form a DAO investor group, pool money, and manage joint investments.</h2>
                <div class="group_btn animate five">
                    <button class="btn-blue" href="#">Enter Clubs</button>
                </div>
            </div>
        </section>
        
        <section class="bg-launch-title animate six">
            <h2>Everything You Need to Launch Your DAO</h2>
            <div class="content-width">
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <article class="card-box">
                            <img src={imgNetwork01} alt="Network 01" />
                            <div class="content">
                                <h5>Quick & easy to create a <br /> DAOClub with Dabase</h5>
                                <p>Dabase protocol is free to create a DAOClub, except for the network gas fee.</p>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-sm-4">
                        <article class="card-box">
                            <img src={imgNetwork02} alt="Network 02" />
                            <div class="content">
                                <h5>Raise funds flexibly</h5>
                                <p>Raise funds from your friends or communities, and track all contributions to the Club's share-based membership.</p>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-sm-4">
                        <article class="card-box">
                            <img src={imgNetwork03} alt="Network 03" />
                            <div class="content">
                                <h5>Quick & easy to create a <br /> DAOClub with Dabase</h5>
                                <p>Dabase protocol is free to create a DAOClub, except for the network gas fee.</p>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 col-sm-4">
                        <article class="card-box">
                            <img src={imgNetwork04} alt="Network 04" />
                            <div class="content">
                                <h5>Onchain branding</h5>
                                <p>Easily build the club's reputation to global investors with the DAO governance model, as well as share club asset management with startups.</p>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-sm-4">
                        <article class="card-box">
                            <img src={imgNetwork05} alt="Network 05" />
                            <div class="content">
                                <h5>Non-custodial</h5>
                                <p>The Dabase protocol is fully decentralized and non-custodial with Club's, members' funds.</p>
                            </div>
                        </article>
                    </div>
                    <div class="col-12 col-sm-2"></div>
                </div>
            </div>
        </section>

        <section id="portfolio" class="space_section">
            <div class="content-width">
                <h2>Diversified investment portfolio</h2>
                <div class="row">
                    <div class="col-12 col-sm-4">
                        <article>
                            <img src={iconDAO_01} alt="Early Investments" />
                            <h3>Early Investments</h3>
                            <p>Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required.</p>
                        </article>
                    </div>
                    <div class="col-12 col-sm-4">
                        <article>
                            <img src={iconDAO_02} alt="Digital Assets" />
                            <h3>Digital Assets</h3>
                            <p>Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required.</p>
                        </article>
                    </div>
                    <div class="col-12 col-sm-4">
                        <article>
                            <img src={iconDAO_03} alt="NFT Projects" />
                            <h3>NFT Projects</h3>
                            <p>Get paid by your listeners, every month, predictably. No CPMs, and no scheduling required.</p>
                        </article>
                    </div>
                </div>
            </div>
        </section>

        <section id="dao-feature-02" class="space_section">
            <div class="content-width">
                <article class="row">
                    <div class="col-12 col-sm-7 order-a">
                        <div class="rel-item">
                                <div class="item-child">
                                <img src={iconDAO_04} alt="Voting" />
                                <h5>Voting</h5>
                                <p>Vote on proposals to make decisions on any Club transaction.</p>
                            </div>
                            <div class="item-child">
                                <img src={iconDAO_05} alt="Governance" />
                                <h5>Governance</h5>
                                <p>Members receive governance LP tokens proportional to their contributions for voting on proposals.</p>
                            </div>           
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 order-b">
                        <h4>DAO Features</h4>
                        <p>
                            Dabase is all about reducing the risks for investors and increasing 
                            the funding possibilities for startups. An immutable funding protocol 
                            enables investors to put their confidence in new blockchain businesses 
                            while also pushing startups to achieve what they truly aim to become.
                        </p>
                    </div>
                </article>
            </div>
        </section>
        
        <section id="faq" class="space_section">
            <div class="content-width">
                <h3>FAQ</h3>
                <div class="accordion" id="accordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-01">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-01" aria-expanded="true" aria-controls="collapse-01">
                                What is an IMO DAO Pool?
                            </button>
                        </h2>
                        <div id="collapse-01" class="accordion-collapse collapse show" aria-labelledby="heading-01" data-bs-parent="#accordion">
                            <div class="accordion-body">
                                IMO DAO Pool is a pool created by a startup to raise funds from early investors. It differs from other fundraising platforms in 2 features: IMO & DAO. 
                                IMO (Initial Milestone offering) is a milestone-based funding release protocol where allocated funds are escrowed until a startup achieves its predetermined 
                                milestones. <br /><br />DAO governance allows investors to be involved in the funding release process base on proposal & voting features. Let's say it simply: Even if 
                                startups successfully raise funds from investors, they cannot get all these funds, but escrowed in milestone smartcontract. Investors will vote on proposals 
                                of release funds or even a reimbursement in case startups fail to meet the committed milestones. The protocol is fully decentralized and non-custodial for 
                                all funds from Dabase.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-02">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-02" aria-expanded="false" aria-controls="collapse-02">
                                How do I create an IMO DAO pool?
                            </button>
                        </h2>
                        <div id="collapse-02" class="accordion-collapse collapse" aria-labelledby="heading-02" data-bs-parent="#accordion">
                            <div class="accordion-body">
                            Only projects that meet the appraisal criteria can create an IMO pool and start raising funds. To join the IMO platform, startups first need to verify 
                            KYC and start submitting profiles. The startup profile will be listed in the Buidl Space. However, to create an IMO Pool, startups need to meet the appraisal 
                            criteria from Dabase team. 
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-03">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-03" aria-expanded="false" aria-controls="collapse-03">
                                How much does it cost to create an IMO DAO Pool?
                            </button>
                        </h2>
                        <div id="collapse-03" class="accordion-collapse collapse" aria-labelledby="heading-03" data-bs-parent="#accordion">
                            <div class="accordion-body">
                                It is free for startups to submit profiles and get listed in Buidl Space, excluding the network gas fee. For the IMO protocol, Dabase charges based on the amount raised. 
                                This fee is collected when the startup successfully claims from the pool.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-04">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-04" aria-expanded="false" aria-controls="collapse-04">
                                How to join in an IMO Pool?
                            </button>
                        </h2>
                        <div id="collapse-04" class="accordion-collapse collapse" aria-labelledby="heading-04" data-bs-parent="#accordion">
                            <div class="accordion-body">
                                All the IMO Pools will be listed in Pools space. However, to join an IMO Pool, investors need to apply and be accepted by startups. A successful startup, in 
                                addition to investment capital, also needs a lot of support resources from investors. Therefore, we think it will be best for the startup to choose the most suitable 
                                investors. Early-stage investors can be VCs/communities/organizations that can invest capital as well as support resources in potential startups.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-05">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-05" aria-expanded="false" aria-controls="collapse-05">
                                What is a DAO Club?
                            </button>
                        </h2>
                        <div id="collapse-05" class="accordion-collapse collapse" aria-labelledby="heading-05" data-bs-parent="#accordion">
                            <div class="accordion-body">
                            DAOClub is a group of people who contribute funds and invest together. DAOClub is governed by all the members with decision-making based on proposal and voting features.  
                            Dabase DAOClub protocol enables friends or communities to easily assemble, formalize, fund, and manage assets together.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-06">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-06" aria-expanded="false" aria-controls="collapse-06">
                            How do I create a DAO Club?
                            </button>
                        </h2>
                        <div id="collapse-06" class="accordion-collapse collapse" aria-labelledby="heading-06" data-bs-parent="#accordion">
                            <div class="accordion-body">
                            Anyone verified KYC account can create a DAOClub.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-07">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-07" aria-expanded="false" aria-controls="collapse-07">
                            How much does it cost to start a DAO Club?
                            </button>
                        </h2>
                        <div id="collapse-07" class="accordion-collapse collapse" aria-labelledby="heading-07" data-bs-parent="#accordion">
                            <div class="accordion-body">
                            Dabase is currently not charging any fees DAOClub protocol, excluding the network gas fee. A verified KYC user can easily create a DAOClub with no charge. However, the Club owner can set up the Club membership policy for his own club. 
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading-08">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-08" aria-expanded="false" aria-controls="collapse-08">
                            How to join in a DAOClub?
                            </button>
                        </h2>
                        <div id="collapse-08" class="accordion-collapse collapse" aria-labelledby="heading-08" data-bs-parent="#accordion">
                            <div class="accordion-body">
                            All the public clubs will be listed in the Club space, excluding private clubs. Depend on the Club setup, investors can apply to join or waiting for approval from Club owner. 
                            </div>
                        </div>
                    </div>

                </div>                    
            </div>
        </section>
        </>
    )
}

export default DaoClub