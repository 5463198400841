import { Pagination, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import API from 'apis/apiProject'
import VcItem from './VcItem'
import { NodataUpdatingPage } from 'pages/NodataUpdating'
export default function VclistWrapper(props) {
  const { totalCount, setTotalCount, param } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [currentData, setCurrentData] = useState([])
  const [loading, setLoading] = useState(false)
  const findPubVcList = async () => {
    setLoading(true)
    let body = {
      fundRank: param.filterTotalFund,
      hashtag: param.listFilterTrending,
      interestedFielad_Industr: param.listFilterInvestedField,
      nameVentureCapital: param.contentSearch,
      status: true,
      pageIndex: currentPage,
      pageSize: 10,
    }
    let vcslist = []
    try {
      const res = await API.getListByRole(body)
      if (Array.isArray(res.Data)) {
        vcslist = res.Data
        setCurrentData(vcslist)
        if (setTotalCount) {
          setTotalCount(res.TotalRecords)
        }
      }
      setLoading(false)
    } finally {
    }
  }

  useEffect(() => {
    findPubVcList()
  }, [currentPage])

  useEffect(() => {
    if (currentPage == 1) {
      console.log('change param')
      findPubVcList()
    } else {
      console.log('change page')
      setCurrentPage(1)
    }
  }, [param])

  const handleChange = (event) => {
    setCurrentPage(event)
  }
  return (
    <Spin spinning={loading}>
      {currentData.length > 0 ? (
        <>
          <VcItem datas={currentData} />
          <div className="col-12 d-flex align-items-center justify-content-center">
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              showSizeChanger={false}
              onChange={handleChange}
              total={totalCount}
            />
          </div>
        </>
      ) : (
        <NodataUpdatingPage />
      )}
    </Spin>
  )
}
