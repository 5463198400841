import React from 'react'
import { Button, List } from 'antd'
import { debounce } from 'utils/common'
import { NodataUpdatingPage } from 'pages/NodataUpdating'

export const LoadMoreList = (props) => {
  const { setTotalCount, loadPageData, param, className, itemLayout, dataSource, ...restProps } =
    props
  const [page, setPage] = React.useState(1)
  const [pageSize] = React.useState(props.pageSize || 20)
  const [currentData, setCurrentData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [isLoadMore, setIsLoadMore] = React.useState(true)

  const loadData = async (index, size, param) => {
    try {
      setLoading(true)
      const respBody = await loadPageData(index, size, param)
      let { TotalRecords, Data } = respBody
      if (!Data) {
        Data = []
      }
      if (Array.isArray(Data)) {
        if (Data.length > 0) {
          setCurrentData((oldData) => {
            if (index == 1) {
              return Data
            }
            if (props.dataKey) {
              // try deduplicate if dataKey specified
              const dupIndex = oldData.findIndex((val) => {
                return val[props.dataKey] === Data[0][props.dataKey]
              })
              if (dupIndex >= 0) {
                return [...oldData.slice(0, dupIndex), ...Data]
              }
            }
            // simple appending
            return [...oldData, ...Data]
          })

          if (setTotalCount) {
            setTotalCount(TotalRecords)
          }
          if (Data.length < size) {
            setIsLoadMore(false)
          } else {
            setIsLoadMore(true)
          }
        } else {
          if (page === 1) {
            setTotalCount(0)
            setCurrentData([])
          }
          setIsLoadMore(false)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onLoadMore = () => {
    setPage((p) => {
      return p + 1
    })
  }
  const resetContent = () => {
    // setCurrentData([])
    setPage((p) => {
      if (p == 1) {
        loadData(p, pageSize, param)
      }
      return 1
    })
  }

  React.useEffect(() => {
    resetContent()
  }, [param])

  React.useEffect(() => {
    loadData(page, pageSize, param)
  }, [page])

  return (
    <section className="content-main">
      <div className="row d-flex">
        <List
          className="loadmore-list"
          loading={loading}
          itemLayout="horizontal"
          loadMore={
            <div className="col-12 d-flex justify-content-center mb-4">
              {isLoadMore ? (
                <Button disabled={loading} onClick={onLoadMore}>
                  Load more
                </Button>
              ) : null}
            </div>
          }
          locale={{ emptyText: <NodataUpdatingPage /> }}
          dataSource={currentData}
          {...restProps}
        />
      </div>
    </section>
  )
}
