import React from 'react'
import { Layout } from 'antd'
import { PoolInvestorManagement } from 'components/for-startup/pool/manage-investor/PoolInvestorManagement'
import { useParams } from 'react-router-dom'

export const MyPoolInvestorManagementPage = () => {
  const { id: poolID } = useParams()

  return (
    <div>
      <PoolInvestorManagement poolID={poolID} />
    </div>
  )
}
