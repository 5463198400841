import { Button, Col, Row, Spin, Typography } from 'antd'
import 'assets/css/pool-detail.css'
import { useNavigate, useParams } from 'react-router-dom'
import API from 'apis/apiProject'
import { useEffect, useState } from 'react'
import PoolTimeline from './PoolTimeline'
import { useTranslation } from 'react-i18next'
import JoinProjectModal from './JoinProjectModal'
import { useSelector, useDispatch } from 'react-redux'
import { formatNumber, usdFormatter } from 'utils/constants'
import {
  convertDatetimeFromTimestamp,
  formartDateTimeFromUnix,
  getIconChain,
  getCurrencyById,
} from 'utils/common'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { userProfileSelector } from 'redux/reducers/userProfile'
import { CaretUpFilled } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'

const PoolDetail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [poolDetail, setPoolDetail] = useState('')
  const paramUrl = useParams()
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [visibleBlogs, setVisibleBlogs] = useState(2)
  const [isJoin, setIsJoin] = useState(false)
  const [historyInvest, setHistoryInvest] = useState([])
  const address = useSelector((state) => state.connectWalletSlice.address)
  const { walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const [milestones, setMilestones] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [projectDetail, setProjectDetail] = useState('')
  const [totalTokenSupply, setTotalTokenSupply] = useState(0)
  const [poolPub, setPoolPub] = useState({})
  const [projectID, setProjectID] = useState('')
  const [projectChain, setProjectChain] = useState('')
  const { currentNetwork } = useSelector(authSelector)
  useEffect(() => {
    if (paramUrl.id) {
      ;(async () => {
        setIsLoading(true)
        const getPoolPub = await API.getPoolPubById(paramUrl.id)
        const getDetailPool = await API.getDetailPoolById(paramUrl.id)
        setPoolPub(getPoolPub.Data)
        let body = {
          poolID: paramUrl.id,
          pageIndex: 1,
          pageSize: 100000,
        }
        const listInvestorsById = await API.listInvestors(body)
        setHistoryInvest(listInvestorsById.Data)
        const checkJoin = listInvestorsById.Data.filter(
          (item) => item.WalletAddress.toLowerCase() === walletID,
        )
        if (checkJoin.length > 0) {
          setIsJoin(true)
        }
        if (
          getDetailPool.Data &&
          getDetailPool.Data.Step03 &&
          getDetailPool.Data.Step03.Milestones
        ) {
          const arrMilestone = getDetailPool.Data.Step03.Milestones
          setMilestones(arrMilestone)
        }
        const getDetailProject = await API.getProjectDetailPublic(
          getDetailPool.Data?.Step01?.ProjectID,
        )
        setProjectDetail(getDetailProject.Data)
        setProjectChain(getDetailProject.Data?.Step01?.Chain)
        setProjectID(getDetailProject.Data.ProjectID)
        setProjectName(getDetailProject.Data?.Step01?.ProjectName)
        var supply = getDetailProject.Data?.Step04?.TotalSupply
        setTotalTokenSupply(supply ?? 0)
        setPoolDetail(getDetailPool.Data)
        setIsLoading(false)
      })()
    }
  }, [paramUrl && address && isModalVisible])

  const checkChangeBeforeSubmit = (chainId) => {
    var result = false
    if (currentNetwork && chainId) {
      return currentNetwork.chainID === chainId
    }
    return result
  }
  const handedPreview = (e) => {
    if (userProfile.WalletID) {
      if (checkChangeBeforeSubmit(projectChain)) {
        setIsModalVisible(true)
      } else {
        dispatch(
          updateSwitchChainId({
            chainId: projectChain,
            title: 'Please switch chain of buidl',
            description: 'You need to switch chain to continue',
          }),
        )
      }
    } else {
      dispatch(authActions.requestConnectWallet())
    }
  }
  const listHistory = historyInvest.slice(0, visibleBlogs).map((item, i) => {
    const investedAmount = item.Deposit_Amount * poolDetail?.Step02?.PriceOfToken
    return (
      <>
        {/* <li>
        {item.WalletAddress} <span>{item.InvestedAmount || 0} USDT</span>
      </li> */}
        <Row gutter={32} style={{ borderBottom: '1px solid rgba(255,255,255,0.2)', padding: 8 }}>
          <Col
            span={12}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/vcs-detail/${item.WalletAddress}/0`)}
          >
            <Typography.Text>
              {item.NameVentureCapital ? item.NameVentureCapital : '[Unknown Venture Capital]'}
            </Typography.Text>
          </Col>
          {/* <Col span={6}>
          <Typography.Text ellipsis copyable>
            {item.WalletAddress}
          </Typography.Text>
        </Col> */}
          <Col flex={1}>
            <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>
              {investedAmount || 0} {getCurrencyById(poolPub.Currency)}
            </Typography.Text>
          </Col>
          <Col flex={1}>
            <Typography.Text>{formartDateTimeFromUnix(item.RegisteredTime)}</Typography.Text>
          </Col>
        </Row>
      </>
    )
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const userJoinable =
    !!userProfile.WalletID &&
    !isJoin &&
    !userProfile.RoleStartup &&
    userProfile.RoleVC &&
    userProfile.ValidateKYC &&
    poolPub?.Status === 'Live'

  return (
    <>
      <div className="title_main">
        <h2>
          {projectName} - {poolDetail?.Step01?.NameInvestment}
        </h2>
      </div>
      <div className="box-detail-main">
        <div className="back-link">
          <a onClick={() => navigate('/pool')}>
            <img src={require('assets/images/arrow-back.svg').default} alt="" /> Back
          </a>
        </div>
        <section className="box-bg">
          <div className="row">
            <div className="col-12 col-sm-3">
              <div className="avatar">
                <img className="img-fluid" src={poolDetail?.Step01?.Avatar} alt="" />
                <p className="overlay">
                  <a href="https://timebit.sg" target="_blank">
                    <img src={require('assets/images/fan-01.png').default} alt="" />
                  </a>
                  <a href="https://t.me/Timebit_global" target="_blank">
                    <img src={require('assets/images/fan-02.png').default} alt="" />
                  </a>
                  {/* <a href="#">
                      <img src={require('assets/images/fan-03.png').default} alt="" />
                    </a> */}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="content-box">
                <div className="box-title-key">
                  <h3>Overview</h3>
                  <hr />
                </div>
                <ul className="metric pool">
                  <li>
                    Buidl
                    <span>
                      <a href={`${document.location.origin}/buidls/${projectID}`} target="_blank">
                        {projectName}
                      </a>
                    </span>
                  </li>
                  <li>
                    Pool <span>{poolDetail?.Step01?.NameInvestment}</span>
                  </li>
                  <li>
                    Token symbol <span>{projectDetail?.Step01?.TokenSymbol}</span>
                  </li>
                  <li>
                    Amount of Token sell{' '}
                    <span>{formatNumber(poolDetail?.Step02?.AmountOfTokenSell)}</span>
                  </li>
                  <li>
                    Total Token supply <span>{formatNumber(totalTokenSupply)}</span>
                  </li>
                  <li>
                    Price sale <span>{usdFormatter.format(poolDetail?.Step02?.PriceOfToken)}</span>
                  </li>
                  <li>
                    Token address{' '}
                    <Typography.Text copyable>{poolDetail?.Step01?.TokenAddress}</Typography.Text>
                  </li>
                </ul>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
          <div className="list-network">
            {/* <img src={require('assets/images/network-icon01.png').default} alt="" /> */}
            <img
              src={
                getIconChain(projectDetail?.Step01?.Chain) ||
                require('assets/images/network-icon02.png').default
              }
              alt=""
            />
          </div>
        </section>
      </div>
      {poolDetail?.Step01?.YoutubeLink ? (
        <section className="bg-show">
          <ReactPlayer
            url={poolDetail?.Step01?.YoutubeLink}
            playing={false}
            volume={1}
            width="100%"
            height="60vh"
            onReady={() => console.log('ready now')}
          />
        </section>
      ) : null}
      <div className="row">
        <div className="col-12 col-sm-8">
          <section className="bg-show">
            <div className="row">
              <div className="col-12 col-sm-12 d-flex align-items-center justify-content-space-between">
                <div className="title-show">
                  <h5>Investment Progress</h5>
                  <hr />
                </div>
                {userProfile.WalletID ? (
                  <div className="col-12 col-sm-6 btn-join">
                    <Button
                      className="btn-submit"
                      disabled={!userJoinable}
                      onClick={() => handedPreview()}
                    >
                      {isJoin ? 'Already joined' : 'Join In'}
                    </Button>
                  </div>
                ) : (
                  <>
                    <div disabled={isJoin} className="col-12 col-sm-6 btn-join">
                      <div className="btn-submit" onClick={() => handedPreview()}>
                        Join In
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${poolPub?.RateRaise}%` }}
                aria-valuenow={poolPub?.RateRaise}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {poolPub?.RateRaise}%
              </div>
            </div>
            <div style={{ width: '100%', position: 'relative', left: -8, top: -8 }}>
              <span style={{ position: 'relative', left: `${poolPub?.RateRaise}%` }}>
                <CaretUpFilled />
              </span>
            </div>
            <div style={{ width: '100%', position: 'relative', left: -32, top: -12 }}>
              <span
                style={{
                  position: 'relative',
                  left: `${poolPub?.RateRaise}%`,
                  height: 16,
                  borderRadius: 8,
                  padding: 4,
                  backgroundColor: '#384BF2',
                  color: '#fff',
                  fontSize: '0.8em',
                }}
              >
                {poolPub?.TotalMoneyRaise} {getCurrencyById(poolPub.Currency)}
              </span>
            </div>
            <br />
            <ul className="list-vc">
              {isLoading ? (
                <>
                  <Spin />
                </>
              ) : (
                <>{listHistory}</>
              )}
            </ul>
            {historyInvest.length !== listHistory.length ? (
              <>
                <div className="watch-more" onClick={() => setVisibleBlogs((prev) => prev + 2)}>
                  Watch more
                </div>
              </>
            ) : (
              <></>
            )}
          </section>
        </div>
        <div className="col-12 col-sm-4">
          <section className="bg-show bg-blue-gradient">
            <div className="title-show">
              <h5>Tokenomic</h5>
              <hr />
            </div>
            <ul>
              <li>
                TGE time{' '}
                <span>
                  {convertDatetimeFromTimestamp(poolDetail?.Step02?.TGETime).format(
                    'YYYY/MM/DD HH:mm',
                  )}
                </span>
              </li>
              <li>
                Cliff time <span>{poolDetail?.Step02?.CliffTime} days</span>
              </li>
              <li>
                First release time{' '}
                <span>
                  {convertDatetimeFromTimestamp(poolDetail?.Step02?.FirstReleaseTime).format(
                    'YYYY/MM/DD HH:mm',
                  )}
                </span>
              </li>
              <li>
                Linear unlock time <span>{poolDetail?.Step02?.LinearUnlockTime} days</span>
              </li>
              <li>
                First release percent <span>{poolDetail?.Step02?.FirstReleasePercent}%</span>
              </li>
            </ul>
          </section>
        </div>
      </div>
      <div className="milestone">
        <div style={{ width: '100%', display: 'block' }}>
          <PoolTimeline data={milestones} />
        </div>
      </div>
      <JoinProjectModal
        walletID={userProfile.WalletID}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        poolDetail={poolDetail}
        setIsJoin={setIsJoin}
      />
    </>
  )
}
export default PoolDetail
