import React from 'react'
import { message } from 'antd'
import * as storageApi from 'apis/apistorage'

export const useUploadFile = () => {
  const fileUploadRef = React.useRef()
  const [fileUrl, setFileUrl] = React.useState()

  const uploadProps = {
    name: 'file',
    action: '',
    beforeUpload: (file) => {
      fileUploadRef.current = file
      // delay upload 'till form submit
      return false
    },
  }

  const doUploadFile = async () => {
    if (!fileUploadRef.current) {
      return
    }
    const formData = new FormData()
    formData.append('file', fileUploadRef.current)
    const { OK, ResultCode, Message, UrlFile } = await storageApi.uploadFile(formData)
    if (!OK) {
      message.error(`FileUpload failed: ${ResultCode} ${Message}`)
    } else {
      setFileUrl(UrlFile)
      return UrlFile
    }
  }
  return {
    fileUploadRef,
    fileUrl,
    uploadProps,
    doUploadFile,
  }
}
