import axios from 'axios';
import {getAuthorizationHeader} from 'utils/common'
const baseURL = process.env.REACT_APP_API_URL+'/Projects'
export function getClaim(data){
    var url = baseURL+'/Vote_Reponse/auth/GetList/Vote_Claimable';
    const config = getAuthorizationHeader();
    return axios.post(url, data,config);
}
export function getVote(data){
    var url = baseURL+'/Vote_Reponse/auth/GetList/Vote_Create';
    const config = getAuthorizationHeader();
    return axios.post(url, data,config);
}
export function getRegisterVote(data){
    var url = baseURL+'/Vote_Reponse/auth/GetList/Vote_Register';
    const config = getAuthorizationHeader();
    return axios.post(url, data,config);
}
export function getProposalInfo(data){
    var url = baseURL+'/Vote_Proposal/auth/GetList';
    const config = getAuthorizationHeader();
    return axios.post(url, data,config);
}
