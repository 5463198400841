import { getSigner, getSignerByRPC } from 'smartcontract/common'
import erc20Config from 'smartcontract/abi/IERC20.json'
import { ethers } from 'ethers'
import { getChainById } from 'utils/common';

const getContract = async (address, chainId)=> {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  return new ethers.Contract(address, erc20Config.abi, signer ?? getSigner())
}

const viewDataContract = async (address, name, chainId, ...args) => {
  try {
    console.log(`[erc20][${address}][${name}] >>> `, args)
    const contract = await getContract(address, chainId)
    const result = await contract[name](...args)
    // console.log(`[erc20][${address}][${name}] <<< `, result)
    return result
  } catch (err) {
    console.log(`[erc20][${address}][${name}] <<< `, err.reason)
    return
  }
}


export const symbol = async (address, chainId) => {
  return await viewDataContract(address, 'symbol', chainId)
}
export const decimals = async (address, chainId) => {
  return await viewDataContract(address, 'decimals', chainId)
}
