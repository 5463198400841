import React from 'react'

export const NodataUpdatingPage = () => {
  return (
    <div
      className="waiting-update"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <img src="/images/nodata.png" style={{ maxWidth: 600, maxHeight: 300 }} />
      <span style={{ color: '#74ABFF', fontSize: '36px' }}>This feature have no data yet</span>
    </div>
  )
}
