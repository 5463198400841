import React from 'react'

export const FeatureUpdatingPage = () => {
  return (
    <div className="waiting-update" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src="/images/updating@2x.png" style={{maxWidth: 600, maxHeight: 300}} />
      <span style={{color: '#74ABFF', fontSize: '36px'}}>This feature is waiting for update</span>
    </div>
  )
}
