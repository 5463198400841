import PoolBasicInformation from 'components/for-startup/pool/create-pool/PoolBasicInformation'
import PoolFinanceMetric from 'components/for-startup/pool/create-pool/PoolFinanceMetric'
import PoolMilestone from 'components/for-startup/pool/create-pool/PoolMilestone'
import PoolSummary from 'components/for-startup/pool/create-pool/PoolSummary'
import PoolDepositReview from 'components/for-startup/pool/create-pool/PoolDepositReview'
import PoolFunction from 'components/for-startup/pool/create-pool/PoolFunction'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getChainById } from 'utils/common'
import {
  setStep,
  setPoolId,
  setStep1Info,
  setStep2Info,
  setStep3Info,
  setLoaded,
  setProjects,
  setCurrencies,
  resetData,
  setImageUrl,
  setTitle
} from 'redux/reducers/pool/createPoolSlice'
import { useEffect, useRef } from 'react'
import { Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { getPoolInfo, getProjects, getCurrencies } from 'apis/apipool'
import { apiCall, convertDatetimeFromTimestamp } from 'utils/common'
import { v4 as newId } from 'uuid'
import BaseSteps from 'components/common/steps/BaseSteps'
const CreatePool = (props) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const step1Form = useRef(null)
  const step2Form = useRef(null)
  const step3Form = useRef(null)
  const step4Form = useRef(null)
  const step5Form = useRef(null)
  const { t } = useTranslation()
  const contentSteps = [
    {
      title: '01',
      contentText: t('poolStepProgress.basicInfo'),
    },
    {
      title: '02',
      contentText: t('poolStepProgress.financeMetric'),
    },
    {
      title: '03',
      contentText: t('poolStepProgress.goal'),
    },
    {
      title: '04',
      contentText: t('poolStepProgress.summary'),
    },
    {
      title: '05',
      contentText: t('poolStepProgress.depositReview'),
    },
  ]

  // const step = useSelector((state) => state.createPoolSlice.step)
  // const poolStatus = useSelector((state) => state.createPoolSlice.poolStatus)
  // const busy = useSelector((state) => state.createPoolSlice.busy)
  // const loaded = useSelector((state) => state.createPoolSlice.loaded)
  // const titlePool = useSelector((state) => state.createPoolSlice.titlePool)
  const { step, poolStatus, busy, loaded, titlePool } = useSelector((state) => state.createPoolSlice)

  const dispatch = useDispatch()
  const onNext = () => {
    switch (step) {
      case 0:
        step1Form.current.submit()
        break
      case 1:
        step2Form.current.submit()
        break
      case 2:
        step3Form.current.submit()
        break
    }
  }

  const onPrevious = () => {
    dispatch(setStep(step - 1))
  }

  const onDeposit = () => {
    step4Form.current.submit()
  }
  const onBackList = () => {
    const path = '/dashboard/pools'
    navigate(path)
  }

  const loadInfor = async () => {
    dispatch(resetData())
    const param = {
      status: 2,
      pageIndex: 1,
      pageSize: 10000000,
    }
    var rsProject = await apiCall(getProjects, [param])
    if (rsProject && rsProject.data && rsProject.data.Data) {
      var arr = rsProject.data.Data
      arr = arr.filter((x) => x.SmartID != null)
      dispatch(setProjects(arr))
    }
    var rsCurrency = await apiCall(getCurrencies, [
      {
        parenT_CODE: 'Currency',
        pageIndex: 1,
        pageSize: 100,
      },
    ])
    if (rsCurrency && rsCurrency.data && rsCurrency.data.Data) {
      dispatch(setCurrencies(rsCurrency.data.Data))
    }
    if (id !== 'create-pool') {
      dispatch(setTitle('Edit pool'))
      dispatch(setLoaded(false))
      var rs = await apiCall(getPoolInfo, [id])
      if (rs && rs.data && rs.data.Data) {
        const data = rs.data.Data
        const objStep1 = data.Step01
        if (objStep1) {
          var objChain
          if (rsProject && rsProject.data && rsProject.data.Data) {
            const objProject = rsProject.data.Data.find((x) => x.ProjectID === objStep1.ProjectID)
            if (objProject) {
              objChain = getChainById(objProject.Chain)
              objStep1.ProjectName = objProject.ProjectName
              objStep1.TokenSymbol = objProject.TokenSymbol
              objStep1.TotalSupply = objProject?.Step04?.TotalSupply
              objStep1.SmartProjectId = objProject.SmartID
              objStep1.Chain = objProject?.Chain
              objStep1.ChainName = objChain?.chainName
            }
          }
          const objCurrency = rsCurrency?.data?.Data?.find((x) => x.CODE === objStep1.Currency)
          objStep1.CurrencyName = objCurrency?.NAME
          objStep1.StartTime = convertDatetimeFromTimestamp(objStep1.StartTime)
          objStep1.EndTime = convertDatetimeFromTimestamp(objStep1.EndTime)
          if (objStep1.Avatar) {
            dispatch(setImageUrl(objStep1.Avatar))
          }
          dispatch(setStep1Info(objStep1))
        }
        const objStep2 = data.Step02
        if (objStep2) {
          objStep2.FirstReleaseTime = convertDatetimeFromTimestamp(objStep2.FirstReleaseTime)
          objStep2.TGETime = convertDatetimeFromTimestamp(objStep2.TGETime)
          dispatch(setStep2Info(objStep2))
        }
        const objStep3 = data.Step03
        if (objStep3 && objStep3.Milestones) {
          objStep3.Milestones.map((item) => {
            item.key = newId()
            item.StartTime = convertDatetimeFromTimestamp(item.StartTime)
            item.EndTime = convertDatetimeFromTimestamp(item.EndTime)
            if (item.Goal) {
              item.Goal.map((item) => (item.key = item.ID))
            }
          })
          dispatch(setStep3Info(objStep3.Milestones))
        }
        else {

          //mặc định khi sang step3 thì có sẵn 1 milestone
          const key = newId();
          const newMilestone = {
            key: key,
            MilestoneName: "Milestone 1",
            OrderBy: 1,
            StartTime: null,
            EndTime: null,
            Goal: []
          }
          dispatch(setStep3Info([newMilestone]));
        }
        dispatch(setPoolId(data.PoolID))
        dispatch(setStep(data.CurentStep))
        dispatch(setLoaded(true))
      }
    } else {
      dispatch(setTitle('Create pool'))
      dispatch(setLoaded(true))
    }
  }
  useEffect(() => {
    loadInfor()
  }, [])
  return (
    <div>
      <Spin size="large" spinning={busy}>
        {/* <PoolStepProgress step={step} /> */}
        <div className='step-pool-wrapper'>
          <div className="title-show">
            <a style={{display:'block', marginBottom:50}} onClick={() => navigate('/dashboard/pools')}>
              <img src={require('assets/images/arrow-back.svg').default} alt="" /> Back
            </a>
            <h5>{titlePool}</h5><hr />
          </div>
          <BaseSteps style={{ flex: 1 }} currentStep={step} contentSteps={contentSteps} />
        </div>
        {loaded ? (
          <>
            {step === 0 ? <PoolBasicInformation ref={step1Form} /> : null}
            {step === 1 ? <PoolFinanceMetric ref={step2Form} /> : null}
            {step === 2 ? <PoolMilestone ref={step3Form} /> : null}
            {step === 3 ? <PoolSummary ref={step4Form} /> : null}
            {step === 4 ? <PoolDepositReview backPool={onBackList} ref={step5Form} /> : null}
          </>
        ) : (
          <Spin size="large"></Spin>
        )}

        <PoolFunction
          onCancel={() => navigate('/dashboard/pools')}
          steps={step}
          poolStatus={poolStatus}
          onNext={onNext}
          onPrevious={onPrevious}
          onDeposit={onDeposit}
        />
      </Spin>
    </div>
  )
}
export default CreatePool
