import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FilBasicInfo from 'components/for-startup/project/create-project/FillBasicInfor'
import TeamMember from 'components/for-startup/project/create-project/TeamMember'
import AdvanceInformation from 'components/for-startup/project/create-project/AdvanceInformation'
import DetailDescription from 'components/for-startup/project/create-project/DetailDescription'
import Description from 'components/for-startup/project/create-project/Description'
import API from 'apis/apiProject'
import StatusProject from 'components/for-startup/project/create-project/StatusProject'
import StepDefault from 'components/stepdefault'
import ReadMore from 'components/common/read-more'
import {
  setCurrentStep,
  setProjectId,
  setSmartId,
  setStep01,
  setStep02,
  setStep03,
  setStep04,
  setStep05,
  resetData,
} from 'redux/reducers/project'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
function CreateProject() {
  const currentStep = useSelector((state) => state.myProject.currentStep)
  const [backStep, setBackStep] = useState(false)
  const [buidlName, setBuidlName] = useState('')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const paramUrl = useParams()

  const [current, setCurrent] = useState(0)
  const [isChangeText, setIsChangeText] = useState(false)

  useEffect(() => {
    dispatch(resetData())
    if (paramUrl.id) {
      if (paramUrl.id === 'create-project') return
      setIsChangeText(true)
    }
  }, [])

  useEffect(() => {
    if (paramUrl.id) {
      if (paramUrl.id === 'create-project') return
      ;(async () => {
        try {
          const projectDetail = await API.getProjectById(paramUrl.id)
          if (projectDetail?.ResultCode != 200) {
            message.error(projectDetail.Message)
          } else {
            const { ProjectID, CurentStep, SmartID, Step01, Step02, Step03, Step04, Step05 } =
              projectDetail.Data
            setBuidlName(Step01?.ProjectName)
            if (!backStep) {
              const totalStep = [Step01, Step02, Step03, Step04, Step05].filter(
                (item) => item !== null,
              ).length
              setCurrent(totalStep)
            }
            dispatch(setCurrentStep(CurentStep))
            dispatch(setProjectId(ProjectID))
            dispatch(setSmartId(SmartID))
            dispatch(setStep01(Step01))
            dispatch(setStep02(Step02))
            dispatch(setStep03(Step03))
            dispatch(setStep04(Step04))
            dispatch(setStep05(Step05))
          }
        } catch (error) {
          message.error(error)
        }
      })()
    }
  }, [paramUrl, current])

  const contentSteps = [
    {
      title: '01',
      contentText: t('myProject.fillBasicInformation'),
      content: <FilBasicInfo setCurrent={setCurrent} current={current} setBackStep={setBackStep} />,
    },
    {
      title: '02',
      contentText: t('myProject.teamMember'),
      content: <TeamMember setCurrent={setCurrent} current={current} setBackStep={setBackStep} />,
    },
    {
      title: '03',
      contentText: t('myProject.advanceInformation'),
      content: (
        <AdvanceInformation setCurrent={setCurrent} current={current} setBackStep={setBackStep} />
      ),
    },
    {
      title: '04',
      contentText: t('myProject.detailDescription'),
      content: (
        <DetailDescription setCurrent={setCurrent} current={current} setBackStep={setBackStep} />
      ),
    },
    {
      title: '05',
      contentText: t('myProject.description'),
      content: <Description setCurrent={setCurrent} current={current} setBackStep={setBackStep} />,
    },
    {
      title: '06',
      contentText: t('myProject.statusProject'),
      content: (
        <StatusProject setCurrent={setCurrent} current={current} setBackStep={setBackStep} />
      ),
    },
  ]
  return (
    <>
      <div className="title_main">
        <h2>
          {!isChangeText ? (
            `Create Buidl`
          ) : (
            <div>
              Edit Buidl
              <ReadMore size={'20px'} color={'#fff'}>{` - ${buidlName}`}</ReadMore>
            </div>
          )}
        </h2>
      </div>
      <StepDefault currentStep={current} contentSteps={contentSteps} titleStep="Create Buidls" />
    </>
  )
}
export default CreateProject
