import 'assets/css/pool-public.css'
import PoolListWrapper from './PoolListWrapper'
import { debounce } from 'utils/common'
import { useCallback, useEffect, useState } from 'react'

function PoolPublic(props) {
  const [totalCount, setTotalCount] = useState(0)
  const initParam={
    poolName:'',
    poolStatus:''
  }
  const [param, setParam] = useState(initParam)
  const handleChangeSearch = debounce((value,currentParam) => {
    const newParam={...currentParam};
    newParam.poolName = value;
    setParam(newParam);
  })
  useEffect(() => {
    console.log(process.env.REACT_APP_TITLE)
  }, []);
  const optimizedFn = useCallback(debounce(handleChangeSearch), [])

  const listPoolPubByStatus = [
    {
      id: 1,
      status: '',
      tabId: 'nav-all',
      labelledby: 'nav-all-tab',
      target: 'nav-all',
      name: 'All',
    },
    {
      id: 2,
      status: 1,
      tabId: 'nav-upcoming',
      labelledby: 'nav-upcoming-tab',
      target: 'nav-upcoming',
      name: 'Upcoming',
    },
    {
      id: 3,
      status: 2,
      tabId: 'nav-live',
      labelledby: 'nav-live-tab',
      target: 'nav-live',
      name: 'Live',
    },
    {
      id: 4,
      status: 3,
      tabId: 'nav-end',
      labelledby: 'nav-end-tab',
      target: 'nav-end',
      name: 'End',
    },
  ]
  const handleClick = (value) => {
    const newParam={...param};
    newParam.poolStatus = value;
    setParam(newParam);
  }
  return (
    <>
      <div className="title_main row">
        <div className="col-12 col-sm-3">
          <h2>Pools</h2>
        </div>
        <div className="col-12 col-sm-6">
          <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
            {listPoolPubByStatus.map((item) => {
              return (
                <>
                  <button
                    onClick={() => handleClick(item.status)}
                    className={`nav-item nav-link ${item.status === param.poolStatus ? 'active' : ''}`}
                    id={item.labelledby}
                    data-bs-toggle="tab"
                    data-bs-target={`#${item.target}`}
                    type="button"
                    role="tab"
                    aria-controls={item.target}
                    aria-selected="false"
                  >
                    {item.name}
                  </button>
                </>
              )
            })}
          </div>
        </div>
        <div className="col-12 col-sm-3">
          <div className="search search-config">
            <input
              onChange={(e) => optimizedFn(e.target.value,param)}
              type="text"
              placeholder="Search pools here..."
            />
            <img src="images/icon-search.png" alt="" />
            <div className="total-search">
              Total: <span className="violent">{totalCount}</span> Pools
            </div>
          </div>
        </div>
      </div>
      <section className="content-main">
        <div id="nav-sub">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="row">
              <div span={24}>
                <div
                  id="poolListItem"
                  className="scroll-infinity"
                >
                  <PoolListWrapper
                    setTotalCount={setTotalCount}
                    param={param}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default PoolPublic
