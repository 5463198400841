import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    projectId: '',
    currentStep: '',
    smartId: null,
    step01: null,
    step02: null,
    step03: null,
    step04: null,
    step05: null,
  },
}

export const myProjectSlice = createSlice({
  name: 'myProject',
  initialState,
  reducers: {
    setMasterData: (state, action) => {
      state.masterData = action.payload.data
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
    setSmartId: (state, action) => {
      state.smartId = action.payload
    },
    setStep01: (state, action) => {
      state.step01 = action.payload
    },
    setStep02: (state, action) => {
      state.step02 = action.payload
    },
    setStep03: (state, action) => {
      state.step03 = action.payload
    },
    setStep04: (state, action) => {
      state.step04 = action.payload
    },
    setStep05: (state, action) => {
      state.step05 = action.payload
    },
    resetData: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const {
  setMasterData,
  setCurrentStep,
  setProjectId,
  setSmartId,
  setStep01,
  setStep02,
  setStep03,
  setStep04,
  setStep05,
  resetData,
} = myProjectSlice.actions


//state 
export const masterData = (state) => state.masterData
export const currentStep = (state) => state.currentStep
export const projectId = (state) => state.projectId
export const smartId = (state) => state.smartId
export const step01 = (state) => state.step01
export const step02 = (state) => state.step02
export const step03 = (state) => state.step03
export const step04 = (state) => state.step04
export const step05 = (state) => state.step05


export default myProjectSlice.reducer
