import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'antd/dist/antd.dark.min.css'
import 'assets/css/App.css'
import 'assets/css/my-project.css'
import 'assets/css/vendor.min.css'
import 'assets/css/layout.css'
import 'assets/css/media-screen.css'
import 'bootstrap/dist/js/bootstrap'
import 'assets/css/dstarter.css'
import MainLayout from 'pages/layouts/MainLayout'
import MyProject from 'pages/for-startup/project/MyProject'
import CreateProject from 'pages/for-startup/project/CreateProject'
import MyPool from 'pages/for-startup/pool/MyPool'
import CreatePool from 'pages/for-startup/pool/CreatePool'
import PrivateRoute from 'components/common/route/PrivateRoute'
import MyProfileComponent from 'pages/dashboard/MyProfile'
import { VerifyEmailComponent } from 'pages/dashboard/VerifyEmailPage'
import PoolPublic from 'pages/pool-public/pool'
import PoolDetail from 'components/pool/PoolDetail'
import { InvestorProfilePage } from 'pages/dashboard/investor/InvestorProfilePage'
import ProjectPublic from 'pages/project-public'
import { MyPoolInvestorManagementPage } from 'pages/dashboard/startup/MyPoolInvestorManagementPage'
import { MyInvestmentPage } from 'pages/dashboard/investor/MyInvestmentPage'
import {
  InvestorVoteDetailPage,
  StartupVoteDetailPage,
} from 'pages/dashboard/investor/VoteDetailPage'
import Page404 from 'pages/common/Page404'
import ProjectDetail from 'components/project-details'
import Dashboard from 'pages/dashboard/Dashboard'
import { SelectUserRolePage } from 'pages/dashboard/SelectUserRolePage'
import { ConnectWalletModal } from 'components/common/connect-wallet/ConnectWalletModal'
import { WalletAuth } from 'components/common/connect-wallet/WalletAuth'
import StartupClaim from 'pages/dashboard/startup/StartupClaim'
import Home from 'pages/Home'
import { FeatureUpdatingPage } from 'pages/FeatureUpdating'
import VcsList from 'pages/vcs-list'
import VcDetail from 'pages/vcs-list/VcDetail'
import MeditKit from 'pages/MeditKit'
import Funding from 'pages/funding'
import DaoClub from 'pages/daoclub'
import { useDispatch } from 'react-redux'

import API_Chain from 'apis/apichain'
import {
  updateListChain,
  updateListContract,
  updateListCurrency,
} from 'redux/reducers/networkSlice'
import { useEffect } from 'react'
import { useState } from 'react'
import { Spin } from 'antd'

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getInitDataContract = async () => {
    const chains = await API_Chain.getListChain()
    const listContract = await API_Chain.getListContract({
      pageIndex: 0,
      pageSize: 10000,
    })

    const listCurrency = await API_Chain.getListCurrency()
    if (listCurrency) {
      dispatch(updateListCurrency(listCurrency))
    }
    if (chains.Data) {
      dispatch(updateListChain(chains.Data))
    }
    if (listContract.Data) {
      dispatch(updateListContract(listContract.Data))
    }
    setLoading(false)
  }
  useEffect(() => {
    getInitDataContract();
  }, []);
  const comLoading = () => {
    return <div style={{ display: 'flex', height:'100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Spin size='large'></Spin>
    </div>
  }
  return <>
    {
      loading ? comLoading() : <div className="App">
        <div className="container-fluid padding0">
          <WalletAuth />
          <BrowserRouter>
            <ConnectWalletModal />
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path="pool" element={<PoolPublic />} />
                <Route path="pool/:id" element={<PoolDetail />} />
                <Route path="buidls" element={<ProjectPublic />} />
                <Route path="buidls/:id" element={<ProjectDetail />} />
                <Route path="dashboard" element={<Dashboard />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        requireWallet
                        requireEmail
                        requireAccessToken
                        requireUserRole
                        requireKYC
                      >
                        <MyProfileComponent />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/my-profile"
                    element={
                      <PrivateRoute requireWallet requireEmail requireAccessToken requireUserRole>
                        <MyProfileComponent />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/myproject"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <MyProject />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/pools"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <MyPool />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/pools/:id"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <CreatePool />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/dashboard/investor/my-investment"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyInvestor
                        requireKYC
                      >
                        <MyInvestmentPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/startup/vote-detail"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <StartupVoteDetailPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/startup/my-pool-investor/:id"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <MyPoolInvestorManagementPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/investor/my-investment"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyInvestor
                        requireKYC
                      >
                        <MyInvestmentPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/investor/profile"
                    element={
                      <PrivateRoute requireAccessToken requireEmail requireUserRole onlyInvestor>
                        <InvestorProfilePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/investor/vote-detail"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyInvestor
                        requireKYC
                      >
                        <InvestorVoteDetailPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/select-user-role"
                    element={
                      <PrivateRoute requireWallet requireAccessToken requireEmail>
                        <SelectUserRolePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard/claims"
                    element={
                      <PrivateRoute
                        requireAccessToken
                        requireEmail
                        requireUserRole
                        onlyStartup
                        requireKYC
                      >
                        <StartupClaim />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/dashboard/myproject/:id"
                  element={
                    <PrivateRoute
                      requireAccessToken
                      requireEmail
                      requireUserRole
                      onlyStartup
                      requireKYC
                    >
                      <CreateProject />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/verify-email"
                  element={
                    <PrivateRoute requireWallet requireAccessToken>
                      <VerifyEmailComponent />
                    </PrivateRoute>
                  }
                />
                <Route path="updating" element={<FeatureUpdatingPage />} />
                <Route path="statistic" element={<FeatureUpdatingPage />} />
                <Route path="incubator" element={<FeatureUpdatingPage />} />
                <Route path="blog" element={<FeatureUpdatingPage />} />
                <Route path="faq" element={<FeatureUpdatingPage />} />
                <Route path="vcslist" element={<VcsList />} />
                <Route path="vcs-detail/:id/:tabId" element={<VcDetail />} />
                <Route path="medit-kit" element={<MeditKit />} />
                <Route path="funding" element={<Funding />} />
                <Route path="daoclub" element={<DaoClub />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    }

  </>

}

export default App
