import { takeEvery, put, call } from 'redux-saga/effects';
import { ethers } from "ethers";
import * as actionName from 'redux/actions/actionName';
import { message } from 'antd';
import {connected} from 'redux/reducers/connectWalletSlice';
import {authActions} from 'redux/reducers/authSlice';
import {auth,getServerTime} from 'apis/apiauth';
import {setCookie} from 'utils/common'

function* connectWalletSaga() {
    yield takeEvery(actionName.ConnectWallet.Metamask, doConnectMetaMask)
}

function* doConnectMetaMask() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    var addresses = null;
    try {
        addresses = yield call(() =>{
            return provider.send("eth_requestAccounts", []);
        } );
        const signer =  provider.getSigner();
        var rsDateTime = yield call(() =>getServerTime());
        const timestamp = rsDateTime.data.Timestamp;
        const hashmessage = timestamp+"#"+"abc";
        const signature = yield call(() =>signer.signMessage(hashmessage));
        const rs = yield call(() =>auth({address:addresses[0], message:hashmessage,signature:signature}));
        yield put(authActions.authenticated(rs.data.Data.AccessToken))
    } catch (error) {
        message.warning(error.message);
    }
    if (addresses && addresses.length > 0) {
        yield put(connected({address: addresses[0]}));
        yield put(authActions.walletConnected(addresses[0]))
    }

}
export default connectWalletSaga;

