import { Avatar, Col, Row, Typography } from "antd"

export const PoolInfoCell = (props) => {
    const {pool} = props
    return <Row>
        <Col>
        {/* <Avatar /> */}
        </Col>
        <Col>
        <Row><Typography.Text>{pool.PoolName}</Typography.Text></Row>
        <Row><Typography.Text style={{color: '#A8B1FF', padding: '8px 0px'}}>{pool.ProjectName?.toUpperCase()}</Typography.Text></Row>
        </Col>
    </Row>
}