import React from 'react'
import { Button, message, Space, Spin, Table } from 'antd'
import { getTransferToVestingList } from 'apis/apiPoolInvestor'
// import { formartDateTimeFromUnix } from 'utils/common'
import { doClaimToken } from 'smartcontract/saleContract'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { PoolInfoCell } from 'components/pool/PoolInfoCell'
import { getClaimTokenInfo } from 'smartcontract/utilsContract'
import { ethers } from 'ethers'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
import { getIconChain } from 'utils/common'
export const TransferToVestingTable = () => {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const onRowDataChanged = (row, rowIndex) => {
    data[rowIndex] = { ...row }
    setData([...data])
  }

  React.useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const { OK, Data, Message } = await getTransferToVestingList({})
      if (OK) {
        setData(Data)
      } else {
        console.log('getTransferToVestingList', Message)
      }
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Pool Name',
      dataIndex: 'PoolName',
      key: 'PoolName',
      render: (_, record) => {
        return <PoolInfoCell pool={record} />
      },
    },
    {
      title: 'Chain',
      dataIndex: 'Chain',
      width: 80,
      key: 'Chain',
      render: (_, record) => {
        return <img style={{ width: 30 }} src={getIconChain(record.Chain)} />
      },
    },
    {
      title: 'Token Allocation',
      dataIndex: 'TokenAllocation',
      key: 'TokenAllocation',
      width: 300,
      render: (text, record, rowIndex) => (
        <AllocationCell
          text={text}
          record={record}
          rowIndex={rowIndex}
          onRowDataChanged={onRowDataChanged}
        />
      ),
    },
    {
      title: 'TokenVesting',
      width: 180,
      dataIndex: 'TokenVesting',
      key: 'TokenVesting',
    },
  ]

  return <Table loading={loading} columns={columns} dataSource={data} />
}

const AllocationCell = (props) => {
  const { text, record, rowIndex, onRowDataChanged } = props
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState(0)
  const { walletID } = useSelector(authSelector)
  const { currentNetwork } = useSelector(authSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    loadData()
  }, [])
  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }
  const doTransferToken = async () => {
    if (!checkChangeBeforeSubmit(record.Chain)) {
      dispatch(updateSwitchChainId({
        chainId: record.Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
      return;
    }
    try {
      setLoading(true)
      await doClaimToken(record.PoolSmartID, walletID)
      //refresh data
      loadData()
    } catch (err) {
      if (err.reason) {
        message.error(err.reason)
      }
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const loadData = async () => {
    try {
      setLoading(true)
      const { tokenRemaining, tokenClaimed } = await getClaimTokenInfo(record.PoolSmartID, walletID,record.Chain)
      record.TokenVesting = ethers.utils.formatEther(tokenClaimed)
      record.TokenAllocation = ethers.utils.formatEther(tokenRemaining)
      setData(tokenRemaining)
      // if (currentNetwork.chainID === record.Chain) {

      // }
      // else {
      //   record.TokenVesting = '0.0'
      //   record.TokenAllocation = '0.0'
      // }
      onRowDataChanged(record, rowIndex)
    } catch (err) {
      console.log(err.message)
      if (err.reason) {
        message.error(err.reason)
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      {
        <Spin spinning={loading}>
          <Space>
            <span>{text}</span>
            {data > 0 ? <Button onClick={doTransferToken}>Transfer</Button> : null}
          </Space>
        </Spin>
        // (currentNetwork.chainID === record.Chain) ? 
        //   : <Button onClick={() => switchNetwork(record.Chain)}>Switch chain to view</Button>
      }
    </>
  )
}
