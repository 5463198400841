import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, message } from 'antd'
import { useSelector } from 'react-redux'

import * as api from 'apis/apiprofiles'
import { authSelector } from 'redux/reducers/authSlice'
import { useNavigate } from 'react-router-dom'
import { userProfileSelector } from 'redux/reducers/userProfile'

const STEP_NONE = 0
const STEP_IN_PROGRESS = 1
const STEP_VERFIED = 2

export const VerifyEmailComponent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const [currentEmail, setCurrentEmail] = React.useState('')
  const [step, setStep] = React.useState(STEP_NONE)
  const [loading, setLoading] = React.useState(false)
  const goToSelectUserRole = () => {
    navigate('/dashboard/select-user-role')
  }

  React.useEffect(() => {
    if (userProfile?.ValidateEmail) {
      form.setFieldsValue({ email: userProfile.Email })
      setStep(STEP_VERFIED)
    }
  }, [userProfile])

  const [form] = Form.useForm()
  React.useEffect(() => {
    form.setFieldsValue({ walletID })
  }, [walletID])

  const startVerifyEmail = async (email) => {
    setCurrentEmail(email)
    const { OK, ResultCode, Message } = await api.startVerifyingEmail({ email })
    if (OK) {
      setStep(STEP_IN_PROGRESS)
    } else {
      message.error(`startVerifyEmailFailed: ${ResultCode} - ${Message}`)
    }
  }
  const confirmVerificationCode = async (code) => {
    const { OK, ResultCode, Message } = await api.confirmVerificationCode({ code })
    if (OK) {
      message.success('Success')
      setStep(STEP_VERFIED)
    } else {
      message.error(`confirmVerificationCodeFailed: ${ResultCode} - ${Message}`)
    }
  }

  const resendVerificationCode = async () => {
    if (step == STEP_IN_PROGRESS) {
      const { OK, ResultCode, Message } = await api.resendVerificationCode(currentEmail)
      if (OK) {
        message.success('Success')
      } else {
        message.error(`confirmVerificationCodeFailed: ${ResultCode} - ${Message}`)
      }
    }
    return false
  }

  const handleSteps = () => {
    setLoading(true)
    const values = form.getFieldsValue()
    switch (step) {
      case STEP_NONE:
        startVerifyEmail(values.email)
        break
      case STEP_IN_PROGRESS:
        confirmVerificationCode(values.code)
        break
      case STEP_VERFIED:
      default:
    }
    setLoading(false)
  }

  return (
    <>
      <div className="title_main row">
        <div className="col-12 col-sm-3">
          <h2>Verify Email</h2>
        </div>
        <div className="col-12 col-sm-9">
          <ul className="title-step">
            <li className="active">
              <span>01</span>Verify Email
            </li>
            <li>
              <span>02</span>Choose Role
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="bg-step bg-verify">
        <div className="email-p">
          {step === STEP_NONE ? (
            <>
              <p>Enter your email to verify your account.</p>
              <Form form={form} layout="vertical" onFinish={handleSteps}>
                <Form.Item name="email" noStyle>
                  <Input className="form-control" placeholder="Example: dstarter@gmail.com" />
                </Form.Item>
                <div className="group_btn">
                  <button type="submit" className="btn-submit">
                    Send Email
                  </button>
                </div>
              </Form>
            </>
          ) : null}
          {step === STEP_IN_PROGRESS ? (
            <>
              <p>
                We have sent an active email to your email{' '}
                <span style={{ color: '#05FF00' }}>{currentEmail}</span>, please visit your email to
                activate your account.
              </p>
              <Form form={form} layout="vertical" onFinish={null} className="verify-email">
                <Form.Item name="code" label="Email Code" noStyle>
                  <Input className="form-control" placeholder="" />
                </Form.Item>
                <div className="group_btn">
                  <button disabled={loading} onClick={handleSteps} className="btn-submit">
                    {t('user-profile.submit-email-code')}
                  </button>
                  <button onClick={resendVerificationCode} className="btn-submit">
                    Send Again
                  </button>
                </div>
              </Form>
            </>
          ) : null}
          {step === STEP_VERFIED ? (
            <>
              <img className="status-img" src="/images/true.png" alt="" />
              <p>Verify Email Successful!</p>
              <form className="verify-email">
                <div className="group_btn">
                  <button type="submit" className="btn-submit" onClick={goToSelectUserRole}>
                    Select User Role
                  </button>
                </div>
              </form>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
