import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { authSelector } from 'redux/reducers/authSlice'
import { userProfileSelector } from 'redux/reducers/userProfile'

const BLOCKPASS_SERVICE_ID = process.env.REACT_APP_BLOCKPASS_SERVICE_ID
const BLOCKPASS_BUTTON_ID = 'blockpass-kyc-connect'

// const {firstName, lastName, dateOfBirth, nationality, country, stage, city, street, postalCode, email, phone} = KYC

export const KYCButton = () => {
  const { t } = useTranslation()
  const { walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const notValidatedKYC = !userProfile?.ValidateKYC

  // Load BLockpass js lib
  React.useEffect(() => {
    if (walletID) {
      initBlockpass(walletID)
    }
  }, [walletID])

  // Init Blockpass sdk with current user's walletID
  const initBlockpass = async (refId) => {
    if (window.BlockpassKYCConnect && document.getElementById(BLOCKPASS_BUTTON_ID)) {
      console.log('Init KYC')
      const blockpass = new window.BlockpassKYCConnect(BLOCKPASS_SERVICE_ID, { refId })
      blockpass.startKYCConnect()
      blockpass.on('KYCConnectSuccess', () => {
        console.log('Blockpass data has been sent')
      })
    } else {
      console.log('KYC not loaded')
    }
  }

  return notValidatedKYC ? (
    <div className="d-flex justify-content-space-between align-items-center">
      <div style={{ paddingRight: '20px' }}>
        Status KYC : <span className="red">Unverify</span>
      </div>
      <button id={BLOCKPASS_BUTTON_ID} className="btn btn-submit">
        KYC
      </button>
    </div>
  ) : (
    <div>
      Status KYC : <span className="green"> {t('user-profile.kyc-verified')}</span>
    </div>
  )
}
