import { Statistic } from 'antd'
import { useNavigate } from 'react-router-dom'
import iconDrop from '../../../../assets/images/drop.svg'
import moment from 'moment'
import { formatNumber, getPoolStatusBg, getStatusPool } from 'utils/constants'
import { useSelector } from 'react-redux'
import { chainSelector } from 'redux/reducers/networkSlice'
import { getCurrencyById, getIconChain } from 'utils/common'
const { Countdown } = Statistic

function PoolItem(props) {
  const navigate = useNavigate()
  const navigateToManageInvestor = () => {
    navigate(`/dashboard/startup/my-pool-investor/${props.data.PoolID}`)
  }

  return props.data ? (
    <div>
      <article className="item-card">
        <img className="img-fluid" src={props.data?.PoolAvatar} alt="" />
        <h4>
          {props.data?.NameInvestment}
          <img
            src={getIconChain(props.data?.Chain) || require('assets/images/wallet-bsc.png').default}
            alt=""
            style={{ width: '30px' }}
          />
        </h4>
        <hr />
        <ul>
          <li>
            Total Fund Raise:
            <span className="text-ocean">
              {`${formatNumber(props?.data?.TotalFundRaise)} ${getCurrencyById(
                props.data.Currency,
              )} `}
            </span>
          </li>
          <li>
            Pool End In:
            <span className="text-red">
              <Countdown
                value={moment.utc(props?.data?.EndTime, 'X')}
                format={'D [Days] HH : mm : ss'}
              />
            </span>
          </li>
        </ul>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${props?.data?.RateRaise}%` }}
            aria-valuenow={`${props?.data?.RateRaise}`}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {props?.data?.RateRaise}%
          </div>
        </div>
        <p className="current">
          {props?.data?.TotalMoneyRaise} {getCurrencyById(props.data.Currency)}
        </p>
        <div className="status-text text-center">
          Status: <b className="text-green">Verified</b>
        </div>
        <div className="dropdown">
          <button
            className="btn dropdown-toggle"
            type="button"
            id="manager"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Manage <img src={iconDrop} alt="" />
          </button>
          <ul className="dropdown-menu" aria-labelledby="manager">
            <li>
              <div
                className="dropdown-item"
                onClick={() => navigate(`/dashboard/pools/${props.data.PoolID}`)}
              >
                Edit Pool
              </div>
            </li>
            <li>
              <div className="dropdown-item" onClick={navigateToManageInvestor}>
                Manage Investor
              </div>
            </li>
            {/* <li>
              <a className="dropdown-item" href="#">
                Delete Pool
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Preview Pool
              </a>
            </li> */}
          </ul>
        </div>
        <span className={`status-item ${getPoolStatusBg(props?.data?.StatusCode)}`}>
          {getStatusPool(props?.data?.StatusCode).toUpperCase()}
        </span>
      </article>
    </div>
  ) : null
}
export default PoolItem
