import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'
import { chainSelector, closeConfirmSwitchChain } from 'redux/reducers/networkSlice'
import { authSelector } from 'redux/reducers/authSlice'
import { useSwitchChain } from 'components/hooks/useSwitchChain';
import { getChainById } from 'utils/common'

export const SwitchChainModal = () => {
    const network = useSelector(chainSelector)
    const dispatch = useDispatch();
    const { currentNetwork } = useSelector(authSelector);
    const { onSwitch } = useSwitchChain();
    const switchNetwork = async () => {
        const chain = getChainById(network.switchChainId);
        onSwitch(chain);
    }
    const onCancel = () => {
        dispatch(closeConfirmSwitchChain())
    }
    return (
        <>{
            currentNetwork ? <Modal
                width={500}
                style={{ padding: 0 }}
                bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
                visible={network.showConfirmSwitchChain}
                footer={null}
                onCancel={null}
                closable={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{network.switchTitle}</h5>
                </div>
                <div className="modal-body">
                    <div className="group_connect">
                        <p className="text-center">{network.switchDescription}</p>
                        <div className="row content-convert">
                            <div className="col-6 pd0">
                                <div className="item-convert convert-to">
                                    <img src={currentNetwork.icon} alt="" />
                                    <p>{currentNetwork.chainName}</p>
                                </div>
                            </div>
                            <div className="col-6 pd0">
                                <div className="item-convert">
                                    <img src={getChainById(network.switchChainId).icon} alt="" />
                                    <p>{getChainById(network.switchChainId).chainName}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center">
                    <button onClick={onCancel} type="button" className="btn btn-cancel">
                        Cancel
                    </button>
                    <button type="button" className="btn btn-submit" onClick={switchNetwork}>
                        Confirm
                    </button>
                </div>
            </Modal> : null
        }

        </>
    )
}