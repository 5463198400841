import React from 'react'
import { Form, Upload, Input, message, Select, InputNumber, Collapse, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import * as profilesApi from 'apis/apiprofiles'
import { useSelector } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { useUploadFile } from 'components/hooks/useUploadFile'
import { ConfirmModal } from 'components/common/ConfirmModal'
import iconAvatar from 'assets/images/avatar.png'
import { useCoinData } from 'components/hooks/data/useCoinData'
import { RightOutlined } from '@ant-design/icons'
import BaseInputNumber from 'components/common/form-control/BaseInputNumber'
const { Option } = Select
const { Panel } = Collapse;
const countries = require('assets/data/country_list.json').sort(
  (left, right) => left.name > right.name,
)
const countryOptions = countries.map((c) => <Option value={c.name}>{c.name}</Option>)

export const InvestorProfilePage = () => {
  const { t } = useTranslation()
  const [avatarUrl, setAvatarUrl] = React.useState()
  const recentProfileRef = React.useRef({})
  const [confirmSubmitVisible, setconfirmSubmitVisible] = React.useState(false)
  const [confirmCancelVisible, setconfirmCancelVisible] = React.useState(false)
  const { coinList = [] } = useCoinData()

  const [form] = Form.useForm()
  const { accessToken, walletID } = useSelector(authSelector)
  const { fileUploadRef, uploadProps, doUploadFile } = useUploadFile()

  React.useEffect(() => {
    if (accessToken) {
      getInvestorProfile()
    }
  }, [accessToken])

  const dataToFormValues = (d) => {
    const v = Object.assign({}, d)
    if (d.InterestedFielad_Industr) {
      v.InterestedFielad_Industr = d.InterestedFielad_Industr.split(';')
    } else {
      v.InterestedFielad_Industr = []
    }
    if (d.InterestedStage) {
      v.InterestedStage = d.InterestedStage.split(';')
    } else {
      v.InterestedStage = []
    }
    if (v.InterestedSeries) {
      v.InterestedSeries = d.InterestedSeries.split(';')
    } else {
      v.InterestedSeries = []
    }
    if (v.HashTag) {
      v.HashTag = d.HashTag.split(';')
    } else {
      v.HashTag = []
    }
    if (v.Portfolio) {
      v.Portfolio = d.Portfolio.split(';')
    } else {
      v.Portfolio = []
    }
    return v
  }
  const formValuesToData = (v) => {
    const d = Object.assign({}, v)
    if (Array.isArray(v.InterestedFielad_Industr)) {
      d.InterestedFielad_Industr = v.InterestedFielad_Industr.join(';')
    }
    if (Array.isArray(v.InterestedStage)) {
      d.InterestedStage = v.InterestedStage.join(';')
    }
    if (Array.isArray(v.InterestedSeries)) {
      d.InterestedSeries = v.InterestedSeries.join(';')
    }
    if (Array.isArray(v.HashTag)) {
      d.HashTag = v.HashTag.join(';')
    }
    if (Array.isArray(v.Portfolio)) {
      d.Portfolio = v.Portfolio.join(';')
    }
    return d
  }

  const onFormFinish = async (values) => {
    if (avatarUrl) {
      const param = formValuesToData(values)
      if (fileUploadRef.current) {
        const url = await doUploadFile()
        param.Avatar = url
      }
      const { OK, ResultCode, Message } = await profilesApi.createInvestorProfile({
        walletID: walletID,
        information: param,
      })
      if (!OK) {
        message.error(`${ResultCode} ${Message}`)
      } else {
        message.success(Message)
      }
    } else {
      message.error('Please upload avatar')
    }
  }

  const onFormFinishFailed = () => {
    message.error('FormSubmit failed')
  }

  const getInvestorProfile = async () => {
    const { OK, ResultCode, Message, Data } = await profilesApi.getInvestorProfile(walletID)
    if (!OK) {
      console.log(`${ResultCode} ${Message}`)
    } else {
      form.setFieldsValue(dataToFormValues(Data))
      setAvatarUrl(form.getFieldValue('Avatar'))
      recentProfileRef.current = Object.assign({}, Data)
    }
  }

  const confirmCancel = () => {
    // restore form values from stored profile
    setconfirmCancelVisible(false)
    form.setFieldsValue(recentProfileRef.current)
  }
  const cancelCancel = () => {
    setconfirmCancelVisible(false)
  }
  const confirmSubmit = () => {
    setconfirmSubmitVisible(false)
    form.submit()
  }
  const cancelSubmit = () => {
    setconfirmSubmitVisible(false)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const handleChange = (info) => {
    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    const isLt2M = info.file.size / 1024 / 1024 < 2
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    if (isJpgOrPng && isLt2M) {
      getBase64(info.file, (url) => {
        setAvatarUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      <div
        style={{
          padding: '0 25px',
        }}
      >
        <img src={iconAvatar} alt="" />
      </div>
    </div>
  )
  return (
    <>
      <div className="col-12 col-sm-12">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormFinish}
          onFinishFailed={onFormFinishFailed}
        >
          <div className="bg-gradient-blue" style={{borderRadius:10}}>
            <div className="bg-d" style={{paddingBottom:30}}>
              <div className="row mb-5">
                <div className="col-12 col-sm-5">
                  <div className="title-show">
                    <h5>Investor Profile</h5>
                    <hr />
                    <p>
                      You need to fill full information about your Venture capital. Then, it needs
                      to be authenticated by DStarter's Admin before you invest in a startup project
                      on our system.
                    </p>
                    <div className="text_underline">
                      <a href="#">More information </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-7"></div>
              </div>
              <div className="row mg-80 d-flex justify-content-space-between">
                <div style={{ margin: '30px 0' }} className="col-12 col-sm-5">
                  <div className="title-show">
                    <h5>Avatar of Investor</h5>
                    <hr />
                    <p>
                      You should set your logo as your avatar. This picture will be shown public to
                      everyone.
                    </p>
                    <div className="text_underline">
                      <a href="#">More information </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-7">
                  <div className="bg-file">
                    <div className="row d-flex align-items-center justify-content-space-between ">
                      <div className="col-12 col-sm-4">
                        <div className="picture-frames-investor d-flex align-items-center justify-content-center ">
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action=""
                            onChange={handleChange}
                            {...uploadProps}
                          >
                            {avatarUrl ? (
                              <img src={avatarUrl} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </div>
                      </div>
                      <div className="col-12 col-sm-8">
                        <p className="note-p">
                          * The image should be used with a ratio suitable for the display interface
                          (Recommended: 334 x 500 px). <br />
                          * Drag or tap upload here to add photos.
                          <br />
                          * JPEG or PNG format. <br />* No larger than 2MB.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="title-show">
                  <h5>Information of Investor</h5>
                  <hr />
                  <p>
                    Users need to fill full information about your investment fund. Then, it needs
                    to be authenticated by DStarter before you can invest to a startup on our
                    system.
                  </p>
                  <div className="text_underline" style={{ display: 'flex', justifyContent: 'end' }}>
                    <a href="#">More information </a>
                  </div>
                </div>
              </div>
              <div className='row'>
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
                  expandIconPosition={'end'}
                  className="investor-col"
                >
                  <Panel header="Basic Information" key="1">
                    <Row gutter={[20, 0]}>
                      <Col lg={12} md={24} sm={24} xs={24} >
                        <div className="form-group">
                          <Form.Item
                            name="NameVentureCapital"
                            label="Name of Venture Capital"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Input placeholder="Ex: Max Capital" />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="LegalEntity"
                            label="Legal entity"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Input placeholder="Ex: Vingroup joint stock company" />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item name="NumberEmployee" label="Estimated staff count">
                            <Select placeholder={t('myProject.numberOfEmployee')}>
                              <Option value={1}>1-10</Option>
                              <Option value={2}>10-100</Option>
                              <Option value={3}>100-300</Option>
                              <Option value={4}>300-600</Option>
                              <Option value={5}>600-1,000</Option>
                              <Option value={6}>More than 1,000</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <div className="form-group">
                          <Form.Item
                            name="TypeVentureCapital"
                            label=" Type of Venture Capital"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Select placeholder="Ex: Choose your Type of Venture Capital">
                              <Option value="vcfirm">Vc firm</Option>
                              <Option value="corporatevc">Corporate VC</Option>
                              <Option value="pefirm">PE firm</Option>
                              <Option value="accelerator">Accelerator</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="ShortDescription"
                            label="Short description"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Input placeholder="Insert your text here. " />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="Country"
                            label="Country"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Select
                            >
                              <Option value=""></Option>
                              {countryOptions}
                            </Select>
                          </Form.Item>
                        </div>

                      </Col>
                    </Row>
                    <Row>
                        <Form.Item
                          name="TotalFund"
                          label="Total Fund"
                          rules={[
                            { required: true, message: 'Total Fund is required' },
                            {
                              type: 'number',
                              min: 1,
                              message: 'Total Fund cannot be less than 1',
                            },
                          ]}
                        >
                          <BaseInputNumber
                            placeholder="Specify total fund"
                            style={{ width: '100%' }}
                          ></BaseInputNumber>
                        </Form.Item>
                    </Row>
                  </Panel>
                  <Panel header="Interested Investment" key="2">
                    <Row gutter={[20, 0]}>
                      <Col lg={12} md={24} sm={24} xs={24} >
                        <div className="form-group">
                          <Form.Item
                            name="InterestedFielad_Industr"
                            label="Interested field / Industry"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Select mode="multiple">
                              <Option value="GameFi">Game FI</Option>
                              <Option value="Edtech">Edtech</Option>
                              <Option value="Martech">Martech</Option>
                              <Option value="Software As A Services">Software as a Services</Option>
                              <Option value="Metaverse">Metaverse</Option>
                              <Option value="AI">AI</Option>
                              <Option value="Web3">Web3</Option>
                              <Option value="Agritech">Agritech</Option>
                              <Option value="Health Care">Health Care</Option>
                              <Option value="Social Platform">Social Platform</Option>
                              <Option value="E-Commerce">E-Commerce</Option>
                              <Option value="RealEstate">Real Estate</Option>
                              <Option value="Banking">Banking</Option>
                              <Option value="Automative">Automative</Option>
                              <Option value="Analystics">Analystics</Option>
                              <Option value="Advertising">Advertising</Option>
                              <Option value="Security">Security</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="InterestedSeries"
                            label="Interested Series"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Select  mode="multiple">
                              <Option value="Private">Private Sale</Option>
                              <Option value="Public">Public Sale</Option>
                              <Option value="Pre">Pre Seed</Option>
                              <Option value="Seed">Seed</Option>
                              <Option value="Late">Late Seed</Option>
                              <Option value="Angel">Angel Funding</Option>
                              <Option value="SeriesA">Series A</Option>
                              <Option value="SeriesB">Series B</Option>
                              <Option value="Series">Series</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24} >
                        <div className="form-group">
                          <Form.Item
                            name="InterestedStage"
                            label="  Interested Stage"
                            rules={[{ required: true, message: `${t('myProject.inputRequired')}` }]}
                          >
                            <Select mode="multiple">
                              <Option value="IdeaStage">Idea Stage</Option>
                              <Option value="Cofounder">Co-founder Stage</Option>
                              <Option value="MVP">MVP Stage</Option>
                              <Option value="Early">Early Stage</Option>
                              <Option value="Prototype">Prototype Stage</Option>
                              <Option value="Growth">Growth Stage</Option>
                              <Option value="Early">Early revenue Stage</Option>
                              <Option value="Revenue">Revenue Generate Stage</Option>
                              <Option value="Other">Other Stage</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item name="HashTag" label="Hash Tags">
                            <Select  mode="multiple">
                              <Option value="DAO">DAO</Option>
                              <Option value="NFT">NFT</Option>
                              <Option value="GameFi">GameFi</Option>
                              <Option value="Metaverse">Metaverse</Option>
                              <Option value="X-2-Earn">X-2-Earn</Option>
                              <Option value="Music NFT">Music NFT</Option>
                              <Option value="ETH 2.0">ETH 2.0</Option>
                              <Option value="L2">L2</Option>
                              <Option value="ZK">ZK</Option>
                              <Option value="Frontier Tech">Frontier Tech</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Social Media" key="3">
                    <Row gutter={[20, 0]}>
                      <Col lg={12} md={24} sm={24} xs={24} >
                        <div className="form-group">
                          <Form.Item
                            name="Website"
                            label="Website"
                            rules={[
                              {
                                required: true,
                                type: 'url',
                                message: 'Invalid website url',
                              },
                            ]}
                          >
                            <Input placeholder="Ex: http://dstarter.io" />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="Linkedin"
                            label="Linkedin"
                            rules={[
                              {
                                type: 'url',
                                message: 'Invalid linkedin url',
                              },
                            ]}
                          >
                            <Input placeholder="Ex: http://linkedin.com" />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item
                            name="Twitter"
                            rules={[
                              {
                                type: 'url',
                                message: 'Invalid twitter url',
                              },
                            ]}
                            label="Twitter"
                          >
                            <Input placeholder="Ex: http://twitter.com" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={12} md={24} sm={24} xs={24} >
                        <div className="form-group">
                          <Form.Item
                            name="ContactEmail"
                            label="Email"
                            rules={[
                              {
                                type: 'email',
                                message: `${t('myProject.inputRequired')}`,
                              },
                            ]}
                          >
                            <Input placeholder="Ex: info@dstarter.io" />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="Telegram"
                            label="Telegram"
                            placeholder="Ex: http://telegram.io"
                            rules={[
                              {
                                type: 'url',
                                message: 'Invalid telegram url',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </div>

                        <div className="form-group">
                          <Form.Item
                            name="Medium"
                            label="Medium"
                            rules={[
                              {
                                type: 'url',
                                message: 'Invalid medium url',
                              },
                            ]}
                          >
                            <Input placeholder="Ex: http://medium.com" />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Investment Portfolio" key="4">
                    <div className="form-group">
                      <Form.Item name="Portfolio" label="Portfolio">
                        <Select
                          showSearch
                          mode="multiple"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          {coinList.map((coin) => (
                            <Option value={coin.symbol}>{coin.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>

            <div className="btn-group">
              <Space>
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={() => setconfirmCancelVisible(true)}
                >
                  Cancel
                </button>
                <div
                  // type="submit"
                  className="btn-submit"
                  onClick={() => setconfirmSubmitVisible(true)}
                >
                  Update
                </div>
              </Space>
            </div>
          </div>
        </Form>
      </div>

      <ConfirmModal
        visible={confirmCancelVisible}
        onOk={confirmCancel}
        onCancel={cancelCancel}
        message="Are you sure to cancel ?"
      />
      <ConfirmModal
        visible={confirmSubmitVisible}
        onOk={confirmSubmit}
        onCancel={cancelSubmit}
        message="Are you sure to submit this form ?"
      />
    </>
  )
}
