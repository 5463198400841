import { customAxios } from 'utils/customAxios'

const baseURLStorage = process.env.REACT_APP_API_URL+'/storage'
const baseURLProject = process.env.REACT_APP_API_URL+'/projects'
const baseURLProfile = process.env.REACT_APP_API_URL+'/profiles'

export const handleResponse = (res) => {
  const data = res?.data?.data
  return data
}

export const handleError = (err) => {
  console.error(err)
  throw err
}

export default {
  /// API Upload image
  uploadImage: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLStorage}/Upload/auth/UploadFiles`,
      data: data,
    })
    return res.data
  },

  // Pool API Step 01:
  getPoolStep1List: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step01/auth/GetList`,
      data: body,
    })
    return res.data
  },
  createPoolStep1Project: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Step01/auth/Create`,
      data: data,
    })
    return res.data
  },

  getPoolStep1ProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Step01/auth/GetInfo/${projectId}`,
    })
    return res.data
  },

  // Pool API Step 02:
  createPoolStep2Project: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Step02/auth/Create`,
      data: data,
    })
    return res.data
  },
  getPoolStep2ProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Step02/auth/GetInfo/${projectId}`,
    })
    return res.data
  },

  // Pool API Step 03:
  createPoolStep3Project: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Step03/auth/Create`,
      data: data,
    })
    return res.data
  },
  getPoolStep3ProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Step03/auth/GetInfo/${projectId}`,
    })
    return res.data
  },
  // Pool API Step 04:
  createPoolStep4Project: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Step04/auth/Create`,
      data: data,
    })
    return res.data
  },
  getPoolStep4ProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Step04/auth/GetInfo/${projectId}`,
    })
    return res.data
  },
  // Pool API Step 05:
  createPoolStep5Project: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Step05/auth/Create`,
      data: data,
    })
    return res.data
  },
  getPoolStep5ProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Step05/auth/GetInfo/${projectId}`,
    })
    return res.data
  },

  ///My Buidls get list
  getMyProject: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Information/auth/GetList`,
      data: body,
    })
    return res.data
  },

  getProjectById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Information/auth/GetInfo/${projectId}`,
    })
    return res.data
  },

  // Project API Step 01:
  createProjectStep1: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step01/auth/Create`,
      data: data,
    })
    return res.data
  },
  getProjectStep1ById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Step01/auth/GetInfo/${projectId}`,
    })
    return res.data
  },
  getProjectStep1List: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step01/auth/GetList`,
      data: body,
    })
    return res.data
  },

  // Project API Step 02:
  createProjectStep2: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step02/auth/Create`,
      data: data,
    })
    return res.data
  },
  getProjectStep2ById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Step02/auth/GetInfo/${projectId}`,
    })
    return res.data
  },

  // Project API Step 03:
  createProjectStep3: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step03/auth/Create`,
      data: data,
    })
    return res.data
  },
  getProjectStep3ById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Step03/auth/GetInfo/${projectId}`,
    })
    return res.data
  },
  // Project API Step 04:
  createProjectStep4: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step04/auth/Create`,
      data: data,
    })
    return res.data
  },
  getProjectStep4ById: async (projectId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Step04/auth/GetInfo/${projectId}`,
    })
    return res.data
  },
  // Project API Step 05:
  createProjectStep5: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Step05/auth/Create`,
      data: data,
    })
    return res.data
  },
  //Get Pool
  getListPoolPub: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Information/pub/MyPool/GetList`,
      data: body,
    })
    return res.data
  },
  getDetailPoolById: async (poolId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Information/pub/GetInfo/${poolId}`,
    })
    return res.data
  },
  createPoolInvestor: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Investor/auth/Create`,
      data: data,
    })
    return res.data
  },
  listInvestors: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Pool_Investor/pub/GetList`,
      data: data,
    })
    return res.data
  },

  getListProjectPublic: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProject}/Project_Information/pub/GetListProject`,
      data: data,
    })
    return res.data
  },

  getProjectDetailPublic: async (projectID) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Project_Information/pub/GetInfo/${projectID}`,
    })
    return res.data
  },

  getPoolPubById: async (poolId) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProject}/Pool_Information/pub/MyPool/GetInfo/${poolId}`,
    })
    return res.data
  },

  getListByRole: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProfile}/UserProfile/pub/Investor/GetList`,
      data: body,
    })
    return res.data
  },
  getVcDetailByWalletID: async (walletID) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProfile}/UserProfile/pub/Investor/GetInfo/${walletID}`,
    })
    return res.data
  },
}
