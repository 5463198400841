import { Modal } from 'antd'

export const DStarterModal = (props) => {
  const { style, bodyStyle, ...restProps } = props
  return (
    <Modal
      style={{ padding: 0, ...style }}
      bodyStyle={{
        background: "#1A1E54 url('/images/bg-wave.png') no-repeat top left",
        borderRadius: '1em',
        ...bodyStyle,
      }}
      {...restProps}
    >
      {props.children}
    </Modal>
  )
}
