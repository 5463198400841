import { message, Row, Space, Spin, Statistic, Typography, Tooltip, Button } from 'antd'
import { DStarterModal } from 'components/common/DStarterModal'
import { ethers } from 'ethers'
import React, { useEffect } from 'react'
import { castVote, proposalVotes } from 'smartcontract/daoContract'
import { getBlockVoteInfo } from 'smartcontract/utilsContract'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector } from 'redux/reducers/authSlice'
import { useState } from 'react'
import moment from 'moment'
import { userProfileSelector } from 'redux/reducers/userProfile'
import { formatDateTime, getIconChain } from 'utils/common'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
const VOTE_YES = 'YES'
const VOTE_NO = 'NO'
const VOTE_ABSTAIN = 'ABSTENTION'

/**
 * Render vote buttons
 * @param {*} props {onYes, onNo, onAbstain}
 * @returns
 */
const VoteButtonGroup = (props) => {
  const voteItem = props.voteItem || {}
  const { AddressDao, ProposalID, ProjectName,Chain } = voteItem
  const [modalVisible, setModalVisible] = React.useState(false)
  const [voteValue, setVoteValue] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const hideModal = () => setModalVisible(false)
  const dispatch = useDispatch();
  const {currentNetwork } = useSelector(authSelector)
  const checkChangeBeforeSubmit = (chainId) => {
    var result = false;
    if (currentNetwork && chainId) {
      return (currentNetwork.chainID === chainId)
    }
    return result;
  }
  const onVoteClick = (value) => {
    if (!checkChangeBeforeSubmit(Chain)) {
      dispatch(updateSwitchChainId({
        chainId: Chain,
        title: 'Please switch chain of buidl',
        description: 'You need to switch chain to continue'
      }))
      return;
    }
    setVoteValue(value)
    setModalVisible(true)
  }
  const onConfirmVote = async () => {
    try {
      let castValue = -1
      switch (voteValue) {
        case VOTE_YES:
          castValue = 1
          break
        case VOTE_NO:
          castValue = 0
          break
        case VOTE_ABSTAIN:
          castValue = 2
          break
      }
      if (castValue < 0 || castValue > 2) {
        message.error(`Invalid vote: ${castValue}`)
        return
      }
      setLoading(true)
      await castVote(AddressDao, ProposalID, castValue)
      if (props.voteComplete) props.voteComplete()
    } catch (err) {
      message.error(err.reason)
    } finally {
      setLoading(false)
      hideModal()
    }
  }
  return (
    <>
      <div className="group_btn">
        <button type="button" className="btn-submit" onClick={() => onVoteClick(VOTE_YES)}>
          <img src="/images/icon-like-l.png" alt="" /> Yes
        </button>
        <button type="button" className="btn-submit bg-red" onClick={() => onVoteClick(VOTE_NO)}>
          <img src="/images/icon-dislike-l.png" alt="" /> No
        </button>
        <button type="button" className="btn-submit bg-gray" onClick={() => onVoteClick(VOTE_ABSTAIN)}>
          <img src="/images/icon-balance.png" alt="" /> Abstention
        </button>
      </div>
      <DStarterModal
        visible={modalVisible}
        footer={null}
        onCancel={hideModal}
        closable={false}
        bodyStyle={{ width: 700 }}
      >
        <Row justify="center" style={{ marginBottom: 16 }}>
          <Typography.Text style={{ fontSize: 20, padding: 16, color: '#A8B1FF' }}>
            Your vote is <span style={{ color: '#fff' }}>{voteValue}</span> in the{' '}
            <span style={{ color: '#fff' }}>{ProjectName}</span> do you want to submit ?
          </Typography.Text>
          <Typography.Text style={{ padding: 8, fontSize: 16, fontStyle: 'italic', color: 'red' }}>
            *After submit, Investor cannot change the vote
          </Typography.Text>
        </Row>
        <Row justify="center">
          <Spin spinning={!!loading}>
            <Space size={'large'}>
              <button className="btn btn-cancel" onClick={hideModal}>
                No
              </button>
              <button className="btn btn-submit" onClick={onConfirmVote}>
                Yes
              </button>
            </Space>
          </Spin>
        </Row>
      </DStarterModal>
    </>
  )
}
/**
 * Render vote result
 * @param {*} props {yesValue, yesPercent, noValue, noPercent}
 * @returns
 */
export const VoteResult = (props) => {
  const voteItem = props.voteItem || {}
  const blockInfo = props.blockInfo || {}
  const { AddressDao, ProposalID, Chain } = voteItem
  const [loading, setLoading] = React.useState(false)
  const [voteResult, setVoteResult] = React.useState({})

  React.useEffect(() => {
    loadData()
  }, [AddressDao])

  const loadData = async () => {
    if (!AddressDao) {
      return
    }
    try {
      setLoading(true)
      let { againstVotes, forVotes, abstainVotes } = await proposalVotes(AddressDao, ProposalID,Chain)
      againstVotes = parseFloat(ethers.utils.formatEther(againstVotes))
      forVotes = parseFloat(ethers.utils.formatEther(forVotes))
      abstainVotes = parseFloat(ethers.utils.formatEther(abstainVotes))

      let total = voteItem.AmountOfTokenSell
      try {
        let val = parseFloat(blockInfo.totalSell)
        if (val > 0) {
          total = val
        }
      } catch (e) { }
      let forPercent = ((100.0 * forVotes) / total).toFixed(2)
      let againstPercent = ((100.0 * againstVotes) / total).toFixed(2)
      let abstainPercent = 100 - forPercent - againstPercent

      setVoteResult({
        againstVotes,
        forVotes,
        abstainVotes,
        forPercent,
        againstPercent,
        abstainPercent,
      })
    } finally {
      setLoading(false)
    }
    try {
      setLoading(true)
      const { blockRemainingRegister, blockRemainingVote } = await getBlockVoteInfo(
        AddressDao,
        ProposalID,
        Chain
      )
      // ethers.utils.formatEther
      // console.log({ blockRemainingRegister: ethers.utils.formatEther(blockRemainingRegister), blockRemainingVote: ethers.utils.formatEther(blockRemainingVote) })
      console.log({
        blockRemainingRegister: blockRemainingRegister.toNumber(),
        blockRemainingVote: blockRemainingVote.toNumber(),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="group_line">
        <Tooltip title={`Yes: ${voteResult?.forPercent}%`} color="#384bf2">
          <span
            className="vote-y"
            data-bs-customclassName="custom-e"
            style={{ width: `${voteResult?.forPercent}%` }}
          ></span>
        </Tooltip>
        <Tooltip title={`No: ${voteResult?.againstPercent}%`} color="#fa4759">
          <span
            className="vote-n"
            data-bs-customclassName="custom-e"
            style={{ width: `${voteResult?.againstPercent}%` }}
          ></span>
        </Tooltip>
        <Tooltip title={`Abstention: ${voteResult?.abstainPercent}%`} color="#2e3147">
          <span
            className="vote-e"
            data-bs-customclassName="custom-e"
            style={{ width: `${voteResult?.abstainPercent}%` }}
          ></span>
        </Tooltip>
      </div>
    </Spin>
  )
}

/**
 * Render vote detail item for investors
 * @param {*} props {item} the vote detail item
 * @returns
 */
export const VoteDetailItemTpl = (props) => {
  const { walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const { item } = props
  const [loaded, setLoaded] = useState(false)
  const { AddressDao, AddressVesting, ProposalID, Chain } = item
  const [blockInfo, setBlockInfo] = useState({})
  const { Countdown } = Statistic
  const [endRegisterTime, setEndRegisterTime] = useState(null)
  const [endVoteTime, setEndVoteTime] = useState(null)
  const loadInfo = async () => {
    try {
      setLoaded(false)
      const res = await getBlockVoteInfo(AddressDao, AddressVesting, ProposalID, walletID, Chain)
      if (res) {
        const obj = {
          voted: res.hasVoted,
          blockRemainingVote: res.blockRemainingVote.toNumber(),
          blockRemainingRegister: res.blockRemainingRegister.toNumber(),
          amountBuy: ethers.utils.formatEther(res.amountBuy),
          totalSell: ethers.utils.formatEther(res.totalSell),
          total: ethers.utils.formatEther(res.total),
        }
        setEndRegisterTime(getEndtimeFromBlock(obj.blockRemainingRegister))
        setEndVoteTime(getEndtimeFromBlock(obj.blockRemainingVote))
        setBlockInfo(obj)
        console.log(res)
      }
    } finally {
      setLoaded(true)
    }
  }
  const getPercent = () => {
    if (blockInfo && blockInfo.amountBuy > 0 && blockInfo.totalSell > 0) {
      const per = (blockInfo.amountBuy / blockInfo.totalSell) * 100
      return per.toFixed() + ' %'
    }
    return '0 %'
  }
  const getEndtimeFromBlock = (block) => {
    //đang quy ước số thời gian trên mạng BSC là 3s/block
    const totalSecond = block * 3
    return moment().add(totalSecond, 'seconds')
  }
  const checkEndtimeRegister = () => {
    const currentDate = moment()
    if (endRegisterTime) return endRegisterTime.diff(currentDate) >= 0
    return false
  }
  const voteComplete = () => {
    loadInfo()
  }
  useEffect(() => {
    loadInfo()
  }, [])

  const isVotable = !!userProfile?.RoleVC
  return (
    <>
      <form className="box-vote" method="POST" action="">
        <h2>
          {item.ProjectName || '_PROJECT_NAME'}{' '}
          {isVotable ? (
            <span>Your token vote: {loaded ? <>{getPercent()}</> : <Spin></Spin>}</span>
          ) : null}
        </h2>
        <div className="bg-dark">

          <img className='chain-logo' style={{ width: 30 }} src={getIconChain(item.Chain)} />
          <h3>
            {item.PoolName || '_POOL_NAME'}{' '}
            <span>
              Status: <b>{blockInfo && blockInfo.blockRemainingVote == 0 ? 'End' : 'Voting'}</b>
            </span>
          </h3>
          <div className="border-gradient">
            <div className="row">
              <div className="col-12 col-sm-6">
                <h5></h5>
                <ul>
                  <li>
                    <img src="/images/icon-like.png" alt="" /> Voting for milestone:{' '}
                    <span>{item.MilestoneName}</span>
                  </li>
                  <li>
                    <img src="/images/icon-phao.png" alt="" /> Token sell:{' '}
                    <span>
                      {/* {item.AmountOfTokenSell} {item.TokenSymbol} */}
                      {loaded ? (
                        <>
                          {blockInfo ? blockInfo.totalSell : 0} {item.TokenSymbol}
                        </>
                      ) : (
                        <Spin></Spin>
                      )}
                    </span>
                  </li>
                  <li>
                    <img src="/images/icon-usdt.png" alt="" /> Voting for:{' '}
                    <span>
                      {loaded ? (
                        <>
                          {blockInfo ? blockInfo.total : 0} {item.CurrencyName}
                        </>
                      ) : (
                        <Spin></Spin>
                      )}
                    </span>
                  </li>
                  {isVotable ? (
                    <li className="after-done">
                      <img src="/images/icon-logo.png" alt="" /> Your Vote:{' '}
                      <span className="text-blue">
                        {loaded ? (
                          <> {blockInfo && blockInfo.voted ? 'Voted' : 'Not vote'} </>
                        ) : (
                          <Spin></Spin>
                        )}
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="col-12 col-sm-6">
                <h5>
                  Vote to <span>{item.VoteType == 1 ? 'REFUND' : 'DISBURSE'}</span>
                </h5>
                <ul>
                  {endVoteTime && endVoteTime.isAfter(moment()) ? (
                    <li>
                      <img src="/images/icon-like.png" alt="" />
                      Estimated voting time: <b>{formatDateTime(endRegisterTime) || ''}</b> to{' '}
                      <b>{formatDateTime(endVoteTime) || ''}</b>
                    </li>
                  ) : null}
                  <li>
                    <img src="/images/icon-x-circle.png" alt="" />
                    Estimate time to the expired date:{' '}
                    <b>
                      {loaded ? (
                        <>
                          <Countdown
                            onFinish={voteComplete}
                            value={endVoteTime}
                            format={'D [Days] HH : mm : ss'}
                          />
                        </>
                      ) : (
                        <Spin></Spin>
                      )}
                    </b>
                  </li>
                </ul>
                {loaded ? (
                  <>
                    {blockInfo && (blockInfo.voted || blockInfo.blockRemainingVote == 0) ? (
                      <VoteResult voteItem={item} blockInfo={blockInfo} />
                    ) : (
                      <>
                        {checkEndtimeRegister() ? (
                          <>
                            {' '}
                            <span>This vote is going to start after</span>{' '}
                            <Countdown
                              onFinish={voteComplete}
                              value={endRegisterTime}
                              format={'D [Days] HH : mm : ss'}
                            />{' '}
                          </>
                        ) : isVotable ? (
                          <VoteButtonGroup voteComplete={voteComplete} voteItem={item} />
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <Spin></Spin>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
