import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  listChain: [],
  listContract: [],
  listCurrency: [],
  switchChainId: '',
  switchTitle: '',
  switchDescription: '',
  showConfirmSwitchChain: false,
}

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    updateListChain: (state, { payload }) => {
      state.listChain = payload
    },
    updateListContract: (state, { payload }) => {
      state.listContract = payload
    },
    updateSwitchChainId: (state, { payload }) => {
      const { chainId, title, description } = payload
      state.switchChainId = chainId
      state.switchTitle = title
      state.switchDescription = description
      state.showConfirmSwitchChain = true
    },
    updateListCurrency: (state, { payload }) => {
      state.listCurrency = payload
    },
    closeConfirmSwitchChain: (state) => {
      state.showConfirmSwitchChain = false
    },
  },
})

// Action creators
export const {
  updateListChain,
  updateListContract,
  closeConfirmSwitchChain,
  updateSwitchChainId,
  updateListCurrency,
} = slice.actions

// Selector
export const chainSelector = (state) => state.network

export default slice.reducer
