import downloadLOGO from 'assets/images/logo-dstarter.zip'
import logoFooter from 'assets/images/logo.png'
const FooterAll = ()=>{
    return  <footer className="space_section footer_bg">
    <div className="content-width">
      <div className="row">
        <div className="col-12 col-sm-4">
          <div className="logo-footer">
            <a href="/"> <img src={logoFooter} alt="DStarter Logo" /> </a>
            <p>
              <strong>Web3 IMO Fundraising Ecosystem</strong>              
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-2"></div>
        <div className="col-12 col-sm-6">
          <div className="title-home">
            <h2>Menu</h2>
            <hr />
          </div>
          <ul className="menu-footer-all">
            <li><a href="/">Home</a></li>
            <li><a href="/pool">Pool</a></li>
            <li><a href="/">Incubator</a></li>
            <li><a href="/">FAQs</a></li>
            <li><a href="/buidls">Buidls</a></li>
            <li><a href="/">Statistics</a></li>
            <li><a href="/">Blog</a></li>
            <li><a href="https://docsend.com/view/vf7ugei747mx8jbj" target="_blank">Pitchdeck</a></li>            
            <li><a href="https://www.verichains.io/?search=dstarter" target="_blank">Audited by Verichain</a></li>  
            <li><a href={downloadLOGO} download>Medit Kit</a></li> 
          </ul>
        </div>
      </div>
      <div className="clearfix"></div>
      <hr />
      <div className="row footer-bottom">
        <div className="col-12 col-sm-6">
          <p className="copyright">
            &copy; 2022. All rights reserved by <strong>DStarter</strong>
          </p>
        </div>
        <div className="col-12 col-sm-6">
          <ul>
            <li>
              <a target="_blank" href="https://dstarter-platform.gitbook.io/privacy-policy/">Privacy Policy</a>
            </li>
            <li>
              <a target="_blank" href="https://dstarter-platform.gitbook.io/term-of-use/">Term of Services</a>
            </li>            
          </ul>
        </div>
      </div>
    </div>
  </footer>
}
export default FooterAll