import { createSlice } from '@reduxjs/toolkit';
const initData={
  poolId: "",
  imageUrl:"",
  imageFile:null,
  titlePool:'',
  step1Infor: null,
  step2Infor: null,
  step3Infor: [],
  step: 0,
  stepDeposit:0,
  showDeposit:false,
  poolStatus:0,
  busy: false,
  loaded:false,
  projects:[],
  currencies:[
  ]
}
export const createPoolSlice = createSlice({
  name: "createpool",
  initialState: initData,
  reducers: {
    setMasterData: (state, action) => {
      state.masterData = action.payload.data
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setBusy: (state, action) => {
      state.busy = action.payload
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload
    },
    setStep1Info: (state, action) => {
      state.step1Infor = action.payload
    },
    setStep2Info: (state, action) => {
      state.step2Infor = action.payload
    },
    setStep3Info: (state, action) => {
      state.step3Infor = action.payload
    },
    setPoolId: (state, action) => {
      state.poolId = action.payload
    },
    setProjects:(state, action) => {
      state.projects = action.payload
    },
    setCurrencies:(state, action) => {
      state.currencies = action.payload
    },
    setShowDeposit:(state, action) => {
      state.showDeposit = action.payload
    },
    setStepDeposit:(state, action) => {
      state.stepDeposit = action.payload
    },
    setImageUrl:(state, action) => {
      state.imageUrl = action.payload
    },
    setImageFile:(state, action) => {
      state.imageFile = action.payload
    },
    setTitle:(state, action) => {
      state.titlePool = action.payload
    },
    resetData:()=>initData
  },
});

//actions
export const { setMasterData, setStep, 
  setBusy,setLoaded, setStep1Info, 
  setStep2Info, setStep3Info, 
  setPoolId,setProjects, setCurrencies,resetData,setShowDeposit,setStepDeposit,setImageUrl,setImageFile,setTitle } = createPoolSlice.actions;

//state
export const masterData = (state) => state.masterData;
export const step = (state) => state.step;
export const stepDeposit = (state) => state.stepDeposit;
export const showDeposit = (state) => state.showDeposit;
export const poolStatus = (state) => state.poolStatus;
export const busy = (state) => state.busy;
export const loaded = (state) => state.loaded;
export const poolId = (state) => state.poolId;
export const step1Infor = (state) => state.step1Infor;
export const step2Infor = (state) => state.step2Infor;
export const step3Infor = (state) => state.step3Infor;
export const projects = (state) => state.projects;
export const currencies = (state) => state.currencies;
export const imageUrl = (state) => state.imageUrl;
export const imageFile = (state) => state.imageFile;
export const titlePool = (state) => state.titlePool;

export default createPoolSlice.reducer;