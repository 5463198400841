import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Input, message, DatePicker, Spin, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import iconAvatar from '../../assets/images/avatar.png'
import { CheckCircleTwoTone } from '@ant-design/icons'

import { authActions, authSelector } from 'redux/reducers/authSlice'
import { createProfileApi, getProfileByWalletIDApi } from 'apis/apiprofiles'
import { KYCButton } from '../../components/profile/KYCButton'
import { PhoneNumberPrefixSelect } from 'components/common/phone-number-input/PhoneNumberPrefixSelect'
import { useUploadFile } from '../../components/hooks/useUploadFile'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { convertDatetimeFromTimestamp } from 'utils/common'
import { userProfileActions, userProfileSelector } from 'redux/reducers/userProfile'

const dateFormat = 'YYYY-MM-DD'

const VerifiedMark = <CheckCircleTwoTone twoToneColor="#52c41a" />

const MyProfileComponent = () => {
  const { t } = useTranslation()
  const { walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const dispatch = useDispatch()
  const { ValidateEmail, ValidateKYC, ValidatePhone } = userProfile || {}
  const [submitting, setSubmitting] = React.useState(false)
  const [blockpassSDKLoaded, setBlockpassSDKLoaded] = React.useState(false)
  const recentProfileRef = React.useRef({})
  const [form] = Form.useForm()
  const [avatarUrl, setAvatarUrl] = React.useState('')
  const [isLoadingUpload, setIsLoadingUpload] = React.useState(false)
  const { fileUploadRef, uploadProps, doUploadFile } = useUploadFile()

  const [confirmSubmitVisible, setconfirmSubmitVisible] = React.useState(false)
  const [confirmCancelVisible, setconfirmCancelVisible] = React.useState(false)

  React.useEffect(() => {
    form.setFieldsValue({ walletID })
  }, [walletID])

  const getFormValuesFromProfile = (profile) => {
    const {
      Email,
      FirstName,
      LastName,
      DateOfBirth,
      Country,
      Nationality,
      Phone,
      Avatar,
      PhonePrefix,
    } = profile
    return {
      email: Email,
      firstName: FirstName,
      lastName: LastName,
      dateOfBirth: DateOfBirth ? moment(DateOfBirth, dateFormat) : null,
      country: Country,
      nationality: Nationality,
      phone: Phone,
      avatar: Avatar,
      phonePrefix: PhonePrefix ? PhonePrefix : '+84',
    }
  }
  // Initialize after getting userProfile
  React.useEffect(() => {
    if (userProfile.WalletID) {
      form.setFieldsValue(getFormValuesFromProfile(userProfile))
      setAvatarUrl(userProfile.Avatar)
      recentProfileRef.current = Object.assign({}, userProfile)
    }
  }, [userProfile, blockpassSDKLoaded])

  const onFinish = async (values) => {
    if (!values.phonePrefix) {
      values.phonePrefix = '+84'
    }
    if (avatarUrl) {
      if (fileUploadRef.current) {
        const fileUrl = await doUploadFile()
        if (fileUrl) {
          values.avatar = fileUrl
          setAvatarUrl(fileUrl)
        }
      }
      // format datepicker value as moment
      if (values.dateOfBirth) {
        values.dateOfBirth = values.dateOfBirth.format(dateFormat)
      }
      const { walletID, ...information } = values
      setSubmitting(true)
      const { OK, ResultCode, Message } = await createProfileApi({ walletID, information })
      if (OK) {
        message.success(t('user-profile.createSuccess'))
      } else {
        message.error(`${ResultCode}: ${Message}`)
      }
      setSubmitting(false)
      const { OK: updateOK, Data: updateData } = await getProfileByWalletIDApi(walletID)
      if (updateOK) {
        // dispatch(authActions.userProfileUpdated(updateData))
        dispatch(userProfileActions.userProfileUpdated(updateData))
      }
    } else {
      message.error('Please upload avatar')
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('SubmitFailed', errorInfo)
  }

  const PhonePrefixFormItem = () => {
    return (
      <Form.Item name="phonePrefix" noStyle>
        <PhoneNumberPrefixSelect />
      </Form.Item>
    )
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const handleChange = (info) => {
    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    const isLt2M = info.file.size / 1024 / 1024 < 2
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    if (isJpgOrPng && isLt2M) {
      getBase64(info.file, (url) => {
        setAvatarUrl(url)
      })
    }
    setIsLoadingUpload(false)
  }
  const uploadButton = (
    <div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        <img src={iconAvatar} alt="" />
      </div>
    </div>
  )

  const confirmCancel = () => {
    // restore form values from stored profile
    setconfirmCancelVisible(false)
    form.setFieldsValue(getFormValuesFromProfile(recentProfileRef.current))
  }
  const cancelCancel = () => {
    setconfirmCancelVisible(false)
  }
  const confirmSubmit = () => {
    setconfirmSubmitVisible(false)
    form.submit()
  }
  const cancelSubmit = () => {
    setconfirmSubmitVisible(false)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="col-12 col-sm-12">
            <Form
              form={form}
              layout="vertical"
              name="time_related_controls"
              initialValues={{ walletID }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="bg-step">
                <div className="row d-flex justify-content-space-between">
                  <div className="col-12 col-sm-6">
                    <div className="title-show">
                      <h5>My Profile</h5>
                      <hr />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <Row justify="end">
                      <KYCButton />
                    </Row>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="d-flex">
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="bg-file">
                  <div className="row">
                    <div className="col-12 col-sm-3">
                      <div className="picture-frames d-flex align-items-center justify-content-center flex-wrap">
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action=""
                          onChange={handleChange}
                          {...uploadProps}
                        >
                          {avatarUrl ? (
                            <img src={avatarUrl} alt="avatar" style={{ width: '100%' }} />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </div>
                    </div>
                    <div className="col-12 col-sm-9">
                      <p className="note-p">
                        * The image should be used with a ratio suitable for the display interface
                        (Recommended: 334 x 500 px). <br />
                        * Drag or tap upload here to add photos.
                        <br />
                        * JPEG or PNG format. <br />* No larger than 2MB.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <Form.Item name="walletID" label={t('user-profile.walletID')}>
                        <Input disabled prefix={VerifiedMark} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <Form.Item name="email" label={t('user-profile.email')}>
                        <Input
                          placeholder=""
                          disabled={ValidateEmail}
                          prefix={ValidateEmail ? VerifiedMark : <span />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.firstName')}</span>
                        <span className="text">
                          First Name must match your government-issued ID
                        </span>
                      </div>
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: t('user-profile.firstNameRequired') }]}
                      >
                        <Input
                          placeholder=""
                          disabled={ValidateKYC}
                          prefix={ValidateKYC ? VerifiedMark : <span />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.lastName')}</span>
                        <span className="text">Last Name must match your government-issued ID</span>
                      </div>
                      <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: t('user-profile.lastNameRequired') }]}
                      >
                        <Input
                          placeholder=""
                          disabled={ValidateKYC}
                          prefix={ValidateKYC ? VerifiedMark : <span />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.dateOfBirth')}</span>
                        <span className="text">DOB must match your government-issued ID</span>
                      </div>
                      <Form.Item
                        name="dateOfBirth"
                        rules={[{ required: true, message: t('user-profile.dobRequired') }]}
                      >
                        <DatePicker
                          format={dateFormat}
                          disabled={ValidateKYC}
                          prefix={ValidateKYC ? VerifiedMark : <span />}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.nationality')}</span>
                        <span className="text">
                          Nationality must match your government-issued ID
                        </span>
                      </div>
                      <Form.Item name="nationality" rules={[{ required: true }]}>
                        <Input placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.country')}</span>
                        <span className="text">
                          Country must match your Proof of Address document
                        </span>
                      </div>
                      <Form.Item name="country" rules={[{ required: true }]}>
                        <Input placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <div
                        className="d-flex align-items-center justify-content-space-between"
                        style={{ paddingBottom: '15px' }}
                      >
                        <span className="required">{t('user-profile.phone')}</span>
                      </div>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Phone number is required',
                          },
                          { min: 9, message: 'Phone number must be min 9 characters.' },
                          {
                            pattern: new RegExp(/^[0-9]*$/),
                            message: 'Phone number must be numeric.',
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<PhonePrefixFormItem />}
                          placeholder=""
                          disabled={ValidatePhone}
                          maxLength={18}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-group">
                <Spin spinning={submitting}>
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => setconfirmCancelVisible(true)}
                  >
                    {t('button.cancel')}
                  </button>
                  <button
                    type="button"
                    className="btn-submit"
                    onClick={() => setconfirmSubmitVisible(true)}
                  >
                    Update
                  </button>
                </Spin>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <ConfirmModal
        visible={confirmCancelVisible}
        onOk={confirmCancel}
        onCancel={cancelCancel}
        message="Are you sure to cancel ?"
      />
      <ConfirmModal
        visible={confirmSubmitVisible}
        onOk={confirmSubmit}
        onCancel={cancelSubmit}
        message="Are you sure to submit this form ?"
      />
    </>
  )
}
export default MyProfileComponent
