import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { closeConfirmSwitchChain } from 'redux/reducers/networkSlice'

import { requestSwitchNetwork } from 'smartcontract/common'
import { refreshToken } from 'apis/apiwallet'
/**
 * Call api to check user register on current chain when accessToken becomes available or updated
 * @returns
 */
 export const useSwitchChain = () => {
    const dispatch = useDispatch()
    const onSwitch = async (chain) => {
      try {
        await requestSwitchNetwork(chain);
        const token = await refreshToken();
        if (token) {
          dispatch(authActions.setCurrentNetWork({ chainID: chain.chainID, chainName: chain.chainName, icon: chain.icon }));
          dispatch(authActions.authenticated(token))
          dispatch(closeConfirmSwitchChain())
        }
        else{
            dispatch(authActions.globalLogout());
        }
      } catch (err) {
        console.log(err);
      }
    }
    return { onSwitch }
  }