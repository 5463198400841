import { all, put, call, takeLeading, select } from 'redux-saga/effects'
import { ethers, providers } from 'ethers'
import { message } from 'antd'

import { authActions } from '../reducers/authSlice'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { refreshToken } from 'apis/apiwallet'
import * as apiProfile from 'apis/apiprofiles'
import { userProfileActions } from 'redux/reducers/userProfile'
import { requestSwitchNetwork } from 'smartcontract/common'

function* doConnectMetaMask({ payload }) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const addresses = yield call(() => {
      console.log('CONNECTING')
      return provider.send('eth_requestAccounts', [])
    })
    if (addresses && addresses.length > 0) {
      //nếu có chain thì switch
      if (payload && payload.chain) {
        const chain = payload.chain;
        yield call(() => requestSwitchNetwork(chain))
        yield put(authActions.setCurrentNetWork({ chainID: chain.chainID, chainName: chain.chainName, icon: chain.icon }))
      }
      yield put(authActions.walletConnected(addresses[0].toLowerCase()))
    }
  } catch (error) {
    console.log('doConnectMetaMask', error)
    message.error(error.message)
    yield put(authActions.requestConnectWallet(false))
  }
}

function* doConnectWalletConnect() {
  try {
    //  Create WalletConnect Provider
    const provider = new WalletConnectProvider({
      infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
    })

    //  Enable session (triggers QR Code modal)
    yield call(provider.enable)
    //  Wrap with Web3Provider from ethers.js
    const web3Provider = new providers.Web3Provider(provider)
    const signer = yield call(web3Provider.getSigner)
    const address = yield call(signer.getAddress)
    // Subscribe to accounts change
    provider.on('accountsChanged', (accounts) => {
      console.log(accounts)
    })

    // Subscribe to chainId change
    provider.on('chainChanged', (chainId) => {
      console.log(chainId)
    })

    // Subscribe to session disconnection
    provider.on('disconnect', (code, reason) => {
      console.log(code, reason)
    })

    if (address) {
      yield put(authActions.walletConnected(address))
    }
  } catch (err) {
    console.log('doConnectWalletConnect', err)
    // reset status as of failure
    yield put(authActions.requestConnectWallet(false))
  }
}

function* doBackendAuthentication({ payload }) {
  try {
    const token = yield call(refreshToken)
    if (token) {
      yield put(authActions.authenticated(token))
    }
  } catch (err) {
    console('doBackendAuthentication', err)
    // reset status as of failure
    yield put(authActions.requestBackendAuth(false))
  }
}

function* doSelectUserRole({ payload: isInvestor }) {
  // TODO Call smart contract

  // Call api backend
  const { OK } = yield call(apiProfile.selectUserRole, isInvestor)
  // yield put(authActions.selectUserRoleResult({ isError: OK, isInvestor }))
  yield put(userProfileActions.selectUserRoleResult({ isError: OK, isInvestor }))
}

function* doLogout() {
  let walletID = yield select((state) => state.auth.walletID)
  console.log('doLogout')
  if (walletID) {
    // Cleanup the whole redux tree & navigate to home
    yield put({ type: 'ROOT_REDUCER_RESET' })
    setTimeout(() => window.location.replace('/'), 500)
  } else {
    console.log('No need to log out')
  }
}

function* doWalletAccountChanged({ payload }) {
  yield put(authActions.globalLogout())
}
// function* doWalletNetworkChanged({ payload: chainId }) {
//   if (chainId != process.env.REACT_APP_DEFAULT_CHAINID) {
//     yield put(authActions.globalLogout())
//   }
// }

export default function* authSaga() {
  try {
    yield all([
      yield takeLeading(authActions.connectMetaMask, doConnectMetaMask),
      yield takeLeading(authActions.connectWalletConnect, doConnectWalletConnect),
      yield takeLeading(authActions.authenticateWithBackend, doBackendAuthentication),
      yield takeLeading(authActions.selectUserRoleStart, doSelectUserRole),
      yield takeLeading(authActions.globalLogout, doLogout),
      yield takeLeading(authActions.walletAccountChanged, doWalletAccountChanged),
      // yield takeLeading(authActions.walletNetworkChanged, doWalletNetworkChanged),
    ])
  } catch (err) {
    console.log('authSaga', err)
  }
}
