import { fork } from 'redux-saga/effects'
import connectWalletSaga from './connectWalletSaga'
import authSaga from './authSaga'
import poolSaga from './pool/poolSaga';

export default function* rootSaga() {
    yield fork(connectWalletSaga)
    yield fork(authSaga)
yield fork(poolSaga)
}
