import axios from 'axios';
import { getAuthorizationHeader } from 'utils/common'
const baseURL = process.env.REACT_APP_API_URL + '/Projects';
export function saveStep1(data) {
    var url = baseURL + '/Pool_Step01/auth/Create';
    const config = getAuthorizationHeader();
    return axios.post(url, data, config);
}
export function saveStep2(data) {
    var url = baseURL+'/Pool_Step02/auth/Create';
    const config = getAuthorizationHeader();
    return axios.post(url, data, config);
}
export function saveStep3(data) {
    var url = baseURL+'/Pool_Step03/auth/Create';
    const config = getAuthorizationHeader();
    return axios.post(url, data, config);
}
export function saveStep4(poolId) {
    var url = baseURL+'/Pool_Step04/auth/Deposit?poolID='+poolId;
    const config = getAuthorizationHeader();
    return axios.post(url, null, config);
}
export function getPoolInfo(id) {
    var url = baseURL+'/Pool_Information/auth/GetInfo/'+id;
    
    const config = getAuthorizationHeader();
    return axios.get(url, config);
}
export function getProjects(data) {
    var url = baseURL+'/Project_Information/auth/GetList';
    const config = getAuthorizationHeader();
    return axios.post(url, data, config);
}
export function getChains() {
    var url = baseURL+'/Category/auth/GetList/Chain00';
    const config = getAuthorizationHeader();
    return axios.get(url, config);
}
export function getCurrencies() {
    var url = baseURL+'/Category02/auth/GetList';
    const config = getAuthorizationHeader();
    return axios.post(url, {
        parenT_CODE: 'Currency',
        pageIndex: 1,
        pageSize: 100,
    },
        config);
}
export function getCurrenciesPub() {
      var url = baseURL + '/Category02/pub/GetList'
    return axios.post(url, {
        parenT_CODE: 'Currency',
        pageIndex: 1,
        pageSize: 100,
    })
}
export function getPools(data) {
    var url = baseURL + '/Pool_Information/auth/MyPool/GetList'
    const config = getAuthorizationHeader();
    return axios.post(url, data, config);
}