import React from 'react'
import { Spin } from 'antd'
import { getMyVoteCreateList } from 'apis/apiPoolInvestor'
import { VoteDetailItemTpl } from 'components/for-investor/voting/VoteDetailItem'

export const StartupVoteList = () => {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    try {
      setLoading(true)
      const { OK, Data, Message } = await getMyVoteCreateList()
      if (OK) {
        setData(Data.map(voteCreateDataTransform))
      } else {
        console.log('getMyVoteCreateList', Message)
      }
    } finally {
      setLoading(false)
    }
  }
  
  const voteCreateDataTransform = (item) => {
    return {
      VotingFor: `Milestone ${item.MilestoneID}`,
      ...item,
    }
  }

  return (
    <Spin spinning={loading}>
      {data.map((item) => (
        <VoteDetailItemTpl key={item.PoolID} item={item} />
      ))}
    </Spin>
  )
}
