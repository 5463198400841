import iconTwitter from 'assets/images/social/s-twitter.svg'
import iconLinkedin from 'assets/images/social/s-linkedin.svg'
import iconTelegram from 'assets/images/social/s-telegram.svg'
import iconYoutube from 'assets/images/social/s-youtube.svg'
import iconDiscord from 'assets/images/social/s-discord.png'
import iconReddit from 'assets/images/social/s-reddit.png'
import iconMedium from 'assets/images/social/s-medium.png'
import iconGithub from 'assets/images/social/s-github.png'
import downloadLOGO from 'assets/images/logo-dstarter.zip'
import logoFooter from 'assets/images/logo-light.png'

import {Link} from'react-router-dom'
const Footer = ()=>{
    return  <footer className="space_section">
    <div className="content-width">
      <div className="row">
        <div className="col-12 col-sm-4">
          <div className="logo-footer">
            <a href="/">
              <img src={logoFooter} alt="Dabase" />
            </a>
            <p>
            Our vision: Empower DAOs to reach their full potential in the digital finance space
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="title-home">
            <h2>Menu</h2>
            <hr />
          </div>
          <ul className="menu-footer">
            <li><a href="https://docsend.com/view/vf7ugei747mx8jbj" target="_blank">Pitchdeck</a></li>
            <li><a href="https://www.verichains.io/?search=dstarter" target="_blank">Audited by Verichain</a></li>  
            <li><a href={downloadLOGO} download>Medit Kit</a></li> 
          </ul>
        </div>
        <div className="col-12 col-sm-4">
          <div className="title-home">
            <h2>Follow Us</h2>
            <hr />
          </div>
          <ul className="fanpages ">
            <li>
              <a href="https://t.me/dstarter" target="_blank">
                <img src={iconTelegram} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Dstarterio" target="_blank">
                <img src={iconTwitter} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/dstarter/" target="_blank">
                <img src={iconLinkedin} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCoSCZbc9ur4Lxag6mkO--WA"
                target="_blank"
              >
                <img src={iconYoutube} alt="" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/ncA8c64yMb" target="_blank">
                <img src={iconDiscord} alt="" />
              </a>
            </li>
            <li>
              <a href="https://medium.com/@dstarter" target="_blank">
                <img src={iconMedium} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.reddit.com/user/dstarterio" target="_blank">
                <img src={iconReddit} alt="" />
              </a>
            </li>
            <li>
              <a href="https://github.com/timestarter/time-daofund" target="_blank">
                <img src={iconGithub} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="clearfix"></div>
      <hr />
      <div className="row footer-bottom">
        <div className="col-12 col-sm-6">
          <p className="copyright">
            &copy; 2022. All rights reserved by <strong>Dabase</strong>
          </p>
        </div>
        <div className="col-12 col-sm-6">
          <ul>
            <li>
              <a target="_blank" href="https://dstarter-platform.gitbook.io/privacy-policy/">Privacy Policy</a>
            </li>
            <li>
              <a target="_blank" href="https://dstarter-platform.gitbook.io/term-of-use/">Term of Services</a>
            </li>            
          </ul>
        </div>
      </div>
    </div>
  </footer>
}
export default Footer