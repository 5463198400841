import { getSigner,getSignerByRPC, getSmartContract } from 'smartcontract/common'
import vestingConfig from 'smartcontract/abi/TSVesting.json'
import { getChainById } from 'utils/common';

const getVestingContract = async (address,chainId)=> {
  var signer = null;
  let chain = getChainById(chainId)
  if (chain && chain.rpcUrls) {
    signer = await getSignerByRPC(chain.rpcUrls);
  }
  return getSmartContract(address, vestingConfig.abi, signer??getSigner())
}

//hàm thực hiện đăng ký vote
export const delegate = async (addressVesting, addressRegister) => {
  const contract = await getVestingContract(addressVesting);
  return await contract.delegate(addressRegister);
}

///hàm kiểm tra xem đã registervote chưa
export const checkRegisterVote = async (addressVesting, addressRegister,chainId) => {
  try {
    const contract = await getVestingContract(addressVesting,chainId);
    return await contract.delegates(addressRegister);
  } catch (err) {
    console.log(err);
  }
  return "";
}
///hàm lấy số token mà vc được refund
export const getBalanceRefund = async (addressVesting, userAddress,chainId) => {
  try {
    const contract = await getVestingContract(addressVesting,chainId);
    const rs = await contract.getBalanceRefund(userAddress);
    return rs;
  } catch (err) {
    console.log(err);
  }
  return null ;
}
///hàm lấy số token mà startup được refund
export const getTokenRefund = async (addressVesting,chainId) => {
  try {
    const contract = await getVestingContract(addressVesting,chainId);
    const rs = await contract.getTokenRefund();
    return rs;
  } catch (err) {
    console.log(err);
  }
  return null ;
}
///hàm claim số tiền mà vc được refund
export const claimRefund = async (addressVesting, userAddress) => {
  try {
    const contract = await getVestingContract(addressVesting);
    const rs = await contract.claimRefund(userAddress);
    return rs;
  } catch (err) {
    console.log(err);
  }
  return null ;
}
///hàm claim số token mà startup được refund
export const claimTokenRefund = async (addressVesting) => {
  try {
    const contract = await getVestingContract(addressVesting);
    const rs = await contract.claimTokenRefund();
    return rs;
  } catch (err) {
    console.log(err);
  }
  return null ;
}
///hàm claim số token mà VC được disburse
export const claimTokenDisburse = async (addressVesting,account) => {
  try {
    const contract = await getVestingContract(addressVesting,account);
    const rs = await contract.release(account);
    return rs;
  } catch (err) {
    console.log(err);
  }
  return null ;
}

