import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import defaultAvatar from 'assets/img/avt-user.png'
import API from 'apis/apiProject'
import Overview from 'components/vc-detail/Overview'
import Investment from 'components/vc-detail/Investment'
import { VCPortfolio } from 'components/for-investor/detail/VcPortforlio'
import icon_d1 from 'assets/images/icon-d-01.png'
import icon_d2 from 'assets/images/icon-d-02.png'
import icon_d3 from 'assets/images/icon-d-03.png'
import icon_d4 from 'assets/images/icon-d-04.png'
import social_1 from 'assets/images/icon-s-01.png'
import social_2 from 'assets/images/icon-s-02.png'
import social_3 from 'assets/images/icon-s-03.png'
import social_4 from 'assets/images/icon-s-04.png'
import social_5 from 'assets/images/icon-s-05.png'
import social_6 from 'assets/images/icon-s-06.png'
import { formatNumber, getTypeVc } from 'utils/constants'
import iconBack from 'assets/images/arrow-back.svg'
const VcDetail = () => {
  const navigate = useNavigate()
  const paramUrl = useParams()
  const [detailVc, setDetailVc] = useState('')
  const [status, setStatus] = useState(0)
  const [socials, setSocials] = useState([])
  const listPoolPubByStatus = [
    {
      id: 1,
      status: 0,
      tabId: 'nav-01-tab',
      target: '#nav-01',
      controls: 'nav-01',
      name: 'Overview',
    },
    {
      id: 2,
      status: 1,
      tabId: 'nav-02-tab',
      target: '#nav-02',
      controls: 'nav-02',
      name: 'Portfolio',
    },
  ]
  const handleClick = (value) => {
    setStatus(value)
  }
  useEffect(() => {
    if (paramUrl.id) {
      ;(async () => {
        const res = await API.getVcDetailByWalletID(paramUrl.id)
        const result = res.Data
        const bodySocial = [
          {
            name: 'Website',
            url: result?.Website,
            icon: social_1,
          },
          {
            name: 'Medium',
            url: result?.Medium,
            icon: social_2,
          },
          {
            name: 'Contact Email',
            url: result?.ContactEmail,
            icon: social_3,
          },
          {
            name: 'Telegram',
            url: result?.Telegram,
            icon: social_4,
          },
          {
            name: 'Linkedin',
            url: result?.Linkedin,
            icon: social_5,
          },
          {
            name: 'Twitter',
            url: result?.Twitter,
            icon: social_6,
          },
        ]
        setSocials(bodySocial)
        setDetailVc(result)
      })()
    }
    setStatus(Number(paramUrl.tabId))
  }, [paramUrl])
  return (
    <>
      <div className="content-width">
        <div className="back-link">
          <a onClick={() => navigate('/vcslist')}>
            <img src={iconBack} alt="" /> Back VC List
          </a>
        </div>
        <div className="title_main">
          <h2>{detailVc.NameVentureCapital}</h2>
        </div>
        <div className="box-detail-summary">
          <div className="row">
            <div className="col-12 col-sm-2">
              <aside>
                <div className="box-img">
                  <img src={detailVc?.Avatar} alt="avatar" />
                </div>
                <h5>{detailVc?.NameVentureCapital}</h5>
                <ul>
                  <li>
                    <img src={icon_d1} alt="" /> <span>{detailVc?.Country}</span>
                  </li>
                  <li>
                    <img src={icon_d2} alt="" />{' '}
                    <span>{detailVc?.NumberEmployee || 0} employees</span>
                  </li>
                  <li>
                    <img src={icon_d3} alt="" /> <span>{formatNumber(detailVc?.TotalFund)}</span>
                  </li>
                  <li>
                    <img src={icon_d4} alt="" /> <span>Silver</span>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-12 col-sm-10">
              <section className="content-detail-summary">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {listPoolPubByStatus.map((item, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() => handleClick(item.status)}
                        className={`nav-item nav-link  ${item.status === status ? 'active' : ''}`}
                        id={item.id}
                        data-bs-toggle="tab"
                        data-bs-target={item.target}
                        type="button"
                        role="tab"
                        aria-controls={item.controls}
                        aria-selected="true"
                      >
                        {item.name}
                      </button>
                    )
                  })}
                </div>
                <div className="dibi">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="box-trans">
                        <h4>About Us</h4>
                        <p>{detailVc?.ShortDescription}</p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="box-trans">
                        <h4>Highlights</h4>
                        <ul>
                          <li>
                            Internal Invested <b>02</b>
                          </li>
                          <li>
                            Portfolio <b>{detailVc?.Portfolio?.split(';').length || 0}</b>
                          </li>
                          <li>
                            Total Fund <b>{formatNumber(detailVc?.TotalFund)}</b>
                          </li>
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="tab-content" id="nav-tabContent">
            {status === 0 ? (
              <Overview detailVc={detailVc} socials={socials} />
            ) : (
              <VCPortfolio data={detailVc.Portfolio?.split(';').map((item) => item) || []} />
            )}
          </div>
          <div className="row">
            <div className="col-12 col-sm-2"></div>
            <div className="col-12 col-sm-10"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  )
}
export default VcDetail
