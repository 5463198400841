import { AuthRequirementModal } from 'components/common/connect-wallet/AuthRequirementModal'
import { useCheckPermission } from 'components/hooks/useCheckPermission'

/**
 * 
 * @param {*} props { requireWallet, requireAccessToken, requireProfile, requireVerifyEmail, requireKYC, requireUserRole, onlyStartup, onlyInvestor }
 * @returns 
 */
const PrivateRoute = (props) => {
  const { children, ...restProps } = props
  const pass = useCheckPermission(restProps)
  return !!pass ? props.children : <AuthRequirementModal {...restProps} />
}
export default PrivateRoute
