import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, authSelector } from 'redux/reducers/authSlice'
import { getProfileByWalletIDApi } from 'apis/apiprofiles'
import { userProfileActions, userProfileSelector } from 'redux/reducers/userProfile'

/**
 * Call api to get userProfile when accessToken becomes available or updated
 * @returns
 */
export const useGetUserProfile = () => {
  const dispatch = useDispatch()
  const { accessToken, walletID } = useSelector(authSelector)
  const userProfile = useSelector(userProfileSelector)
  const [inProgress, setInProgress] = React.useState(false)
  React.useEffect(() => {
    if (accessToken && walletID) {
      getUserProfile(walletID, accessToken)
    }
  }, [accessToken, walletID])

  const getUserProfile = async (walletID) => {
    setInProgress(true)
    const { OK, ResultCode, Message, Data } = await getProfileByWalletIDApi(walletID)
    if (OK) {
      if (!Data.Information.RoleVC && !Data.Information.RoleStartup) {
        Data.Information.UndefinedRole = true
      }
      if (!Data.Information.ValidateEmail) {
        Data.Information.UnverifiedEmail = true
      }
      // dispatch(authActions.userProfileUpdated(Data))
      dispatch(userProfileActions.userProfileUpdated(Data))
    } else {
      console.log('[useGetUserProfile]', ResultCode, Message)
      if (ResultCode === '404') {
        // dispatch(authActions.userProfileUpdated({ Information: { FirstSignIn: true } }))
        dispatch(userProfileActions.userProfileUpdated({ Information: { FirstSignIn: true } }))
      }
    }
    setInProgress(false)
  }
  return { accessToken, walletID, inProgress, userProfile }
}
