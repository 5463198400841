import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Pie, measureTextWidth } from '@ant-design/plots'
import { Spin } from 'antd'
const PieChart = (props) => {
  const data = props?.data?.map((item) => {
    return {
      value: item.OwnedRate,
      type: item.OwnedContent,
    }
  })
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    color: ['#E4622A', '#FFCB78', '#FF9D60', '#FF77A9', '#C35EBA', '#167BD3', '#38AAF0', '#42CEF2', '#2AE4E3', '#2AE431', '#E42A6D', '#FF0000'],
  }
  return (
    <Spin spinning={!data ? true : false}>
      <div style={{ marginRight: '200px' }}>{data ? <Pie {...config} /> : null}</div>
    </Spin>
  )
}
export default PieChart
