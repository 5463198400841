const SmartContractCode = {
  SALE_CONTRACT: 'SALE_CONTRACT',
  UTILS_CONTRACT: 'UTILS_CONTRACT',
  TSPROJECT_CONTRACT: 'TSPROJECT_CONTRACT',
}
const controlType = {
  input: 0,
  inputNumber: 1,
  currency: 2,
  percent: 3,
  select: 4,
  checkbox: 5,
  datepicker: 6,
}

const valueType = {
  string: 0,
  int: 1,
  decimal: 2,
  boolean: 3,
  datetime: 4,
}

const statusProject = {
  DRAFF: 0,
  CREATED: 1,
  ACTIVE: 2,
}

const InvestmentStatus = {
  UNDEFINED: -1,
  APPLIED_WHITELIST: 0,
  WIN_WHITELIST: 1,
  NOT_WIN_WHITELIST: 2,
  REJECTED: 3,
  REFUNDED: 4,
  CLAIMABLE: 5,
}

export const InvestmentWhiteListStatus = {
  UNDEFINED: -1,
  APPLIED_WHITELIST: 0,
  WIN_WHITELIST: 1,
  NOT_WIN_WHITELIST: 2,
  POOL_INVESTOR_Whitelist_WaitConfirm: 6,
}
export const ClaimType = {
  VOTE_Claimable_Disburse: 1,
  VOTE_Claimable_Refunded: 2,
  VOTE_Claimable_SummaryFailed: 3,
  VOTE_Claimable_LeftoverToken: 4,
}

export const InvestmentDepositStatus = {
  UNDEFINED: -1,
  APPLIED_WHITELIST: 0,
  WIN_WHITELIST: 1,
  NOT_WIN_WHITELIST: 2,
}

const statusNames = {
  en_US: [
    'Applied whitelist',
    'Win whitelist',
    'Not win whitelist',
    'Rejected',
    'Refunded',
    'Claimable',
  ],
}

const GetInvestmentStatusName = (status, locale) => {
  let names = statusNames['en_US']
  if (statusNames[locale]) {
    names = statusNames[locale]
  }
  if (status < 0) {
    return 'All status'
  }
  try {
    return names[status]
  } catch {
    return names[0]
  }
}
const getStatusPool = (status) => {
  if (status == 0) {
    return 'CREATE'
  } else if (status == 1) {
    return 'WAITING'
  } else {
    return 'APPROVE'
  }
}

export const getPoolStatusBg = (status) => {
  let bg = ''
  if (typeof status === 'string') {
    switch (status) {
      case 'Create':
        bg = 'bg-blue'
        break
      case 'Waiting':
        bg = 'bg-gold'
        break
      case 'Approve':
        bg = 'bg-green'
        break
      default:
        bg = 'bg-pink'
        break
    }
  } else {
    switch (status) {
      case 0:
        bg = 'bg-blue'
        break
      case 1:
        bg = 'bg-gold'
        break
      case 2:
        bg = 'bg-green'
        break
      default:
        bg = 'bg-pink'
        break
    }
  }
  return bg
}

const getBgByStatus = (status) => {
  let bg
  switch (status) {
    case 'Up':
      bg = 'bg-blue'
      break
    case 'Live':
      bg = 'bg-green'
      break
    case 'End':
      bg = 'bg-pink'
      break
    default:
      bg = 'bg-blue'
  }
  return bg
}
const formatNumber = (number) => {
  return Intl.NumberFormat('en-US').format(number)
}

const usdFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 10,
})

const getBgStatusMyProject = (status) => {
  let bg
  switch (status) {
    case 0:
      bg = 'bg-blue'
      break
    case 1:
      bg = 'bg-gold'
      break
    case 2:
      bg = 'bg-green'
      break
    default:
      bg = 'bg-blue'
  }
  return bg
}

const getTypeVc = (key) => {
  if (key === 'vcfirm') {
    return 'Vc firm'
  } else if (key === 'corporatevc') {
    return 'Corporate VC'
  } else if (key === 'pefirm') {
    return 'PE firm'
  } else if (key === 'accelerator') {
    return 'accelerator'
  }
}

const ListChain = [
  {
    id: 'chain-bsc',
    name: 'Binance Smart Chain',
    chainId: '0x61',
    chain: '97',
    icon: 'images/chain-bsc.png',
    value: 'option1',
  },
  {
    id: 'chain-okex',
    name: 'Okex Chain',
    chainId: '0x41',
    chain: '65',
    icon: 'images/chain-okex.png',
    value: 'option2',
  },
]
export {
  getBgStatusMyProject,
  getBgByStatus,
  controlType,
  valueType,
  statusProject,
  GetInvestmentStatusName,
  InvestmentStatus,
  getStatusPool,
  formatNumber,
  usdFormatter,
  getTypeVc,
  ListChain,
  SmartContractCode
}
