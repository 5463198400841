import React, { useState, useEffect, useRef } from 'react'
import { Spin } from 'antd'

export default function ScrollInfiniteOld(props) {
  const [loading, setLoading] = useState(false)
  const [datas, setData] = useState([])
  const [page, setPage] = useState(0)
  const loadCompleted = (data) => {
    if (data && data.length > 0) setData((datas) => [...datas, ...data])
    setLoading(false)
  }
  useEffect(() => {
    console.log('=====')
    setPage(0)
    setData([])
    setLoading(false)
  }, [props.isReset, props.status])
  useEffect(() => {
    const list = document.getElementById(props.containerId)
    if (list) {
      list.addEventListener('scroll', (e) => {
        const el = e.target
        if (el.scrollTop + el.clientHeight === el.scrollHeight) {
          setLoading(true)
          setPage((page) => page + 1)
        }
      })
    }
  }, [])
  useEffect(() => {
    const list = document.getElementById(props.containerId)
    if (list)
      if (list.clientHeight === list.scrollHeight) {
        setLoading(true)
        setPage((page) => page + 1)
      }
  }, [datas])

  return (
    <>
      {React.cloneElement(props.children, { page, loadCompleted })}
      <Spin spinning={loading} size="large">
        <div className="loading-box"></div>
      </Spin>
    </>
  )
}

/**
 *
 * @param {*} props containerId, dataKey, setExternalData, setTotalCount
 * @returns
 */
export function ScrollInfinite(props) {
  const [loading, setLoading] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [page, setPage] = useState(1)
  const pageFullRef = useRef(true)
  const [loadPageRequest, setLoadPageRequest] = useState(0)

  const loadCompleted = (data, isPageFull) => {
    if (data && data.length > 0) {
      pageFullRef.current = isPageFull
      setCurrentData((oldData) => {
        if (props.dataKey) {
          // try deduplicate if dataKey specified
          const dupIndex = oldData.findIndex((val) => {
            return val[props.dataKey] === data[0][props.dataKey]
          })
          if (dupIndex >= 0) {
            return [...oldData.slice(0, dupIndex), ...data]
          }
        }
        // simple appending
        return [...oldData, ...data]
      })
    }
  }

  const resetData = () => {
    setPage(1)
    setCurrentData([])
    setLoading(false)
  }

  useEffect(() => {
    const list = document.getElementById(props.containerId)
    if (list) {
      list.addEventListener('scroll', (e) => {
        const el = e.target
        if (el.scrollTop + el.clientHeight === el.scrollHeight) {
          if (pageFullRef.current) {
            // load next page
            setPage((val) => val + 1)
          } else {
            // reload current page
            setLoadPageRequest((val) => val + 1)
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const list = document.getElementById(props.containerId)
      if (list)
        if (list.clientHeight === list.scrollHeight) {
          // load next page until scroll bar appear
          setPage((val) => val + 1)
        }

      if (props.setExternalData) {
        // Update new data for parent component
        props.setExternalData(currentData)
      }
    }, 1000)
  }, [currentData])

  return (
    <>
      {React.cloneElement(props.children, {
        currentData,
        page,
        setLoading,
        loadPageRequest,
        loadCompleted,
        resetData,
        setTotalCount: props.setTotalCount,
      })}
      <Spin spinning={loading} size="large">
        <div className="loading-box"></div>
      </Spin>
    </>
  )
}
