import { EditOutlined, DeleteOutlined, SaveOutlined, CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Space, Table, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { controlType } from 'utils/constants'
import { PlusOutlined } from '@ant-design/icons';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    rules,
    ...restProps
}) => {
    var inputNode = <Input />;
    switch (inputType) {
        case controlType.inputNumber:
            inputNode = <InputNumber />;
            break;
        case controlType.checkbox:
            inputNode = <Checkbox />;
            break;
        case controlType.datepicker:
            inputNode = <DatePicker />;
            break;
        default:
            inputNode = <Input />;
            break;
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={rules}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const TableEditable = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState(props?.data);
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => {
        return (record[props.primaryKey] === editingKey);
    }

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record[props.primaryKey]);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const addNew = () => {
        if (props.addNew)
            props.addNew(setData, data, setEditingKey, form)

    }
    const handleDelete = (key) => {
        const newData = data.filter((item) => item[props.primaryKey] !== key);
        const currentData = newData.map((item, index) => {
            return {
                key: item.key,
                ID: item.key,
                GoalName: item.GoalName,
                Order: index + 1,
                editable: item.editable,
            }
        })
        setData(currentData)
        if (props.deleteItem)
            props.deleteItem(newData);
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item[props.primaryKey]);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
            if (props.onSaveChange)
                props.onSaveChange(newData, key);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const mergedColumns = props?.columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                rules: col.rules,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    mergedColumns.push(
        {
            title: t('tableEdit.operationTitle'),
            dataIndex: 'operation',
            width: 120,
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button type='link' title={t('button.save')}
                            onClick={() => save(record[props.primaryKey])} icon={<SaveOutlined />}>{t('button.save')}</Button>
                        <Button type='link' title={t('button.cancel')}
                            onClick={cancel} icon={<CloseCircleOutlined style={{ color: "red" }} />}>{t('button.cancel')}</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button type='link' disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />}>{t('button.edit')}</Button>
                        <Button type='link' disabled={editingKey !== ''} onClick={() => handleDelete(record[props.primaryKey])} icon={<DeleteOutlined style={editingKey !== '' ? null : { color: "red" }} />}>{t('button.delete')}</Button>
                    </Space>
                );
            },
        }
    )
    return (
        <>
            {
                (data && data.length > 0) ? <><Form form={form} component={false}>

                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
                    <button type="button" className="btn-submit" style={{ display: 'flex', alignItems: 'center', width: 160,marginTop:10 }} onClick={addNew}><PlusOutlined style={{ color: 'white', fontSize: 20, paddingRight: 5 }} />{props.addText ? props.addText : t('button.addNew')}</button>
                </>
                :<button type="button" className="btn-submit" style={{ display: 'flex', alignItems: 'center', width: 160,marginTop:10 }} onClick={addNew}><PlusOutlined style={{ color: 'white', fontSize: 20, paddingRight: 5 }} />{props.addText ? props.addText : t('button.addNew')}</button>
                    // : <div onClick={addNew} className='addnew-item-table'>
                    //     <Space><PlusCircleOutlined /><span>{props.addText ? props.addText : t('button.addNew')}</span></Space>
                    // </div>
            }

        </>
    );
};

export default TableEditable;