import { Spin } from 'antd'
import { useEffect } from 'react'
import { useState } from 'react'
import API from '../../apis/apiProject'
import SimilarBuidlProjectPubItem from './SimilarBuidlProjectPubItem'
const SminilarBuidl = () => {
  const [listBuidl, setListBuidl] = useState([])
  const [page, setPage] = useState(1)
  const [isLoadPage, setIsLoadPage] = useState(true)
  const [loading, setLoading] = useState(false)
  const getBuidl = async () => {
    setLoading(true)
    let body = {
      status: 2,
      isSmartID: true,
      pageIndex: page,
      pageSize: 3,
    }
    const projects = await API.getListProjectPublic(body)
    if (projects.Data.length > 0) {
      setListBuidl(projects.Data)
    } else {
      setIsLoadPage(false)
    }
    setLoading(false)
  }
  const add = () => {
    if (isLoadPage) {
      setPage(page + 1)
    }
  }
  const remove = () => {
    setIsLoadPage(true)
    if (page > 1) {
      setPage(page - 1)
    }
  }
  useEffect(() => {
    getBuidl()
  }, [page, isLoadPage])
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="d-flex align-items-center justify-content-space-between">
            <h5>Similar Buidls</h5>
            <div className="group-svg d-flex align-items-center justify-content-space-between">
              <div onClick={remove} className="mx-2 cursor-pointer">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.1937 16.8795C33.1937 25.9266 25.874 33.259 16.8468 33.259C7.81964 33.259 0.499995 25.9266 0.499997 16.8795C0.499999 7.83244 7.81965 0.500001 16.8468 0.500003C25.8741 0.500005 33.1937 7.83244 33.1937 16.8795Z"
                    stroke="white"
                  />
                  <path
                    d="M18.7853 23.7365C18.6547 23.737 18.5257 23.7064 18.4077 23.6468C18.2897 23.5873 18.1858 23.5004 18.1035 23.3926L13.8817 17.8149C13.7531 17.6485 13.6829 17.4399 13.6829 17.2246C13.6829 17.0092 13.7531 16.8006 13.8817 16.6342L18.2521 11.0565C18.4004 10.8667 18.6136 10.7473 18.8448 10.7246C19.0759 10.702 19.306 10.7779 19.4845 10.9357C19.663 11.0935 19.7753 11.3202 19.7966 11.566C19.8179 11.8118 19.7465 12.0566 19.5982 12.2464L15.691 17.2292L19.467 22.212C19.5739 22.3484 19.6418 22.5146 19.6627 22.6908C19.6836 22.867 19.6566 23.0459 19.5848 23.2063C19.5131 23.3666 19.3997 23.5018 19.258 23.5958C19.1163 23.6898 18.9522 23.7386 18.7853 23.7365Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div onClick={add} className="mx-2 cursor-pointer">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.806306 16.8793C0.806307 7.83222 8.12595 0.499784 17.1532 0.499785C26.1804 0.499786 33.5 7.83223 33.5 16.8793C33.5 25.9264 26.1804 33.2588 17.1532 33.2588C8.12595 33.2588 0.806306 25.9263 0.806306 16.8793Z"
                    stroke="white"
                  />
                  <path
                    d="M15.2147 10.7234C15.3453 10.7229 15.4743 10.7536 15.5923 10.8131C15.7103 10.8726 15.8142 10.9595 15.8965 11.0674L20.1183 16.6451C20.2469 16.8114 20.3171 17.0201 20.3171 17.2354C20.3171 17.4507 20.2469 17.6594 20.1183 17.8257L15.7479 23.4034C15.5996 23.5933 15.3864 23.7127 15.1552 23.7353C14.9241 23.758 14.694 23.6821 14.5155 23.5243C14.337 23.3665 14.2247 23.1397 14.2034 22.8939C14.1821 22.6481 14.2535 22.4034 14.4018 22.2135L18.309 17.2308L14.533 12.248C14.4261 12.1115 14.3582 11.9454 14.3373 11.7692C14.3164 11.593 14.3434 11.4141 14.4152 11.2537C14.4869 11.0933 14.6003 10.9581 14.742 10.8642C14.8837 10.7702 15.0478 10.7213 15.2147 10.7234Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          <hr />
          <Spin spinning={loading}>
            <div className="d-flex align-items-center justify-content-between">
              {listBuidl.map((item, index) => (
                <div className='col-12 col-sm-4'>
                  <SimilarBuidlProjectPubItem key={index} index={index} data={item} />
                </div>
              ))}
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default SminilarBuidl
