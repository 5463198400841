const BaseSteps = (props) => {
  const { currentStep, contentSteps } = props

  return (
    <>

      <div className="title_main row d-flex justify-content-center" style={props.style?props.style:null}>
        <div className="col-12 col-sm-12">
          <ul className="title-step">
            {contentSteps?.map((item, index) => {
              return (
                <li className={currentStep === index ? 'active' : currentStep > index ? 'checked' : ''}>
                  <span>{item?.title}</span>
                  <p>{item?.contentText}</p>
                </li>
              )
            })}
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  )
}
export default BaseSteps
