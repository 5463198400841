import { useEffect, useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Col, Row, Modal, Spin, Statistic } from 'antd'
import { useTranslation } from 'react-i18next'
import iconBack from 'assets/images/arrow-back.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import API from 'apis/apiProject'
import { getPools } from 'apis/apipool'
import fan1 from 'assets/images/fan-01.png'
import fan2 from 'assets/images/fan-02.png'
import fan3 from 'assets/images/fan-03.png'
import network1 from 'assets/images/network-icon01.png'
import network2 from 'assets/images/network-icon02.png'
import network8 from 'assets/images/network-08.jpg'
import network03 from 'assets/images/network-03.jpg'
import network02 from 'assets/images/network-02.jpg'
import network01 from 'assets/images/network-01.jpg'
import avatar from 'assets/images/avatar-team-member.png'
import iconTwitter from 'assets/images/social/s-twitter.svg'
import iconLinkedin from 'assets/images/social/s-linkedin.svg'
import iconTelegram from 'assets/images/social/s-telegram.svg'
import iconYoutube from 'assets/images/social/s-youtube.svg'
import iconDiscord from 'assets/images/social/s-discord.png'
import iconReddit from 'assets/images/social/s-reddit.png'
import iconMedium from 'assets/images/social/s-medium.png'
import iconGithub from 'assets/images/social/s-github.png'
import iconLindin from 'assets/images/lindin.png'
import coin01 from 'assets/images/coin-01.png'
import { formatNumber, getBgByStatus } from 'utils/constants'
import moment from 'moment'
import PieChart from './piechart'
import SminilarBuidl from './sminilar-buidl'
import { useSelector } from 'react-redux'
import { chainSelector } from 'redux/reducers/networkSlice'
import { getIconChain } from 'utils/common'
const { Countdown } = Statistic
const shiftTopStyle = { position: 'relative', top: -64 }

const ProjectDetail = () => {
  const { listChain } = useSelector(chainSelector)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [projectDetail, setProjectDetail] = useState('')
  const [listPools, setListPools] = useState([])
  const paramUrl = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (paramUrl.id) {
      ;(async () => {
        const result = await API.getProjectDetailPublic(paramUrl.id)
        let body = {
          projectID: paramUrl.id,
          isSmartID: true,
          pageIndex: 1,
          pageSize: 1000,
        }
        const projects = await API.getListPoolPub(body)
        console.log('result.Data', result.Data)
        setListPools(projects.Data)
        setProjectDetail(result.Data)
      })()
    }
  }, [paramUrl.id])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [paramUrl])

  return (
    <>
      <div className="content-width">
        <div className="title_main">
          <h2>{`Preview Buidl - ${projectDetail?.Step01?.ProjectName}`}</h2>
        </div>
        <div className="box-detail-main">
          <div className="back-link">
            <a onClick={() => navigate('/buidls')}>
              <img src={iconBack} alt="" /> Back
            </a>
          </div>
          <section className="box-bg">
            <div className="row">
              <div className="col-12 col-sm-3">
                <div className="avatar">
                  <img className="img-fluid" src={projectDetail?.Step01?.Avatar} alt="" />
                </div>
              </div>
              <div className="col-12 col-sm-9">
                <div className="content-box">
                  <div className="title-box">
                    <div className="name-box">
                      <h3>{projectDetail?.Step01?.ProjectName}</h3>
                      <div className="group_tags mt-3">
                        {projectDetail?.Step01?.Hastag?.split(',').map((value, key) => {
                          return <span key={key}>{value}</span>
                        })}
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="box-title-key">
                    <h3>Key Metrics</h3>
                    <hr />
                  </div>
                  <ul className="metric">
                    <li>
                      Ticker <span>{projectDetail?.Step04?.Ticker}</span>
                    </li>
                    <li>
                      Token supply{' '}
                      <span className="over-flow">
                        {formatNumber(projectDetail?.Step04?.TotalSupply)}
                      </span>
                    </li>
                    <li>
                      Allocation{' '}
                      <span className="over-flow">
                        {formatNumber(projectDetail?.Step04?.IndividualAllowcation)}
                      </span>
                    </li>
                    <li>
                      Evaluation{' '}
                      <span className="over-flow">
                        {formatNumber(projectDetail?.Step04?.Evaluation)}
                      </span>
                    </li>
                    <li>
                      Token offer{' '}
                      <span className="over-flow">
                        {formatNumber(projectDetail?.Step04?.TokenOffer)}
                      </span>
                    </li>
                    <li>
                      Public raise{' '}
                      <span className="over-flow">
                        {formatNumber(projectDetail?.Step04?.PublicTotalRaise)}
                      </span>
                    </li>
                  </ul>
                  <div className="box-title-key">
                    <h3>Follow Us</h3>
                    <hr />
                  </div>
                  <div>
                    {projectDetail?.Step01?.Website ? (
                      <a
                        href={projectDetail?.Step01?.Website}
                        className="px-3 text-decoration text-center"
                        target="_blank"
                      >
                        <img src={fan1} className="mb-1 img-icon-similar" alt="" /> <span className='px-2'>Website</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Telegram ? (
                      <a href={projectDetail?.Step01?.Telegram} className="px-3 text-decoration" target="_blank">
                        <img src={fan2} alt="" className="mb-1 img-icon-similar"  /> <span className='px-2'>Telegram</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Discord ? (
                      <a href={projectDetail?.Step01?.Discord} className="px-3 text-decoration" target="_blank">
                        <img src={iconDiscord} className="mb-1 img-icon-similar"  alt="" />
                        <span className='px-2'>Discord</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Linkedlin ? (
                      <a href={projectDetail?.Step01?.Linkedlin} className="px-3 text-decoration" target="_blank">
                        <img src={iconLinkedin} className="mb-1 img-icon-similar" alt="" />
                        <span className='px-2'>LinkedIn</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Youtube ? (
                      <a href={projectDetail?.Step01?.Youtube}  className="px-3 text-decoration" target="_blank">
                        <img src={iconYoutube} className="mb-1 img-icon-similar" alt="" />
                        <span className='px-2'>Youtube</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Reddit ? (
                      <a href={projectDetail?.Step01?.Reddit} className="px-3 text-decoration" target="_blank">
                        <img src={iconReddit} className="mb-1 img-icon-similar" alt="" />
                        <span className='px-2'>Reddit</span>
                      </a>
                    ) : null}
                    {projectDetail?.Step01?.Twitter ? (
                      <a href={projectDetail?.Step01?.Twitter} className="px-3 text-decoration" target="_blank">
                        <img src={iconTwitter} className="mb-1 img-icon-similar" alt="" />
                        <span className='px-2'>Twitter</span>
                      </a>
                    ) : null}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="list-network">
              {/* <img src={network1} alt="" /> */}
              <img src={getIconChain(projectDetail?.Step01?.Chain) || network2} alt="" />
            </div>
          </section>
        </div>
        <div className="row space_section">
          {listPools?.map((value, key) => {
            return (
              <div key={key} className="col-12 col-sm-3">
                <article className="item-card">
                  <img className="img-fluid" src={value.PoolAvatar} alt="" />
                  <h4>
                    {value?.NameInvestment} <img src={coin01} alt="" />
                  </h4>
                  <hr />
                  <ul>
                    <li>
                      Total Fund Raise:{' '}
                      <span className="text-ocean">{formatNumber(value?.TotalMoneyRaise)} USDT</span>
                    </li>
                    <li>
                      {value?.StartTime > moment().unix() ? (
                        <>
                          {t('createPool.poolStartIn')}
                          <span className="text-red">
                            <Countdown
                              value={moment.utc(value?.StartTime, 'X')}
                              format={'D [Days] HH : mm : ss'}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          {t('createPool.poolEndIn')}
                          <span className="text-red">
                            <Countdown
                              value={moment.utc(value?.EndTime, 'X')}
                              format={'D [Days] HH : mm : ss'}
                            />
                          </span>
                        </>
                      )}
                    </li>
                  </ul>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${value?.RateRaise || 0}%` }}
                      aria-valuenow={value?.RateRaise || 0}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {value?.RateRaise}%
                    </div>
                  </div>
                  <p className="current">{formatNumber(value?.TotalFundRaise)} USDT</p>
                  <div className="link-a">
                    <Link to={`/pool/${value?.PoolID}`}>Join In</Link>
                  </div>
                  <span className={`status-item ${getBgByStatus(value?.Status)}`}>{value?.Status}</span>
                </article>
              </div>
            )
          })}
        </div>
        <div className="nav nav-tabs tab-detail" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-overview-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-overview"
            type="button"
            role="tab"
            aria-controls="nav-overview"
            aria-selected="true"
          >
            Overview
          </button>
          <button
            className="nav-link"
            id="nav-team-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-team"
            type="button"
            role="tab"
            aria-controls="nav-team"
            aria-selected="false"
          >
            Team Member
          </button>
          <button
            className="nav-link"
            id="nav-metrics-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-metrics"
            type="button"
            role="tab"
            aria-controls="nav-metrics"
            aria-selected="false"
          >
            Metrics
          </button>
        </div>

        <div id="spy_main" className="tab-content">
          <div
            className="tab-pane fade show active"
            id="nav-overview"
            role="tabpanel"
            aria-labelledby="nav-overview-tab"
          >
            <div className="row">
              <div className="col-4">
                <div id="list-spy" className="list-group">
                  <a className="list-group-item list-group-item-action" href="#list-item-1">
                    What is this buidls?
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-2">
                    How to use?
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-3">
                    Advantage Competitive
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-4">
                    Roadmap
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-5">
                    Technology
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-6">
                    Partner
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-7">
                    Marketing Strategy
                  </a>
                  <a className="list-group-item list-group-item-action" href="#list-item-8">
                    Token Economy
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div
                  data-bs-spy="scroll"
                  data-bs-target="#list-spy"
                  data-bs-offset="0"
                  className="scrollspy-main"
                  tabIndex="0"
                >
                  <span id="list-item-1" style={shiftTopStyle} />
                  <h4>What is this buidls?</h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.WhatIsThisProject }}
                  />
                  <span id="list-item-2" style={shiftTopStyle} />
                  <h4>How to use?</h4>
                  <p dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.HowToUse }} />
                  <span id="list-item-3" style={shiftTopStyle} />
                  <h4>Advantage Competitive</h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.AdvantageCompetive }}
                  />
                  <span id="list-item-4" style={shiftTopStyle} />
                  <h4>Roadmap</h4>
                  <p dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.Roadmap }} />
                  <span id="list-item-5" style={shiftTopStyle} />
                  <h4>Technology</h4>
                  <p dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.Technology }} />
                  <span id="list-item-6" style={shiftTopStyle} />
                  <h4>Partner</h4>
                  <p dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.Partner }} />
                  <span id="list-item-7" style={shiftTopStyle} />
                  <h4>Marketing Strategy</h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.MarketingStrategy }}
                  />
                  <span id="list-item-8" style={shiftTopStyle} />
                  <h4>Token Economy</h4>
                  <p dangerouslySetInnerHTML={{ __html: projectDetail?.Step03?.TokenEconomy }} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade pb-5"
            id="nav-team"
            role="tabpanel"
            aria-labelledby="nav-team-tab"
          >
            <div className="row">
              <div className="col-4">
                <div id="list-spy" className="list-group">
                  <a className="list-group-item list-group-item-action" href="#list-item-1">
                    Who is the team of {projectDetail?.Step01?.ProjectName}
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div
                  data-bs-spy="scroll"
                  data-bs-target="#list-spy"
                  data-bs-offset="0"
                  className="scrollspy-main"
                  tabIndex="0"
                >
                  <h4 id="list-item-1">Who is the team of {projectDetail?.Step01?.ProjectName} </h4>
                  <div className="col-12 bg-team-member">
                    {projectDetail?.Step02?.Information.map((item) => {
                      return (
                        <div className="item-member">
                          <div className="item-avatar">
                            <img
                              style={{ height: '100px' }}
                              src={item.ImageUrl ? item.ImageUrl : avatar}
                              alt=""
                            />
                            <a
                              className="linkedin-member"
                              href={item.Linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img style={{ height: '30px' }} src={iconLindin} alt="" />
                            </a>
                          </div>
                          <div
                            className="col-12 pt-5  d-flex align-items-center justify-content-center"
                            style={{
                              color: '#BCC3CF',
                              fontSize: '20px',
                              fontWeight: '600',
                            }}
                          >
                            {item.Name}
                          </div>
                          <div
                            className="col-12  d-flex align-items-center justify-content-center "
                            style={{
                              color: '#A8B1FF',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}
                          >
                            {item.MembershipFunction}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="col-8"></div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-metrics"
            role="tabpanel"
            aria-labelledby="nav-metrics-tab"
          >
            <div className="content-metrics col-12 mb-5">
              <div className="title-metrics col-12  d-flex align-items-center justify-content-center">
                Documented token distribution
              </div>
              <div className="col-12  d-flex align-items-center justify-content-center">
                Data provided by project
              </div>
              <div className="col-12 tokenomics">
                <PieChart data={projectDetail?.Step04?.ListOwned} />
              </div>
              <div className="title-metrics col-12 mt-5 mb-3 d-flex align-items-center justify-content-center">
                Sales round details
              </div>
              <div className="col-12 table-team-member">
                <div className="table-responsive">
                  <table className="table table-striped table-dark">
                    <thead>
                      <tr style={{ color: '#676D7C' }}>
                        <th style={{ borderTopLeftRadius: '10px' }} scope="col"></th>
                        <th scope="col">Price ($)</th>
                        <th scope="col">Fundraised ($)</th>
                        <th style={{ borderTopRightRadius: '10px' }} scope="col">
                          Lock-up
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectDetail?.Step05?.Information.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{item.NameOfRound}</th>

                            <td>{formatNumber(item.Price)}</td>
                            <td>{formatNumber(item.Fundraised)}</td>
                            <td>{item.LockUp}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="similar-buidls">
          <SminilarBuidl />
        </div>
      </div>
    </>
  )
}
export default ProjectDetail
