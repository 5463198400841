import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MyInvestmentList } from 'components/for-investor/investment-list/MyInvestmentList'

export const MyInvestmentPage = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  return (
    <div className="bg-frame">
      <div className="title-show">
        <h5>Investment list</h5>
        <hr />
        <p>Here are all pools that you have participated in.</p>
      </div>
      <MyInvestmentList />
    </div>
  )
}
