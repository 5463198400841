import { memo } from 'react'
import ProjectPubItem from './ProjectPubItem'
function ProjectPublicList(props) {
  return (
    <div id="list-build">
        {
          props?.data?.length > 0 && (
            props?.data?.map(item => (
              <ProjectPubItem data={item} />
            ))
          )
        }
    </div>
  )
}
export default memo(ProjectPublicList)
