import { customAxios } from 'utils/customAxios'

const baseURLProfile = process.env.REACT_APP_API_URL + '/admin'

export const handleResponse = (res) => {
  const data = res?.data?.data
  return data
}

export const handleError = (err) => {
  console.error(err)
  throw err
}

export default {
  /// API Upload image
  chainExist: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProfile}/profile-wallets/pub/exist`,
      data: data,
    })
    return res.data
  },
  createNewChainForWallet: async (data) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProfile}/profile-wallets/pub/create`,
      data: data,
    })
    return res.data
  },
  getListChain: async () => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProfile}/chains/pub/get-list`,
    })
    return res.data
  },
  getListContract: async (body) => {
    const res = await customAxios({
      method: 'post',
      url: `${baseURLProfile}/internal-smart-contracts/pub/filter`,
      data: body,
    })
    return res.data
  },

  getListCurrency: async (body) => {
    const res = await customAxios({
      method: 'get',
      url: `${baseURLProfile}/currencies/pub/get-list`,
    })
    return res.data
  },
}
